import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UnitQuantity, UnitQuantityControl } from 'src/app/form-controls/unit-quantity/unit-quantity.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { OxygenAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface OxygenActionFormGroup {
	percentageGiven: FormControl<UnitQuantity | null>;
}

@Component({
	selector: 'app-oxygen-action-controls',
	templateUrl: './oxygen-action-controls.component.html',
	styleUrls: ['../../../common.scss', './oxygen-action-controls.component.scss'],
	standalone: true,
	imports: [CommonActionControlsComponent, ActionFormComponent,UnitQuantityControl, ReactiveFormsModule],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OxygenActionControlsComponent implements SheetActionControlsBase {

	slot!: Slot | null;
	action!: OxygenAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as OxygenAction;
	}

	@Input() form!: FormGroup<OxygenActionFormGroup>;
	@Input() item!: Row | null;
	
	constructor(private formBuilder: FormBuilder) {
	}

  	ngOnInit(): void {
      	this.form.addControl('percentageGiven', this.formBuilder.control(this.action?.percentageGiven ?? null));
  	}
}
