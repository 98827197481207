import { AfterViewInit, ComponentRef, Directive, ElementRef, Input, OnChanges, Renderer2, ViewContainerRef } from '@angular/core';
import { IonRippleEffect } from '@ionic/angular/standalone';
import { CoercionService } from '../services/coercion.service';

@Directive({
	selector: '[appNavigable]',
	standalone: true
})
export class NavigableDirective implements AfterViewInit, OnChanges {

	private isNavigable: boolean = false;

	@Input('appNavigable')
	get navigable() {
		return this.isNavigable;
	}
	set navigable(value: any) {
		this.isNavigable = this.coercionService.coerceToBoolean(value);
	}

	private componentRef: ComponentRef<IonRippleEffect> | undefined;

	constructor(private coercionService: CoercionService, private viewContainerRef: ViewContainerRef, private renderer: Renderer2, private elementRef: ElementRef) {
	}

	ngAfterViewInit(): void {
		// this puts the component next to the element where the directive is applied
		this.componentRef = this.viewContainerRef.createComponent(IonRippleEffect);
		// this moves the component inside the element where the directive is applied
		this.renderer.appendChild(this.viewContainerRef.element.nativeElement, this.componentRef!.location.nativeElement);
	}

	ngOnChanges(): void {
		if (this.isNavigable) {
			this.renderer.addClass(this.elementRef.nativeElement, 'navigable-element');
			this.renderer.setStyle(this.elementRef.nativeElement, 'position', 'relative');
			this.renderer.addClass(this.elementRef.nativeElement, 'ion-activatable');
		} else {
			this.renderer.removeClass(this.elementRef.nativeElement, 'navigable-element');
			setTimeout(() => {
				this.renderer.removeStyle(this.elementRef.nativeElement, 'position');
				this.renderer.removeClass(this.elementRef.nativeElement, 'ion-activatable');
			}, 500);
		}
	}
}