import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { isToday, isYesterday, parseISO } from 'date-fns';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, delay, expand, filter, map, pairwise, switchMap, take } from 'rxjs/operators';
import { routeParams } from 'src/app/app.routes';
import { ErrorService } from 'src/app/services/error.service';
import { GraphAggregateChangeOperation, ServiceTask, ServiceTaskTypeName } from 'src/app/types/aggregate-graph.types';
import { connectHubCompleted } from '../aggregate-graph-hub/aggregate-graph-hub.actions';
import { accessToken } from '../auth/auth.selectors';
import { clientErrored } from '../error/error.actions';
import { AppState } from '../reducers';
import { selectRouteParam } from '../router/router.selectors';
import * as serviceGraphActions from './service-graph.actions';
import { serviceId, subscribedDate } from './service-graph.selectors';

const midnightCheckDelay = 10000;

@Injectable()
export class ServiceGraphEffects {

	constructor(private actions$: Actions, private store: Store<AppState>, private errorService: ErrorService) {
	}

	serviceIdParamChanged$ = createEffect(() =>
		this.store.select(selectRouteParam(routeParams.serviceId)).pipe(
			concatLatestFrom(() => [
				this.store.select(accessToken),
				this.store.select(serviceId)
			]),
			filter(([, accessToken]) => !!accessToken),
			switchMap(([serviceId, , previousServiceId]) => {
				const actions: any[] = [];
				if (previousServiceId) {
					actions.push(serviceGraphActions.unsubscribeServiceRequested());
				}
				if (serviceId) {
					actions.push(serviceGraphActions.subscribeServiceRequested({ serviceId: serviceId }));
				}
				return actions;
			})
		)
	);

	serviceGraphGenerated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(serviceGraphActions.serviceGraphGenerated),
			concatLatestFrom(() => this.store.select(serviceId)),
			filter(([action, serviceId]) => action.serviceId === serviceId),
			map(([action]) =>
				serviceGraphActions.serviceGraphLoaded({
					serviceTasks: action.aggregates.filter(agg => agg.aggregateType === ServiceTaskTypeName) as ServiceTask[]
				})
			),
			catchError(error => of(clientErrored({ toastMessage: 'Service graph generation failed', errorMessage: this.errorService.getErrorMessage(error) })))
		)
	);

	serviceGraphChanged$ = createEffect(() =>
		this.actions$.pipe(
			ofType(serviceGraphActions.serviceGraphChanged),
			concatLatestFrom(() => this.store.select(serviceId)),
			filter(([action, serviceId]) => action.serviceId === serviceId),
			map(([action,]) => action.changes),
			concatMap(changes => {
				const actions: any[] = [];
				changes.forEach(change => {
					// guard against b/e not sending the operation
					if (!change.operation) throw new Error('Error processing serviceGraphChanged action: operation is missing');

					if (change.aggregate.aggregateType === ServiceTaskTypeName) {
						if (change.operation === GraphAggregateChangeOperation.Update) {
							actions.push(serviceGraphActions.serviceTaskUpdated({ partialServiceTask: change.aggregate as ServiceTask }));
						}
						else if (change.operation === GraphAggregateChangeOperation.Addition) {
							actions.push(serviceGraphActions.serviceTaskAdded({ serviceTask: change.aggregate as ServiceTask }));
						}
					}
				});
				return actions;
			}),
			catchError(error => of(clientErrored({ toastMessage: 'Service graph change failed', errorMessage: this.errorService.getErrorMessage(error) })))
		)
	);

	resubscribeServiceInitiated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(connectHubCompleted, serviceGraphActions.serviceGraphSystemRestarted, serviceGraphActions.reloadServiceRequested),
			concatLatestFrom(() => this.store.select(serviceId)),
			filter(([, serviceId]) => !!serviceId),
			map(([, serviceId]) => serviceGraphActions.subscribeServiceRequested({ serviceId: serviceId }))
		)
	);

	resubscribeAfterMidnightIfStillSubscribed$ = createEffect(() =>
		this.store.select(subscribedDate).pipe(
			// remember the old and new subscribed dates
			pairwise(),
			// only care about subscribes, not unsubscribes. Also, only care if the subscribed date has changed (unlikely, but possible that reconnects happen within the same second)
			filter(([previousDate, currentDate]) => !!currentDate && previousDate !== currentDate),
			// only need current date
			map(([, currentDate]) => currentDate),
			// delay
			delay(midnightCheckDelay),
			// expand will recurse until EMPTY is returned (more precisely, until the observable that is fed back into expand completes, which EMPTY does, it completes immediately)
			expand(previousDate => this.store.select(subscribedDate).pipe(
				take(1),
				// recursion should happen whilst the subscribed date has not changed (if it has, it will be picked up by this effect when it fires again), and the subscribed date is still the current day
				switchMap(currentDate => {
					if (currentDate === previousDate && isToday(parseISO(currentDate))) {
						return of(currentDate).pipe(delay(midnightCheckDelay));
					}
					return EMPTY;
				})
			)),
			// if the date has changed, resubscribe
			filter(subscribedDate => !!subscribedDate && isYesterday(parseISO(subscribedDate))),
			map(() => serviceGraphActions.reloadServiceRequested())
		)
	);
}