import { createAction, props } from '@ngrx/store';
import { ServiceTask } from 'src/app/types/aggregate-graph.types';
import { ServiceTaskTab } from 'src/app/types/service-task.types';

export enum ServiceTaskActionTypes {
	ServiceTasksLoaded = '[Service Graph] Service Tasks Loaded',
	InboxServiceTaskAdded = '[Service Graph] Inbox Service Task Added',
	InboxServiceTaskUpdated = '[Service Graph] Inbox Service Tasks Updated',
	InboxServiceTaskRemoved = '[Service Graph] Inbox Service Tasks Removed',
	InboxSortToggled = '[Component] Inbox Sort Toggled',
	ScheduledServiceTaskAdded = '[Service Graph] Scheduled Service Task Added',
	ScheduledServiceTaskUpdated = '[Service Graph] Scheduled Service Tasks Updated',
	ScheduledServiceTaskRemoved = '[Service Graph] Scheduled Service Tasks Removed',
	ScheduledSortToggled = '[Component] Scheduled Sort Toggled',
	ServiceTaskTabChanged = '[Component] Service Task Tab Changed',
	InboxDateFilterChanged = '[Component] Inbox Date Filter Changed',
	ScheduledDateFilterChanged = '[Component] Scheduled Date Filter Changed',
	RemoveServiceTaskRequested = '[Component] Remove Service Task Requested',
	RemoveServiceTaskCompleted = '[Effect] Remove Service Task Completed'
}

export const serviceTasksLoaded = createAction(
	ServiceTaskActionTypes.ServiceTasksLoaded,
	props<{ inboxServiceTasks: ServiceTask[], scheduledServiceTasks: ServiceTask[] }>()
)

export const inboxServiceTaskAdded = createAction(
	ServiceTaskActionTypes.InboxServiceTaskAdded,
	props<{ serviceTask: ServiceTask }>()
)

export const inboxServiceTaskUpdated = createAction(
	ServiceTaskActionTypes.InboxServiceTaskUpdated,
	props<{ partialServiceTask: Partial<ServiceTask> }>()
)

export const inboxServiceTaskRemoved = createAction(
	ServiceTaskActionTypes.InboxServiceTaskRemoved,
	props<{ id: string }>()
)

export const inboxSortToggled = createAction(
	ServiceTaskActionTypes.InboxSortToggled
)

export const scheduledServiceTaskAdded = createAction(
	ServiceTaskActionTypes.ScheduledServiceTaskAdded,
	props<{ serviceTask: ServiceTask }>()
)

export const scheduledServiceTaskUpdated = createAction(
	ServiceTaskActionTypes.ScheduledServiceTaskUpdated,
	props<{ partialServiceTask: Partial<ServiceTask> }>()
)

export const scheduledServiceTaskRemoved = createAction(
	ServiceTaskActionTypes.ScheduledServiceTaskRemoved,
	props<{ id: string }>()
)

export const scheduledSortToggled = createAction(
	ServiceTaskActionTypes.ScheduledSortToggled
)

export const serviceTaskTabChanged = createAction(
	ServiceTaskActionTypes.ServiceTaskTabChanged,
	props<{ selectedTab: ServiceTaskTab }>()
)

export const inboxDateFilterChanged = createAction(
	ServiceTaskActionTypes.InboxDateFilterChanged,
	props<{ dateFilter: string }>()
)

export const scheduledDateFilterChanged = createAction(
	ServiceTaskActionTypes.ScheduledDateFilterChanged,
	props<{ dateFilter: string }>()
)

export const removeServiceTaskRequested = createAction(
	ServiceTaskActionTypes.RemoveServiceTaskRequested,
	props<{ taskId: string, recipientId: number }>()
)

export const removeServiceTaskCompleted = createAction(
	ServiceTaskActionTypes.RemoveServiceTaskCompleted
)