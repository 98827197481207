import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VisitParticipationType } from '../types/aggregate-graph.types';

export interface SearchedUser {
	id: number;
	aggregateId: string;
	fullname: string;
	initials: string;
	isActive: boolean;
	roles: UserRole[];
}

export enum UserRole {
	Clinician = 1,
	Student = 2,
	FacultyClinician = 3,
	Administration = 4,
	ReportCounterSigner = 5,
	Nurse = 6,
	SystemAdministrator = 7
}

@Injectable({
	providedIn: 'root'
})
export class UsersService {

	constructor(private httpClient: HttpClient) {
	}

	queryUsers(queryTerm: string, participationTypes: VisitParticipationType[], skip: number, take: number) {
		const participationTypeParams = participationTypes && participationTypes.length ? '&participationType=' + participationTypes.join('&participationType=') : '';
		return this.httpClient.get<SearchedUser[]>(`/api/users?queryTerm=${queryTerm}${participationTypeParams}&skip=${skip}&take=${take}`);
	}
}