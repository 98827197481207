import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UnitQuantity, UnitQuantityControl } from 'src/app/form-controls/unit-quantity/unit-quantity.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { NutritionActionV2 } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface NutritionActionFormGroup {
	amountGiven: FormControl<UnitQuantity | null>;
	amountEaten: FormControl<UnitQuantity | null>;
}

@Component({
	selector: 'app-nutrition-action-controls',
	templateUrl: './nutrition-action-controls-v2.component.html',
	styleUrls: ['../../../common.scss', './nutrition-action-controls-v2.component.scss'],
	standalone: true,
	imports: [ReactiveFormsModule, CommonActionControlsComponent, ActionFormComponent, UnitQuantityControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NutritionActionControlsComponentV2 implements SheetActionControlsBase, OnInit {

	slot!: Slot | null;
	action!: NutritionActionV2;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as NutritionActionV2;
	}

	@Input() form!: FormGroup<NutritionActionFormGroup>;
	@Input() item!: Row | null;

	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.form.addControl('amountGiven', this.formBuilder.control(this.action?.amountGiven ?? null));
		this.form.addControl('amountEaten', this.formBuilder.control(this.action?.amountEaten ?? null));
	}
}