import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AuthorisationClinicianItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/authorisation-clinician-item/authorisation-clinician-item.component';
import { AuthorisationItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/authorisation-item/authorisation-item.component';
import { BandageCheckItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/bandage-check-item/bandage-check-item.component';
import { BearScoreItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/bear-score-item/bear-score-item.component';
import { BloodGasItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/blood-gas-item/blood-gas-item.component';
import { BloodGlucoseItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/blood-glucose-item/blood-glucose-item.component';
import { BloodSampleItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/blood-sample-item/blood-sample-item.component';
import { BusterCollarItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/buster-collar-item/buster-collar-item.component';
import { CheckBedOrLitterTrayItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/check-bed-or-litter-tray-item/check-bed-or-litter-tray-item.component';
import { CheckBladderSizeItemComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/check-bladder-size-item-v2/check-bladder-size-item-v2.component';
import { CheckBladderSizeItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/check-bladder-size-item/check-bladder-size-item.component';
import { CheckIdTagItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/check-id-tag-item/check-id-tag-itemcomponent';
import { CheckIvCatheterItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/check-iv-catheter-item/check-iv-catheter-item.component';
import { CheckWoundItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/check-wound-item/check-wound-item.component';
import { CleanKennelItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/clean-kennel-item/clean-kennel-item.component';
import { ColdCompressItemComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/cold-compress-item-v2/cold-compress-item-v2.component';
import { ColdCompressItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/cold-compress-item/cold-compress-item.component';
import { ContinuousEcgItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/continuous-ecg-item/continuous-ecg-item.component';
import { DiagnosticsItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/diagnostics-item/diagnostics-item.component';
import { EndoscopyItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/endoscopy-item/endoscopy-item.component';
import { EuthanasiaItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/euthanasia-item/euthanasia-item.component';
import { EyeLubricationItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/eye-lubrication-item/eye-lubrication-item.component';
import { FaecesCheckItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/faeces-check-item/faeces-check-item.component';
import { FaecesSampleItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/faeces-sample-item/faeces-sample-item.component';
import { FluidsBolusItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/fluids-bolus-item/fluids-bolus-item.component';
import { FluidsInfusionItemComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/fluids-infusion-item-v2/fluids-infusion-item-v2.component';
import { FluidsInfusionItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/fluids-infusion-item/fluids-infusion-item.component';
import { FluidsInfusionRedLabelItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/fluids-infusion-red-label-item/fluids-infusion-red-label-item.component';
import { FluidsSubcutaneousItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/fluids-subcutaneous-item/fluids-subcutaneous-item.component';
import { FlushIntravenousCatheterItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/flush-intravenous-catheter-item/flush-intravenous-catheter-item.component';
import { FoodBowlOutItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/food-bowl-out-item/food-bowl-out-item.component';
import { GlasgowComaScaleItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/glasgow-coma-scale-item/glasgow-coma-scale-item.component';
import { HotCompressItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/hot-compress-item/hot-compress-item.component';
import { HydrotherapyItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/hydrotherapy-item/hydrotherapy-item.component';
import { IntraoccularPressureLeftEyeItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/intraoccular-pressure-left-eye-item/intraoccular-pressure-left-eye-item.component';
import { IntraoccularPressureRightEyeItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/intraoccular-pressure-right-eye-item/intraoccular-pressure-right-eye-item.component';
import { IvtfSupplementationItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/ivtf-supplementation-item/ivtf-supplementation-item.component';
import { HeartRateItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/measure-heartrate-item/measure-heartrate-item.component';
import { MedicationInfusionItemComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/medication-infusion-item-v2/medication-infusion-item-v2.component';
import { MedicationInfusionItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/medication-infusion-item/medication-infusion-item.component';
import { MedicationInfusionRedLabelItemComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/medication-infusion-red-label-item-v2/medication-infusion-red-label-item-v2.component';
import { MedicationInfusionRedLabelItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/medication-infusion-red-label-item/medication-infusion-red-label-item.component';
import { MedicationItemComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/medication-item-v2/medication-item-v2.component';
import { MedicationItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/medication-item/medication-item.component';
import { MedicationRedLabelItemComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/medication-red-label-item-v2/medication-red-label-item-v2.component';
import { MedicationRedLabelItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/medication-red-label-item/medication-red-label-item.component';
import { MentationItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/mentation-item/mentation-item.component';
import { MucousMembranesAndCrtItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/mucous-membranes-and-crt-item/mucous-membranes-and-crt-item.component';
import { NegativePressureWoundTherapyItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/negative-pressure-wound-therapy-item/negative-pressure-wound-therapy-item.component';
import { NilByMouthItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/nil-by-mouth-item/nil-by-mouth-item.component';
import { BloodPressureItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/non-invasive-blood-pressure-item/non-invasive-blood-pressure-item.component';
import { NoteItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/note-item/note-item.component';
import { NutritionItemComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/nutrition-item-v2/nutrition-item-v2.component';
import { NutritionItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/nutrition-item/nutrition-item.component';
import { OfferWaterItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/offer-water-item/offer-water-item.component';
import { OralHealthCareItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/oral-health-care-item/oral-health-care-item.component';
import { OtherItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/other-item/other-item.component';
import { OtherMeasurementItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/other-measurement/other-measurement-item.component';
import { OxygenItemComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/oxygen-item-v2/oxygen-item-v2.component';
import { OxygenItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/oxygen-item/oxygen-item.component';
import { PackedCellVolumeItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/packed-cell-volume-item/packed-cell-volume-item.component';
import { PainScoreCatItemComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/pain-score-cat-item-v2/pain-score-cat-item-v2.component';
import { PainScoreCatItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/pain-score-cat-item/pain-score-cat-item.component';
import { PainScoreDogItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/pain-score-dog-item/pain-score-dog-item.component';
import { PainScoreExoticItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/pain-score-exotic-item/pain-score-exotic-item.component';
import { PhysiotherapyItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/physiotherapy-item/physiotherapy-item.component';
import { PlaceIntravenousCatheterItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/place-intravenous-catheter-item/place-intravenous-catheter-item.component';
import { PlateletCountItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/platelet-count-item/platelet-count-item.component';
import { PtsItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/pts-item/pts-item.component';
import { RemovePressureBandageItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/remove-pressure-bandage-item/remove-pressure-bandage-item.component';
import { RespiratoryRateItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/respiratory-rate-and-effort-item/respiratory-rate-and-effort-item.component';
import { SchirmerTearTestLeftEyeItemComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/schirmer-tear-test-left-eye-item-v2/schirmer-tear-test-left-eye-item-v2.component';
import { SchirmerTearTestLeftEyeItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/schirmer-tear-test-left-eye-item/schirmer-tear-test-left-eye-item.component';
import { SchirmerTearTestRightEyeItemComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/schirmer-tear-test-right-eye-item-v2/schirmer-tear-test-right-eye-item-v2.component';
import { SchirmerTearTestRightEyeItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/schirmer-tear-test-right-eye-item/schirmer-tear-test-right-eye-item.component';
import { SkincareItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/skincare-item/skincare-item.component';
import { SoapItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/soap-item/soap-item.component';
import { StandardEcgItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/standard-ecg-item/standard-ecg-item.component';
import { SurgeryItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/surgery-item/surgery-item.component';
import { TemperatureItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/temperature-item/temperature-item.component';
import { ToGoHomeItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/to-go-home-item/to-go-home-item.component';
import { TotalSolidsItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/total-solids-item/total-solids-item.component';
import { TransferItemComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/transfer-item-v2/transfer-item-v2.component';
import { TransferItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/transfer-item/transfer-item.component';
import { UrinalysisItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/urinalysis-item/urinalysis-item.component';
import { UrineItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/urine-item/urine-item.component';
import { UrineOutputItemComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/urine-output-item-v2/urine-output-item-v2.component';
import { UrineOutputItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/urine-output-item/urine-output-item.component';
import { UrineSampleItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/urine-sample-item/urine-sample-item.component';
import { VomitingOrRegurgitationItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/vomiting-or-regurgitation-item/vomiting-or-regurgitation-item.component';
import { WalkOutItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/walk-out-item/walk-out-item.component';
import { WaterDiaryItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/water-diary-item/water-diary-item.component';
import { WeightItemComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-item-detail/components/items/weight-item/weight-item.component';
import { AuthorisationClinicianSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/authorisation-clinician-slot/authorisation-clinician-slot.component';
import { AuthorisationSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/authorisation-slot/authorisation-slot.component';
import { BandageCheckSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/bandage-check-slot/bandage-check-slot.component';
import { BearScoreSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/bear-score-slot/bear-score-slot.component';
import { BloodGasSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/blood-gas-slot/blood-gas-slot.component';
import { BloodGlucoseSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/blood-glucose-slot/blood-glucose-slot.component';
import { BloodSampleSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/blood-sample-slot/blood-sample-slot.component';
import { BusterCollarSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/buster-collar-slot/buster-collar-slot.component';
import { CheckBedOrLitterTraySlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/check-bed-or-litter-tray-slot/check-bed-or-litter-tray-slot.component';
import { CheckBladderSizeSlotComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/check-bladder-size-slot-v2/check-bladder-size-slot-v2.component';
import { CheckBladderSizeSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/check-bladder-size-slot/check-bladder-size-slot.component';
import { CheckIdTagSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/check-id-tag-slot/check-id-tag-slot.component';
import { CheckIvCatheterSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/check-iv-catheter-slot/check-iv-catheter-slot.component';
import { CheckWoundSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/check-wound-slot/check-wound-slot.component';
import { CleanKennelSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/clean-kennel-slot/clean-kennel-slot.component';
import { ColdCompressSlotComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/cold-compress-slot-v2/cold-compress-slot-v2.component';
import { ColdCompressSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/cold-compress-slot/cold-compress-slot.component';
import { ContinuousEcgSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/continuous-ecg-slot/continuous-ecg-slot.component';
import { DiagnosticsSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/diagnostics-slot/diagnostics-slot.component';
import { EndoscopySlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/endoscopy-slot/endoscopy-slot.component';
import { EuthanasiaSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/euthanasia-slot/euthanasia-slot.component';
import { EyeLubricationSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/eye-lubrication-slot/eye-lubrication-slot.component';
import { FaecesCheckSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/faeces-check-slot/faeces-check-slot.component';
import { FaecesSampleSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/faeces-sample-slot/faeces-sample-slot.component';
import { FluidsBolusSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/fluids-bolus-slot/fluids-bolus-slot.component';
import { FluidsInfusionRedLabelSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/fluids-infusion-red-label-slot/fluids-infusion-red-label-slot.component';
import { FluidsInfusionSlotComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/fluids-infusion-slot-v2/fluids-infusion-slot-v2.component';
import { FluidsInfusionSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/fluids-infusion-slot/fluids-infusion-slot.component';
import { FluidsSubcutaneousSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/fluids-subcutaneous-slot/fluids-subcutaneous-slot.component';
import { FlushIntravenousCatheterSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/flush-intravenous-catheter-slot/flush-intravenous-catheter-slot.component';
import { FoodBowlOutSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/food-bowl-out-slot/food-bowl-out-slot.component';
import { GlasgowComaScaleSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/glasgow-coma-scale-slot/glasgow-coma-scale-slot.component';
import { HotCompressSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/hot-compress-slot/hot-compress-slot.component';
import { HydrotherapySlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/hydrotherapy-slot/hydrotherapy-slot.component';
import { IntraoccularPressureLeftEyeSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/intraoccular-pressure-left-eye-slot/intraoccular-pressure-left-eye-slot.component';
import { IntraoccularPressureRightEyeSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/intraoccular-pressure-right-eye-slot/intraoccular-pressure-right-eye-slot.component';
import { IvtfSupplementationSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/ivtf-supplementation-slot/ivtf-supplementation-slot.component';
import { HeartRateSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/measure-heartrate-slot/measure-heartrate-slot.component';
import { MedicationInfusionRedLabelSlotComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/medication-infusion-red-label-slot-v2/medication-infusion-red-label-slot-v2.component';
import { MedicationInfusionRedLabelSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/medication-infusion-red-label-slot/medication-infusion-red-label-slot.component';
import { MedicationInfusionSlotComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/medication-infusion-slot-v2/medication-infusion-slot-v2.component';
import { MedicationInfusionSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/medication-infusion-slot/medication-infusion-slot.component';
import { MedicationRedLabelSlotComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/medication-red-label-slot-v2/medication-red-label-slot-v2.component';
import { MedicationRedLabelSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/medication-red-label-slot/medication-red-label-slot.component';
import { MedicationSlotComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/medication-slot-v2/medication-slot-v2.component';
import { MedicationSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/medication-slot/medication-slot.component';
import { MentationSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/mentation-slot/mentation-slot.component';
import { MucousMembranesAndCrtSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/mucous-membranes-and-crt-slot/mucous-membranes-and-crt-slot.component';
import { NegativePressureWoundTherapySlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/negative-pressure-wound-therapy-slot/negative-pressure-wound-therapy-slot.component';
import { NilByMouthSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/nil-by-mouth-slot/nil-by-mouth-slot.component';
import { BloodPressureSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/non-invasive-blood-pressure-slot/non-invasive-blood-pressure-slot.component';
import { NoteSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/note-slot/note-slot.component';
import { NutritionSlotComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/nutrition-slot-v2/nutrition-slot-v2.component';
import { NutritionSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/nutrition-slot/nutrition-slot.component';
import { OfferWaterSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/offer-water-slot/offer-water-slot.component';
import { OralHealthCareSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/oral-health-care-slot/oral-health-care-slot.component';
import { OtherMeasurementSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/other-measurement-slot/other-measurement-slot.component';
import { OtherSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/other-slot/other-slot.component';
import { OxygenSlotComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/oxygen-slot-v2/oxygen-slot-v2.component';
import { OxygenSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/oxygen-slot/oxygen-slot.component';
import { PackedCellVolumeSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/packed-cell-volume-slot/packed-cell-volume-slot.component';
import { PainScoreCatSlotComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/pain-score-cat-slot-v2/pain-score-cat-slot-v2.component';
import { PainScoreCatSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/pain-score-cat-slot/pain-score-cat-slot.component';
import { PainScoreDogSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/pain-score-dog-slot/pain-score-dog-slot.component';
import { PainScoreExoticSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/pain-score-exotic-slot/pain-score-exotic-slot.component';
import { PhysiotherapySlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/physiotherapy-slot/physiotherapy-slot.component';
import { PlaceIntravenousCatheterSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/place-intravenous-catheter-slot/place-intravenous-catheter-slot.component';
import { PlateletCountSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/platelet-count-slot/platelet-count-slot.component';
import { PtsSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/pts-slot/pts-slot.component';
import { RemovePressureBandageSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/remove-pressure-bandage-slot/remove-pressure-bandage-slot.component';
import { RespiratoryRateSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/respiratory-rate-and-effort-slot/respiratory-rate-and-effort-slot.component';
import { SchirmerTearTestLeftEyeSlotComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/schirmer-tear-test-left-eye-slot-v2/schirmer-tear-test-left-eye-slot-v2.component';
import { SchirmerTearTestLeftEyeSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/schirmer-tear-test-left-eye-slot/schirmer-tear-test-left-eye-slot.component';
import { SchirmerTearTestRightEyeSlotComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/schirmer-tear-test-right-eye-slot-v2/schirmer-tear-test-right-eye-slot-v2.component';
import { SchirmerTearTestRightEyeSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/schirmer-tear-test-right-eye-slot/schirmer-tear-test-right-eye-slot.component';
import { SkincareSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/skincare-slot/skincare-slot.component';
import { SoapSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/soap-slot/soap-slot.component';
import { StandardEcgSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/standard-ecg-slot/standard-ecg-slot.component';
import { SurgerySlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/surgery-slot/surgery-slot.component';
import { TemperatureSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/temperature-slot/temperature-slot.component';
import { ToGoHomeSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/to-go-home-slot/to-go-home-slot.component';
import { TotalSolidsSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/total-solids-slot/total-solids-slot.component';
import { TransferSlotComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/transfer-slot-v2/transfer-slot-v2.component';
import { TransferSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/transfer-slot/transfer-slot.component';
import { UrinalysisSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/urinalysis-slot/urinalysis-slot.component';
import { UrineOutputSlotComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/urine-output-slot-v2/urine-output-slot-v2.component';
import { UrineOutputSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/urine-output-slot/urine-output-slot.component';
import { UrineSampleSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/urine-sample-slot/urine-sample-slot.component';
import { UrineSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/urine-slot/urine-slot.component';
import { VomitingOrRegurgitationSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/vomiting-or-regurgitation-slot/vomiting-or-regurgitation-slot.component';
import { WalkOutSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/walk-out-slot/walk-out-slot.component';
import { WaterDiarySlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/water-diary-slot/water-diary-slot.component';
import { WeightSlotComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/components/sheet-slot-detail/components/slots/weight-slot/weight-slot.component';
import { AuthorisationActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/authorisation-action-controls/authorisation-action-controls.component';
import { AuthorisationClinicianActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/authorisation-clinician-action-controls/authorisation-clinician-action-controls.component';
import { BandageCheckActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/bandage-check-action-controls/bandage-check-action-controls.component';
import { BearScoreActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/bear-score-action-controls/bear-score-action-controls.component';
import { BloodGasActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/blood-gas-action-controls/blood-gas-action-controls.component';
import { BloodGlucoseActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/blood-glucose-action-controls/blood-glucose-action-controls.component';
import { BloodSampleActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/blood-sample-action-controls/blood-sample-action-controls.component';
import { BusterCollarActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/buster-collar-action-controls/buster-collar-action-controls.component';
import { CheckBedOrLitterTrayActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/check-bed-or-litter-tray-action-controls/check-bed-or-litter-tray-action-controls.component';
import { CheckBladderSizeActionControlsComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/check-bladder-size-action-controls-v2/check-bladder-size-action-controls-v2.component';
import { CheckBladderSizeActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/check-bladder-size-action-controls/check-bladder-size-action-controls.component';
import { CheckIdTagActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/check-id-tag-action-controls/check-id-tag-action-controls.component';
import { CheckIvCatheterActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/check-iv-catheter-action-controls/check-iv-catheter-action-controls.component';
import { CheckWoundActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/check-wound-action-controls/check-wound-action-controls.component';
import { CleanKennelActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/clean-kennel-action-controls/clean-kennel-action-controls.component';
import { ColdCompressActionControlsComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/cold-compress-action-controls-v2/cold-compress-action-controls-v2.component';
import { ColdCompressActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/cold-compress-action-controls/cold-compress-action-controls.component';
import { ContinuousEcgActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/continuous-ecg-action-controls/continuous-ecg-action-controls.component';
import { DiagnosticsActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/diagnostics-action-controls/diagnostics-action-controls.component';
import { EndoscopyActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/endoscopy-action-controls/endoscopy-action-controls.component';
import { EuthanasiaActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/euthanasia-action-controls/euthanasia-action-controls.component';
import { EyeLubricationActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/eye-lubrication-action-controls/eye-lubrication-action-controls.component';
import { FaecesCheckActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/faeces-check-action-controls/faeces-check-action-controls.component';
import { FaecesSampleActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/faeces-sample-action-controls/faeces-sample-action-controls.component';
import { FluidsBolusActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/fluids-bolus-action-controls/fluids-bolus-action-controls.component';
import { FluidsInfusionActionControlsComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/fluids-infusion-action-controls-v2/fluids-infusion-action-controls-v2.component';
import { FluidsInfusionActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/fluids-infusion-action-controls/fluids-infusion-action-controls.component';
import { FluidsInfusionRedLabelActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/fluids-infusion-red-label-action-controls/fluids-infusion-red-label-action-controls.component';
import { FluidsSubcutaneousActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/fluids-subcutaneous-action-controls/fluids-subcutaneous-action-controls.component';
import { FlushIntravenousCatheterActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/flush-intravenous-catheter-action-controls/flush-intravenous-catheter-action-controls.component';
import { FoodBowlOutActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/food-bowl-out-action-controls/food-bowl-out-action-controls.component';
import { GlasgowComaScaleActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/glasgow-coma-scale-action-controls/glasgow-coma-scale-action-controls.component';
import { HotCompressActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/hot-compress-action-controls/hot-compress-action-controls.component';
import { HydrotherapyActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/hydrotherapy-action-controls/hydrotherapy-action-controls.component';
import { IntraoccularPressureLeftEyeActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/intraoccular-pressure-left-eye-action-controls/intraoccular-pressure-left-eye-action-controls.component';
import { IntraoccularPressureRightEyeActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/intraoccular-pressure-right-eye-action-controls/intraoccular-pressure-right-eye-action-controls.component';
import { IvtfSupplementationActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/ivtf-supplementation-action-controls/ivtf-supplementation-action-controls.component';
import { HeartRateActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/measure-heartrate-action-controls/measure-heartrate-action-controls.component';
import { MedicationActionControlsComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/medication-action-controls-v2/medication-action-controls-v2.component';
import { MedicationActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/medication-action-controls/medication-action-controls.component';
import { MedicationInfusionActionControlsComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/medication-infusion-action-controls-v2/medication-infusion-action-controls-v2.component';
import { MedicationInfusionActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/medication-infusion-action-controls/medication-infusion-action-controls.component';
import { MedicationInfusionRedLabelActionControlsComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/medication-infusion-red-label-action-controls-v2/medication-infusion-red-label-action-controls-v2.component';
import { MedicationInfusionRedLabelActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/medication-infusion-red-label-action-controls/medication-infusion-red-label-action-controls.component';
import { MedicationRedLabelActionControlsComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/medication-red-label-action-controls-v2/medication-red-label-action-controls-v2.component';
import { MedicationRedLabelActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/medication-red-label-action-controls/medication-red-label-action-controls.component';
import { MentationActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/mentation-action-controls/mentation-action-controls.component';
import { MucousMembranesAndCrtActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/mucous-membranes-and-crt-action-controls/mucous-membranes-and-crt-action-controls.component';
import { NegativePressureWoundTherapyActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/negative-pressure-wound-therapy-action-controls/negative-pressure-wound-therapy-action-controls.component';
import { NilByMouthActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/nil-by-mouth-action-controls/nil-by-mouth-action-controls.component';
import { BloodPressureActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/non-invasive-blood-pressure-action-controls/non-invasive-blood-pressure-action-controls.component';
import { NoteActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/note-action-controls/note-action-controls.component';
import { NutritionActionControlsComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/nutrition-action-controls-v2/nutrition-action-controls-v2.component';
import { NutritionActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/nutrition-action-controls/nutrition-action-controls.component';
import { OfferWaterActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/offer-water-action-controls/offer-water-action-controls.component';
import { OralHealthCareActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/oral-health-care-action-controls/oral-health-care-action-controls.component';
import { OtherActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/other-action-controls/other-action-controls.component';
import { OtherMeasurementActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/other-measurement-action-controls/other-measurement-action-controls.component';
import { OxygenActionControlsComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/oxygen-action-controls-v2/oxygen-action-controls-v2.component';
import { OxygenActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/oxygen-action-controls/oxygen-action-controls.component';
import { PackedCellVolumeActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/packed-cell-volume-action-controls/packed-cell-volume-action-controls.component';
import { PainScoreCatActionControlsComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/pain-score-cat-action-controls-v2/pain-score-cat-action-controls-v2.component';
import { PainScoreCatActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/pain-score-cat-action-controls/pain-score-cat-action-controls.component';
import { PainScoreDogActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/pain-score-dog-action-controls/pain-score-dog-action-controls.component';
import { PainScoreExoticActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/pain-score-exotic-action-controls/pain-score-exotic-action-controls.component';
import { PhysiotherapyActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/physiotherapy-action-controls/physiotherapy-action-controls.component';
import { PlaceIntravenousCatheterActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/place-intravenous-catheter-action-controls/place-intravenous-catheter-action-controls.component';
import { PlateletCountActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/platelet-count-action-controls/platelet-count-action-controls.component';
import { PtsActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/pts-action-controls/pts-action-controls.component';
import { RemovePressureBandageActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/remove-pressure-bandage-action-controls/remove-pressure-bandage-action-controls.component';
import { RespiratoryRateActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/respiratory-rate-and-effort-action-controls/respiratory-rate-and-effort-action-controls.component';
import { SchirmerTearTestLeftEyeActionControlsComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/schirmer-tear-test-left-eye-action-controls-v2/schirmer-tear-test-left-eye-action-controls-v2.component';
import { SchirmerTearTestLeftEyeActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/schirmer-tear-test-left-eye-action-controls/schirmer-tear-test-left-eye-action-controls.component';
import { SchirmerTearTestRightEyeActionControlsComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/schirmer-tear-test-right-eye-action-controls-v2/schirmer-tear-test-right-eye-action-controls-v2.component';
import { SchirmerTearTestRightEyeActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/schirmer-tear-test-right-eye-action-controls/schirmer-tear-test-right-eye-action-controls.component';
import { SkincareActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/skincare-action-controls/skincare-action-controls.component';
import { SoapActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/soap-action-controls/soap-action-controls.component';
import { StandardEcgActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/standard-ecg-action-controls/standard-ecg-action-controls.component';
import { SurgeryActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/surgery-action-controls/surgery-action-controls.component';
import { TemperatureActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/temperature-action-controls/temperature-action-controls.component';
import { ToGoHomeActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/to-go-home-action-controls/to-go-home-action-controls.component';
import { TotalSolidsActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/total-solids-action-controls/total-solids-action-controls.component';
import { TransferActionControlsComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/transfer-action-controls-v2/transfer-action-controls-v2.component';
import { TransferActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/transfer-action-controls/transfer-action-controls.component';
import { UrinalysisActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/urinalysis-action-controls/urinalysis-action-controls.component';
import { UrineActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/urine-action-controls/urine-action-controls.component';
import { UrineOutputActionControlsComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/urine-output-action-controls-v2/urine-output-action-controls-v2.component';
import { UrineOutputActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/urine-output-action-controls/urine-output-action-controls.component';
import { UrineSampleActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/urine-sample-action-controls/urine-sample-action-controls.component';
import { VomitingOrRegurgitationActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/vomiting-or-regurgitation-action-controls/vomiting-or-regurgitation-action-controls.component';
import { WalkOutActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/walk-out-action-controls/walk-out-action-controls.component';
import { WaterDiaryActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/water-diary-action-controls/water-diary-action-controls.component';
import { WeightActionControlsComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/components/action-controls/weight-action-controls/weight-action-controls.component';
import { AuthorisationClinicianItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/authorisation-clinician-item-form/authorisation-clinician-item-form.component';
import { AuthorisationItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/authorisation-item-form/authorisation-item-form.component';
import { BandageCheckItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/bandage-check-item-form/bandage-check-item-form.component';
import { BearScoreItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/bear-score-item-form/bear-score-item-form.component';
import { BloodGasItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/blood-gas-item-form/blood-gas-item-form.component';
import { BloodGlucoseItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/blood-glucose-item-form/blood-glucose-item-form.component';
import { BloodSampleItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/blood-sample-item-form/blood-sample-item-form.component';
import { BusterCollarItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/buster-collar-item-form/buster-collar-item-form.component';
import { CheckBedOrLitterTrayItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/check-bed-or-litter-tray-item-form/check-bed-or-litter-tray-item-form.component';
import { CheckBladderSizeItemFormComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/check-bladder-size-item-form-v2/check-bladder-size-item-form-v2.component';
import { CheckBladderSizeItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/check-bladder-size-item-form/check-bladder-size-item-form.component';
import { CheckIdTagItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/check-id-tag-item-form/check-id-tag-item-form.component';
import { CheckIvCatheterItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/check-iv-catheter-item-form/check-iv-catheter-item-form.component';
import { CheckWoundItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/check-wound-item-form/check-wound-item-form.component';
import { CleanKennelItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/clean-kennel-item-form/clean-kennel-item-form.component';
import { ColdCompressItemFormComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/cold-compress-item-form-v2/cold-compress-item-form-v2.component';
import { ColdCompressItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/cold-compress-item-form/cold-compress-item-form.component';
import { ContinuousEcgItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/continuous-ecg-item-form/continuous-ecg-item-form.component';
import { DiagnosticsItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/diagnostics-item-form/diagnostics-item-form.component';
import { EndoscopyItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/endoscopy-item-form/endoscopy-item-form.component';
import { EuthanasiaItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/euthanasia-item-form/euthanasia-item-form.component';
import { EyeLubricationItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/eye-lubrication-item-form/eye-lubrication-item-form.component';
import { FaecesCheckItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/faeces-check-item-form/faeces-check-item-form.component';
import { FaecesSampleItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/faeces-sample-item-form/faeces-sample-item-form.component';
import { FluidsBolusItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/fluids-bolus-item-form/fluids-bolus-item-form.component';
import { FuildsInfusionItemFormComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/fluids-infusion-item-form-v2/fluids-infusion-item-form-v2.component';
import { FuildsInfusionItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/fluids-infusion-item-form/fluids-infusion-item-form.component';
import { FluidsInfusionRedLabelItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/fluids-infusion-red-label-item-form/fluids-infusion-red-label-item-form.component';
import { FluidsSubcutaneousItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/fluids-subcutaneous-item-form/fluids-subcutaneous-item-form.component';
import { FlushIntravenousCatheterItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/flush-intravenous-catheter-item-form/flush-intravenous-catheter-item-form.component';
import { FoodBowlOutItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/food-bowl-out-item-form/food-bowl-out-item-form.component';
import { GlasgowComaScaleItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/glasgow-coma-scale-item-form/glasgow-coma-scale-item-form.component';
import { HotCompressItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/hot-compress-item-form/hot-compress-item-form.component';
import { HydrotherapyItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/hydrotherapy-item-form/hydrotherapy-item-form.component';
import { IntraoccularPressureLeftEyeItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/intraoccular-pressure-left-eye-item-form/intraoccular-pressure-left-eye-item-form.component';
import { IntraoccularPressureRightEyeItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/intraoccular-pressure-right-eye-item-form/intraoccular-pressure-right-eye-item-form.component';
import { IvtfSupplementationItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/ivtf-supplementation-item-form/ivtf-supplementation-item-form.component';
import { HeartRateItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/measure-heartrate-item-form/measure-heartrate-item-form.component';
import { MedicationInfusionItemFormComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/medication-infusion-item-form-v2/medication-infusion-item-form-v2.component';
import { MedicationInfusionItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/medication-infusion-item-form/medication-infusion-item-form.component';
import { MedicationInfusionRedLabelItemFormComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/medication-infusion-red-label-item-form-v2/medication-infusion-red-label-item-form-v2.component';
import { MedicationInfusionRedLabelItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/medication-infusion-red-label-item-form/medication-infusion-red-label-item-form.component';
import { MedicationItemFormComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/medication-item-form-v2/medication-item-form-v2.component';
import { MedicationItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/medication-item-form/medication-item-form.component';
import { MedicationRedLabelItemFormComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/medication-red-label-item-form-v2/medication-red-label-item-form-v2.component';
import { MedicationRedLabelItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/medication-red-label-item-form/medication-red-label-item-form.component';
import { MentationItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/mentation-item-form/mentation-item-form.component';
import { MucousMembranesAndCrtItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/mucous-membranes-and-crt-item-form/mucous-membranes-and-crt-item-form.component';
import { NegativePressureWoundTherapyItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/negative-pressure-wound-therapy-item-form/negative-pressure-wound-therapy-item-form.component';
import { NilByMouthItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/nil-by-mouth-item-form/nil-by-mouth-item-form.component';
import { BloodPressureItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/non-invasive-blood-pressure-item-form/non-invasive-blood-pressure-item-form.component';
import { NoteItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/note-item-form/note-item-form.component';
import { NutritionItemFormComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/nutrition-item-form-v2/nutrition-item-form-v2.component';
import { NutritionItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/nutrition-item-form/nutrition-item-form.component';
import { OfferWaterItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/offer-water-item-form/offer-water-item-form.component';
import { OralHealthCareItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/oral-health-care-item-form/oral-health-care-item-form.component';
import { OtherItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/other-item-form/other-item-form.component';
import { OtherMeasurementItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/other-measurement-item-form/other-measurement-item-form.component';
import { OxygenItemFormComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/oxygen-item-form-v2/oxygen-item-form-v2.component';
import { OxygenItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/oxygen-item-form/oxygen-item-form.component';
import { PackedCellVolumeItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/packed-cell-volume-item-form/packed-cell-volume-item-form.component';
import { PainScoreCatItemFormComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/pain-score-cat-item-form-v2/pain-score-cat-item-form-v2.component';
import { PainScoreCatItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/pain-score-cat-item-form/pain-score-cat-item-form.component';
import { PainScoreDogItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/pain-score-dog-item-form/pain-score-dog-item-form.component';
import { PainScoreExoticItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/pain-score-exotic-item-form/pain-score-exotic-item-form.component';
import { PhysiotherapyItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/physiotherapy-item-form/physiotherapy-item-form.component';
import { PlaceIntravenousCatheterItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/place-intravenous-catheter-item-form/place-intravenous-catheter-item-form.component';
import { PlateletCountItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/platelet-count-item-form/platelet-count-item-form.component';
import { PtsItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/pts-item-form/pts-item-form.component';
import { RemovePressureBandageItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/remove-pressure-bandage-item-form/remove-pressure-bandage-item-form.component';
import { RespiratoryRateItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/respiratory-rate-and-effort-item-form/respiratory-rate-and-effort-item-form.component';
import { SchirmerTearTestLeftEyeItemFormComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/schirmer-tear-test-left-eye-item-form-v2/schirmer-tear-test-left-eye-item-form-v2.component';
import { SchirmerTearTestLeftEyeItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/schirmer-tear-test-left-eye-item-form/schirmer-tear-test-left-eye-item-form.component';
import { SchirmerTearTestRightEyeItemFormComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/schirmer-tear-test-right-eye-item-form-v2/schirmer-tear-test-right-eye-item-form-v2.component';
import { SchirmerTearTestRightEyeItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/schirmer-tear-test-right-eye-item-form/schirmer-tear-test-right-eye-item-form.component';
import { SkincareItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/skincare-item-form/skincare-item-form.component';
import { SoapItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/soap-item-form/soap-item-form.component';
import { StandardEcgItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/standard-ecg-item-form/standard-ecg-item-form.component';
import { SurgeryItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/surgery-item-form/surgery-item-form.component';
import { TemperatureItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/temperature-item-form/temperature-item-form.component';
import { ToGoHomeItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/to-go-home-item-form/to-go-home-item-form.component';
import { TotalSolidsItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/total-solids-item-form/total-solids-item-form.component';
import { TransferItemFormComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/transfer-item-form-v2/transfer-item-form-v2.component';
import { TransferItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/transfer-item-form/transfer-item-form.component';
import { UrinalysisItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/urinalysis-item-form/urinalysis-item-form.component';
import { UrineItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/urine-item-form/urine-item-form.component';
import { UrineOutputItemFormComponentV2 } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/urine-output-item-form-v2/urine-output-item-form-v2.component';
import { UrineOutputItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/urine-output-item-form/urine-output-item-form.component';
import { UrineSampleItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/urine-sample-item-form/urine-sample-item-form.component';
import { VomitingOrRegurgitationItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/vomiting-or-regurgitation-item-form/vomiting-or-regurgitation-item-form.component';
import { WalkOutItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/walk-out-item-form/walk-out-item-form.component';
import { WaterDiaryItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/water-diary-item-form/water-diary-item-form.component';
import { WeightItemFormComponent } from '../pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/components/item-forms/weight-item-form/weight-item-form.component';
import { AppState } from '../store/reducers';
import { loadSheetItemEditorRequested, loadSheetSlotEditorRequested } from '../store/sheet/sheet.actions';
import { Action, Row, ScheduleType, Slot } from '../types/aggregate-graph.types';
import { TimeQuantity, TimeUnit } from './timespan-conversion.service';

export interface SheetItemComponentBase {
	row: Row;
	startLabel: string;
	endLabel: string;
}

export interface SheetItemFormBase {
	instructionTypeName: string;
	instructionsFormArray: FormArray;
	item: Row | null;
	minDate: string;
	maxDate: string;
	itemConfig: SheetItemDetailConfig;
	addInstruction: () => void; // used to add any custom controls to the form group and push it to the form array
}

export interface SheetSlotComponentBase {
	action: Action;
	value: string | null;
}

export interface SheetActionControlsBase {
	slot: Slot | null;
	form: FormGroup;
	item: Row | null;
}

export interface SheetItemScheduleConfig {
	defaultScheduleType: ScheduleType;
	showFrequencyControl: boolean;
	showTimeToCompleteControl: boolean;
	repeatEveryControlLabel: string;
	timeToCompleteControlLabel: string;
	defaultRepeatingScheduleFrequency: TimeQuantity;
}

export interface SheetItemDetailConfig {
	allowCustomName: boolean;
	customNameLabel: string;
	scheduleConfig: SheetItemScheduleConfig;
}

@Injectable({
	providedIn: 'root'
})
export class SheetsRendererService {

	constructor(private store: Store<AppState>) {
	}

	getItemDetailComponentType(typeName: string) {
		return sheetItemDetailTypes[typeName];
	}

	showItemModal(item: Row) {
		this.store.dispatch(loadSheetItemEditorRequested({ item: item }));
	}

	getItemEditorFormType(typeName: string) {
		return sheetItemEditorFormTypes[typeName];
	}

	getSlotDetailComponentType(typeName: string) {
		return sheetSlotDetailTypes[typeName];
	}

	showSlotModal(itemId: number, slotIndex: number) {
		this.store.dispatch(loadSheetSlotEditorRequested({ itemId, slotIndex }));
	}

	getSlotEditorControlsType(typeName: string) {
		return sheetSlotEditorControlsTypes[typeName];
	}

	getItemDetailConfig(typeName: string) {
		return sheetItemDetailConfigs[typeName];
	}
}

const defaultSheetItemDetailConfig: SheetItemDetailConfig = {
	allowCustomName: false,
	customNameLabel: '',
	scheduleConfig: {
		defaultScheduleType: ScheduleType.Repeating,
		showFrequencyControl: true,
		showTimeToCompleteControl: true,
		repeatEveryControlLabel: 'Repeat Every',
		timeToCompleteControlLabel: 'Time To Complete',
		defaultRepeatingScheduleFrequency: { unit: TimeUnit.Hours, quantity: 4 } 
	}
}

const sheetItemDetailConfigs: { [key: string]: SheetItemDetailConfig } = {
	'AuthorisationClinicianInstruction': { ...defaultSheetItemDetailConfig, scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, defaultRepeatingScheduleFrequency: { unit: TimeUnit.Hours, quantity: 24 } } },
	'AuthorisationInstruction': { ...defaultSheetItemDetailConfig, scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, defaultRepeatingScheduleFrequency: { unit: TimeUnit.Hours, quantity: 24 } } },
	'BandageCheckInstruction': defaultSheetItemDetailConfig,
	'BearScoreInstruction': defaultSheetItemDetailConfig,
	'BloodGasInstruction': defaultSheetItemDetailConfig,
	'BloodGlucoseInstruction': defaultSheetItemDetailConfig,
	'BloodPressureInstruction': defaultSheetItemDetailConfig,
	'BloodSampleInstruction': { ...defaultSheetItemDetailConfig, scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, defaultScheduleType: ScheduleType.OneOff } },
	'BusterCollarInstruction': { ...defaultSheetItemDetailConfig, scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, defaultScheduleType: ScheduleType.OneOff, defaultRepeatingScheduleFrequency: { unit: TimeUnit.Hours, quantity: 10 } } },
	'CheckBedOrLitterTrayInstruction': defaultSheetItemDetailConfig,
	'CheckBladderSizeInstruction': defaultSheetItemDetailConfig,
	'CheckBladderSizeInstructionV2': defaultSheetItemDetailConfig,
	'CheckIdTagInstruction': { ...defaultSheetItemDetailConfig, scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, defaultRepeatingScheduleFrequency: { unit: TimeUnit.Hours, quantity: 24 } }  },
	'CheckIvCatheterInstruction': { ...defaultSheetItemDetailConfig, scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, defaultRepeatingScheduleFrequency: { unit: TimeUnit.Hours, quantity: 24 } } },
	'CheckWoundInstruction': defaultSheetItemDetailConfig,
	'CleanKennelInstruction': { ...defaultSheetItemDetailConfig, scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, defaultRepeatingScheduleFrequency: { unit: TimeUnit.Hours, quantity: 24 } } },
	'ColdCompressInstruction': defaultSheetItemDetailConfig,
	'ColdCompressInstructionV2': defaultSheetItemDetailConfig,
	'ContinuousEcgInstruction': { ...defaultSheetItemDetailConfig, scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, defaultScheduleType: ScheduleType.OneOff, timeToCompleteControlLabel: 'Duration' } },
	'DiagnosticsInstruction': { ...defaultSheetItemDetailConfig, scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, defaultScheduleType: ScheduleType.OneOff, timeToCompleteControlLabel: 'Duration' } },
	'EndoscopyInstruction': { ...defaultSheetItemDetailConfig, scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, defaultScheduleType: ScheduleType.OneOff, timeToCompleteControlLabel: 'Duration' } },
	'EuthanasiaInstruction': { ...defaultSheetItemDetailConfig, scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, defaultScheduleType: ScheduleType.OneOff } },
	'EyeLubricationInstruction': defaultSheetItemDetailConfig,
	'FaecesCheckInstruction': defaultSheetItemDetailConfig,
	'FaecesSampleInstruction': defaultSheetItemDetailConfig,
	'FluidsBolusInstruction': { ...defaultSheetItemDetailConfig, allowCustomName: true, customNameLabel: 'Product Name' },
	'FluidsInfusionInstruction': { ...defaultSheetItemDetailConfig, allowCustomName: true, customNameLabel: 'Product Name', scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, showFrequencyControl: false, showTimeToCompleteControl: false, repeatEveryControlLabel: 'Check Every' } },
	'FluidsInfusionInstructionV2': { ...defaultSheetItemDetailConfig, allowCustomName: true, customNameLabel: 'Product Name', scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, showFrequencyControl: false, showTimeToCompleteControl: false, repeatEveryControlLabel: 'Check Every' } },
	'FluidsInfusionRedLabelInstruction': { ...defaultSheetItemDetailConfig, allowCustomName: true, customNameLabel: 'Product Name', scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, showFrequencyControl: false, showTimeToCompleteControl: false, repeatEveryControlLabel: 'Check Every' } },
	'FluidsSubcutaneousInstruction': { ...defaultSheetItemDetailConfig, allowCustomName: true, customNameLabel: 'Product Name' },
	'FlushIntravenousCatheterInstruction': defaultSheetItemDetailConfig,
	'FoodBowlOutInstruction': { ...defaultSheetItemDetailConfig, scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, defaultScheduleType: ScheduleType.OneOff } },
	'GlasgowComaScaleInstruction': defaultSheetItemDetailConfig,
	'HeartRateInstruction': defaultSheetItemDetailConfig,
	'HotCompressInstruction': defaultSheetItemDetailConfig,
	'HydrotherapyInstruction': { ...defaultSheetItemDetailConfig, scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, defaultScheduleType: ScheduleType.OneOff, timeToCompleteControlLabel: 'Duration' } },
	'IntraoccularPressureLeftEyeInstruction': defaultSheetItemDetailConfig,
	'IntraoccularPressureRightEyeInstruction': defaultSheetItemDetailConfig,
	'IvtfSupplementationInstruction': { ...defaultSheetItemDetailConfig, allowCustomName: true, customNameLabel: 'Product Name' },
	'MedicationInfusionInstruction': { ...defaultSheetItemDetailConfig, allowCustomName: true, customNameLabel: 'Product Name', scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, showFrequencyControl: false, showTimeToCompleteControl: false, repeatEveryControlLabel: 'Check Every' } },
	'MedicationInfusionInstructionV2': { ...defaultSheetItemDetailConfig, allowCustomName: true, customNameLabel: 'Product Name', scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, showFrequencyControl: false, showTimeToCompleteControl: false, repeatEveryControlLabel: 'Check Every' } },
	'MedicationInfusionRedLabelInstruction': { ...defaultSheetItemDetailConfig, allowCustomName: true, customNameLabel: 'Product Name', scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, showFrequencyControl: false, showTimeToCompleteControl: false, repeatEveryControlLabel: 'Check Every' } },
	'MedicationInfusionRedLabelInstructionV2': { ...defaultSheetItemDetailConfig, allowCustomName: true, customNameLabel: 'Product Name', scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, showFrequencyControl: false, showTimeToCompleteControl: false, repeatEveryControlLabel: 'Check Every' } },
	'MedicationInstruction': { ...defaultSheetItemDetailConfig, allowCustomName: true, customNameLabel: 'Product Name' },
	'MedicationInstructionV2': { ...defaultSheetItemDetailConfig, allowCustomName: true, customNameLabel: 'Product Name' },
	'MedicationRedLabelInstruction': { ...defaultSheetItemDetailConfig, allowCustomName: true, customNameLabel: 'Product Name' },
	'MedicationRedLabelInstructionV2': { ...defaultSheetItemDetailConfig, allowCustomName: true, customNameLabel: 'Product Name' },
	'MentationInstruction': defaultSheetItemDetailConfig,
	'MucousMembranesAndCrtInstruction': defaultSheetItemDetailConfig,
	'NegativePressureWoundTherapyInstruction': defaultSheetItemDetailConfig,
	'NilByMouthInstruction': { ...defaultSheetItemDetailConfig, scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, defaultScheduleType: ScheduleType.OneOff, timeToCompleteControlLabel: 'Duration' } },
	'NoteInstruction': defaultSheetItemDetailConfig,
	'NutritionInstruction': defaultSheetItemDetailConfig,
	'NutritionInstructionV2': defaultSheetItemDetailConfig,
	'OfferWaterInstruction': defaultSheetItemDetailConfig,
	'OralHealthCareInstruction': defaultSheetItemDetailConfig,
	'OtherInstruction': { ...defaultSheetItemDetailConfig, allowCustomName: true, customNameLabel: 'Name' },
	'OtherMeasurementInstruction': { ...defaultSheetItemDetailConfig, allowCustomName: true, customNameLabel: 'Name' },
	'OxygenInstruction': { ...defaultSheetItemDetailConfig, scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, showFrequencyControl: false, showTimeToCompleteControl: false, repeatEveryControlLabel: 'Check Every', defaultRepeatingScheduleFrequency: { unit: TimeUnit.Hours, quantity: 1 } } },
	'OxygenInstructionV2': { ...defaultSheetItemDetailConfig, scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, showFrequencyControl: false, showTimeToCompleteControl: false, repeatEveryControlLabel: 'Check Every', defaultRepeatingScheduleFrequency: { unit: TimeUnit.Hours, quantity: 1 } } },
	'PackedCellVolumeInstruction': defaultSheetItemDetailConfig,
	'PainScoreCatInstruction': defaultSheetItemDetailConfig,
	'PainScoreCatInstructionV2': defaultSheetItemDetailConfig,
	'PainScoreDogInstruction': defaultSheetItemDetailConfig,
	'PainScoreExoticInstruction': defaultSheetItemDetailConfig,
	'PhysiotherapyInstruction': { ...defaultSheetItemDetailConfig, scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, defaultScheduleType: ScheduleType.OneOff } },
	'PlaceIntravenousCatheterInstruction': { ...defaultSheetItemDetailConfig, scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, defaultRepeatingScheduleFrequency: { unit: TimeUnit.Days, quantity: 3 } } },
	'PlateletCountInstruction': defaultSheetItemDetailConfig,
	'PtsInstruction': { ...defaultSheetItemDetailConfig, scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, defaultScheduleType: ScheduleType.OneOff } },
	'RemovePressureBandageInstruction': { ...defaultSheetItemDetailConfig, scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, defaultScheduleType: ScheduleType.OneOff } },
	'RespiratoryRateInstruction': defaultSheetItemDetailConfig,
	'SchirmerTearTestLeftEyeInstruction': defaultSheetItemDetailConfig,
	'SchirmerTearTestLeftEyeInstructionV2': defaultSheetItemDetailConfig,
	'SchirmerTearTestRightEyeInstruction': defaultSheetItemDetailConfig,
	'SchirmerTearTestRightEyeInstructionV2': defaultSheetItemDetailConfig,
	'SkincareInstruction': defaultSheetItemDetailConfig,
	'SoapInstruction': { ...defaultSheetItemDetailConfig, scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, defaultRepeatingScheduleFrequency: { unit: TimeUnit.Hours, quantity: 24 } } },
	'StandardEcgInstruction': { ...defaultSheetItemDetailConfig, scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, defaultScheduleType: ScheduleType.OneOff } },
	'SurgeryInstruction': { ...defaultSheetItemDetailConfig, scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, defaultScheduleType: ScheduleType.OneOff, timeToCompleteControlLabel: 'Duration' } },
	'TemperatureInstruction': defaultSheetItemDetailConfig,
	'ToGoHomeInstruction': { ...defaultSheetItemDetailConfig, scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, defaultScheduleType: ScheduleType.OneOff } },
	'TotalSolidsInstruction': defaultSheetItemDetailConfig,
	'TransferInstruction': { ...defaultSheetItemDetailConfig, scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, defaultScheduleType: ScheduleType.OneOff } },
	'TransferInstructionV2': { ...defaultSheetItemDetailConfig, scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, defaultScheduleType: ScheduleType.OneOff } },
	'UrinalysisInstruction': defaultSheetItemDetailConfig,
	'UrineInstruction': defaultSheetItemDetailConfig,
	'UrineOutputInstruction': defaultSheetItemDetailConfig,
	'UrineOutputInstructionV2': defaultSheetItemDetailConfig,
	'UrineSampleInstruction': defaultSheetItemDetailConfig,
	'VomitingOrRegurgitationInstruction': defaultSheetItemDetailConfig,
	'WalkOutInstruction': defaultSheetItemDetailConfig,
	'WaterDiaryInstruction': defaultSheetItemDetailConfig,
	'WeightInstruction': { ...defaultSheetItemDetailConfig, scheduleConfig: { ...defaultSheetItemDetailConfig.scheduleConfig, defaultRepeatingScheduleFrequency: { unit: TimeUnit.Hours, quantity: 24 } } }
}

const sheetItemDetailTypes: { [key: string]: any } = {
	'AuthorisationClinicianInstruction': AuthorisationClinicianItemComponent,
	'AuthorisationInstruction': AuthorisationItemComponent,
	'BandageCheckInstruction': BandageCheckItemComponent,
	'BearScoreInstruction': BearScoreItemComponent,
	'BloodGasInstruction': BloodGasItemComponent,
	'BloodGlucoseInstruction': BloodGlucoseItemComponent,
	'BloodPressureInstruction': BloodPressureItemComponent,
	'BloodSampleInstruction': BloodSampleItemComponent,
	'BusterCollarInstruction': BusterCollarItemComponent,
	'CheckBedOrLitterTrayInstruction': CheckBedOrLitterTrayItemComponent,
	'CheckBladderSizeInstruction': CheckBladderSizeItemComponent,
	'CheckBladderSizeInstructionV2': CheckBladderSizeItemComponentV2,
	'CheckIdTagInstruction': CheckIdTagItemComponent,
	'CheckIvCatheterInstruction': CheckIvCatheterItemComponent,
	'CheckWoundInstruction': CheckWoundItemComponent,
	'CleanKennelInstruction': CleanKennelItemComponent,
	'ColdCompressInstruction': ColdCompressItemComponent,
	'ColdCompressInstructionV2': ColdCompressItemComponentV2,
	'ContinuousEcgInstruction': ContinuousEcgItemComponent,
	'DiagnosticsInstruction': DiagnosticsItemComponent,
	'EndoscopyInstruction': EndoscopyItemComponent,
	'EuthanasiaInstruction': EuthanasiaItemComponent,
	'EyeLubricationInstruction': EyeLubricationItemComponent,
	'FaecesCheckInstruction': FaecesCheckItemComponent,
	'FaecesSampleInstruction': FaecesSampleItemComponent,
	'FluidsBolusInstruction': FluidsBolusItemComponent,
	'FluidsInfusionInstruction': FluidsInfusionItemComponent,
	'FluidsInfusionInstructionV2': FluidsInfusionItemComponentV2,
	'FluidsInfusionRedLabelInstruction': FluidsInfusionRedLabelItemComponent,
	'FluidsSubcutaneousInstruction': FluidsSubcutaneousItemComponent,
	'FlushIntravenousCatheterInstruction': FlushIntravenousCatheterItemComponent,
	'FoodBowlOutInstruction': FoodBowlOutItemComponent,
	'GlasgowComaScaleInstruction': GlasgowComaScaleItemComponent,
	'HeartRateInstruction': HeartRateItemComponent,
	'HotCompressInstruction': HotCompressItemComponent,
	'HydrotherapyInstruction': HydrotherapyItemComponent,
	'IntraoccularPressureLeftEyeInstruction': IntraoccularPressureLeftEyeItemComponent,
	'IntraoccularPressureRightEyeInstruction': IntraoccularPressureRightEyeItemComponent,
	'IvtfSupplementationInstruction': IvtfSupplementationItemComponent,
	'MedicationInfusionInstruction': MedicationInfusionItemComponent,
	'MedicationInfusionInstructionV2': MedicationInfusionItemComponentV2,
	'MedicationInfusionRedLabelInstruction': MedicationInfusionRedLabelItemComponent,
	'MedicationInfusionRedLabelInstructionV2': MedicationInfusionRedLabelItemComponentV2,
	'MedicationInstruction': MedicationItemComponent,
	'MedicationInstructionV2': MedicationItemComponentV2,
	'MedicationRedLabelInstruction': MedicationRedLabelItemComponent,
	'MedicationRedLabelInstructionV2': MedicationRedLabelItemComponentV2,
	'MentationInstruction': MentationItemComponent,
	'MucousMembranesAndCrtInstruction': MucousMembranesAndCrtItemComponent,
	'NegativePressureWoundTherapyInstruction': NegativePressureWoundTherapyItemComponent,
	'NilByMouthInstruction': NilByMouthItemComponent,
	'NoteInstruction': NoteItemComponent,
	'NutritionInstruction': NutritionItemComponent,
	'NutritionInstructionV2': NutritionItemComponentV2,
	'OfferWaterInstruction': OfferWaterItemComponent,
	'OralHealthCareInstruction': OralHealthCareItemComponent,
	'OtherInstruction': OtherItemComponent,
	'OtherMeasurementInstruction': OtherMeasurementItemComponent,
	'OxygenInstruction': OxygenItemComponent,
	'OxygenInstructionV2': OxygenItemComponentV2,
	'PackedCellVolumeInstruction': PackedCellVolumeItemComponent,
	'PainScoreCatInstruction': PainScoreCatItemComponent,
	'PainScoreCatInstructionV2': PainScoreCatItemComponentV2,
	'PainScoreDogInstruction': PainScoreDogItemComponent,
	'PainScoreExoticInstruction': PainScoreExoticItemComponent,
	'PhysiotherapyInstruction': PhysiotherapyItemComponent,
	'PlaceIntravenousCatheterInstruction': PlaceIntravenousCatheterItemComponent,
	'PlateletCountInstruction': PlateletCountItemComponent,
	'PtsInstruction': PtsItemComponent,
	'RemovePressureBandageInstruction': RemovePressureBandageItemComponent,
	'RespiratoryRateInstruction': RespiratoryRateItemComponent,
	'SchirmerTearTestLeftEyeInstruction': SchirmerTearTestLeftEyeItemComponent,
	'SchirmerTearTestLeftEyeInstructionV2': SchirmerTearTestLeftEyeItemComponentV2,
	'SchirmerTearTestRightEyeInstruction': SchirmerTearTestRightEyeItemComponent,
	'SchirmerTearTestRightEyeInstructionV2': SchirmerTearTestRightEyeItemComponentV2,
	'SkincareInstruction': SkincareItemComponent,
	'SoapInstruction': SoapItemComponent,
	'StandardEcgInstruction': StandardEcgItemComponent,
	'SurgeryInstruction': SurgeryItemComponent,
	'TemperatureInstruction': TemperatureItemComponent,
	'ToGoHomeInstruction': ToGoHomeItemComponent,
	'TotalSolidsInstruction': TotalSolidsItemComponent,
	'TransferInstruction': TransferItemComponent,
	'TransferInstructionV2': TransferItemComponentV2,
	'UrinalysisInstruction': UrinalysisItemComponent,
	'UrineInstruction': UrineItemComponent,
	'UrineOutputInstruction': UrineOutputItemComponent,
	'UrineOutputInstructionV2': UrineOutputItemComponentV2,
	'UrineSampleInstruction': UrineSampleItemComponent,
	'VomitingOrRegurgitationInstruction': VomitingOrRegurgitationItemComponent,
	'WalkOutInstruction': WalkOutItemComponent,
	'WaterDiaryInstruction': WaterDiaryItemComponent,
	'WeightInstruction': WeightItemComponent
};

const sheetItemEditorFormTypes: { [key: string]: any } = {
	'AuthorisationClinicianInstruction': AuthorisationClinicianItemFormComponent,
	'AuthorisationInstruction': AuthorisationItemFormComponent,
	'BandageCheckInstruction': BandageCheckItemFormComponent,
	'BearScoreInstruction': BearScoreItemFormComponent,
	'BloodGasInstruction': BloodGasItemFormComponent,
	'BloodGlucoseInstruction': BloodGlucoseItemFormComponent,
	'BloodPressureInstruction': BloodPressureItemFormComponent,
	'BloodSampleInstruction': BloodSampleItemFormComponent,
	'BusterCollarInstruction': BusterCollarItemFormComponent,
	'CheckBedOrLitterTrayInstruction': CheckBedOrLitterTrayItemFormComponent,
	'CheckBladderSizeInstruction': CheckBladderSizeItemFormComponent,
	'CheckBladderSizeInstructionV2': CheckBladderSizeItemFormComponentV2,
	'CheckIdTagInstruction': CheckIdTagItemFormComponent,
	'CheckIvCatheterInstruction': CheckIvCatheterItemFormComponent,
	'CheckWoundInstruction': CheckWoundItemFormComponent,
	'CleanKennelInstruction': CleanKennelItemFormComponent,
	'ColdCompressInstruction': ColdCompressItemFormComponent,
	'ColdCompressInstructionV2': ColdCompressItemFormComponentV2,
	'ContinuousEcgInstruction': ContinuousEcgItemFormComponent,
	'DiagnosticsInstruction': DiagnosticsItemFormComponent,
	'EndoscopyInstruction': EndoscopyItemFormComponent,
	'EuthanasiaInstruction': EuthanasiaItemFormComponent,
	'EyeLubricationInstruction': EyeLubricationItemFormComponent,
	'FaecesCheckInstruction': FaecesCheckItemFormComponent,
	'FaecesSampleInstruction': FaecesSampleItemFormComponent,
	'FluidsBolusInstruction': FluidsBolusItemFormComponent,
	'FluidsInfusionInstruction': FuildsInfusionItemFormComponent,
	'FluidsInfusionInstructionV2': FuildsInfusionItemFormComponentV2,
	'FluidsInfusionRedLabelInstruction': FluidsInfusionRedLabelItemFormComponent,
	'FluidsSubcutaneousInstruction': FluidsSubcutaneousItemFormComponent,
	'FlushIntravenousCatheterInstruction': FlushIntravenousCatheterItemFormComponent,
	'FoodBowlOutInstruction': FoodBowlOutItemFormComponent,
	'GlasgowComaScaleInstruction': GlasgowComaScaleItemFormComponent,
	'HeartRateInstruction': HeartRateItemFormComponent,
	'HotCompressInstruction': HotCompressItemFormComponent,
	'HydrotherapyInstruction': HydrotherapyItemFormComponent,
	'IntraoccularPressureLeftEyeInstruction': IntraoccularPressureLeftEyeItemFormComponent,
	'IntraoccularPressureRightEyeInstruction': IntraoccularPressureRightEyeItemFormComponent,
	'IvtfSupplementationInstruction': IvtfSupplementationItemFormComponent,
	'MedicationInfusionInstruction': MedicationInfusionItemFormComponent,
	'MedicationInfusionInstructionV2': MedicationInfusionItemFormComponentV2,
	'MedicationInfusionRedLabelInstruction': MedicationInfusionRedLabelItemFormComponent,
	'MedicationInfusionRedLabelInstructionV2': MedicationInfusionRedLabelItemFormComponentV2,
	'MedicationInstruction': MedicationItemFormComponent,
	'MedicationInstructionV2': MedicationItemFormComponentV2,
	'MedicationRedLabelInstruction': MedicationRedLabelItemFormComponent,
	'MedicationRedLabelInstructionV2': MedicationRedLabelItemFormComponentV2,
	'MentationInstruction': MentationItemFormComponent,
	'MucousMembranesAndCrtInstruction': MucousMembranesAndCrtItemFormComponent,
	'NegativePressureWoundTherapyInstruction': NegativePressureWoundTherapyItemFormComponent,
	'NilByMouthInstruction': NilByMouthItemFormComponent,
	'NoteInstruction': NoteItemFormComponent,
	'NutritionInstruction': NutritionItemFormComponent,
	'NutritionInstructionV2': NutritionItemFormComponentV2,
	'OfferWaterInstruction': OfferWaterItemFormComponent,
	'OralHealthCareInstruction': OralHealthCareItemFormComponent,
	'OtherInstruction': OtherItemFormComponent,
	'OtherMeasurementInstruction': OtherMeasurementItemFormComponent,
	'OxygenInstruction': OxygenItemFormComponent,
	'OxygenInstructionV2': OxygenItemFormComponentV2,
	'PackedCellVolumeInstruction': PackedCellVolumeItemFormComponent,
	'PainScoreCatInstruction': PainScoreCatItemFormComponent,
	'PainScoreCatInstructionV2': PainScoreCatItemFormComponentV2,
	'PainScoreDogInstruction': PainScoreDogItemFormComponent,
	'PainScoreExoticInstruction': PainScoreExoticItemFormComponent,
	'PhysiotherapyInstruction': PhysiotherapyItemFormComponent,
	'PlaceIntravenousCatheterInstruction': PlaceIntravenousCatheterItemFormComponent,
	'PlateletCountInstruction': PlateletCountItemFormComponent,
	'PtsInstruction': PtsItemFormComponent,
	'RemovePressureBandageInstruction': RemovePressureBandageItemFormComponent,
	'RespiratoryRateInstruction': RespiratoryRateItemFormComponent,
	'SchirmerTearTestLeftEyeInstruction': SchirmerTearTestLeftEyeItemFormComponent,
	'SchirmerTearTestLeftEyeInstructionV2': SchirmerTearTestLeftEyeItemFormComponentV2,
	'SchirmerTearTestRightEyeInstruction': SchirmerTearTestRightEyeItemFormComponent,
	'SchirmerTearTestRightEyeInstructionV2': SchirmerTearTestRightEyeItemFormComponentV2,
	'SkincareInstruction': SkincareItemFormComponent,
	'SoapInstruction': SoapItemFormComponent,
	'StandardEcgInstruction': StandardEcgItemFormComponent,
	'SurgeryInstruction': SurgeryItemFormComponent,
	'TemperatureInstruction': TemperatureItemFormComponent,
	'ToGoHomeInstruction': ToGoHomeItemFormComponent,
	'TotalSolidsInstruction': TotalSolidsItemFormComponent,
	'TransferInstruction': TransferItemFormComponent,
	'TransferInstructionV2': TransferItemFormComponentV2,
	'UrinalysisInstruction': UrinalysisItemFormComponent,
	'UrineInstruction': UrineItemFormComponent,
	'UrineOutputInstruction': UrineOutputItemFormComponent,
	'UrineOutputInstructionV2': UrineOutputItemFormComponentV2,
	'UrineSampleInstruction': UrineSampleItemFormComponent,
	'VomitingOrRegurgitationInstruction': VomitingOrRegurgitationItemFormComponent,
	'WalkOutInstruction': WalkOutItemFormComponent,
	'WaterDiaryInstruction': WaterDiaryItemFormComponent,
	'WeightInstruction': WeightItemFormComponent
};

const sheetSlotDetailTypes: { [key: string]: any } = {
	'AuthorisationAction': AuthorisationSlotComponent,
	'AuthorisationClinicianAction': AuthorisationClinicianSlotComponent,
	'BandageCheckAction': BandageCheckSlotComponent,
	'BearScoreAction': BearScoreSlotComponent,
	'BloodGasAction': BloodGasSlotComponent,
	'BloodGlucoseAction': BloodGlucoseSlotComponent,
	'BloodPressureAction': BloodPressureSlotComponent,
	'BloodSampleAction': BloodSampleSlotComponent,
	'BusterCollarAction': BusterCollarSlotComponent,
	'CheckBedOrLitterTrayAction': CheckBedOrLitterTraySlotComponent,
	'CheckBladderSizeAction': CheckBladderSizeSlotComponent,
	'CheckBladderSizeActionV2': CheckBladderSizeSlotComponentV2,
	'CheckIdTagAction': CheckIdTagSlotComponent,
	'CheckIvCatheterAction': CheckIvCatheterSlotComponent,
	'CheckWoundAction': CheckWoundSlotComponent,
	'CleanKennelAction': CleanKennelSlotComponent,
	'ColdCompressAction': ColdCompressSlotComponent,
	'ColdCompressActionV2': ColdCompressSlotComponentV2,
	'ContinuousEcgAction': ContinuousEcgSlotComponent,
	'DiagnosticsAction': DiagnosticsSlotComponent,
	'EndoscopyAction': EndoscopySlotComponent,
	'EuthanasiaAction': EuthanasiaSlotComponent,
	'EyeLubricationAction': EyeLubricationSlotComponent,
	'FaecesCheckAction': FaecesCheckSlotComponent,
	'FaecesSampleAction': FaecesSampleSlotComponent,
	'FluidsBolusAction': FluidsBolusSlotComponent,
	'FluidsInfusionAction': FluidsInfusionSlotComponent,
	'FluidsInfusionActionV2': FluidsInfusionSlotComponentV2,
	'FluidsInfusionRedLabelAction': FluidsInfusionRedLabelSlotComponent,
	'FluidsSubcutaneousAction': FluidsSubcutaneousSlotComponent,
	'FlushIntravenousCatheterAction': FlushIntravenousCatheterSlotComponent,
	'FoodBowlOutAction': FoodBowlOutSlotComponent,
	'GlasgowComaScaleAction': GlasgowComaScaleSlotComponent,
	'HeartRateAction': HeartRateSlotComponent,
	'HotCompressAction': HotCompressSlotComponent,
	'HydrotherapyAction': HydrotherapySlotComponent,
	'IntraoccularPressureLeftEyeAction': IntraoccularPressureLeftEyeSlotComponent,
	'IntraoccularPressureRightEyeAction': IntraoccularPressureRightEyeSlotComponent,
	'IvtfSupplementationAction': IvtfSupplementationSlotComponent,
	'MedicationAction': MedicationSlotComponent,
	'MedicationActionV2': MedicationSlotComponentV2,
	'MedicationInfusionAction': MedicationInfusionSlotComponent,
	'MedicationInfusionActionV2': MedicationInfusionSlotComponentV2,
	'MedicationInfusionRedLabelAction': MedicationInfusionRedLabelSlotComponent,
	'MedicationInfusionRedLabelActionV2': MedicationInfusionRedLabelSlotComponentV2,
	'MedicationRedLabelAction': MedicationRedLabelSlotComponent,
	'MedicationRedLabelActionV2': MedicationRedLabelSlotComponentV2,
	'MentationAction': MentationSlotComponent,
	'MucousMembranesAndCrtAction': MucousMembranesAndCrtSlotComponent,
	'NegativePressureWoundTherapyAction': NegativePressureWoundTherapySlotComponent,
	'NilByMouthAction': NilByMouthSlotComponent,
	'NoteAction': NoteSlotComponent,
	'NutritionAction': NutritionSlotComponent,
	'NutritionActionV2': NutritionSlotComponentV2,
	'OfferWaterAction': OfferWaterSlotComponent,
	'OralHealthCareAction': OralHealthCareSlotComponent,
	'OtherAction': OtherSlotComponent,
	'OtherMeasurementAction': OtherMeasurementSlotComponent,
	'OxygenAction': OxygenSlotComponent,
	'OxygenActionV2': OxygenSlotComponentV2,
	'PackedCellVolumeAction': PackedCellVolumeSlotComponent,
	'PainScoreCatAction': PainScoreCatSlotComponent,
	'PainScoreCatActionV2': PainScoreCatSlotComponentV2,
	'PainScoreDogAction': PainScoreDogSlotComponent,
	'PainScoreExoticAction': PainScoreExoticSlotComponent,
	'PhysiotherapyAction': PhysiotherapySlotComponent,
	'PlaceIntravenousCatheterAction': PlaceIntravenousCatheterSlotComponent,
	'PlateletCountAction': PlateletCountSlotComponent,
	'PtsAction': PtsSlotComponent,
	'RemovePressureBandageAction': RemovePressureBandageSlotComponent,
	'RespiratoryRateAction': RespiratoryRateSlotComponent,
	'SchirmerTearTestLeftEyeAction': SchirmerTearTestLeftEyeSlotComponent,
	'SchirmerTearTestLeftEyeActionV2': SchirmerTearTestLeftEyeSlotComponentV2,
	'SchirmerTearTestRightEyeAction': SchirmerTearTestRightEyeSlotComponent,
	'SchirmerTearTestRightEyeActionV2': SchirmerTearTestRightEyeSlotComponentV2,
	'SkincareAction': SkincareSlotComponent,
	'SoapAction': SoapSlotComponent,
	'StandardEcgAction': StandardEcgSlotComponent,
	'SurgeryAction': SurgerySlotComponent,
	'TemperatureAction': TemperatureSlotComponent,
	'ToGoHomeAction': ToGoHomeSlotComponent,
	'TotalSolidsAction': TotalSolidsSlotComponent,
	'TransferAction': TransferSlotComponent,
	'TransferActionV2': TransferSlotComponentV2,
	'UrinalysisAction': UrinalysisSlotComponent,
	'UrineAction': UrineSlotComponent,
	'UrineOutputAction': UrineOutputSlotComponent,
	'UrineOutputActionV2': UrineOutputSlotComponentV2,
	'UrineSampleAction': UrineSampleSlotComponent,
	'VomitingOrRegurgitationAction': VomitingOrRegurgitationSlotComponent,
	'WalkOutAction': WalkOutSlotComponent,
	'WaterDiaryAction': WaterDiarySlotComponent,
	'WeightAction': WeightSlotComponent
};

const sheetSlotEditorControlsTypes: { [key: string]: any } = {
	'AuthorisationAction': AuthorisationActionControlsComponent,
	'AuthorisationClinicianAction': AuthorisationClinicianActionControlsComponent,
	'BandageCheckAction': BandageCheckActionControlsComponent,
	'BearScoreAction': BearScoreActionControlsComponent,
	'BloodGasAction': BloodGasActionControlsComponent,
	'BloodGlucoseAction': BloodGlucoseActionControlsComponent,
	'BloodPressureAction': BloodPressureActionControlsComponent,
	'BloodSampleAction': BloodSampleActionControlsComponent,
	'BusterCollarAction': BusterCollarActionControlsComponent,
	'CheckBedOrLitterTrayAction': CheckBedOrLitterTrayActionControlsComponent,
	'CheckBladderSizeAction': CheckBladderSizeActionControlsComponent,
	'CheckBladderSizeActionV2': CheckBladderSizeActionControlsComponentV2,
	'CheckIdTagAction': CheckIdTagActionControlsComponent,
	'CheckIvCatheterAction': CheckIvCatheterActionControlsComponent,
	'CheckWoundAction': CheckWoundActionControlsComponent,
	'CleanKennelAction': CleanKennelActionControlsComponent,
	'ColdCompressAction': ColdCompressActionControlsComponent,
	'ColdCompressActionV2': ColdCompressActionControlsComponentV2,
	'ContinuousEcgAction': ContinuousEcgActionControlsComponent,
	'DiagnosticsAction': DiagnosticsActionControlsComponent,
	'EndoscopyAction': EndoscopyActionControlsComponent,
	'EuthanasiaAction': EuthanasiaActionControlsComponent,
	'EyeLubricationAction': EyeLubricationActionControlsComponent,
	'FaecesCheckAction': FaecesCheckActionControlsComponent,
	'FaecesSampleAction': FaecesSampleActionControlsComponent,
	'FluidsBolusAction': FluidsBolusActionControlsComponent,
	'FluidsInfusionAction': FluidsInfusionActionControlsComponent,
	'FluidsInfusionActionV2': FluidsInfusionActionControlsComponentV2,
	'FluidsInfusionRedLabelAction': FluidsInfusionRedLabelActionControlsComponent,
	'FluidsSubcutaneousAction': FluidsSubcutaneousActionControlsComponent,
	'FlushIntravenousCatheterAction': FlushIntravenousCatheterActionControlsComponent,
	'FoodBowlOutAction': FoodBowlOutActionControlsComponent,
	'GlasgowComaScaleAction': GlasgowComaScaleActionControlsComponent,
	'HeartRateAction': HeartRateActionControlsComponent,
	'HotCompressAction': HotCompressActionControlsComponent,
	'HydrotherapyAction': HydrotherapyActionControlsComponent,
	'IntraoccularPressureLeftEyeAction': IntraoccularPressureLeftEyeActionControlsComponent,
	'IntraoccularPressureRightEyeAction': IntraoccularPressureRightEyeActionControlsComponent,
	'IvtfSupplementationAction': IvtfSupplementationActionControlsComponent,
	'MedicationAction': MedicationActionControlsComponent,
	'MedicationActionV2': MedicationActionControlsComponentV2,
	'MedicationInfusionAction': MedicationInfusionActionControlsComponent,
	'MedicationInfusionActionV2': MedicationInfusionActionControlsComponentV2,
	'MedicationInfusionRedLabelAction': MedicationInfusionRedLabelActionControlsComponent,
	'MedicationInfusionRedLabelActionV2': MedicationInfusionRedLabelActionControlsComponentV2,
	'MedicationRedLabelAction': MedicationRedLabelActionControlsComponent,
	'MedicationRedLabelActionV2': MedicationRedLabelActionControlsComponentV2,
	'MentationAction': MentationActionControlsComponent,
	'MucousMembranesAndCrtAction': MucousMembranesAndCrtActionControlsComponent,
	'NegativePressureWoundTherapyAction': NegativePressureWoundTherapyActionControlsComponent,
	'NilByMouthAction': NilByMouthActionControlsComponent,
	'NoteAction': NoteActionControlsComponent,
	'NutritionAction': NutritionActionControlsComponent,
	'NutritionActionV2': NutritionActionControlsComponentV2,
	'OfferWaterAction': OfferWaterActionControlsComponent,
	'OralHealthCareAction': OralHealthCareActionControlsComponent,
	'OtherAction': OtherActionControlsComponent,
	'OtherMeasurementAction': OtherMeasurementActionControlsComponent,
	'OxygenAction': OxygenActionControlsComponent,
	'OxygenActionV2': OxygenActionControlsComponentV2,	
	'PackedCellVolumeAction': PackedCellVolumeActionControlsComponent,
	'PainScoreCatAction': PainScoreCatActionControlsComponent,
	'PainScoreCatActionV2': PainScoreCatActionControlsComponentV2,
	'PainScoreDogAction': PainScoreDogActionControlsComponent,
	'PainScoreExoticAction': PainScoreExoticActionControlsComponent,
	'PhysiotherapyAction': PhysiotherapyActionControlsComponent,
	'PlaceIntravenousCatheterAction': PlaceIntravenousCatheterActionControlsComponent,
	'PlateletCountAction': PlateletCountActionControlsComponent,
	'PtsAction': PtsActionControlsComponent,
	'RemovePressureBandageAction': RemovePressureBandageActionControlsComponent,
	'RespiratoryRateAction': RespiratoryRateActionControlsComponent,
	'SchirmerTearTestLeftEyeAction': SchirmerTearTestLeftEyeActionControlsComponent,
	'SchirmerTearTestLeftEyeActionV2': SchirmerTearTestLeftEyeActionControlsComponentV2,
	'SchirmerTearTestRightEyeAction': SchirmerTearTestRightEyeActionControlsComponent,
	'SchirmerTearTestRightEyeActionV2': SchirmerTearTestRightEyeActionControlsComponentV2,
	'SkincareAction': SkincareActionControlsComponent,
	'SoapAction': SoapActionControlsComponent,
	'StandardEcgAction': StandardEcgActionControlsComponent,
	'SurgeryAction': SurgeryActionControlsComponent,
	'TemperatureAction': TemperatureActionControlsComponent,
	'ToGoHomeAction': ToGoHomeActionControlsComponent,
	'TotalSolidsAction': TotalSolidsActionControlsComponent,
	'TransferAction': TransferActionControlsComponent,
	'TransferActionV2': TransferActionControlsComponentV2,
	'UrinalysisAction': UrinalysisActionControlsComponent,
	'UrineAction': UrineActionControlsComponent,
	'UrineOutputAction': UrineOutputActionControlsComponent,
	'UrineOutputActionV2': UrineOutputActionControlsComponentV2,
	'UrineSampleAction': UrineSampleActionControlsComponent,
	'VomitingOrRegurgitationAction': VomitingOrRegurgitationActionControlsComponent,
	'WalkOutAction': WalkOutActionControlsComponent,
	'WaterDiaryAction': WaterDiaryActionControlsComponent,
	'WeightAction': WeightActionControlsComponent
};
