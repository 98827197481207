import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { enterLeave } from 'src/app/animations/common.animations';
import { HeadingComponent } from 'src/app/components/heading/heading.component';
import { ItemLabelComponent } from 'src/app/components/item-label/item-label.component';
import { ItemValueComponent } from 'src/app/components/item-value/item-value.component';
import { ItemComponent } from 'src/app/components/item/item.component';
import { DateService } from 'src/app/services/date.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';

@Component({
	selector: 'app-action-form',
	templateUrl: './action-form.component.html',
	styleUrls: ['../../common.scss', './action-form.component.scss'],
	standalone: true,
	imports: [HeadingComponent, ItemComponent, ItemLabelComponent, ItemValueComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [enterLeave]
})
export class ActionFormComponent {

	actionedByString: string = '';

	@Input() item!: Row | null;

	slot!: Slot | null;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;

		if (slot?.actionedBy && slot?.actionedDate) {
			this.actionedByString = slot?.action?.isComplete || slot?.action?.isSkipped ? `${slot?.actionedBy}, ${this.dateService.formatAsDay(slot?.actionedDate!, 'dd/MM/yyyy')} ${this.dateService.format(slot?.actionedDate!, 'HH:mm')}` : '';
		}
		else {
			this.actionedByString = '';
		}
	}

	@Input() form!: FormGroup;

	constructor(private dateService: DateService) {
	}
}