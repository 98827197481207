import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { formatISO, roundToNearestHours } from 'date-fns';
import { isAfter } from '../form-validators/is-after.validator';
import { Instruction, OnAdmitAndDailySchedule, OneOffSchedule, RepeatingSchedule, Row, Schedule, ScheduleType, Unscheduled, WhenRequired } from '../types/aggregate-graph.types';
import { TimeQuantity, TimeSpanConversionService, TimeUnit } from './timespan-conversion.service';

interface InstructionFormGroup {
	id: FormControl<number>,
	schedule: FormGroup<ScheduleFormGroup> | FormGroup<OneOffScheduleFormGroup> | FormGroup<RepeatingScheduleFormGroup>,
	instructionTypeName: FormControl<string>
}

interface ScheduleFormGroup {
	scheduleTypeName: FormControl<string>
}

interface OneOffScheduleFormGroup extends ScheduleFormGroup {
	start: FormControl<string>,
	timeToComplete: FormControl<string>
}

interface RepeatingScheduleFormGroup extends ScheduleFormGroup {
	start: FormControl<string>,
	timeToComplete: FormControl<string>,
	frequency: FormControl<string>,
	last: FormControl<string | null>
}

@Injectable({
	providedIn: 'root'
})
export class SheetItemService {

	constructor(private formBuilder: FormBuilder, private timeSpanConversionService: TimeSpanConversionService) {
	}

	getInstructions(item: Row | null, instructionTypeName: string, defaultScheduleType: ScheduleType, defaultRepeatingScheduleFrequency: TimeQuantity): Instruction[] {
		if (item) {
			return Object.entries(item.instructions).map(instructionKeyVal => instructionKeyVal[1]);
		}
		else {
			return [this.getNewInstruction(instructionTypeName, defaultScheduleType, defaultRepeatingScheduleFrequency)];
		}
	}

	private getNewInstruction(instructionTypeName: string, defaultScheduleType: ScheduleType, defaultRepeatingScheduleFrequency: TimeQuantity) {
		return {
			id: 0,
			schedule: this.getNewSchedule(defaultScheduleType, defaultRepeatingScheduleFrequency),
			instructionTypeName: instructionTypeName,
			comment: ''
		} as Instruction;
	}

	getNewSchedule(scheduleType: ScheduleType, defaultRepeatingScheduleFrequency: TimeQuantity ): Unscheduled | WhenRequired | OneOffSchedule | RepeatingSchedule | OnAdmitAndDailySchedule {

		const schedule = { scheduleTypeName: scheduleType } as Schedule;

		switch (scheduleType) {

			case ScheduleType.OneOff:
				const oneOffSchedule = schedule as OneOffSchedule;
				oneOffSchedule['start'] = formatISO(roundToNearestHours(new Date(), { roundingMethod: 'floor' }));
				oneOffSchedule['timeToComplete'] = this.timeSpanConversionService.convertTimeUnitToCSharpTimeSpan(TimeUnit.Hours, 1);
				return oneOffSchedule;

			case ScheduleType.Repeating:
				const repeatingSchedule = schedule as RepeatingSchedule;
				repeatingSchedule['start'] = formatISO(roundToNearestHours(new Date(), { roundingMethod: 'floor' }));
				repeatingSchedule['timeToComplete'] = this.timeSpanConversionService.convertTimeUnitToCSharpTimeSpan(TimeUnit.Hours, 1);
				repeatingSchedule['frequency'] = this.timeSpanConversionService.convertTimeUnitToCSharpTimeSpan(defaultRepeatingScheduleFrequency.unit!, defaultRepeatingScheduleFrequency.quantity!);
				repeatingSchedule['last'] = null;
				return repeatingSchedule;

			default:
				return schedule as Unscheduled | WhenRequired | OnAdmitAndDailySchedule;
		}
	}

	buildExistingInstructionGroup(instructionTypeName: string, instruction: Instruction) {
		return this.getInstructionFormGroup(instructionTypeName, instruction);
	}

	getNewInstructionGroup(instructionTypeName: string, defaultScheduleType: ScheduleType, defaultRepeatingScheduleFrequency: TimeQuantity) {
		return this.getInstructionFormGroup(instructionTypeName, this.getNewInstruction(instructionTypeName, defaultScheduleType, defaultRepeatingScheduleFrequency));
	}

	private getInstructionFormGroup(instructionTypeName: string, instruction: Instruction): FormGroup<any> {
		return this.formBuilder.group<InstructionFormGroup>({
			id: this.formBuilder.nonNullable.control(instruction?.id ?? 0),
			schedule: this.getScheduleFormGroup(instruction.schedule),
			instructionTypeName: this.formBuilder.nonNullable.control(instructionTypeName)
		})
	}

	getScheduleFormGroup(schedule: Schedule) {

		switch (schedule.scheduleTypeName) {

			case ScheduleType.OneOff:
				return this.formBuilder.group<OneOffScheduleFormGroup>({
					scheduleTypeName: this.formBuilder.nonNullable.control(schedule.scheduleTypeName),
					start: this.formBuilder.nonNullable.control((schedule as OneOffSchedule).start),
					timeToComplete: this.formBuilder.nonNullable.control((schedule as OneOffSchedule).timeToComplete)
				}) as FormGroup<OneOffScheduleFormGroup>;

			case ScheduleType.Repeating:
				return this.formBuilder.group<RepeatingScheduleFormGroup>({
					scheduleTypeName: this.formBuilder.nonNullable.control(schedule.scheduleTypeName),
					start: this.formBuilder.nonNullable.control((schedule as RepeatingSchedule).start),
					timeToComplete: this.formBuilder.nonNullable.control((schedule as RepeatingSchedule).timeToComplete),
					frequency: this.formBuilder.nonNullable.control((schedule as RepeatingSchedule).frequency, [Validators.required]),
					last: this.formBuilder.nonNullable.control((schedule as RepeatingSchedule).last)
				}, { validators: isAfter('last', 'start') }) as FormGroup<RepeatingScheduleFormGroup>;

			default:
				return this.formBuilder.group<ScheduleFormGroup>({ scheduleTypeName: this.formBuilder.nonNullable.control(schedule.scheduleTypeName) }) as FormGroup<ScheduleFormGroup>;
		}
	}
}
