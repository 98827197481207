import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, delay, filter, map, switchMap } from 'rxjs/operators';
import { routeParams } from 'src/app/app.routes';
import { ErrorService } from 'src/app/services/error.service';
import { Client, ClientTypeName, GraphAggregateChangeOperation, Patient, PatientTypeName, PatientRecordTask, TaskDocument, TaskDocumentTypeName, TaskTypeName, Visit, VisitTypeName } from 'src/app/types/aggregate-graph.types';
import { connectHubCompleted } from '../aggregate-graph-hub/aggregate-graph-hub.actions';
import { clientErrored } from '../error/error.actions';
import * as patientGraphActions from './patient-graph.actions';
import { AppState } from '../reducers';
import { accessToken } from '../auth/auth.selectors';
import { entityIdentifiers, patientId } from './patient-graph.selectors';
import { selectRouteParam } from '../router/router.selectors';

const TransitionDelayInMilliseconds = 500;

@Injectable()
export class PatientGraphEffects {

	constructor(private actions$: Actions, private store: Store<AppState>, private errorService: ErrorService) {
	}

	patientIdParamChanged$ = createEffect(() =>
		this.store.select(selectRouteParam(routeParams.patientId)).pipe(
			concatLatestFrom(() => [
				this.store.select(accessToken),
				this.store.select(patientId),
				this.store.select(selectRouteParam(routeParams.taskId)),
				this.store.select(selectRouteParam(routeParams.serviceId))
			]),
			filter(([, accessToken]) => !!accessToken),
			switchMap(([patientId, , previousPatientId, taskId, serviceId]) => {
				const actions: any[] = [];
				if (previousPatientId) {
					actions.push(patientGraphActions.unsubscribePatientRequested());
				}
				if (patientId) {
					actions.push(patientGraphActions.subscribePatientRequested({ patientChanged: true, patientId: patientId, taskId: taskId ?? '', serviceId: serviceId ?? '' }));
				}
				return actions;
			})
		)
	);

	patientGraphGenerated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientGraphActions.patientGraphGenerated),
			delay(TransitionDelayInMilliseconds),
			concatLatestFrom(() => this.store.select(entityIdentifiers)),
			filter(([action, identifiers]) => action.patientId === identifiers.patientId),
			map(([action]) =>
				patientGraphActions.patientGraphLoaded({
					patient: action.aggregates.find(agg => agg.aggregateType === PatientTypeName) as Patient,
					clients: action.aggregates.filter(agg => agg.aggregateType === ClientTypeName) as Client[],
					visits: action.aggregates.filter(agg => agg.aggregateType === VisitTypeName) as Visit[],
					tasks: action.aggregates.filter(agg => agg.aggregateType === TaskTypeName) as PatientRecordTask[],
					taskDocuments: action.aggregates.filter(agg => agg.aggregateType === TaskDocumentTypeName) as TaskDocument[]
				})
			),
			catchError(error => of(clientErrored({ toastMessage: 'Patient graph generation failed', errorMessage: this.errorService.getErrorMessage(error) })))
		)
	);

	patientGraphChanged$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientGraphActions.patientGraphChanged),
			concatLatestFrom(() => this.store.select(patientId)),
			filter(([action, selectedPatientId]) => action.patientId === selectedPatientId),
			map(([action,]) => action.changes),
			concatMap(changes => {
				const actions: any[] = [];
				changes.forEach(change => {
					// guard against b/e not sending the operation
					if (!change.operation) throw new Error('Error processing patientGraphChanged action: operation is missing');

					// visits can be updated or added
					if (change.aggregate.aggregateType === VisitTypeName) {
						if (change.operation === GraphAggregateChangeOperation.Update) {
							actions.push(patientGraphActions.visitUpdated({ partialVisit: change.aggregate as Visit }));
						}
						else if (change.operation === GraphAggregateChangeOperation.Addition) {
							actions.push(patientGraphActions.visitAdded({ visit: change.aggregate as Visit }));
						}
					}
					// patients can be updated
					else if (change.aggregate.aggregateType === PatientTypeName && change.operation === GraphAggregateChangeOperation.Update) {
						actions.push(patientGraphActions.patientUpdated({ partialPatient: change.aggregate }));
					}
					// clients can be updated
					else if (change.aggregate.aggregateType === ClientTypeName && change.operation === GraphAggregateChangeOperation.Update) {
						actions.push(patientGraphActions.clientUpdated({ partialClient: change.aggregate }));
					}
					// tasks can be updated or added
					else if (change.aggregate.aggregateType === TaskTypeName) {
						if (change.operation === GraphAggregateChangeOperation.Update) {
							actions.push(patientGraphActions.taskUpdated({ partialTask: change.aggregate as PatientRecordTask }));
						}
						else if (change.operation === GraphAggregateChangeOperation.Addition) {
							actions.push(patientGraphActions.taskAdded({ task: change.aggregate as PatientRecordTask }));
						}
					}
					// task documents can be updated or added
					else if (change.aggregate.aggregateType === TaskDocumentTypeName) {
						if (change.operation === GraphAggregateChangeOperation.Update) {
							actions.push(patientGraphActions.taskDocumentUpdated({ partialTaskDocument: change.aggregate as TaskDocument }));
						}
						else if (change.operation === GraphAggregateChangeOperation.Addition) {
							actions.push(patientGraphActions.taskDocumentAdded({ taskDocument: change.aggregate as TaskDocument }));
						}
					}
				});
				return actions;
			}),
			catchError(error => of(clientErrored({ toastMessage: 'Patient graph change failed', errorMessage: this.errorService.getErrorMessage(error) })))
		)
	);

	resubscribePatientInitiated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(connectHubCompleted, patientGraphActions.patientGraphSystemRestarted, patientGraphActions.reloadPatientRequested),
			concatLatestFrom(() => this.store.select(entityIdentifiers)),
			filter(([, entityIdentifiers]) => !!entityIdentifiers.patientId),
			map(([, entityIdentifiers]) => patientGraphActions.subscribePatientRequested({ patientChanged: false, patientId: entityIdentifiers.patientId, taskId: entityIdentifiers.taskId, serviceId: entityIdentifiers.serviceId })),
		)
	);
}