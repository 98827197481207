import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { HeadingComponent } from 'src/app/components/heading/heading.component';
import { TextInputControl } from 'src/app/form-controls/text-input/text-input.control';
import { UnitQuantity, UnitQuantityControl } from 'src/app/form-controls/unit-quantity/unit-quantity.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { BloodGasAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface BloodGasActionFormGroup {
	fO2: FormControl<UnitQuantity | null>;
	ph: FormControl<number | null>;
	pCO2: FormControl<UnitQuantity | null>;
	pO2: FormControl<UnitQuantity | null>;
	ctHb: FormControl<UnitQuantity | null>;
	sO2: FormControl<UnitQuantity | null>;
	hCtc: FormControl<UnitQuantity | null>;
	fCOHb: FormControl<UnitQuantity | null>;
	fMetHb: FormControl<UnitQuantity | null>;
	cKPlus: FormControl<UnitQuantity | null>;
	cNaPlus: FormControl<UnitQuantity | null>;
	cCa2Plus: FormControl<UnitQuantity | null>;
	cClMinus: FormControl<UnitQuantity | null>;
	cGlu: FormControl<UnitQuantity | null>;
	cLac: FormControl<UnitQuantity | null>;
	ctBil: FormControl<UnitQuantity | null>;
	cUrea: FormControl<UnitQuantity | null>;
	cCrea: FormControl<UnitQuantity | null>;
	pHT: FormControl<number | null>;
	pCO2T: FormControl<UnitQuantity | null>;
	cBaseEcfC: FormControl<UnitQuantity | null>;
	cHCO3EcfC: FormControl<UnitQuantity | null>;
}

@Component({
	selector: 'app-blood-gas-action-controls',
	templateUrl: './blood-gas-action-controls.component.html',
	styleUrls: ['../../../common.scss', './blood-gas-action-controls.component.scss'],
	standalone: true,
	imports: [HeadingComponent, ReactiveFormsModule, UnitQuantityControl, TextInputControl, CommonActionControlsComponent, ActionFormComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BloodGasActionControlsComponent implements SheetActionControlsBase, OnInit {

	slot!: Slot | null;
	action!: BloodGasAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as BloodGasAction;
	}

	@Input() form!: FormGroup<BloodGasActionFormGroup>;
	@Input() item!: Row | null;
	
	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.form.addControl('fO2', this.formBuilder.control(this.action?.fO2 ?? null));
		this.form.addControl('ph', this.formBuilder.control(this.action?.ph ?? null));
		this.form.addControl('pCO2', this.formBuilder.control(this.action?.pCO2 ?? null));
		this.form.addControl('pO2', this.formBuilder.control(this.action?.pO2 ?? null));
		this.form.addControl('ctHb', this.formBuilder.control(this.action?.ctHb ?? null));
		this.form.addControl('sO2', this.formBuilder.control(this.action?.sO2 ?? null));
		this.form.addControl('hCtc', this.formBuilder.control(this.action?.hCtc ?? null));
		this.form.addControl('fCOHb', this.formBuilder.control(this.action?.fCOHb ?? null));
		this.form.addControl('fMetHb', this.formBuilder.control(this.action?.fMetHb ?? null));
		this.form.addControl('cKPlus', this.formBuilder.control(this.action?.cKPlus ?? null));
		this.form.addControl('cNaPlus', this.formBuilder.control(this.action?.cNaPlus ?? null));
		this.form.addControl('cCa2Plus', this.formBuilder.control(this.action?.cCa2Plus ?? null));
		this.form.addControl('cClMinus', this.formBuilder.control(this.action?.cClMinus ?? null));
		this.form.addControl('cGlu', this.formBuilder.control(this.action?.cGlu ?? null));
		this.form.addControl('cLac', this.formBuilder.control(this.action?.cLac ?? null));
		this.form.addControl('ctBil', this.formBuilder.control(this.action?.ctBil ?? null));
		this.form.addControl('cUrea', this.formBuilder.control(this.action?.cUrea ?? null));
		this.form.addControl('cCrea', this.formBuilder.control(this.action?.cCrea ?? null));
		this.form.addControl('pHT', this.formBuilder.control(this.action?.pHT ?? null));
		this.form.addControl('pCO2T', this.formBuilder.control(this.action?.pCO2T ?? null));
		this.form.addControl('cBaseEcfC', this.formBuilder.control(this.action?.cBaseEcfC ?? null));
		this.form.addControl('cHCO3EcfC', this.formBuilder.control(this.action?.cHCO3EcfC ?? null));
	}
}
