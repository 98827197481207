import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidationErrors, Validator } from '@angular/forms';
import { enterLeave } from 'src/app/animations/common.animations';
import { HeadingComponent } from 'src/app/components/heading/heading.component';
import { ItemValueComponent } from 'src/app/components/item-value/item-value.component';
import { ItemComponent } from 'src/app/components/item/item.component';
import { SvgIconComponent } from 'src/app/components/svg-icon/svg-icon.component';
import { TaskDocumentService } from 'src/app/services/task-document.service';
import { WidgetValueMapperService } from 'src/app/services/widget-value-mapper.service';
import { GroupRepeaterMetaData } from 'src/app/types/aggregate-graph.types';
import { CheckboxControl } from '../checkbox/checkbox.control';
import { ModalityBodyPartsPickerControl } from '../modality-body-parts-picker/modality-body-parts-picker.control';
import { MultiCheckboxControl } from '../multi-checkbox/multi-checkbox.control';
import { MultiItemPickerControl } from '../multi-item-picker/multi-item-picker.control';
import { SelectControl } from '../select/select.control';
import { TextInputControl } from '../text-input/text-input.control';
import { TextAreaControl } from '../textarea/textarea.control';

@Component({
	selector: 'app-group-repeater',
	templateUrl: './group-repeater.control.html',
	styleUrl: './group-repeater.control.scss',
	standalone: true,
	imports: [
		ReactiveFormsModule, HeadingComponent, SvgIconComponent, TextInputControl, TextAreaControl, CheckboxControl, SelectControl,
		MultiCheckboxControl, MultiItemPickerControl, ModalityBodyPartsPickerControl, JsonPipe, ItemComponent, ItemValueComponent
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: GroupRepeaterControl,
			multi: true
		}
	],
	animations: [enterLeave]
})
export class GroupRepeaterControl implements ControlValueAccessor, Validator, OnInit {

	@Input() readOnly: boolean = false;
	@Input() metaData!: GroupRepeaterMetaData;

	formArray!: FormArray;

	constructor(private changeDetectorRef: ChangeDetectorRef, private formBuilder: FormBuilder, private taskDocumentService: TaskDocumentService,
		private widgetValueMapperService: WidgetValueMapperService, private destroyRef: DestroyRef) { // note: DestroyRef is needed if takeUntilDestroyed is used outside the constructor
	}

	ngOnInit() {
		this.formArray = this.formBuilder.array([]);

		if (!this.readOnly) {
			this.formArray.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value: any[]) => {
				this.onChange(value);
				this.onValidatorChange();
				this.changeDetectorRef.markForCheck();
			});
		}
	}

	writeValue(value: { [id: number]: any }[] | null): void {

		// initialise
		const valueArrayLength = value?.length ?? 0;
		let values = structuredClone(value) ?? [];

		// FormArray.setValue() errors if its structure does not match the value, so need to ensure the number of items matches first
		if (this.formArray.length < valueArrayLength) {
			for (let i = this.formArray.length; i < valueArrayLength; i++) this.appendGroup(false);
		}
		else if (this.formArray.length > valueArrayLength) {
			do { this.formArray.removeAt(this.formArray.length - 1, { emitEvent: false }) } while (this.formArray.length > valueArrayLength);
		}

		// add an initial value if at least one group is required
		if (this.metaData.validators?.required && !valueArrayLength) {
			this.addGroupRepeaterGroup(this.getDefaultGroupValues(), false);
			values = this.formArray.value;
		}

		// map any individual widget values
		if (valueArrayLength) {
			values.forEach(groupValue => this.metaData.controls.forEach(control => groupValue[control.id] = this.widgetValueMapperService.mapWidgetValue(control.type, groupValue[control.id])));
		}

		// set the value
		this.formArray.setValue(values ?? [], { emitEvent: false });

		// this is required for the view to update correctly (it's a general approach recommended by Angular devs using ChangeDetectionStrategy.OnPush: https://github.com/angular/angular/issues/21780)
		this.changeDetectorRef.markForCheck();
	}

	private onChange: (value: any[] | null) => void = () => { };

	registerOnChange(fn: (value: any[] | null) => void): void {
		this.onChange = fn;
	}

	private onTouched: () => void = () => { };

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(disabled: boolean): void {
	}

	controlClicked() {
		if (this.readOnly) return;

		this.onTouched();
	}

	getGroupHeading = (groupIndex: number) => {
		return this.metaData.label.replace('%n', (groupIndex + 1).toString());
	}

	getFormControl(formGroup: AbstractControl, controlId: number) {
		return (formGroup as FormGroup).get(controlId.toString()) as FormControl;
	}

	appendGroup(emitEvent: boolean) {
		this.addGroupRepeaterGroup(this.getDefaultGroupValues(), emitEvent);
		this.onTouched();
	}

	private getDefaultGroupValues() {
		let groupValues: { [id: number]: any };

		if (this.metaData.defaultValue) {
			// defaultValue for group repeater in task documents is an array with a single item that contains a dictionary. Looks like a bug in the b/e
			// If this is fixed at some point, a mapper will need to be added to the widget-metadata-mapper service
			groupValues = this.metaData.defaultValue[0];
		}
		else {
			groupValues = {};
			this.metaData.controls.forEach(control => groupValues[control.id] = null);
		}

		return groupValues;
	}

	private addGroupRepeaterGroup(groupValues: { [id: number]: any }, emitEvent: boolean) {
		const controlGroup = this.formBuilder.group({});

		this.metaData.controls.forEach(control => {
			const ctrl = this.formBuilder.control(groupValues[control.id], this.taskDocumentService.getWidgetValidators(control.metaData));
			controlGroup.addControl(control.id.toString(), ctrl, { emitEvent: false });
		});

		this.formArray.push(controlGroup, { emitEvent: emitEvent });
	}

	removeGroup(index: number) {
		this.formArray.removeAt(index);
		this.onTouched();
	}

	// custom validation
	private onValidatorChange: () => void = () => { }; // call this when the internal state has changed requiring external validation

	registerOnValidatorChange(fn: () => void): void {
		this.onValidatorChange = fn;
	}

	validate(): ValidationErrors | null {
		// need to ensure this control is invalid if the internal form is invalid
		return this.formArray.errors;
	}
}