import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SheetItemComponentBase } from 'src/app/services/sheets-renderer.service';
import { Row } from 'src/app/types/aggregate-graph.types';
import { OxygenInstructionsV2, OxygenMethod } from 'src/app/types/sheet-instruction.types';
import { InstructionBoxComponent } from '../../instruction-box/instruction-box.component';
import { ItemContentComponent } from '../../item-content/item-content.component';

@Component({
	selector: 'app-oxygen-item',
	templateUrl: './oxygen-item-v2.component.html',
	styleUrl: './oxygen-item-v2.component.scss',
	standalone: true,
	imports: [ItemContentComponent, InstructionBoxComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OxygenItemComponentV2 implements SheetItemComponentBase {

	row!: Row;
	instructions!: OxygenInstructionsV2;
	oxygenMethod!: string[];
    @Input() startLabel!: string;
    @Input() endLabel!: string;
	@Input('row') set updateRow(row: Row) {
		this.row = row;
		this.instructions = row.instructions as OxygenInstructionsV2;
		this.oxygenMethod = Object.values(this.instructions).map(instruction => this.getOxygenMethod(instruction.oxygenMethod));
	}
	
	constructor() {
	}

	private getOxygenMethod(oxygenMethod: OxygenMethod | null) {
		switch (oxygenMethod) {
			case OxygenMethod.Cpap: return 'CPAP';
			case OxygenMethod.HighFlow: return 'High Flow';
			case OxygenMethod.NasalProngs: return 'Nasal Prongs';
			case OxygenMethod.OxygenKennel: return 'Oxygen Kennel';
			default: return '';
		}
	}
}