import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { SkincareAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

@Component({
	selector: 'app-skincare-action-controls',
	templateUrl: './skincare-action-controls.component.html',
	styleUrls: ['../../../common.scss', './skincare-action-controls.component.scss'],
	standalone: true,
	imports: [CommonActionControlsComponent, ActionFormComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkincareActionControlsComponent implements SheetActionControlsBase {

	slot!: Slot | null;
	action!: SkincareAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as SkincareAction;
	}

	@Input() form!: FormGroup;
	@Input() item!: Row | null;
	
	constructor() {
	}
}