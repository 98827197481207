import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { CoercionService } from 'src/app/services/coercion.service';

@Component({
	selector: 'app-heading',
	templateUrl: './heading.component.html',
	styleUrl: './heading.component.scss',
	standalone: true,
	imports: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeadingComponent {

	@Input() background: 'primary-2' | 'primary-3' | 'primary-4' | 'secondary-2' | 'secondary-3' | 'critical-2' | 'custom-background' = 'primary-2';
	@Input() size: 'small' | 'medium' | 'large' | 'custom-size' = 'medium';
	@HostBinding('class') get classes() { return [this.size, this.background] }

	@HostBinding('class.centred') isCentred: boolean = false;
	@Input() set centred(value: any) { this.isCentred = this.coercionService.coerceToBoolean(value) }

	constructor(private coercionService: CoercionService) {
	}
}
