import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SelectControl, SelectItem } from 'src/app/form-controls/select/select.control';
import { TextInputControl } from 'src/app/form-controls/text-input/text-input.control';
import { UnitQuantity, UnitQuantityControl } from 'src/app/form-controls/unit-quantity/unit-quantity.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { BloodPressureAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface BloodPressureActionFormGroup {
	systolic: FormControl<UnitQuantity | null>;
	mean: FormControl<UnitQuantity | null>;
	location: FormControl<string | null>;
	cuffSize: FormControl<number | null>;
	demeanor: FormControl<number | null>;
}

@Component({
	selector: 'app-non-invasive-blood-pressure-action-controls',
	templateUrl: './non-invasive-blood-pressure-action-controls.component.html',
	styleUrls: ['../../../common.scss', './non-invasive-blood-pressure-action-controls.component.scss'],
	standalone: true,
	imports: [ReactiveFormsModule, UnitQuantityControl, CommonActionControlsComponent, ActionFormComponent, SelectControl, TextInputControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BloodPressureActionControlsComponent implements SheetActionControlsBase, OnInit {

	slot!: Slot | null;
	action!: BloodPressureAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as BloodPressureAction;
	}

	@Input() form!: FormGroup<BloodPressureActionFormGroup>;
	@Input() item!: Row | null;
	
	locationItems: SelectItem[] = [
		{ text: 'Left fore' },
		{ text: 'Right fore' },
		{ text: 'Left hind' },
		{ text: 'Right hind' }
	];

	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.form.addControl('systolic', this.formBuilder.control(this.action?.systolic ?? null));
		this.form.addControl('mean', this.formBuilder.control(this.action?.mean ?? null));
		this.form.addControl('location', this.formBuilder.control(this.action?.location ?? null));
		this.form.addControl('cuffSize', this.formBuilder.control(this.action?.cuffSize ?? null));
		this.form.addControl('demeanor', this.formBuilder.control(this.action?.demeanor ?? null));
	}
}
