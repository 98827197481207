<app-item-content [instructionItemContentTemplate]="itemContentBoxes" [row]="row" [startLabel]="startLabel" [endLabel]="endLabel"></app-item-content>
<ng-template #itemContentBoxes let-instruction="instruction" let-overlaps="scheduledAndOverlapsSelectedDate">
	@if (overlaps) {
		@if (instruction.amountToAdminister) {
			<app-instruction-box>{{ instruction.amountToAdminister.value }} {{ instruction.amountToAdminister.unit }}</app-instruction-box>
		}
		@if (instruction.medicationRoute !== null) {
			<app-instruction-box>{{ instruction.medicationRoute | medicationRouteName }}</app-instruction-box>
		}
	}
</ng-template>
