import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers';

export const selectRequestedUrl = (state: AppState) => state.auth.requestedUrl;
export const selectUserName = (state: AppState) => state.auth.userName;
export const selectVerificationCodeRequired = (state: AppState) => state.auth.verificationCodeRequired;
export const selectVerificationSessionId = (state: AppState) => state.auth.verificationSessionId;
export const selectAccessToken = (state: AppState) => state.auth.accessToken;
export const selectRefreshToken = (state: AppState) => state.auth.refreshToken;
export const selectClientSessionId = (state: AppState) => state.auth.clientSessionId;
export const selectRecentUsers = (state: AppState) => state.auth.recentUsers;
export const selectLoginStatus = (state: AppState) => state.auth.loginStatus;

export const requestedUrl = createSelector(
	selectRequestedUrl,
	(requestedUrl) => requestedUrl
);

export const userName = createSelector(
	selectUserName,
	(userName) => userName
);

export const verificationCodeRequired = createSelector(
	selectVerificationCodeRequired,
	(verificationCodeRequired) => verificationCodeRequired
);

export const verificationCodeInfo = createSelector(
	selectVerificationSessionId,
	userName,
	(verificationSessionId, userName) => ({ sessionId: verificationSessionId, userName: userName ?? '' })
);

export const accessToken = createSelector(
	selectAccessToken,
	(accessToken) => accessToken
);

export const refreshToken = createSelector(
	selectRefreshToken,
	(refreshToken) => refreshToken
);

export const hasRefreshToken = createSelector(
	refreshToken,
	(refreshToken) => refreshToken !== ''
);

export const clientSessionId = createSelector(
	selectClientSessionId,
	(clientSessionId) => clientSessionId
);

export const recentUsers = createSelector(
	selectRecentUsers,
	(recentUsers) => recentUsers
);

export const loginStatus = createSelector(
	selectLoginStatus,
	(loginStatus) => loginStatus
);