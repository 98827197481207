import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ServiceAdministrationModal } from 'src/app/pages/service/components/service-settings/modals/service-administration/service-administration.modal';
import { ServiceMembershipModal } from 'src/app/pages/service/components/service-settings/modals/service-membership/service-membership.modal';
import { ErrorService } from 'src/app/services/error.service';
import { TeamsService } from 'src/app/services/teams.service';
import { clientErrored } from '../error/error.actions';
import * as serviceSettingsActions from './service-settings.actions';

@Injectable()
export class ServiceSettingsEffects {

	constructor(private actions$: Actions, private teamsService: TeamsService, private modalController: ModalController, private errorService: ErrorService) {
	}

	administerServiceInitiated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(serviceSettingsActions.administerServiceInitiated),
			switchMap(payload => from(this.modalController.create({ component: ServiceAdministrationModal, backdropDismiss: false })).pipe(
				map(modal => {
					modal.present();
					return serviceSettingsActions.loadServiceSettingsRequested({ serviceId: payload.serviceId, teamMembershipEnabled: payload.teamMembershipEnabled });
				})
			))
		)
	);

	manageServiceMembersInitiated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(serviceSettingsActions.manageServiceMembersInitiated),
			switchMap(payload => from(this.modalController.create({ component: ServiceMembershipModal, backdropDismiss: false })).pipe(
				map(modal => {
					modal.present();
					return serviceSettingsActions.loadServiceSettingsRequested({ serviceId: payload.serviceId, teamMembershipEnabled: payload.teamMembershipEnabled });
				})
			))
		)
	);

	loadServiceSettingsRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(serviceSettingsActions.loadServiceSettingsRequested),
			switchMap(payload => this.teamsService.getTeamMembers(payload.serviceId).pipe(
				map(teamMembers => serviceSettingsActions.serviceSettingsLoaded({ serviceId: payload.serviceId, teamMembers: teamMembers })),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Load team members failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	enableTeamMembershipRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(serviceSettingsActions.enableTeamMembershipRequested),
			switchMap(payload => this.teamsService.enableTeamMembership(payload.serviceId).pipe(
				map(() => serviceSettingsActions.teamMembershipEnabled()),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Enable team membership failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	disableTeamMembershipRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(serviceSettingsActions.disableTeamMembershipRequested),
			switchMap(payload => this.teamsService.disableTeamMembership(payload.serviceId).pipe(
				map(() => serviceSettingsActions.teamMembershipDisabled()),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Disable team membership failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	addAdministratorRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(serviceSettingsActions.addTeamAdministratorRequested),
			switchMap(payload => this.teamsService.addTeamAdministrator(payload.serviceId, payload.userId).pipe(
				map(teamMember => serviceSettingsActions.teamMemberAdded({ serviceId: payload.serviceId, teamMember: teamMember })),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Add team administrator failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	addTeamMemberRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(serviceSettingsActions.addTeamMemberRequested),
			switchMap(payload => this.teamsService.addTeamMember(payload.serviceId, payload.userId).pipe(
				map(teamMember => serviceSettingsActions.teamMemberAdded({ serviceId: payload.serviceId, teamMember: teamMember })),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Add team member failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	removeTeamAdministratorRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(serviceSettingsActions.removeTeamAdministratorRequested),
			switchMap(payload => this.teamsService.removeTeamAdministrator(payload.serviceId, payload.userId).pipe(
				map(() => serviceSettingsActions.teamMemberRemoved({ serviceId: payload.serviceId, userId: payload.userId })),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Remove team administrator failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	removeTeamMemberRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(serviceSettingsActions.removeTeamMemberRequested),
			switchMap(payload => this.teamsService.removeTeamMember(payload.serviceId, payload.userId).pipe(
				map(() => serviceSettingsActions.teamMemberRemoved({ serviceId: payload.serviceId, userId: payload.userId })),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Remove team member failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	setCanEditMemberListStateRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(serviceSettingsActions.setCanEditMemberListStateRequested),
			switchMap(payload => this.teamsService.setCanEditMemberListState(payload.serviceId, payload.userId, payload.canEditMemberList).pipe(
				map(() => serviceSettingsActions.canEditMemberListStateChanged({ serviceId: payload.serviceId, userId: payload.userId, canEditMemberList: payload.canEditMemberList })),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Change team member role failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);
}