import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MedicationRouteNamePipe } from 'src/app/pipes/medication-route-name.pipe';
import { SheetItemComponentBase } from 'src/app/services/sheets-renderer.service';
import { Row } from 'src/app/types/aggregate-graph.types';
import { FluidsInfusionInstructionsV2 } from 'src/app/types/sheet-instruction.types';
import { InstructionBoxComponent } from '../../instruction-box/instruction-box.component';
import { ItemContentComponent } from '../../item-content/item-content.component';

@Component({
	selector: 'app-fluids-infusion-item',
	templateUrl: './fluids-infusion-item-v2.component.html',
	styleUrl: './fluids-infusion-item-v2.component.scss',
	standalone: true,
	imports: [InstructionBoxComponent, ItemContentComponent, MedicationRouteNamePipe],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FluidsInfusionItemComponentV2 implements SheetItemComponentBase {

	row!: Row;
	instructions!: FluidsInfusionInstructionsV2;
	@Input() startLabel!: string;
	@Input() endLabel!: string;
	@Input('row') set updateRow(row: Row) {
		this.row = row;
		this.instructions = row.instructions as FluidsInfusionInstructionsV2;
	}

	constructor() {
	}
}