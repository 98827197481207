import { ChangeDetectionStrategy, Component, Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { DatetimeCustomEvent, IonButton, IonButtons, IonDatetime, PopoverController } from '@ionic/angular/standalone';

export type DateChangerType = 'date' | 'date-time' | 'time';

@Directive({
	selector: '[dateChanger]',
	standalone: true
})
export class DateChangerDirective {

	@Input('dateChanger') dateValue: string | null | undefined = undefined;
	@Input() dateFormat: DateChangerType = 'date';
	@Input() minuteValues: string | undefined = undefined;
	@Input() minDate: string | undefined = undefined;
	@Input() maxDate: string | undefined = undefined;
	@Input() showClearButton: boolean = false;
	@Input() dateSelectionDisabled: boolean = false;
	@Input() reference: 'trigger' | 'event' = 'trigger';
	@Input() side: 'top' | 'right' | 'bottom' | 'left' | 'start' | 'end' = 'bottom';
	@Input() alignment: 'start' | 'center' | 'end' = 'center';
	@Output() dateChanged: EventEmitter<string | null> = new EventEmitter<string | null>(); // can return null if the date is cleared

	constructor(private popoverController: PopoverController) {
	}

	@HostListener('click', ['$event']) async onClick(event: Event) {
		if (this.dateSelectionDisabled) return;
		const modal = await this.popoverController.create({
			component: DateChangerPopover,
			event: event,
			reference: this.reference,
			alignment: this.alignment,
			side: this.side,
			showBackdrop: false,
			componentProps: {
				dateValue: this.dateValue,
				dateFormat: this.dateFormat,
				minDate: this.minDate,
				maxDate: this.maxDate,
				minuteValues: this.minuteValues,
				showClearButton:
				this.showClearButton
			}
		});
		await modal.present();
		const { data } = await modal.onWillDismiss();
		if (data) {
			const dateValue = data.dateValue;
			if (this.showClearButton && typeof dateValue === 'undefined') {
				this.dateChanged.emit(null);

			}
			else if (typeof dateValue === 'string' && dateValue && dateValue !== this.dateValue) {
				this.dateChanged.emit(data.dateValue);
			}
		}
	}
}

@Component({
	template: `
<ion-datetime [showDefaultButtons]="true" doneText="Ok" [showClearButton]="showClearButton" [value]="dateValue" [presentation]="dateFormat" locale="en-GB" hourCycle="h23"
			  [min]="minDate" [max]="maxDate" [minuteValues]="minuteValues" (ionChange)="dateValueChanged($event)">
</ion-datetime>`,
	standalone: true,
	imports: [IonDatetime],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateChangerPopover {

	@Input() showClearButton: boolean = false;
	@Input() dateValue: string | undefined = undefined;
	@Input() dateFormat: DateChangerType = 'date';
	@Input() minDate: string | undefined = undefined;
	@Input() maxDate: string | undefined = undefined;
	@Input() minuteValues: string | undefined = undefined;

	constructor(private popoverController: PopoverController) {
	}

	dateValueChanged(event: Event) {
		this.popoverController.dismiss({ dateValue: (event as DatetimeCustomEvent).detail.value });
	}
}