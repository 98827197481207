import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormArray, FormBuilder, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidationErrors, Validator } from '@angular/forms';
import { enterLeave } from 'src/app/animations/common.animations';
import { CheckboxControl } from '../checkbox/checkbox.control';

export type MultiCheckboxValue = { label: string, value: boolean } | boolean;

@Component({
	selector: 'app-multi-checkbox',
	templateUrl: './multi-checkbox.control.html',
	styleUrl: './multi-checkbox.control.scss',
	standalone: true,
	imports: [ReactiveFormsModule, CheckboxControl],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: MultiCheckboxControl,
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: MultiCheckboxControl
		}
	],
	animations: [enterLeave]
})
export class MultiCheckboxControl implements ControlValueAccessor, Validator, OnInit {

	@Input() readOnly: boolean = false;
	@Input() label: string = '';
	@Input() subLabel: string = '';
	@Input() items: string[] = [];
	@Input() saveItems: boolean = false;
	@Input() minItems: number = 0;

	formArray!: FormArray<FormControl<boolean>>;

	private defaultFormArrayValue!: boolean[];

	constructor(private changeDetectorRef: ChangeDetectorRef, private formBuilder: FormBuilder, private destroyRef: DestroyRef) { // note: DestroyRef is needed if takeUntilDestroyed is used outside the constructor
	}

	ngOnInit() {
		this.formArray = this.formBuilder.nonNullable.array(this.items.map(() => this.formBuilder.nonNullable.control(false)));

		this.defaultFormArrayValue = this.formArray.value;

		if (!this.readOnly) {
			this.formArray.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(values => {
				const multiCheckboxValues: MultiCheckboxValue[] = this.saveItems ? this.items.map((item, index) => ({ label: item, value: values[index] })) : values;
				this.onChange(multiCheckboxValues);
				this.onValidatorChange();
				this.changeDetectorRef.markForCheck();
			});
		}
	}

	writeValue(value: MultiCheckboxValue[] | null): void {

		this.formArray.setValue((this.saveItems ? value?.map(item => typeof item === "boolean" ? item : item.value) : value as boolean[]) ?? this.defaultFormArrayValue, { emitEvent: false });

		// this is required for the view to update correctly (it's a general approach recommended by Angular devs using ChangeDetectionStrategy.OnPush: https://github.com/angular/angular/issues/21780)
		this.changeDetectorRef.markForCheck();
	}

	private onChange: (value: MultiCheckboxValue[] | null) => void = () => { };

	registerOnChange(fn: (value: MultiCheckboxValue[] | null) => void): void {
		this.onChange = fn;
	}

	private onTouched: () => void = () => { };

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(disabled: boolean): void {
	}

	controlClicked() {
		if (this.readOnly) return;

		this.onTouched();
	}

	// custom validation
	private onValidatorChange: () => void = () => { }; // call this when the internal state has changed requiring external validation

	registerOnValidatorChange(fn: () => void): void {
		this.onValidatorChange = fn;
	}

	validate(): ValidationErrors | null {
		let errors: { [key: string]: any } = {};
		const selectedCount = this.formArray.value.filter(value => value).length;
		if (selectedCount < this.minItems) return { minLength: { requiredLength: this.minItems, actualLength: selectedCount } };
		return null;
	}
}