import { Injectable, OnDestroy } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError, filter, take, switchMap, finalize } from 'rxjs/operators';
import { throwError, Observable, Subscription, BehaviorSubject, EMPTY } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppSettings } from '../app-settings';
import { AppState } from '../store/reducers';
import { AuthService } from '../services/auth.service';
import { accessToken as accessTokenSelector } from '../store/auth/auth.selectors';
import { tokenRefreshSucceeded, tokenRefreshFailed } from '../store/auth/auth.actions';

const AuthHeader = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor, OnDestroy {

	private accessTokenSubscription: Subscription;
	private accessToken: string | null = null;
	private refreshTokenInProgress = false;
	private refreshTokenSubject: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);

	constructor(private store: Store<AppState>, private authService: AuthService) {
		this.accessTokenSubscription = this.store.pipe(select(accessTokenSelector)).subscribe(accessToken => this.accessToken = accessToken);
	}

	ngOnDestroy() {
		this.accessTokenSubscription.unsubscribe();
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(this.prepareRequest(request)).pipe(
			catchError((error: HttpErrorResponse) => {
				if (error.status === 401 && !this.isAuthenticationEndpoint(request.url)) {
					if (this.refreshTokenInProgress) {
						// if refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
						// which means the new token is ready and we can retry the request again
						return this.refreshTokenSubject.pipe(
							filter(result => result !== null),
							take(1),
							switchMap(() => next.handle(this.prepareRequest(request)))
						);
					}
					else {
						this.refreshTokenInProgress = true;

						// set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
						this.refreshTokenSubject.next(null);

						// refresh the tokens
						return this.authService.refresh().pipe(
							switchMap(tokenInfo => {
								this.accessToken = tokenInfo.accessToken; // need to set this here, access token is needed in subsequent synchronous prepareRequest call
								this.refreshTokenSubject.next(true); // set to a non-null value
								this.store.dispatch(tokenRefreshSucceeded({ tokenInfo: tokenInfo }));

								return next.handle(this.prepareRequest(request));
							}),
							catchError((error: HttpErrorResponse) => {
								this.store.dispatch(tokenRefreshFailed({ statusCode: error.status }));
								return EMPTY;
							}),
							// when the call to refreshToken completes, reset the refreshTokenInProgress to false for the next time the token needs to be refreshed
							finalize(() => this.refreshTokenInProgress = false)
						);
					}
				}
				return throwError(() => error);
			})
		);
	}

	private prepareRequest(request: HttpRequest<any>): HttpRequest<any> {
		const url = request.url.startsWith(AppSettings.apiEndpoint) ? request.url : `${AppSettings.apiEndpoint}${request.url}`;

		if (this.endpointRequiresAuthorisationHeader(request, url)) {
			return request.clone({
				url: url,
				headers: request.headers.append(AuthHeader, `Bearer ${this.accessToken}`)
			});
		}
		else {
			return request.clone({
				url: url
			});
		}
	}

	private endpointRequiresAuthorisationHeader(request: HttpRequest<any>, url: string): boolean {
		if (!this.accessToken) return false;
		if (request.headers.has(AuthHeader)) return false;
		if (!url.startsWith(AppSettings.apiEndpoint)) return false;
		if (this.isAuthenticationEndpoint(url)) return false;
		return true;
	}

	private authenticationEndpoint = '/api/authentication/';

	private isAuthenticationEndpoint(url: string): boolean {
		var relativeUrl: string;
		if (url.startsWith(AppSettings.apiEndpoint)) {
			relativeUrl = url.substring(AppSettings.apiEndpoint.length);
		}
		else {
			relativeUrl = url;
		}
		return relativeUrl.startsWith(this.authenticationEndpoint);
	}
}