<app-action-form [item]="item" [slot]="slot" [form]="form">
	<div class="form-section app-margin-horizontal app-padding">
        <div class="form-group" [formGroup]="form">
            <app-unit-quantity label="FO2" unitLabel="&percnt;" placeholder="Value" inputMode="numeric" formControlName="fO2"></app-unit-quantity>
            <app-heading background="secondary-2" size="small">Blood gas values</app-heading>
            <app-text-input label="pH" placeholder="Value" formControlName="ph" inputMode="decimal"></app-text-input>
            <app-unit-quantity label="pCO2" unitLabel="mmHg" placeholder="Value" inputMode="numeric" formControlName="pCO2"></app-unit-quantity>
            <app-unit-quantity label="pO2" unitLabel="mmHg" placeholder="Value" inputMode="numeric" formControlName="pO2"></app-unit-quantity>
            <app-heading background="secondary-2" size="small">Oximetry values</app-heading>
            <app-unit-quantity label="ctHb" unitLabel="g/dl" placeholder="Value" inputMode="numeric" formControlName="ctHb"></app-unit-quantity>
            <app-unit-quantity label="sO2" unitLabel="&percnt;" placeholder="Value" inputMode="numeric" formControlName="sO2"></app-unit-quantity>
            <app-unit-quantity label="Hctc" unitLabel="&percnt;" placeholder="Value" inputMode="numeric" formControlName="hCtc"></app-unit-quantity>
            <app-unit-quantity label="FCOHb" unitLabel="&percnt;" placeholder="Value" inputMode="numeric" formControlName="fCOHb"></app-unit-quantity>
            <app-unit-quantity label="FMetHb" unitLabel="&percnt;" placeholder="Value" inputMode="numeric" formControlName="fMetHb"></app-unit-quantity>
            <app-heading background="secondary-2" size="small">Electrolyte values</app-heading>
            <app-unit-quantity label="cK+" unitLabel="mmol/L" placeholder="Value" inputMode="numeric" formControlName="cKPlus"></app-unit-quantity>
            <app-unit-quantity label="cNa+" unitLabel="mmol/L" placeholder="Value" inputMode="numeric" formControlName="cNaPlus"></app-unit-quantity>
            <app-unit-quantity label="cCa2+" unitLabel="mmol/L" placeholder="Value" inputMode="numeric" formControlName="cCa2Plus"></app-unit-quantity>
            <app-unit-quantity label="cCl-" unitLabel="mmol/L" placeholder="Value" inputMode="numeric" formControlName="cClMinus"></app-unit-quantity>
            <app-heading background="secondary-2" size="small">Metabolite values</app-heading>
            <app-unit-quantity label="cGlu" unitLabel="mmol/L" placeholder="Value" inputMode="numeric" formControlName="cGlu"></app-unit-quantity>
            <app-unit-quantity label="cLac" unitLabel="mmol/L" placeholder="Value" inputMode="numeric" formControlName="cLac"></app-unit-quantity>
            <app-unit-quantity label="ctBil" unitLabel="&mu;mol/L" placeholder="Value" inputMode="numeric" formControlName="ctBil"></app-unit-quantity>
            <app-unit-quantity label="cUrea" unitLabel="&mu;mol/L" placeholder="Value" inputMode="numeric" formControlName="cUrea"></app-unit-quantity>
            <app-unit-quantity label="cCrea" unitLabel="&mu;mol/L" placeholder="Value" inputMode="numeric" formControlName="cCrea"></app-unit-quantity>
            <app-heading background="secondary-2" size="small">Temperature corrected values</app-heading>
            <app-text-input label="pH(T)" placeholder="Value" formControlName="pHT" inputMode="decimal"></app-text-input>
            <app-unit-quantity label="pCO2(T)" unitLabel="mmHg" placeholder="Value" inputMode="numeric" formControlName="pCO2T"></app-unit-quantity>
            <app-heading background="secondary-2" size="small">Acid base status</app-heading>
            <app-unit-quantity label="cBase(Ecf)c" unitLabel="mmol/L" placeholder="Value" inputMode="numeric" formControlName="cBaseEcfC"></app-unit-quantity>
            <app-unit-quantity label="cHCO3(Ecf)c" unitLabel="mmol/L" placeholder="Value" inputMode="numeric" formControlName="cHCO3EcfC"></app-unit-quantity>
        </div>
        <app-common-action-controls [action]="action" [form]="form"></app-common-action-controls>
    </div>
</app-action-form>

