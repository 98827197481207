import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SheetSlotComponentBase } from 'src/app/services/sheets-renderer.service';
import { TemperatureAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { TickValueComponent } from '../../tick-value/tick-value.component';

@Component({
	selector: 'app-temperature-slot',
	templateUrl: './temperature-slot.component.html',
	styleUrl: './temperature-slot.component.scss',
	standalone: true,
	imports: [TickValueComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemperatureSlotComponent implements SheetSlotComponentBase {

	action!: TemperatureAction;
	value!: string | null;

	@Input('action') set updateAction(action: TemperatureAction | null) {
		this.action = action as TemperatureAction;
		this.value = this.action.temperature?.quantity?.toString() ?? null;
	}

	constructor() {
	}
}