import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular/standalone';
import { HeadingComponent } from 'src/app/components/heading/heading.component';
import { ItemLabelComponent } from 'src/app/components/item-label/item-label.component';
import { ItemComponent } from 'src/app/components/item/item.component';
import { NavigableDirective } from 'src/app/directives/navigable.directive';
import { SelectItem } from './select.control';

@Component({
    templateUrl: './select.popover.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [HeadingComponent, ItemComponent, NavigableDirective, ItemLabelComponent]
})
export class SelectPopoverComponent {

    @Input() items: SelectItem[] = [];

    constructor(private popoverController: PopoverController) {
    }

    itemSelected(item: SelectItem) {
        this.popoverController.dismiss({ item });
    }
}