import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, ControlValueAccessor, FormArray, FormBuilder, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidationErrors, Validator } from '@angular/forms';
import { ModalController } from '@ionic/angular/standalone';
import { enterLeave } from 'src/app/animations/common.animations';
import { ItemLabelComponent } from 'src/app/components/item-label/item-label.component';
import { ItemComponent } from 'src/app/components/item/item.component';
import { SvgIconComponent } from 'src/app/components/svg-icon/svg-icon.component';
import { TextAreaControl } from '../textarea/textarea.control';
import { MultiItemPickerModal } from './multi-item-picker.modal';

export interface MultiItemPickerItem {
	id: number;
	label: string;
	notePlaceholder: string;
	type?: 'header' | 'option';
}

export interface MultiItemPickerValue {
	id: number;
	label: string;
	note: string;
}

@Component({
	selector: 'app-multi-item-picker',
	templateUrl: './multi-item-picker.control.html',
	styleUrl: './multi-item-picker.control.scss',
	standalone: true,
	imports: [ReactiveFormsModule, ItemComponent, ItemLabelComponent, SvgIconComponent, TextAreaControl],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: MultiItemPickerControl,
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: MultiItemPickerControl
		}
	],
	animations: [enterLeave]
})
export class MultiItemPickerControl implements ControlValueAccessor, Validator, OnInit {

	@Input() readOnly: boolean = false;
	@Input() label: string = '';
	@Input() items: MultiItemPickerItem[] = [];
	@Input() addLabel: string = 'Add';
	@Input() minItems: number = 0;

	formArray!: FormArray;

	getPlaceholder(id: number) {
		return this.items.find(i => i.id === id)!.notePlaceholder;
	}

	constructor(private changeDetectorRef: ChangeDetectorRef, private formBuilder: FormBuilder, private modalController: ModalController, private destroyRef: DestroyRef) { // note: DestroyRef is needed if takeUntilDestroyed is used outside the constructor
	}

	ngOnInit() {
		this.formArray = this.formBuilder.array([]);

		if (!this.readOnly) {
			this.formArray.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value: MultiItemPickerValue[]) => {
				this.onChange(value);
				this.onValidatorChange();
				this.changeDetectorRef.markForCheck();
			});
		}
	}

	writeValue(value: MultiItemPickerValue[] | null): void {

		// FormArray.setValue() errors if its structure does not match the value, so need to ensure the number of items matches first
		const itemCount = value?.length ?? 0;

		if (!value && this.formArray.length) {
			this.formArray.clear({ emitEvent: false });
		}
		else if (this.formArray.length < itemCount) {
			for (let i = this.formArray.length; i < itemCount; i++) this.formArray.push(this.createItemGroup(value![i]), { emitEvent: false });
		}
		else if (this.formArray.length > itemCount) {
			do { this.formArray.removeAt(this.formArray.length - 1, { emitEvent: false }) } while (this.formArray.length > itemCount);
		}

		this.formArray.setValue(value ?? [], { emitEvent: false });

		// this is required for the view to update correctly (it's a general approach recommended by Angular devs using ChangeDetectionStrategy.OnPush: https://github.com/angular/angular/issues/21780)
		this.changeDetectorRef.markForCheck();
	}

	private onChange: (value: MultiItemPickerValue[] | null) => void = () => { };

	registerOnChange(fn: (value: MultiItemPickerValue[] | null) => void): void {
		this.onChange = fn;
	}

	private onTouched: () => void = () => { };

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(disabled: boolean): void {
	}

	controlClicked() {
		if (this.readOnly) return;

		this.onTouched();
	}

	async presentAddItemModal() {

		this.onTouched();

		const popover = await this.modalController.create({
			component: MultiItemPickerModal,
			cssClass: 'stacked-modal',
			componentProps: {
				items: this.items,
				selectedValues: structuredClone(this.formArray.value)
			},
			backdropDismiss: false
		});

		await popover.present();

		const { data } = await popover.onWillDismiss();

		if (!data) return;

		const values: MultiItemPickerValue[] = data.values;

		// sync the modal changes into the form array, maintain the new order
		const oldValues: MultiItemPickerValue[] = structuredClone(this.formArray.value);
		let changed = false;

		values.forEach((value, index) => {
			const oldIndex = oldValues.findIndex(i => i.id === value.id);
			if (oldIndex === -1) {
				this.formArray.insert(index, this.createItemGroup(value), { emitEvent: false });
				changed = true;
			}
			else if (oldIndex !== index) {
				const oldValue: MultiItemPickerValue = this.formArray.at(oldIndex).value;
				this.formArray.removeAt(oldIndex, { emitEvent: false });
				this.formArray.insert(index, this.createItemGroup(oldValue), { emitEvent: false });
				changed = true;
			}
		});

		oldValues.filter(v => !values.some(value => value.id === v.id)).forEach((value, index) => {
			this.formArray.removeAt(index, { emitEvent: false });
			changed = true;
		});

		// trigger valueChanges if the form array has changed
		if (changed) this.formArray.updateValueAndValidity();
	}

	moveUp(index: number) {
		// implementing this way ensures the enterLeave transitions fire
		const value: MultiItemPickerValue = this.formArray.at(index).value;
		this.formArray.removeAt(index, { emitEvent: false });
		this.formArray.insert(index - 1, this.createItemGroup(value), { emitEvent: false });
		this.formArray.updateValueAndValidity();
	}

	removeItem(index: number) {
		this.formArray.removeAt(index);
	}

	getNoteControl(formGroup: AbstractControl) {
		return formGroup.get('note') as FormControl;
	}

	private createItemGroup(value: MultiItemPickerValue) {
		return this.formBuilder.group<MultiItemPickerValue>({
			id: value.id,
			label: value.label,
			note: value.note
		});
	}

	// custom validation
	private onValidatorChange: () => void = () => { }; // call this when the internal state has changed requiring external validation

	registerOnValidatorChange(fn: () => void): void {
		this.onValidatorChange = fn;
	}

	validate(): ValidationErrors | null {
		if (this.formArray.length < this.minItems) return { minLength: { requiredLength: this.minItems, actualLength: this.formArray.length } };
		return null;
	}
}