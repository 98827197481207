<app-action-form [item]="item" [slot]="slot" [form]="form">
	<div class="form-section app-margin-horizontal app-padding">
		<div class="form-group" [formGroup]="form">
			<app-heading background="secondary-2" size="small">Clinician checklist</app-heading>
			<app-checkbox label="Medications to go home" formControlName="medicationsToGoHome"></app-checkbox>
			<app-checkbox label="Discharge time on RX" formControlName="dischargeTimeOnRx"></app-checkbox>
			<app-checkbox label="Write / check / print CRIS & put on kennel " formControlName="writeCheckPrintCris"></app-checkbox>
			<app-checkbox label="Discharge date on Discharge report" formControlName="dischargeDateOnDischargeReport"></app-checkbox>
		</div>
		<div class="form-group" [formGroup]="form">
			<app-heading background="secondary-2" size="small">Nursing team checklist</app-heading>
			<app-checkbox label="Complete pricing" formControlName="completePricing"></app-checkbox>
			<app-checkbox label="File pricing with accounts" formControlName="filePricingWithAccounts"></app-checkbox>
			<app-checkbox label="Medications ready to go home" formControlName="medicationsReady"></app-checkbox>
			<app-checkbox label="Medications collected from dispensary" formControlName="medicationsCollected"></app-checkbox>
			<app-checkbox label="Return unwanted medications to dispensary" formControlName="returnUnwantedMedications"></app-checkbox>
			<app-checkbox label="Add time to remove IV" formControlName="addTimeToRemoveIv"></app-checkbox>
			<app-checkbox label="Remove IV if necessary / appropriate" formControlName="removeIvIfNecessary"></app-checkbox>
			<app-checkbox label="Collect patient's own items / check tray" formControlName="collectPatientItems"></app-checkbox>
			<app-checkbox label="Check wounds / dressing / bandages" formControlName="checkWoundsDressingBandages"></app-checkbox>
			<app-checkbox label="Bathe / groom if necessary" formControlName="batheOrGroom"></app-checkbox>
			<app-checkbox label="Check buster collar" formControlName="checkBusterCollar"></app-checkbox>
		</div>
		<app-common-action-controls [action]="action" [form]="form"></app-common-action-controls>
	</div>
</app-action-form>
