import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SheetItemComponentBase } from 'src/app/services/sheets-renderer.service';
import { Row } from 'src/app/types/aggregate-graph.types';
import { ItemContentComponent } from '../../item-content/item-content.component';

import { ColdCompressInstructions, Method } from 'src/app/types/sheet-instruction.types';
import { InstructionBoxComponent } from '../../instruction-box/instruction-box.component';

@Component({
	selector: 'app-cold-compress-item',
	templateUrl: './cold-compress-item.component.html',
	styleUrl: './cold-compress-item.component.scss',
	standalone: true,
	imports: [ItemContentComponent, InstructionBoxComponent,],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColdCompressItemComponent implements SheetItemComponentBase {

	row!: Row;
	instructions!: ColdCompressInstructions;
	@Input() startLabel!: string;
    @Input() endLabel!: string;
	@Input('row') set updateRow(row: Row) {
		this.row = row;
		this.instructions = row.instructions as ColdCompressInstructions;
	}

	getMethodName(route: Method) {
		switch(route) {
			case 0: return 'Cryo Cuff';
			case 1: return 'Pack';
			default: return '';
		}
	}

	constructor() {
	}
}