<app-action-form [item]="item" [slot]="slot" [form]="form">
	<div class="form-section app-margin-horizontal app-padding">
        <div class="form-group" [formGroup]="form">
            <app-text-input label="Breaths per min" placeholder="Value" formControlName="breathsPerMinute" inputMode="numeric"></app-text-input>
            <app-checkbox label="Panting" formControlName="panting"></app-checkbox>
            <app-select label="Effort" placeholder="choose..." [items]="heartRhythmItems" [saveValue]="true" formControlName="effort"></app-select>
        </div>
        <app-common-action-controls [action]="action" [form]="form"></app-common-action-controls>
	</div>
</app-action-form>
