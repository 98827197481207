import { Injectable } from '@angular/core';

export enum TimeUnit {
	Days = 'd',
	Hours = 'h',
	Minutes = 'm'
}

export interface TimeQuantity {
	unit: TimeUnit | null;
	quantity: number | null;
}

// Because newtonsoft json returns timespan formatted using the "c" format specifier (the default for C# TimeSpan.ToString())
// (https://learn.microsoft.com/en-us/dotnet/standard/base-types/standard-timespan-format-strings#the-constant-c-format-specifier),
// and because date-fns cannot parse this format to a Duration object, custom parsing is required.
// The "c" format is "[-][d.]hh:mm:ss[.fffffff]" (negative time intervals are not expected by this service, nor are fractional seconds),
// so the expected string format is "[d.]hh:mm:ss" (i.e. optional days count, followed by hours, minutes, and seconds).
// Seconds part is always expected to be zero

@Injectable({
	providedIn: 'root'
})
export class TimeSpanConversionService {

	// note: this regex matches "[d.]hh:mm:ss" (see convertCSharpTimeSpanToDateFnsDuration method below for explanation)
	// note: this regex would allow hours of 99, even though 23 would be the limit, but this is not a concern here,
	//       it is more about the overall structure of the string (e.g. no fractional seconds)
	private timeSpanRegex = /^(\d+\.)?(\d{1,2}:)(\d{1,2}:)(\d{1,2})$/;
	private splitRegex = /[.:]/;

	constructor() {
	}

	public convertCSharpTimeSpanToTimeQuantity(timeSpan: string): TimeQuantity {
		if (!this.timeSpanRegex.test(timeSpan)) throw new Error(`Unexpected C# TimeSpan format: ${timeSpan}`);

		const parts = timeSpan.split(this.splitRegex);
		const hasDaysPart = parts.length === 4;
		
		const duration = {
			days: hasDaysPart ? parseInt(parts[0]) : 0,
			hours: parseInt(hasDaysPart ? parts[1] : parts[0]),
			minutes: parseInt(hasDaysPart ? parts[2] : parts[1]),
			seconds: parseInt(hasDaysPart ? parts[3] : parts[2])
		};

		if (duration.seconds) throw new Error(`Seconds are not expected in C# TimeSpan: ${timeSpan}`);

		if (duration.minutes) return { unit: TimeUnit.Minutes, quantity: duration.minutes + (duration.hours * 60) + (duration.days * 1440) };
		if (duration.hours) return { unit: TimeUnit.Hours, quantity: duration.hours + (duration.days * 24) };
		return { unit: TimeUnit.Days, quantity: duration.days };
	}

	public convertTimeUnitToCSharpTimeSpan(unit: TimeUnit, quantity: number): string {
		if (!!(quantity % 1)) throw new Error(`Integer expected for quantity: ${quantity}`);
		switch (unit) {
			case TimeUnit.Days:
				return `${quantity}.00:00:00`;

			case TimeUnit.Hours:
				const daysFromHours = Math.trunc(quantity / 24);
				const daysFromHoursPart = daysFromHours ? `${daysFromHours}.` : '';
				const daysFromHoursRemainder = quantity % 24;
				const hoursPart = daysFromHoursRemainder.toString().padStart(2, '0');
				return `${daysFromHoursPart}${hoursPart}:00:00`;

			case TimeUnit.Minutes:
				const daysFromMinutes = Math.trunc(quantity / 1440);
				const daysFromMinutesPart = daysFromMinutes ? `${daysFromMinutes}.` : '';
				const daysFromMinutesRemainder = (quantity % 1440);
				const hoursFromMinutes = Math.trunc(daysFromMinutesRemainder / 60);
				const hoursFromMinutesPart = hoursFromMinutes.toString().padStart(2, '0');
				const hoursFromMinutesRemainder = daysFromMinutesRemainder % 60;
				const minutesPart = hoursFromMinutesRemainder.toString().padStart(2, '0');
				return `${daysFromMinutesPart}${hoursFromMinutesPart}:${minutesPart}:00`;
		}
	}
}