import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from 'src/app/services/date.service';

@Pipe({
	name: 'isoDateToString',
	standalone: true
})
export class IsoDateToStringPipe implements PipeTransform {

	constructor(private dateService: DateService) {
	}

	transform(value: string | null | undefined, format: string = 'dd/MM/yyyy'): string | null | undefined {
		if (!value) return value;
		return this.dateService.format(value, format);
	}
}