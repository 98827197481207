import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import * as LiveUpdates from '@capacitor/live-updates';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { IonApp, IonRouterOutlet, Platform } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { add, addCircleOutline, arrowBackSharp, arrowDownSharp, arrowUpSharp, chevronUpSharp, closeSharp, pawSharp, peopleSharp, personSharp, searchSharp, settingsSharp } from 'ionicons/icons';

const ionicIcons = {
	add, addCircleOutline, arrowBackSharp, chevronUpSharp, closeSharp, peopleSharp, personSharp, searchSharp, settingsSharp, pawSharp, arrowDownSharp, arrowUpSharp
}

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	standalone: true,
	imports: [IonApp, IonRouterOutlet],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
	constructor(private platform: Platform) {
		addIcons(ionicIcons);
		this.initializeApp();
	}

	async initializeApp() {
		await this.platform.ready();

		if (Capacitor.isNativePlatform()) {
			const result = await LiveUpdates.sync();
			if (result.activeApplicationPathChanged) {
				await LiveUpdates.reload();
			}
			else {
				await SplashScreen.hide();
			}
			await StatusBar.setStyle({ style: Style.Dark });
			if (Capacitor.getPlatform() === 'android') {
				await StatusBar.setOverlaysWebView({ overlay: false });
			}
		}
	}
}
