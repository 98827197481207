import { NgComponentOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AlertController, IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar, IonicSafeString, ModalController } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { enterLeave } from 'src/app/animations/common.animations';
import { ButtonComponent } from 'src/app/components/button/button.component';
import { TextInputControl } from 'src/app/form-controls/text-input/text-input.control';
import { TextAreaControl } from 'src/app/form-controls/textarea/textarea.control';
import { SheetItemDetailConfig, SheetsRendererService } from 'src/app/services/sheets-renderer.service';
import { AppState } from 'src/app/store/reducers';
import { addSheetItemInstructionsRequested, removeSheetItemRequested, updateSheetItemInstructionsRequested } from 'src/app/store/sheet/sheet.actions';
import { ItemProperties, Row } from 'src/app/types/aggregate-graph.types';

@Component({
	templateUrl: './sheet-item-editor.modal.html',
	styleUrls: ['./sheet-item-editor.modal.scss', '../sheet-item-editor/common.scss'],
	standalone: true,
	imports: [
		IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, NgComponentOutlet, ButtonComponent, TextAreaControl, ReactiveFormsModule, TextInputControl
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [enterLeave]
})
export class SheetItemEditorModal implements OnInit {

	@Input() sheetId!: string;
	@Input() instructionTypeName!: string;
	@Input() item!: Row | null;
	@Input() minDate!: string;
	@Input() maxDate!: string;

	instructionsFormArray: FormArray;
	config!: SheetItemDetailConfig;

	itemPropertiesFormGroup!: FormGroup<{
		name: FormControl<string | null>;
		notes: FormControl<string | null>;
	}>;

	constructor(
		private modalController: ModalController, private formBuilder: FormBuilder, private sheetsRendererService: SheetsRendererService,
		private store: Store<AppState>, private alertController: AlertController) {
		this.instructionsFormArray = this.formBuilder.array<FormGroup>([], [Validators.required]);
	}

	ngOnInit(): void {
		this.itemPropertiesFormGroup = new FormGroup({
			name: new FormControl(this.item?.name?.trim() ?? null),
			notes: new FormControl(this.item?.notes?.trim() ?? null)
		})
		this.config = this.sheetsRendererService.getItemDetailConfig(this.instructionTypeName);
	}

	getItemEditorFormComponent() {
		return this.sheetsRendererService.getItemEditorFormType(this.instructionTypeName);
	}

	async saveInstructionsPayload() {
		if (this.instructionsFormArray.invalid) {
			const alert = await this.alertController.create({
				header: 'Input not valid',
				subHeader: 'Please check the following and retry:',
				message: new IonicSafeString('<div><ul><li>The end time cannot be before the start time.</li><li>All timings must be a multiple of 5 minutes.</li><li>All mandatory fields are completed.</li></ul></div>'),
				buttons: ['Ok'],
				backdropDismiss: false
			});
			alert.present();
			return;
		}

		const itemProperties: ItemProperties = { name: this.itemPropertiesFormGroup.value.name ?? null, notes: this.itemPropertiesFormGroup.value.notes ?? null };

		if (this.item) this.store.dispatch(updateSheetItemInstructionsRequested({ itemId: this.item.id, instructions: this.instructionsFormArray.value, itemProperties }));
		else this.store.dispatch(addSheetItemInstructionsRequested({ instructions: this.instructionsFormArray.value, itemProperties }));

		this.close();
	}

	async removeItem() {
		if (this.item!.isRemoved) return;

		const hasActions = this.item!.slots.some(slot => !!slot?.action);

		const alert = await this.alertController.create({
			subHeader: 'Remove item?',
			message: hasActions ? 'This will discontinue the item and remove future schedules' : 'This will delete the item from today\'s sheet',
			buttons: [
				'Cancel',
				{
					text: 'Remove',
					cssClass: 'button-critical',
					handler: () => {
						this.store.dispatch(removeSheetItemRequested({ sheetId: this.sheetId, itemId: this.item!.id }));
						alert.dismiss(true);
						return false;
					}
				}
			],
			backdropDismiss: false
		});

		await alert.present();

		const { data } = await alert.onDidDismiss();

		if (data === true) this.close();
	}

	close() {
		this.modalController.dismiss();
	}
}
