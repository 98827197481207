import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { PopoverController } from '@ionic/angular/standalone';
import { ItemInputComponent } from 'src/app/components/item-input/item-input.component';
import { ItemLabelComponent } from 'src/app/components/item-label/item-label.component';
import { ItemValueComponent } from 'src/app/components/item-value/item-value.component';
import { ItemComponent } from 'src/app/components/item/item.component';
import { Unit, UnitPopoverComponent } from '../shared/popovers/unit-popover/unit.popover';

export interface UnitQuantity {
	unit: string | null;
	quantity: number | null;
}

@Component({
	selector: 'app-unit-quantity',
	templateUrl: './unit-quantity.control.html',
	styleUrl: './unit-quantity.control.scss',
	standalone: true,
	imports: [ReactiveFormsModule, ItemComponent, ItemLabelComponent, ItemInputComponent, ItemValueComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: UnitQuantityControl,
		multi: true
	}]
})
export class UnitQuantityControl implements ControlValueAccessor, OnInit {

	@Input() readOnly: boolean = false;
	@Input() label: string = '';
	@Input() unitLabel: string = '';
	@Input() units: Unit[] = [];
	@Input() placeholder: string = '';
	@Input() maxlength: number | null = null;
	@Input() inputMode: 'numeric' | 'decimal' | null = null;

	@ViewChild(ItemInputComponent) itemInputComponent!: ItemInputComponent;

	private unitQuantity!: UnitQuantity;

	get unit() {
		if (this.unitQuantity.unit === null) return '...';
		return this.units.length ? this.units.find(u => u.value === this.unitQuantity.unit || u.label === this.unitQuantity.unit)?.label ?? '???' : this.unitLabel;
	}
	get quantity() { return this.unitQuantity.quantity?.toString() ?? ''; }

	constructor(private changeDetectorRef: ChangeDetectorRef, private popoverController: PopoverController) {
	}

	ngOnInit(): void {
		if (this.inputMode === null) throw new Error('The inputMode must be specified');
		if (this.unitLabel && this.units.length > 0) throw new Error('Cannot specify both unitLabel and units. Only one of the inputs should be specified');
		this.unitQuantity = this.getDefaultValue();
	}

	writeValue(value: UnitQuantity | null): void {
		this.unitQuantity = structuredClone(value) ?? this.getDefaultValue();

		// this is required for the view to update correctly (it's a general approach recommended by Angular devs using ChangeDetectionStrategy.OnPush: https://github.com/angular/angular/issues/21780)
		this.changeDetectorRef.markForCheck();
	}

	private getDefaultValue() {
		return { unit: this.unitLabel || null, quantity: null } as UnitQuantity;
	}

	private onChange: (value: UnitQuantity) => void = () => { };

	registerOnChange(fn: (value: UnitQuantity) => void): void {
		this.onChange = fn;
	}

	private onTouched: () => void = () => { };

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(disabled: boolean): void {
	}

	quantityChanged(quantity: string | null) {

		this.onTouched();

		if (quantity === null || quantity === '') {
			this.unitQuantity.quantity = null;
		}
		else { // it should be a number ('numeric' or 'decimal')
			const numberValue = this.inputMode === 'numeric' ? parseInt(quantity) : parseFloat(quantity);
			if (isNaN(numberValue)) throw new Error(`Error converting value (${quantity}) for input mode (${this.inputMode})`);
			this.unitQuantity.quantity = numberValue;
		}
		this.onChange(structuredClone(this.unitQuantity));
	}

	async presentUnitsPopover(event: Event) {
		if (this.readOnly) return;

		this.onTouched();

		const popover = await this.popoverController.create({
			component: UnitPopoverComponent,
			cssClass: 'popover-menu',
			componentProps: { units: this.units },
			event: event,
			alignment: 'end',
			showBackdrop: false
		});

		await popover.present();

		const { data } = await popover.onWillDismiss();

		if (!data) return;

		const unit = data.unit as Unit;

		if (this.unitQuantity.unit === (unit.value ?? unit.label)) return;

		this.unitQuantity.unit = unit.value ?? unit.label;

		this.onChange(structuredClone(this.unitQuantity));
		this.changeDetectorRef.markForCheck();
	}

	itemFocused() {
		this.onTouched();
	}

	controlClicked() {
		if (!this.readOnly) this.itemInputComponent.focusInput();
	}
}