<app-instructions-form [instructionTypeName]="instructionTypeName" [instructionsFormArray]="instructionsFormArray" [minDate]="minDate" [minDate]="minDate"
					   [preScheduleInstructionTemplate]="preScheduleInstruction" [postScheduleInstructionTemplate]="postScheduleInstruction" [scheduleConfig]="itemConfig.scheduleConfig" (addInstructionClicked)="addInstruction()">
	<!--only need to set the template references above if they are used in app-instructions-form-->
</app-instructions-form>
<ng-template #preScheduleInstruction let-group="instructionFormGroup">
	<div class="form-group" [formGroup]="group">
		<app-unit-value class="validatable-control" [units]="doseUnits" label="Dose" placeholder="Value" formControlName="dose"></app-unit-value>
		<app-unit-value class="validatable-control" [units]="amountUnits" label="Amount to administer" placeholder="Value" formControlName="amountToAdminister"></app-unit-value>
		<app-select class="validatable-control" label="Route" placeholder="choose..." [items]="medicationRoutes" [saveValue]="true" formControlName="medicationRoute"></app-select>
	</div>
</ng-template>
<ng-template #postScheduleInstruction let-group="instructionFormGroup">
	<!--add any form controls here, typically inside "form-group" classed divs. Public properties/methods of this component are accessible to the template-->
</ng-template>