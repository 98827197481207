import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Instruction, ItemProperties } from '../types/aggregate-graph.types';
import { SheetActionNotes, SheetGranularity } from '../types/sheet.types';

export interface CreateSheetForVisitResponse {
	sheetId: string;
}

export interface SearchedSheetItem {
	instructionTypeName: string;
	label: string;
}

@Injectable({
	providedIn: 'root'
})
export class SheetsService {

	constructor(private httpClient: HttpClient) {
	}

	createForVisit(visitId: string) {
		return this.httpClient.post<CreateSheetForVisitResponse>('/api/sheets', { visitId: visitId });
	}

	updateScheduledUntilDate(sheetId: string, date: string) {
		return this.httpClient.put<string>(`/api/sheets/${sheetId}/scheduled-until-date`, JSON.stringify(date), { headers: { 'Content-Type': 'application/json' } });
	}

	queryItemTypes(queryTerm: string, skip: number, take: number) {
		return this.httpClient.get<SearchedSheetItem[]>(`/api/sheets/instruction-types?queryTerm=${queryTerm}&skip=${skip}&take=${take}`);
	}

	addItemInstructions(sheetId: string, instructions: Instruction[]) {
		return this.httpClient.post<number>(`/api/sheets/${sheetId}/row`, instructions);
	}

	updateItemInstructions(sheetId: string, itemId: number, instructions: Instruction[]) {
		return this.httpClient.put<void>(`/api/sheets/${sheetId}/row/${itemId}/instructions`, instructions);
	}

	addSlotAction(sheetId: string, itemId: number, action: Action, granularity: SheetGranularity) {
		return this.httpClient.post<number>(`/api/sheets/${sheetId}/row/${itemId}/action/${granularity}`, action);
	}

	updateSlotAction(sheetId: string, itemId: number, action: Action) {
		return this.httpClient.put<void>(`/api/sheets/${sheetId}/row/${itemId}/action`, action);
	}

	setItemProperties(sheetId: string, itemId: number, itemProperties: ItemProperties) {
		return this.httpClient.put<number>(`/api/sheets/${sheetId}/row/${itemId}/properties`, itemProperties);
	}

	removeItem(sheetId: string, itemId: number) {
		return this.httpClient.delete<void>(`/api/sheets/${sheetId}/row/${itemId}`);
	}

	getActionNotes(sheetId: string, fromDate: string, toDate: string) {
		return this.httpClient.get<SheetActionNotes[]>(`/api/sheets/${sheetId}/notes?fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`);
	}

	exportSheet(sheetId: string, granularity: SheetGranularity) {
		return this.httpClient.get(`/api/sheets/${sheetId}/xslx?granularity=${granularity}`, { observe: 'response', responseType: 'blob' });
	}
}
