<app-item-content [instructionItemContentTemplate]="itemContentBoxes" [row]="row" [startLabel]="startLabel" [endLabel]="endLabel" [showFrequency]="false"></app-item-content>
<ng-template #itemContentBoxes let-index="index" let-instruction="instruction" let-overlaps="scheduledAndOverlapsSelectedDate">
	@if (overlaps) {
		@if (instruction.percentageToGive) {
			<app-instruction-box>{{ instruction.percentageToGive.value }} {{ instruction.percentageToGive.unit }}</app-instruction-box>
		}
		@if (instruction.oxygenMethod !== null) {
			<app-instruction-box>{{ oxygenMethod[index] }}</app-instruction-box>
		}
	}
</ng-template>