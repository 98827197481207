import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CheckboxControl } from 'src/app/form-controls/checkbox/checkbox.control';
import { SelectControl, SelectItem } from 'src/app/form-controls/select/select.control';
import { TextInputControl } from 'src/app/form-controls/text-input/text-input.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { RespiratoryEffort, RespiratoryRateAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface RespiratoryRateActionFormGroup {
	breathsPerMinute: FormControl<number | null>;
	panting: FormControl<boolean | null>;
	effort: FormControl<RespiratoryEffort | null>;
}

@Component({
	selector: 'app-respiratory-rate-and-effort-action-controls',
	templateUrl: './respiratory-rate-and-effort-action-controls.component.html',
	styleUrls: ['../../../common.scss', './respiratory-rate-and-effort-action-controls.component.scss'],
	standalone: true,
	imports: [ReactiveFormsModule, TextInputControl, SelectControl, CommonActionControlsComponent, ActionFormComponent, CheckboxControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RespiratoryRateActionControlsComponent implements SheetActionControlsBase, OnInit {

	slot!: Slot | null;
	action!: RespiratoryRateAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as RespiratoryRateAction;
	}

	@Input() form!: FormGroup<RespiratoryRateActionFormGroup>;
	@Input() item!: Row | null;
	

	heartRhythmItems: SelectItem[] = [
		{ text: 'Normal', value: RespiratoryEffort.Normal },
		{ text: 'Abnormal', value: RespiratoryEffort.Abnormal }
	];

	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.form.addControl('breathsPerMinute', this.formBuilder.control(this.action?.breathsPerMinute ?? null));
		this.form.addControl('panting', this.formBuilder.control(this.action?.panting ?? null));
		this.form.addControl('effort', this.formBuilder.control(this.action?.effort ?? null));
	}
}