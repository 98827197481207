<app-action-form [item]="item" [slot]="slot" [form]="form">
	<div class="form-section app-margin-horizontal app-padding">
        <div class="form-group" [formGroup]="form">
            <app-textarea formControlName="subjective" label="Subjective" placeholder="General demeanor, behaviour out of the kennel, change compared to the previous day, appetite, etc"></app-textarea>
            <app-textarea formControlName="objective" label="Objective" placeholder="Any new findings or changes on physical examination and diagnostic tests"></app-textarea>
            <app-textarea formControlName="assessment" label="Assessment" placeholder="Interpretation of problems and / or most likely differentials for this case"></app-textarea>
            <app-textarea formControlName="plan" label="Plan" placeholder="Short summary of the plan for today"></app-textarea>
        </div>
        <app-common-action-controls [action]="action" [form]="form" [showNotes]="false"></app-common-action-controls>
    </div>
</app-action-form>
