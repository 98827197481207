<app-item class="rounded-border-box no-select" [class.read-only]="readOnly" (click)="clicked()">
    <app-item-label class="validation-label">{{label}}</app-item-label>
	@if (value?.initials) {
		<app-chip startIcon="person-sharp" [label]="value?.initials ?? ''" clickable (click)="handleUserClick($event)"></app-chip>
	}
    @if (value) {
        <app-svg-icon name="checkbox-outline"></app-svg-icon>
    }
    @else {
        <app-svg-icon name="box-outline"></app-svg-icon>
    }
</app-item>
<ion-popover class="popover-menu popover-alt" #popover [isOpen]="popoverIsOpen" [showBackdrop]="false" alignment="center" (didDismiss)="popoverIsOpen = false">
	<ng-template>
		<div class="popover-menu-content">
			<app-item><app-item-label>{{value?.fullName}}</app-item-label></app-item>
		</div>
	</ng-template>
</ion-popover>