import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { refreshToken } from '../store/auth/auth.selectors';
import { AppState } from '../store/reducers';

export interface LoginInfo {
	username: string;
	password: string;
}

export interface ResendCodeInfo {
	sessionId: string;
	username: string;
}

export interface VerifyCodeInfo {
	sessionId: string;
	username: string;
	verificationCode: string;
}

export interface TokenInfo {
	accessToken: string;
	refreshToken: string;
}

export interface PinInfo {
    username: string;
    pin: number;
}

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	constructor(private httpClient: HttpClient, private store: Store<AppState>) {
	}

	checkStatus(username: string): Observable<void> {
		return this.httpClient.post<void>('/api/authentication/check-status', JSON.stringify(username), { headers: { 'Content-Type': 'application/json' } });
	}

	authenticatePassword(info: LoginInfo): Observable<string | TokenInfo> {
		return this.httpClient.post<string | TokenInfo>('/api/authentication/authenticate-password', info);
	}

	authenticatePin(info: PinInfo): Observable<TokenInfo> {
		return this.httpClient.post<TokenInfo>('/api/authentication/authenticate-pin', info);
	}

	resendCode(info: ResendCodeInfo): Observable<void> {
		return this.httpClient.post<void>('/api/authentication/resend-code', info);
	}

	verifyCode(info: VerifyCodeInfo): Observable<TokenInfo> {
		return this.httpClient.post<TokenInfo>('/api/authentication/verify-code', info);
	}

	refresh(): Observable<TokenInfo> {
		return this.store.pipe(
			select(refreshToken),
			take(1),
			switchMap(refreshToken => this.httpClient.post<TokenInfo>('/api/authentication/refresh', { token: refreshToken }))
		);
	}
}