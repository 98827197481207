import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, exhaustMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { SheetsService } from 'src/app/services/sheets.service';
import { clientErrored } from '../error/error.actions';
import { AppState } from '../reducers';
import * as sheetItemSearchActions from './sheet-item-search.actions';
import { searchParams } from './sheet-item-search.selectors';

@Injectable()
export class SheetItemSearchEffects {

	constructor(private actions$: Actions, private store: Store<AppState>, private sheetsService: SheetsService) {
	}

	newSearchRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetItemSearchActions.newSearchRequested),
			withLatestFrom(this.store.pipe(select(searchParams))),
			switchMap(([payload, searchParams]) => {
				return this.sheetsService.queryItemTypes(payload.queryTerm, searchParams.appendsRetrieved * searchParams.resultsPerAppend, searchParams.resultsPerAppend).pipe(
					map(searchResults => (sheetItemSearchActions.newSearchSucceeded({ searchResults: searchResults }))),
					catchError((error: HttpErrorResponse) => [
						sheetItemSearchActions.newSearchFailed({ statusCode: error.status }),
						clientErrored({ toastMessage: 'Sheet item search error', errorMessage: error.message })
					])
				)
			})
		)
	);

	appendResultsRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetItemSearchActions.appendResultsRequested),
			withLatestFrom(this.store.pipe(select(searchParams))),
			exhaustMap(([, searchParams]) => this.sheetsService.queryItemTypes(searchParams.queryTerm, (searchParams.appendsRetrieved + 1) * searchParams.resultsPerAppend, searchParams.resultsPerAppend).pipe(
				map(searchResults => (sheetItemSearchActions.appendResultsSucceeded({ searchResults: searchResults }))),
				catchError((error: HttpErrorResponse) => [
					sheetItemSearchActions.appendResultsFailed({ statusCode: error.status }),
					clientErrored({ toastMessage: 'Sheet item search append error', errorMessage: error.message })
				])
			))
		)
	);
}