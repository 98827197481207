import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ItemLabelComponent } from 'src/app/components/item-label/item-label.component';
import { ItemValueComponent } from 'src/app/components/item-value/item-value.component';
import { ItemComponent } from 'src/app/components/item/item.component';
import { DateChangerDirective, DateChangerType } from 'src/app/directives/date-changer.directive';
import { IsoDateToRelativePipe } from 'src/app/pipes/iso-date-to-relative.pipe';
import { IsoDateToStringPipe } from 'src/app/pipes/iso-date-to-string.pipe';

@Component({
    selector: 'app-datetime',
    templateUrl: './datetime.control.html',
    styleUrl: './datetime.control.scss',
    standalone: true,
    imports: [ReactiveFormsModule, DateChangerDirective, IsoDateToStringPipe, IsoDateToRelativePipe, ItemComponent, ItemLabelComponent, ItemValueComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: DateTimeControl,
        multi: true
    }]
})
export class DateTimeControl implements ControlValueAccessor {

    @Input() readOnly: boolean = false;
    @Input() label: string = '';
    @Input() placeholder: string = '';
    @Input() dateFormat: DateChangerType = 'date';
    @Input() displayRelative: boolean = false;
    @Input() displayFormat: string = 'dd/MM/yyyy';
	@Input() minuteValues: string | undefined = undefined;
	@Input() minDate: string | undefined = undefined;
	@Input() maxDate: string | undefined = undefined;
	@Input() showClearButton: boolean = false;

    constructor(private changeDetectorRef: ChangeDetectorRef) {
    }

    value: string | null = null;

    writeValue(value: string | null): void {
        this.value = value;
		
        // this is required for the view to update correctly (it's a general approach recommended by Angular devs using ChangeDetectionStrategy.OnPush: https://github.com/angular/angular/issues/21780)
        this.changeDetectorRef.markForCheck();
    }

    private onChange: (value: string | null) => void = () => { };

    registerOnChange(fn: (value: string | null) => void): void {
        this.onChange = fn;
    }

    private onTouched: () => void = () => { };

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(disabled: boolean): void {
    }

    controlClicked() {
        if (!this.readOnly) this.onTouched();
    }

    setDate(value: string | null) {
        this.value = value;
        this.onChange(this.value);
    }
}