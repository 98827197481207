import { createAction, props } from '@ngrx/store';

export enum ClientActionTypes {
	ShowInvalidVersionAlertRequested = '[Client Effect] Show Invalid Version Alert Requested',
	ShowInvalidVersionAlertInitiated = '[Client Effect] Show Invalid Version Alert Initiated',
	ShowInvalidVersionAlertSkipped = '[Client Effect] Show Invalid Version Alert Skipped'
}

export const showInvalidVersionAlertRequested = createAction(
	ClientActionTypes.ShowInvalidVersionAlertRequested,
	props<{ requiredVersion: string }>()
)

export const showInvalidVersionAlertInitiated = createAction(
	ClientActionTypes.ShowInvalidVersionAlertInitiated,
	props<{ requiredVersion: string }>()
)

export const showInvalidVersionAlertSkipped = createAction(
	ClientActionTypes.ShowInvalidVersionAlertSkipped
)