import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ClientsService {

	constructor(private httpClient: HttpClient) {
	}

	setContactNote(id: string, contactNote: string) {
		return this.httpClient.put<string>(`/api/clients/${id}/contact-note`, JSON.stringify(contactNote), { headers: { 'Content-Type': 'application/json' } });
	}
}