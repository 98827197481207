import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { UnitQuantity } from '../form-controls/unit-quantity/unit-quantity.control';

// this validator is specifically for validating the unit quantity control. It validates that the control value (UnitQuantity object) is not null,
// and that it has both a unit and a quantity specified

export function unitQuantityRequired(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {

		if (control.value === null) return { unitQuantityRequired: true };

		const unitQuantity = control.value as UnitQuantity;
		if (unitQuantity.unit === null || unitQuantity.quantity === null) return { unitQuantityRequired: true, unitPresent: unitQuantity.unit !== null, quantityPresent: unitQuantity.quantity !== null };

		return null;
	}
};