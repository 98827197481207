import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CheckboxControl } from 'src/app/form-controls/checkbox/checkbox.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { FaecesCheckAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface FaecesCheckActionFormGroup {
	faecesPassed: FormControl<boolean | null>;
}

@Component({
	selector: 'app-faeces-check-action-controls',
	templateUrl: './faeces-check-action-controls.component.html',
	styleUrls: ['../../../common.scss', './faeces-check-action-controls.component.scss'],
	standalone: true,
	imports: [ CommonActionControlsComponent, ActionFormComponent, ReactiveFormsModule, CheckboxControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FaecesCheckActionControlsComponent implements SheetActionControlsBase {

	slot!: Slot | null;
	action!: FaecesCheckAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as FaecesCheckAction;
	}

	@Input() form!: FormGroup<FaecesCheckActionFormGroup>;
	@Input() item!: Row | null;
	
	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.form.addControl('faecesPassed', this.formBuilder.control(this.action?.faecesPassed ?? null));
	}
}
