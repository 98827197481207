import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SelectControl, SelectItem } from 'src/app/form-controls/select/select.control';
import { UnitQuantity, UnitQuantityControl } from 'src/app/form-controls/unit-quantity/unit-quantity.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { PlasmaColour, TotalSolidsAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface TotalSolidsActionFormGroup {
	totalSolids: FormControl<UnitQuantity | null>;
	plasmaColour: FormControl<PlasmaColour | null>;
}

@Component({
	selector: 'app-total-solids-action-controls',
	templateUrl: './total-solids-action-controls.component.html',
	styleUrls: ['../../../common.scss', './total-solids-action-controls.component.scss'],
	standalone: true,
	imports: [ReactiveFormsModule, UnitQuantityControl, SelectControl, CommonActionControlsComponent, ActionFormComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TotalSolidsActionControlsComponent implements SheetActionControlsBase, OnInit {

	slot!: Slot | null;
	action!: TotalSolidsAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as TotalSolidsAction;
	}

	@Input() form!: FormGroup<TotalSolidsActionFormGroup>;
	@Input() item!: Row | null;
	
	plasmaColour: SelectItem[] = [
		{ text: 'Normal', value: PlasmaColour.Normal },
		{ text: 'Haemolysed (Red)', value: PlasmaColour.Haemolysed  },
		{ text: 'Icteric (Yellow)', value: PlasmaColour.Icteric },
		{ text: 'Lipaemic (Cream)', value: PlasmaColour.Lipaemic },
	];

	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.form.addControl('totalSolids', this.formBuilder.control(this.action?.totalSolids ?? null));
		this.form.addControl('plasmaColour', this.formBuilder.control(this.action?.plasmaColour ?? null));
	}
}