import { createReducer, on } from '@ngrx/store';
import * as authActions from '../auth/auth.actions';
import * as menuSelectionActions from './menu-selection.actions';

export interface State {
	userVisitsSelected: boolean;
	teamTasksSelectedTeamId: string;
}

export const initialState: State = {
	userVisitsSelected: false,
	teamTasksSelectedTeamId: ''
}

export const menuSelectionReducer = createReducer(
	initialState,
	on(menuSelectionActions.userVisitsSelected, (state) => ({
		...state,
		userVisitsSelected: true,
		teamTasksSelectedTeamId: ''
	})),
	on(menuSelectionActions.teamTasksSelected, (state, { teamId }) => ({
		...state,
		userVisitsSelected: false,
		teamTasksSelectedTeamId: teamId
	})),
	on(menuSelectionActions.searchedPatientSelected, (state) => ({
		...state,
		userVisitsSelected: false,
		teamTasksSelectedTeamId: ''
	})),
	on(authActions.clearStateRequested, () => initialState)
);