import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import { Team } from 'src/app/types/aggregate-graph.types';

export const selectIsActive = (state: AppState) => state.userGraph.accountDeactivated;
export const selectUserId = (state: AppState) => state.userGraph.userId;
export const selectFullName = (state: AppState) => state.userGraph.fullName;
export const selectInitials = (state: AppState) => state.userGraph.initials;
export const selectIsClinician = (state: AppState) => state.userGraph.isClinician;
export const selectIsStudent = (state: AppState) => state.userGraph.isStudent;
export const selectIsSystemAdministrator = (state: AppState) => state.userGraph.isSystemAdministrator;
export const selectPatientListId = (state: AppState) => state.userGraph.patientListId;
export const selectSelectedTeamIds = (state: AppState) => state.userGraph.selectedTeamIds;
export const selectSearchedPatients = (state: AppState) => state.userGraph.searchedPatients;
export const selectTeams = (state: AppState) => state.userGraph.teams;

export const userId = createSelector(
	selectUserId,
	(userId) => userId !== 0
)

export const accountDeactivated = createSelector(
	selectIsActive,
	(accountDeactivated) => accountDeactivated
);

export const isClinician = createSelector(
	selectIsClinician,
	(isClinician) => isClinician
);

export const isSystemAdministrator = createSelector(
	selectIsSystemAdministrator,
	(isSystemAdministrator) => isSystemAdministrator
);

export interface ProfileInfo {
	userId: number;
	fullName: string;
	initials: string;
	isClinician: boolean;
	isStudent: boolean;
	isSystemAdministrator: boolean;
}

export const profileInfo = createSelector(
	selectUserId,
	selectFullName,
	selectInitials,
	isClinician,
	selectIsStudent,
	isSystemAdministrator,
	(userId, fullName, initials, isClinician, isStudent, isSystemAdministrator): ProfileInfo => ({ userId, fullName, initials, isClinician, isStudent, isSystemAdministrator })
)

export const patientListId = createSelector(
	selectPatientListId,
	(patientListId) => patientListId
);

export const searchedPatients = createSelector(
	selectSearchedPatients,
	(searchedPatients) => searchedPatients
);

export const teams = createSelector(
	selectTeams,
	(teams) => teams
);

export const selectedTeams = createSelector(
	selectSelectedTeamIds,
	teams,
	(selectedTeamIds, teams) => teams.filter(team => selectedTeamIds.includes(team.id)).sort(sortTeamsByNameAsc)
);

export const activeTeams = createSelector(
	teams,
	(teams) => teams.filter(team => team.isActive).sort(sortTeamsByNameAsc)
);

const sortTeamsByNameAsc = (a: Team, b: Team) => a.name.localeCompare(b.name);