import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { HeadingComponent } from 'src/app/components/heading/heading.component';
import { CheckboxControl } from 'src/app/form-controls/checkbox/checkbox.control';
import { TextInputControl } from 'src/app/form-controls/text-input/text-input.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { GlasgowComaScaleAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface GlasgowComaScaleActionFormGroup {
	motorActvityScoreOne: FormControl<boolean | null>;
	motorActvityScoreTwo: FormControl<boolean | null>;
	motorActvityScoreThree: FormControl<boolean | null>;
	motorActvityScoreFour: FormControl<boolean | null>;
	motorActvityScoreFive: FormControl<boolean | null>;
	motorActvityScoreSix: FormControl<boolean | null>;
	reflexesScoreOne: FormControl<boolean | null>;
	reflexesScoreTwo: FormControl<boolean | null>;
	reflexesScoreThree: FormControl<boolean | null>;
	reflexesScoreFour: FormControl<boolean | null>;
	reflexesScoreFive: FormControl<boolean | null>;
	consciousnessScoreOne: FormControl<boolean | null>;
	consciousnessScoreTwo: FormControl<boolean | null>;
	consciousnessScoreThree: FormControl<boolean | null>;
	consciousnessScoreFour: FormControl<boolean | null>;
	consciousnessScoreFive: FormControl<boolean | null>;
	consciousnessScoreSix: FormControl<boolean | null>;
	totalScore: FormControl<number | null>;
}

@Component({
	selector: 'app-glasgow-coma-scale-action-controls',
	templateUrl: './glasgow-coma-scale-action-controls.component.html',
	styleUrls: ['../../../common.scss', './glasgow-coma-scale-action-controls.component.scss'],
	standalone: true,
	imports: [HeadingComponent, CommonActionControlsComponent, ActionFormComponent, ReactiveFormsModule, CheckboxControl, TextInputControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlasgowComaScaleActionControlsComponent implements SheetActionControlsBase {

	slot!: Slot | null;
	action!: GlasgowComaScaleAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as GlasgowComaScaleAction;
	}

	@Input() form!: FormGroup<GlasgowComaScaleActionFormGroup>;
	@Input() item!: Row | null;

	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.form.addControl('motorActvityScoreOne', this.formBuilder.control(this.action?.motorActvityScoreOne ?? null));
		this.form.addControl('motorActvityScoreTwo', this.formBuilder.control(this.action?.motorActvityScoreTwo ?? null));
		this.form.addControl('motorActvityScoreThree', this.formBuilder.control(this.action?.motorActvityScoreThree ?? null));
		this.form.addControl('motorActvityScoreFour', this.formBuilder.control(this.action?.motorActvityScoreFour ?? null));
		this.form.addControl('motorActvityScoreFive', this.formBuilder.control(this.action?.motorActvityScoreFive ?? null));
		this.form.addControl('motorActvityScoreSix', this.formBuilder.control(this.action?.motorActvityScoreSix ?? null));
		this.form.addControl('reflexesScoreOne', this.formBuilder.control(this.action?.reflexesScoreOne ?? null));
		this.form.addControl('reflexesScoreTwo', this.formBuilder.control(this.action?.reflexesScoreTwo ?? null));
		this.form.addControl('reflexesScoreThree', this.formBuilder.control(this.action?.reflexesScoreThree ?? null));
		this.form.addControl('reflexesScoreFour', this.formBuilder.control(this.action?.reflexesScoreFour ?? null));
		this.form.addControl('reflexesScoreFive', this.formBuilder.control(this.action?.reflexesScoreFive ?? null));
		this.form.addControl('consciousnessScoreOne', this.formBuilder.control(this.action?.consciousnessScoreOne ?? null));
		this.form.addControl('consciousnessScoreTwo', this.formBuilder.control(this.action?.consciousnessScoreTwo ?? null));
		this.form.addControl('consciousnessScoreThree', this.formBuilder.control(this.action?.consciousnessScoreThree ?? null));
		this.form.addControl('consciousnessScoreFour', this.formBuilder.control(this.action?.consciousnessScoreFour ?? null));
		this.form.addControl('consciousnessScoreFive', this.formBuilder.control(this.action?.consciousnessScoreFive ?? null));
		this.form.addControl('consciousnessScoreSix', this.formBuilder.control(this.action?.consciousnessScoreSix ?? null));
		this.form.addControl('totalScore', this.formBuilder.control(this.action?.totalScore ?? null));
	}
}