import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { HeadingComponent } from 'src/app/components/heading/heading.component';
import { CheckboxControl } from 'src/app/form-controls/checkbox/checkbox.control';
import { TextInputControl } from 'src/app/form-controls/text-input/text-input.control';
import { UnitQuantity, UnitQuantityControl } from 'src/app/form-controls/unit-quantity/unit-quantity.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { PlateletCountAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface PlateletCountActionFormGroup {
	motorActvityCheckOne: FormControl<boolean | null>;
	plateletCountClumpingGeneral: FormControl<boolean | null>;
	plateletCountClumpingFeatheredEdge: FormControl<boolean | null>;
	plateletCountClumpingBody: FormControl<boolean | null>;
	plateletCountAccurate: FormControl<boolean | null>;
	averageOne: FormControl<number | null>;
	averageTwo: FormControl<number | null>;
	averageThree: FormControl<number | null>;
	averageFour: FormControl<number | null>;
	averageFive: FormControl<number | null>;
	averageSix: FormControl<number | null>;
	averageSeven: FormControl<number | null>;
	averageEight: FormControl<number | null>;
	averageNine: FormControl<number | null>;
	averageTen: FormControl<number | null>;
	averageTotal: FormControl<number | null>;
	averageMultiplied: FormControl<UnitQuantity | null>;
	evaluationIncreased: FormControl<boolean | null>;
	evaluationAdequate: FormControl<boolean | null>;
	evaluationLow: FormControl<boolean | null>;
	evaluationVeryLow: FormControl<boolean | null>;
}

@Component({
	selector: 'app-platelet-count-action-controls',
	templateUrl: './platelet-count-action-controls.component.html',
	styleUrls: ['../../../common.scss', './platelet-count-action-controls.component.scss'],
	standalone: true,
	imports: [HeadingComponent, CommonActionControlsComponent, ActionFormComponent, ReactiveFormsModule, CheckboxControl, TextInputControl, UnitQuantityControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlateletCountActionControlsComponent implements SheetActionControlsBase {

	slot!: Slot | null;
	action!: PlateletCountAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as PlateletCountAction;
	}

	@Input() form!: FormGroup<PlateletCountActionFormGroup>;
	@Input() item!: Row | null;

	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.form.addControl('plateletCountClumpingGeneral', this.formBuilder.control(this.action?.plateletCountClumpingGeneral ?? null));
		this.form.addControl('plateletCountClumpingFeatheredEdge', this.formBuilder.control(this.action?.plateletCountClumpingFeatheredEdge ?? null));
		this.form.addControl('plateletCountClumpingBody', this.formBuilder.control(this.action?.plateletCountClumpingBody ?? null));
		this.form.addControl('plateletCountAccurate', this.formBuilder.control(this.action?.plateletCountAccurate ?? null));
		this.form.addControl('averageOne', this.formBuilder.control(this.action?.averageOne ?? null));
		this.form.addControl('averageTwo', this.formBuilder.control(this.action?.averageTwo ?? null));
		this.form.addControl('averageThree', this.formBuilder.control(this.action?.averageThree ?? null));
		this.form.addControl('averageFour', this.formBuilder.control(this.action?.averageFour ?? null));
		this.form.addControl('averageFive', this.formBuilder.control(this.action?.averageFive ?? null));
		this.form.addControl('averageSix', this.formBuilder.control(this.action?.averageSix ?? null));
		this.form.addControl('averageSeven', this.formBuilder.control(this.action?.averageSeven ?? null));
		this.form.addControl('averageEight', this.formBuilder.control(this.action?.averageEight ?? null));
		this.form.addControl('averageNine', this.formBuilder.control(this.action?.averageNine ?? null));
		this.form.addControl('averageTen', this.formBuilder.control(this.action?.averageTen ?? null));
		this.form.addControl('averageTotal', this.formBuilder.control(this.action?.averageTotal ?? null));
		this.form.addControl('averageMultiplied', this.formBuilder.control(this.action?.averageMultiplied ?? null));
		this.form.addControl('evaluationIncreased', this.formBuilder.control(this.action?.evaluationIncreased ?? null));
		this.form.addControl('evaluationAdequate', this.formBuilder.control(this.action?.evaluationAdequate ?? null));
		this.form.addControl('evaluationLow', this.formBuilder.control(this.action?.evaluationLow ?? null));
		this.form.addControl('evaluationVeryLow', this.formBuilder.control(this.action?.evaluationVeryLow ?? null));
	}
}