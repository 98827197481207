import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PatientStatus, ResuscitationStatus, VisitParticipationType } from '../types/aggregate-graph.types';

@Injectable({
	providedIn: 'root'
})
export class VisitsService {

	constructor(private httpClient: HttpClient) {
	}

	setPatientStatus(id: string, status: PatientStatus) {
		return this.httpClient.put<void>(`/api/visits/${id}/patient-status`, JSON.stringify(status), { headers: { 'Content-Type': 'application/json' } });
	}

	setCurrentTeam(id: string, currentTeamId: string) {
		return this.httpClient.put<void>(`/api/visits/${id}/current-team`, JSON.stringify(currentTeamId), { headers: { 'Content-Type': 'application/json' } });
	}

	addVisitParticipant(id: string, userId: number, participationType: VisitParticipationType) {
		return this.httpClient.put<void>(`/api/visits/${id}/participant`, { userId: userId, participationType: participationType });
	}

	removeVisitParticipant(id: string, userId: number) {
		return this.httpClient.delete<void>(`/api/visits/${id}/participant/${userId}`);
	}

	setResuscitationStatus(id: string, status: ResuscitationStatus) {
		return this.httpClient.put<void>(`/api/visits/${id}/resuscitation-status`, JSON.stringify(status), { headers: { 'Content-Type': 'application/json' } });
	}

	setWard(id: string, ward: string) {
		return this.httpClient.put<void>(`/api/visits/${id}/ward`, JSON.stringify(ward), { headers: { 'Content-Type': 'application/json' } });
	}

	setKennel(id: string, kennel: number) {
		return this.httpClient.put<void>(`/api/visits/${id}/kennel`, JSON.stringify(kennel), { headers: { 'Content-Type': 'application/json' } });
	}

	setDischargeDate(id: string, dischargeDate: string) {
		return this.httpClient.put<void>(`/api/visits/${id}/discharge-date`, JSON.stringify(dischargeDate), { headers: { 'Content-Type': 'application/json' } });
	}
}