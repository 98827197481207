import { createAction, props } from '@ngrx/store';
import { Client, GraphAggregate, GraphAggregateChange, GraphChangeContext, PartialEntity, Patient, PatientRecordTask, TaskDocument, Visit } from 'src/app/types/aggregate-graph.types';

export enum PatientGraphActionTypes {
	SubscribePatientRequested = '[Patient Effect] Subscribe Patient Requested',
	UnsubscribePatientRequested = '[Patient Effect] Unsubscribe Patient Requested',
	PatientGraphGenerated = '[Aggregate Graph Hub] Patient Graph Generated',
	PatientGraphChanged = '[Aggregate Graph Hub] Patient Graph Changed',
	PatientGraphLoaded = '[Patient Effect] Patient Graph Loaded',
	PatientUpdated = '[Patient Effect] Patient Updated',
	ClientUpdated = '[Patient Effect] Client Updated',
	VisitUpdated = '[Patient Effect] Visit Updated',
	VisitAdded = '[Patient Effect] Visit Added',
	TaskAdded = '[Patient Effect] Task Added',
	TaskUpdated = '[Patient Effect] Task Updated',
	TaskDocumentAdded = '[Patient Effect] Task Document Added',
	TaskDocumentUpdated = '[Patient Effect] Task Document Updated',
	PatientGraphSystemRestarted = '[Aggregate Graph Hub] Patient Graph System Restarted',
	ReloadPatientRequested = '[Patient Effect] Reload Patient Requested'
}

export const subscribePatientRequested = createAction(
	PatientGraphActionTypes.SubscribePatientRequested,
	props<{ patientChanged: boolean, patientId: string, taskId: string, serviceId: string }>()
)

export const unsubscribePatientRequested = createAction(
	PatientGraphActionTypes.UnsubscribePatientRequested
)

export const patientGraphGenerated = createAction(
	PatientGraphActionTypes.PatientGraphGenerated,
	props<{ patientId: string, aggregates: GraphAggregate[] }>()
)

export const patientGraphChanged = createAction(
	PatientGraphActionTypes.PatientGraphChanged,
	props<{ patientId: string, changes: GraphAggregateChange[], context: GraphChangeContext }>()
)

export const patientGraphLoaded = createAction(
	PatientGraphActionTypes.PatientGraphLoaded,
	props<{ patient: Patient, clients: Client[], visits: Visit[], tasks: PatientRecordTask[], taskDocuments: TaskDocument[] }>()
)

export const patientUpdated = createAction(
	PatientGraphActionTypes.PatientUpdated,
	props<{ partialPatient: PartialEntity<Patient> }>()
)

export const clientUpdated = createAction(
	PatientGraphActionTypes.ClientUpdated,
	props<{ partialClient: PartialEntity<Client> }>()
)

export const visitUpdated = createAction(
	PatientGraphActionTypes.VisitUpdated,
	props<{ partialVisit: PartialEntity<Visit> }>()
)

export const visitAdded = createAction(
	PatientGraphActionTypes.VisitAdded,
	props<{ visit: Visit }>()
)

export const taskAdded = createAction(
	PatientGraphActionTypes.TaskAdded,
	props<{ task: PatientRecordTask }>()
)

export const taskUpdated = createAction(
	PatientGraphActionTypes.TaskUpdated,
	props<{ partialTask: PartialEntity<PatientRecordTask> }>()
)

export const taskDocumentAdded = createAction(
	PatientGraphActionTypes.TaskDocumentAdded,
	props<{ taskDocument: TaskDocument }>()
)

export const taskDocumentUpdated = createAction(
	PatientGraphActionTypes.TaskDocumentUpdated,
	props<{ partialTaskDocument: PartialEntity<TaskDocument> }>()
)

export const patientGraphSystemRestarted = createAction(
	PatientGraphActionTypes.PatientGraphSystemRestarted
)

export const reloadPatientRequested = createAction(
	PatientGraphActionTypes.ReloadPatientRequested
)