import { createReducer, on } from '@ngrx/store';
import jwt_decode from 'jwt-decode';
import * as authActions from './auth.actions';

export enum LoginStatus {
	Loading,
	SelectUser,
	NewUser,
	AuthenticatePassword,
	VerifyCode,
	AuthenticatePin
}

export interface RecentUser {
	fullName: string;
	userName: string;
}

export interface State {
	requestedUrl: string,
	verificationCodeRequired: boolean,
	verificationSessionId: string,
	userName: string | null,
	accessToken: string,
	clientSessionId: string,
	refreshToken: string,
	recentUsers: RecentUser[],
	loginStatus: LoginStatus
}

export const initialState: State = {
	requestedUrl: '',
	verificationCodeRequired: false,
	verificationSessionId: '',
	userName: null,
	accessToken: '',
	clientSessionId: '',
	refreshToken: '',
	recentUsers: [],
	loginStatus: LoginStatus.Loading
}

interface decodedJwtToken {
	sid: string
}

export const authReducer = createReducer(
	initialState,
	on(authActions.loginRequired, (state, { requestedUrl }) => ({
		...state,
		requestedUrl: requestedUrl
	})),
	on(authActions.loginInitiated, (state) => ({
		...state,
		loginStatus: LoginStatus.Loading
	})),
	on(authActions.recentUsersLoaded, (state, { recentUsers }) => ({
		...state,
		recentUsers: recentUsers,
		loginStatus: LoginStatus.SelectUser
	})),
	on(authActions.newUserSelected, (state) => ({
		...state,
		loginStatus: LoginStatus.NewUser
	})),
	on(authActions.usernameCancelled, (state) => ({
		...state,
		loginStatus: LoginStatus.SelectUser,
		userName: null
	})),
	on(authActions.usernameEntered, (state, { username }) => ({
		...state,
		userName: username,
		loginStatus: LoginStatus.Loading
	})),
	on(authActions.recentUserSelected, (state, { username }) => ({
		...state,
		loginStatus: LoginStatus.Loading,
		userName: username
	})),
	on(authActions.passwordRequired, (state) => ({
		...state,
		loginStatus: LoginStatus.AuthenticatePassword
	})),
	on(authActions.passwordCancelled, (state) => ({
		...state,
		loginStatus: LoginStatus.SelectUser,
		userName: null
	})),
	on(authActions.passwordEntered, (state) => ({
		...state,
		loginStatus: LoginStatus.Loading
	})),
	on(authActions.verificationCodeRequired, (state, { sessionId }) => ({
		...state,
		verificationCodeRequired: true,
		verificationSessionId: sessionId,
		loginStatus: LoginStatus.VerifyCode
	})),
	on(authActions.authenticatePasswordSucceeded, (state, { tokenInfo }) => ({
		...state,
		accessToken: tokenInfo.accessToken,
		clientSessionId: (jwt_decode(tokenInfo.accessToken) as decodedJwtToken).sid,
		refreshToken: tokenInfo.refreshToken
	})),
	on(authActions.authenticatePasswordFailed, (state) => ({
		...state,
		loginStatus: LoginStatus.SelectUser
	})),
	on(authActions.authenticatePasswordCancelled, (state) => ({
		...state,
		loginStatus: LoginStatus.SelectUser,
		userName: null
	})),
	on(authActions.verificationCodeEntered, (state, { verificationCode }) => ({
		...state,
		loginStatus: LoginStatus.Loading,
		verificationCode: verificationCode
	})),
	on(authActions.verifyCodeSucceeded, (state, { tokenInfo }) => ({
		...state,
		verificationSessionId: '',
		accessToken: tokenInfo.accessToken,
		clientSessionId: (jwt_decode(tokenInfo.accessToken) as decodedJwtToken).sid,
		refreshToken: tokenInfo.refreshToken
	})),
	on(authActions.verifyCodeFailed, (state) => ({
		...state,
		loginStatus: LoginStatus.SelectUser,
		verificationCodeRequired: false
	})),
	on(authActions.pinRequired, (state) => ({
		...state,
		loginStatus: LoginStatus.AuthenticatePin
	})),
	on(authActions.pinCancelled, (state) => ({
		...state,
		loginStatus: LoginStatus.SelectUser,
		userName: null
	})),
	on(authActions.pinEntered, (state) => ({
		...state,
		loginStatus: LoginStatus.Loading
	})),
	on(authActions.authenticatePinSucceeded, (state, { tokenInfo }) => ({
		...state,
		accessToken: tokenInfo.accessToken,
		clientSessionId: (jwt_decode(tokenInfo.accessToken) as decodedJwtToken).sid,
		refreshToken: tokenInfo.refreshToken
	})),
	on(authActions.authenticatePinFailed, (state) => ({
		...state,
		loginStatus: LoginStatus.SelectUser
	})),
	on(authActions.tokenRefreshSucceeded, (state, { tokenInfo }) => ({
		...state,
		accessToken: tokenInfo.accessToken,
		clientSessionId: (jwt_decode(tokenInfo.accessToken) as decodedJwtToken).sid,
		refreshToken: tokenInfo.refreshToken
	})),
	on(authActions.recentUsersUpdated, (state, { recentUsers }) => ({
		...state,
		recentUsers: recentUsers
	})),
	on(authActions.clearStateRequested, () => initialState)
);