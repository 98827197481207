import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SheetItemComponentBase } from 'src/app/services/sheets-renderer.service';
import { Row } from 'src/app/types/aggregate-graph.types';
import { NutritionInstructionsV2, NutritionMethodV2 } from 'src/app/types/sheet-instruction.types';
import { InstructionBoxComponent } from '../../instruction-box/instruction-box.component';
import { ItemContentComponent } from '../../item-content/item-content.component';

@Component({
	selector: 'app-nutrition-item',
	templateUrl: './nutrition-item-v2.component.html',
	styleUrl: './nutrition-item-v2.component.scss',
	standalone: true,
	imports: [ItemContentComponent, InstructionBoxComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NutritionItemComponentV2 implements SheetItemComponentBase {
	
	row!: Row;
	instructions!: NutritionInstructionsV2;
	nutritionMethod!: string[];
	@Input() startLabel!: string;
    @Input() endLabel!: string;
	@Input('row') set updateRow(row: Row) {
		this.row = row;
		this.instructions = row.instructions as NutritionInstructionsV2;
		this.nutritionMethod = Object.values(this.instructions).map(instruction => this.getNutritionMethod(instruction.nutritionMethod));
	}
	
	constructor() {
	}

	private getNutritionMethod(nutritionMethod: NutritionMethodV2 | null) {
		switch (nutritionMethod) {
			case NutritionMethodV2.PerOs: return 'Per os.' ;
			case NutritionMethodV2.AssistedFeeding: return 'Assisted Feeding';
			case NutritionMethodV2.FeedFromHeight: return 'Feed from height';
			case NutritionMethodV2.HandedFeedSmallBits: return 'Handed small bits of food';
			case NutritionMethodV2.SupervisedFeeding: return 'Supervised feeding';
			case NutritionMethodV2.TemptToEat: return 'Tempt to eat';
			default: return '';
		}
	}
}