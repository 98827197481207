import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IonSpinner } from '@ionic/angular/standalone';

@Component({
	selector: 'app-spinner',
	template: '<ion-spinner></ion-spinner>',
	styles: [':host { display: block; min-width: 100%; text-align: center }'],
	standalone: true,
	imports: [IonSpinner],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent { }