import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'app-item-checkbox',
	templateUrl: './item-checkbox.component.html',
	styleUrl: './item-checkbox.component.scss',
	standalone: true,
	imports: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('showStateIcon', [
			state('false', style({ opacity: 0 })),
			state('true', style({ opacity: 1 })),
			transition('false <=> true', animate(50))
		])
	]
})
export class ItemCheckboxComponent {

	@Input() checked: boolean = false;
	@Input() disabled: boolean = false;

	constructor() {
	}
}