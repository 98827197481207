import { createReducer, on } from '@ngrx/store';
import { TeamMember } from 'src/app/services/teams.service';
import { clearStateRequested } from '../auth/auth.actions';
import * as serviceSettingsActions from './service-settings.actions';

export interface State {
	serviceId: string;
	serviceSettingsLoaded: boolean;
	teamMembershipEnabled: boolean;
	teamMembers: TeamMember[];
}

export const initialState: State = {
	serviceId: '',
	serviceSettingsLoaded: false,
	teamMembershipEnabled: false,
	teamMembers: []
}

export const serviceSettingsReducer = createReducer(
	initialState,
	on(serviceSettingsActions.loadServiceSettingsRequested, (state, { serviceId, teamMembershipEnabled }) => ({
		...state,
		serviceId: serviceId,
		serviceSettingsLoaded: false,
		teamMembershipEnabled: teamMembershipEnabled
	})),
	on(serviceSettingsActions.serviceSettingsLoaded, (state, { teamMembers }) => ({
		...state,
		serviceSettingsLoaded: true,
		teamMembers: teamMembers
	})),
	on(serviceSettingsActions.serviceSettingsUnloaded, () => initialState),
	on(serviceSettingsActions.teamMembershipEnabled, (state) => ({
		...state,
		teamMembershipEnabled: true
	})),
	on(serviceSettingsActions.teamMembershipDisabled, (state) => ({
		...state,
		teamMembershipEnabled: false
	})),
	on(serviceSettingsActions.teamMemberAdded, (state, { teamMember }) => ({
		...state,
		teamMembers: [...state.teamMembers, teamMember]
	})),
	on(serviceSettingsActions.teamMemberRemoved, (state, { userId }) => ({
		...state,
		teamMembers: state.teamMembers.filter(teamMember => teamMember.userId !== userId)
	})),
	on(serviceSettingsActions.canEditMemberListStateChanged, (state, { userId, canEditMemberList }) => {
		const teamMember = state.teamMembers.find(teamMember => teamMember.userId === userId);
		if (!teamMember) return { ...state };
		return {
			...state,
			teamMembers: [...state.teamMembers.filter(teamMember => teamMember.userId !== userId), { ...teamMember, canEditMemberList: canEditMemberList }]
		};
	}),
	on(clearStateRequested, () => initialState)
);