import { createAction, props } from '@ngrx/store';
import { SearchedPatient } from 'src/app/services/patients.service';
import { GraphAggregate, GraphAggregateChange, GraphChangeContext, PartialEntity, Team, User } from 'src/app/types/aggregate-graph.types';

export enum UserGraphActionTypes {
	SubscribeUserRequested = '[User Effect] Subscribe User Requested',
	UserGraphGenerated = '[Aggregate Graph Hub] User Graph Generated',
	UserGraphChanged = '[Aggregate Graph Hub] User Graph Changed',
	UserGraphSystemRestarted = '[Aggregate Graph Hub] User Graph System Restarted',
	UserGraphLoaded = '[User Effect] User Graph Loaded',
	UserUpdated = '[User Effect] User Updated',
	TeamUpdated = '[User Effect] Team Updated',
	TeamAdded = '[User Effect] Team Added',
	SelectTeamRequested = '[Component] Select Team Requested',
	DeselectTeamRequested = '[User] Deselect Team Requested',
	TeamSelected = '[User Effect] Team Selected',
	TeamDeselected = '[User Effect] Team Deselected',
	SearchedPatientAdded = '[User Effect] Searched Patient Added',
	SaveUserLoginPinRequested = '[Component] Save User Login Pin Requested',
	SaveUserLoginPinSucceeded = '[Auth Effect] Save User Login Pin Succeeded'
}

export const subscribeUserRequested = createAction(
	UserGraphActionTypes.SubscribeUserRequested
)

export const userGraphGenerated = createAction(
	UserGraphActionTypes.UserGraphGenerated,
	props<{ aggregates: GraphAggregate[] }>()
)

export const userGraphChanged = createAction(
	UserGraphActionTypes.UserGraphChanged,
	props<{ changes: GraphAggregateChange[], context: GraphChangeContext }>()
)

export const userGraphSystemRestarted = createAction(
	UserGraphActionTypes.UserGraphSystemRestarted
)

export const userGraphLoaded = createAction(
	UserGraphActionTypes.UserGraphLoaded,
	props<{ user: User, teams: Team[] }>()
)

export const userUpdated = createAction(
	UserGraphActionTypes.UserUpdated,
	props<{ partialUser: PartialEntity<User> }>()
)

export const teamUpdated = createAction(
	UserGraphActionTypes.TeamUpdated,
	props<{ partialTeam: PartialEntity<Team> }>()
)

export const teamAdded = createAction(
	UserGraphActionTypes.TeamAdded,
	props<{ team: Team }>()
)

export const selectTeamRequested = createAction(
	UserGraphActionTypes.SelectTeamRequested,
	props<{ teamId: string }>()
)

export const deselectTeamRequested = createAction(
	UserGraphActionTypes.DeselectTeamRequested,
	props<{ teamId: string }>()
)

export const teamSelected = createAction(
	UserGraphActionTypes.TeamSelected,
	props<{ teamId: string }>()
)

export const teamDeselected = createAction(
	UserGraphActionTypes.TeamDeselected,
	props<{ teamId: string }>()
)

export const searchedPatientAdded = createAction(
	UserGraphActionTypes.SearchedPatientAdded,
	props<{ patient: SearchedPatient }>()
)

export const saveUserLoginPinRequested = createAction(
	UserGraphActionTypes.SaveUserLoginPinRequested,
	props<{ pin: number }>()
)

export const saveUserLoginPinSucceeded = createAction(
	UserGraphActionTypes.SaveUserLoginPinSucceeded
)