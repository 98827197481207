import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SelectControl, SelectItem } from 'src/app/form-controls/select/select.control';
import { TextInputControl } from 'src/app/form-controls/text-input/text-input.control';
import { SheetItemService } from 'src/app/services/sheet-item.service';
import { SheetItemDetailConfig, SheetItemFormBase } from 'src/app/services/sheets-renderer.service';
import { Row } from 'src/app/types/aggregate-graph.types';
import { ColdCompressInstructionV2, Method } from 'src/app/types/sheet-instruction.types';
import { InstructionsFormComponent } from '../../instructions-form/instructions-form.component';

interface ColdCompressInstructionFormGroup {
	area: FormControl<string | null>;
	method: FormControl<Method | null>;
}

@Component({
	selector: 'app-cold-compress-item-form',
	templateUrl: './cold-compress-item-form-v2.component.html',
	styleUrl: './cold-compress-item-form-v2.component.scss',
	standalone: true,
	imports: [ReactiveFormsModule, InstructionsFormComponent, SelectControl, TextInputControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColdCompressItemFormComponentV2 implements SheetItemFormBase, OnInit {

	@Input() instructionTypeName!: string;
	@Input() item!: Row | null;
	@Input() minDate!: string;
	@Input() maxDate!: string;
	@Input() instructionsFormArray!: FormArray;
	@Input() itemConfig!: SheetItemDetailConfig;

	methods: SelectItem[] = [
		{ text: 'Cryo Cuff', value: Method.CryoCuff },
		{ text: 'Pack', value: Method.Pack },
	];


	constructor(private sheetItemService: SheetItemService, private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.sheetItemService.getInstructions(this.item, this.instructionTypeName, this.itemConfig.scheduleConfig.defaultScheduleType, this.itemConfig.scheduleConfig.defaultRepeatingScheduleFrequency).forEach(instruction => {
			const group: FormGroup<ColdCompressInstructionFormGroup> = this.sheetItemService.buildExistingInstructionGroup(this.instructionTypeName, instruction);
			const coldCompressInstruction = instruction as ColdCompressInstructionV2;
			group.addControl('area', this.formBuilder.control(coldCompressInstruction.area ?? null));
			group.addControl('method', this.formBuilder.control(coldCompressInstruction.method ?? null));
			this.instructionsFormArray.push(group);
		});
	}

	addInstruction() {
		const group: FormGroup<ColdCompressInstructionFormGroup> = this.sheetItemService.getNewInstructionGroup(this.instructionTypeName, this.itemConfig.scheduleConfig.defaultScheduleType, this.itemConfig.scheduleConfig.defaultRepeatingScheduleFrequency);
		group.addControl('area', this.formBuilder.control(null));
		group.addControl('method', this.formBuilder.control(null));
		this.instructionsFormArray.push(group);
	}
}