import { createAction, props } from '@ngrx/store';
import { GraphChangeContext, PartialEntity, Row, Sheet } from 'src/app/types/aggregate-graph.types';
import { SheetGranularity } from 'src/app/types/sheet.types';

export enum SheetGraphActionTypes {
	CreateSheetRequested = '[Component] Create Sheet Requested',
	CreateSheetCompleted = '[Sheet Graph Effect] Create Sheet Completed',
	SubscribeSheetRequested = '[Sheet Graph Effect] Subscribe Sheet Requested',
	ResubscribeSheetRequested = '[Sheet Graph Effect] Resubscribe Sheet Requested',
	UnsubscribeSheetRequested = '[Sheet Graph Effect] Unsubscribe Sheet Requested',
	SheetGraphGeneratedMessageReceived = '[Aggregate Graph Hub Effect] Sheet Graph Generated Message Received',
	SheetGraphGenerated = '[Sheet Graph Effect] Sheet Graph Generated',
	SheetResizedMessageReceived = '[Aggregate Graph Hub Effect] Sheet Resized Message Received',
	SheetResized = '[Sheet Graph Effect] Sheet Resized',
	RowChangedMessageReceived = '[Aggregate Graph Hub Effect] Row Changed Message Received',
	RowChanged = '[Sheet Graph Effect] Row Changed',
	SheetGraphSystemInitialised = '[Sheet Graph Effect] Sheet Graph System Initialised',
	ExportSheetRequested = '[Sheet Graph Effect] Export Sheet Requested'
}

export const createSheetRequested = createAction(
	SheetGraphActionTypes.CreateSheetRequested,
	props<{ visitId: string }>()
)

export const createSheetCompleted = createAction(
	SheetGraphActionTypes.CreateSheetCompleted,
	props<{ visitId: string, sheetId: string }>()
)

export const subscribeSheetRequested = createAction(
	SheetGraphActionTypes.SubscribeSheetRequested,
	props<{ sheetId: string, granularity: SheetGranularity }>()
)

export const resubscribeSheetRequested = createAction(
	SheetGraphActionTypes.ResubscribeSheetRequested,
	props<{ sheetId: string, granularity: SheetGranularity }>()
)

export const unsubscribeSheetRequested = createAction(
	SheetGraphActionTypes.UnsubscribeSheetRequested,
	props<{ sheetId: string }>()
)

export const sheetGraphGeneratedMessageReceived = createAction(
	SheetGraphActionTypes.SheetGraphGeneratedMessageReceived,
	props<{ sheet: Sheet }>()
)

export const sheetGraphGenerated = createAction(
	SheetGraphActionTypes.SheetGraphGenerated,
	props<{ sheet: Sheet, granularityChangeActive: boolean }>()
)

export const sheetResizedMessageReceived = createAction(
	SheetGraphActionTypes.SheetResizedMessageReceived,
	props<{ sheetId: string, partialSheet: PartialEntity<Sheet>, context: GraphChangeContext }>()
)

export const sheetResized = createAction(
	SheetGraphActionTypes.SheetResized,
	props<{ partialSheet: PartialEntity<Sheet> }>()
)

export const rowChangedMessageReceived = createAction(
	SheetGraphActionTypes.RowChangedMessageReceived,
	props<{ sheetId: string, row: Row, context: GraphChangeContext }>()
)

export const rowChanged = createAction(
	SheetGraphActionTypes.RowChanged,
	props<{ row: Row }>()
)

export const sheetGraphSystemInitialised = createAction(
	SheetGraphActionTypes.SheetGraphSystemInitialised,
	props<{ sheetId: string }>()
)