import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SheetSlotComponentBase } from 'src/app/services/sheets-renderer.service';
import { UrinalysisAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { TickValueComponent } from '../../tick-value/tick-value.component';

@Component({
	selector: 'app-urinalysis-slot',
	templateUrl: './urinalysis-slot.component.html',
	styleUrl: './urinalysis-slot.component.scss',
	standalone: true,
	imports: [TickValueComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UrinalysisSlotComponent implements SheetSlotComponentBase {

	@Input() action!: UrinalysisAction;
	value = null;

	constructor() {
	}
}
