import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CoercionService } from 'src/app/services/coercion.service';

@Component({
	selector: 'app-item-input',
	templateUrl: './item-input.component.html',
	styleUrl: './item-input.component.scss',
	standalone: true,
	imports: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemInputComponent implements AfterViewInit {

	@Input() placeholder: string = '';
	@Input() value: string | null = null;
	@Input() maxlength: number | null = null;
	@Input() type: 'text' | 'password' = 'text';
	@Input() inputMode: 'text' | 'numeric' | 'decimal' = 'text';
	@Input() set leftAlign(value: any) { this.isLeftAligned = this.coercionService.coerceToBoolean(value) }

	isLeftAligned: boolean = false;

	@Output() itemFocus = new EventEmitter();
	@Output() inputChange = new EventEmitter<string>(); // this fires on every change by the user (e.g. keypress that adds/removes a character)
	@Output() valueChange = new EventEmitter<string>(); // this fires if the value has changed upon blur
	@Output() itemBlur = new EventEmitter<string>();

	@ViewChild('input') input!: ElementRef<HTMLInputElement>;

	constructor(private coercionService: CoercionService) {
	}

	ngAfterViewInit(): void {
		if (this.inputMode !== 'text') {
			// only allow integer or decimal character entry
			const requiredRegex = new RegExp(this.inputMode === 'numeric' ? '^\\d*$' : '(^\\d*$)|(^\\d+\\.?\\d*$)');
			this.input.nativeElement.addEventListener('beforeinput', (event: InputEvent) => {
				const input = event.target as HTMLInputElement;
				const valueAfterInput = input.value.slice(0, input.selectionStart!) + (event.data ?? '') + input.value.slice(input.selectionEnd!);
				if (!requiredRegex.test(valueAfterInput)) event.preventDefault();
			});
		}
	}

	onFocus() {
		this.itemFocus.emit();
	}

	onInput(event: Event) {
		this.inputChange.emit((event.target as HTMLInputElement).value);
	}

	onChange(event: Event) {
		this.valueChange.emit((event.target as HTMLInputElement).value);
	}

	onBlur(event: Event) {
		this.itemBlur.emit((event.target as HTMLInputElement).value);
	}

	focusInput() {
		this.input.nativeElement.focus();
	}
}