<app-action-form [item]="item" [slot]="slot" [form]="form">
	<div class="form-section app-margin-horizontal app-padding">
    <div class="form-group" [formGroup]="form">
      <app-unit-quantity label="Systolic" unitLabel="mmHg" placeholder="Value" inputMode="numeric" formControlName="systolic"></app-unit-quantity>
      <app-unit-quantity label="Mean" unitLabel="mmHg" placeholder="Value" inputMode="numeric" formControlName="mean"></app-unit-quantity>
      <app-select label="Location" placeholder="Choose" [items]="locationItems" formControlName="location"></app-select>
      <app-text-input label="Cuff size" placeholder="Value" formControlName="cuffSize" inputMode="decimal"></app-text-input>
      <app-text-input label="Demeanor" placeholder="Value" formControlName="demeanor"></app-text-input>
    </div>
    <app-common-action-controls [action]="action" [form]="form"></app-common-action-controls>
</div>
</app-action-form>
