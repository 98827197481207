<ion-header>
	<ion-toolbar>
		<ion-title>Service Administration</ion-title>
		<ion-buttons slot="end">
			<ion-button (click)="close()" color="primary">
				<ion-icon slot="icon-only" name="close-sharp"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
<ng-container *ngrxLet="{ selectedTeam: selectedTeam$, serviceSettingsLoaded: serviceSettingsLoaded$, teamAdministrators: teamAdministrators$ } as vm; suspenseTpl: loading">
	@if(vm.serviceSettingsLoaded){
		<div class="scroll-vertical app-padding">
			@if(!vm.selectedTeam.teamMembershipEnabled){
				<div class="button-container">
					<app-button fill="solid" (click)="presentEnableTeamMembershipAlert()">Enable Service Membership</app-button>
				</div>
			}
			@if(vm.selectedTeam.teamMembershipEnabled){
				<p class="first-paragraph">The following users are administrators of this service. They are authorised to manage service users.</p>
				<div appAccordion class="member-container">
					@for(teamAdministrator of vm.teamAdministrators; track teamAdministrator.userId; let index = $index){
						<app-expander [accordionIndex]="index" @expanderEnterLeave>
							<app-item-label>{{teamAdministrator.fullName}}</app-item-label>
							<div class="expander-content">
								<div class="button-container">
									<app-button fill="clear" (click)="removeTeamAdministrator(teamAdministrator.userId)">Remove Administrator</app-button>
								</div>
							</div>
						</app-expander>
					}
				</div>
				<app-item appNavigable (click)="presentUserSearchModal()">
					<app-item-label>Add administrator</app-item-label>
					<app-svg-icon name="add-circle-outline"></app-svg-icon>
				</app-item>
				<div class="button-container">
					<app-button fill="solid" colour="critical-1" (click)="presentDisableTeamMembershipAlert()">Disable Service Membership</app-button>
				</div>
			}
		</div>
	}
	@else{
		<app-spinner class="margin-top"></app-spinner>
	}
</ng-container>
<ion-alert [isOpen]="teamMembershipEnabledAlertIsOpen" subHeader="Enable Service Membership" message="This will prevent unauthorised users from processing tasks for this service." [buttons]="teamMembershipEnabledAlertButtons" (didDismiss)="enableTeamMembershipAlertDismissed()">
</ion-alert>
<ion-alert [isOpen]="teamMembershipDisabledAlertIsOpen" subHeader="Disable Service Membership" message="This will allow all users to process tasks for this service." [buttons]="teamMembershipDisabledAlertButtons" (didDismiss)="disableTeamMembershipAlertDismissed()">
</ion-alert>
<ng-template #loading>
	<app-spinner class="margin-top"></app-spinner>
</ng-template>