import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UnitQuantity, UnitQuantityControl } from 'src/app/form-controls/unit-quantity/unit-quantity.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { MedicationAmountUnit, MedicationRedLabelAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';
import { UserSignature, UserSignatureControl } from 'src/app/form-controls/user-signature/user-signature.control';
import { Unit } from 'src/app/form-controls/shared/popovers/unit-popover/unit.popover';

interface MedicationRedLabelActionFormGroup {
	amountGiven: FormControl<UnitQuantity | null>;
	approvalOne: FormControl<UserSignature | null>;
	approvalTwo: FormControl<UserSignature | null>;
}

@Component({
	selector: 'app-medication-red-label-action-controls',
	templateUrl: './medication-red-label-action-controls.component.html',
	styleUrls: ['../../../common.scss', './medication-red-label-action-controls.component.scss'],
	standalone: true,
	imports: [ActionFormComponent, ReactiveFormsModule, UnitQuantityControl, CommonActionControlsComponent, UserSignatureControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MedicationRedLabelActionControlsComponent implements SheetActionControlsBase, OnInit {

	slot!: Slot | null;
	action!: MedicationRedLabelAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as MedicationRedLabelAction;
	}

	@Input() form!: FormGroup<MedicationRedLabelActionFormGroup>;
	@Input() item!: Row | null;

	amountUnits: Unit[] = [
		{ label: MedicationAmountUnit.Mg },
		{ label: MedicationAmountUnit.Ml },
		{ label: MedicationAmountUnit.Ug },
		{ label: MedicationAmountUnit.L },
		{ label: MedicationAmountUnit.G },
		{ label: MedicationAmountUnit.Kg },
		{ label: MedicationAmountUnit.Drop },
		{ label: MedicationAmountUnit.Graduation },
		{ label: MedicationAmountUnit.Tablet },
		{ label: MedicationAmountUnit.Capsule },
		{ label: MedicationAmountUnit.Spray },
		{ label: MedicationAmountUnit.Pump }
	];

	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.form.addControl('amountGiven', this.formBuilder.control(this.action?.amountGiven ?? null));
		this.form.addControl('approvalOne', this.formBuilder.control(this.action?.approvalOne ?? null));
		this.form.addControl('approvalTwo', this.formBuilder.control(this.action?.approvalTwo ?? null));
	}
}