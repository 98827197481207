<app-action-form [item]="item" [slot]="slot" [form]="form">
	<div class="form-section app-margin-horizontal app-padding">
    <div class="form-group" [formGroup]="form">
      <app-checkbox label="Suction of oropharynx with yankauer (or other appropriate) suction tip" formControlName="suction"></app-checkbox>
      <app-checkbox label="Clean oral cavity with 0.1% chlorhexidine solution (Corsodyl mouthwash diluted 1:1 in distilled water) using a clean foam tip toothbrush each time" formControlName="cleanCavity"></app-checkbox>
      <app-checkbox label="Clean monitoring equipment/gags sitting in the oral cavity with 0.05% chlorhexidine solution" formControlName="cleanEquipment"></app-checkbox>
    </div>
    <app-common-action-controls [action]="action" [form]="form"></app-common-action-controls>
  </div>
</app-action-form>
