import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ItemInputComponent } from 'src/app/components/item-input/item-input.component';
import { ItemLabelComponent } from 'src/app/components/item-label/item-label.component';
import { ItemValueComponent } from 'src/app/components/item-value/item-value.component';
import { ItemComponent } from 'src/app/components/item/item.component';

export type TextInputValue = string | number;

@Component({
	selector: 'app-text-input',
	templateUrl: './text-input.control.html',
	styleUrl: './text-input.control.scss',
	standalone: true,
	imports: [ReactiveFormsModule, ItemComponent, ItemLabelComponent, ItemInputComponent, ItemValueComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: TextInputControl,
		multi: true
	}]
})
export class TextInputControl implements ControlValueAccessor {

	@Input() readOnly: boolean = false;
	@Input() label: string = '';
	@Input() placeholder: string = '';
	@Input() maxlength: number | null = null;
	@Input() inputMode: 'text' | 'numeric' | 'decimal' = 'text';
	@Input() type: 'text' | 'password' = 'text';

	@Output() valueChange = new EventEmitter<TextInputValue>();

	@ViewChild(ItemInputComponent) itemInputComponent!: ItemInputComponent;

	constructor(private changeDetectorRef: ChangeDetectorRef) {
	}

	value: string = '';

	writeValue(value: TextInputValue | null): void {
		this.value = value?.toString() ?? '';
		
		// this is required for the view to update correctly (it's a general approach recommended by Angular devs using ChangeDetectionStrategy.OnPush: https://github.com/angular/angular/issues/21780)
		this.changeDetectorRef.markForCheck();
	}

	private onChange: (value: TextInputValue) => void = () => { };

	registerOnChange(fn: (value: TextInputValue) => void): void {
		this.onChange = fn;
	}

	private onTouched: () => void = () => { };

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(disabled: boolean): void {
	}

	valueChanged(value: string | null) {
		this.value = value ?? '';
		const convertedValue = this.inputMode === 'numeric' ? parseInt(this.value) : this.inputMode === 'decimal' ? parseFloat(this.value) : this.value;
		this.onChange(convertedValue);
		this.valueChange.emit(convertedValue);
	}

	itemFocused() {
		this.onTouched();
	}

	controlClicked() {
		if (!this.readOnly) this.itemInputComponent.focusInput();
	}
}