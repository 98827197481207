import { NgComponentOutlet } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlertController, IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar, ModalController } from '@ionic/angular/standalone';
import { LetDirective } from '@ngrx/component';
import { select, Store } from '@ngrx/store';
import { map, Subscription, take } from 'rxjs';
import { ButtonComponent } from 'src/app/components/button/button.component';
import { SvgIconComponent } from 'src/app/components/svg-icon/svg-icon.component';
import { SheetsRendererService } from 'src/app/services/sheets-renderer.service';
import { AppState } from 'src/app/store/reducers';
import { saveSheetSlotActionRequested } from 'src/app/store/sheet/sheet.actions';
import { selectedSlotPayload } from 'src/app/store/sheet/sheet.selectors';
import { Action } from 'src/app/types/aggregate-graph.types';

@Component({
	templateUrl: './sheet-action-editor.modal.html',
	styleUrls: ['./common.scss', './sheet-action-editor.modal.scss'],
	standalone: true,
	imports: [
		IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, LetDirective, NgComponentOutlet, ButtonComponent, SvgIconComponent
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SheetActionEditorModal implements OnInit, AfterViewInit, OnDestroy {

	private isAddition!: boolean;
	private subscription!: Subscription;

	selectedSlotPayload$ = this.store.pipe(select(selectedSlotPayload)).pipe(map(payload => structuredClone(payload)));
	formGroup!: FormGroup;

	constructor(
		private modalController: ModalController, private formBuilder: FormBuilder, private sheetsRendererService: SheetsRendererService,
		private store: Store<AppState>, private alertController: AlertController) {
	}

	ngOnInit() {
		this.selectedSlotPayload$.pipe(take(1)).subscribe(payload => {
			this.formGroup = this.formBuilder.group({});
			this.formGroup.addControl('id', this.formBuilder.control(payload.selectedSlot?.action?.id ?? -1)); // at time of writing this, the b/e id can be zero
			this.formGroup.addControl('performedDate', this.formBuilder.control(payload.selectedSlot?.action?.performedDate ?? payload.defaultPerformedDate ? payload.defaultPerformedDate : null));
			this.formGroup.addControl('isComplete', this.formBuilder.control(payload.selectedSlot?.action?.isComplete ?? false));
			this.formGroup.addControl('isSkipped', this.formBuilder.control(payload.selectedSlot?.action?.isSkipped ?? false));
			this.formGroup.addControl('skippedReason', this.formBuilder.control(payload.selectedSlot?.action?.skippedReason ?? null));
			this.formGroup.addControl('actionTypeName', this.formBuilder.control(payload.selectedSlot?.action?.actionTypeName ?? payload.selectedItem!.actionTypeName));
		});
	}

	ngAfterViewInit() {
		// need to do this after child components init because they need to add to the form group before this component starts processing value changes (control creation causes emissions)
		this.selectedSlotPayload$.pipe(take(1)).subscribe(payload => {
			this.isAddition = !payload.selectedSlot?.action;

			this.subscription = this.formGroup.valueChanges.subscribe(value => {
				this.store.dispatch(saveSheetSlotActionRequested({ rowId: payload.selectedItem!.id, action: value, isAddition: this.isAddition }))
				if (this.isAddition) this.isAddition = false;
			});
		});
	}

	getSlotEditorControlsComponent(actionTypeName: string) {
		return this.sheetsRendererService.getSlotEditorControlsType(actionTypeName);
	}

	toggleComplete() {
		if (this.formGroup.controls['isSkipped'].value) return;
		this.formGroup.controls['isComplete'].setValue(!this.formGroup.controls['isComplete'].value);
	}

	async toggleSkipped() {
		if (this.formGroup.controls['isComplete'].value) return;
		if (!this.formGroup.controls['isSkipped'].value) {
			const alert = await this.alertController.create({
				header: 'Skip this item?',
				subHeader: 'Enter a reason why this item is skipped',
				inputs: [{ name: 'reason', type: 'text', placeholder: 'text...' }],
				buttons: [
					'Back',
					{
						text: 'Skip',
						handler: (payload) => {
							this.formGroup.patchValue({ isSkipped: !this.formGroup.controls['isSkipped'].value, skippedReason: payload.reason } as Action);
						}
					}
				],
				backdropDismiss: false
			});
			alert.present();
		}
		else {
			this.formGroup.controls['isSkipped'].setValue(!this.formGroup.controls['isSkipped'].value);
		}
	}

	close() {
		this.modalController.dismiss();
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
