import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { Subscription, filter } from 'rxjs';
import { ItemComponent } from '../item/item.component';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { AccordionService } from './accordion.service';
import { ExpanderGroupService } from './expander-group.service';
import { expanderAnimateTiming } from './expander.animations';

@Component({
	selector: 'app-expander',
	templateUrl: './expander.component.html',
	styleUrl: './expander.component.scss',
	standalone: true,
	imports: [ItemComponent, SvgIconComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('rotateIcon', [
			state('collapsed', style({ transform: 'rotate(0)' })),
			state('expanded', style({ transform: 'rotate(90deg)' })),
			transition('collapsed <=> expanded', [animate(expanderAnimateTiming)])
		]),
		trigger('expandCollapseContent', [
			state('collapsed', style({ height: 0, opacity: 0, visibility: 'hidden' })),
			state('expanded', style({ height: '*', opacity: 1, visibility: 'visible' })),
			transition('collapsed <=> expanded', [animate(expanderAnimateTiming)])
		])
	]
})
export class ExpanderComponent implements OnInit, OnDestroy {

	@Input() isExpanded: boolean = false;
	@Input() accordionIndex: number | undefined;
	private subscription: Subscription | undefined;

	constructor(private changeDetectorRef: ChangeDetectorRef, @Optional() private expanderGroupService: ExpanderGroupService, @Optional() private accordionService: AccordionService) { }

	ngOnInit(): void {
		// either expander group service or accordion directive can be utilised, not both (if neither is applied, expanders operate individually, i.e. disconnected).
		// accordion directive makes the expanders behave like an accordion (only one opens at a time).
		// expander group service adds the ability to expand/collapse all expanders in the group.
		if (this.expanderGroupService !== null) {
			this.subscription = this.expanderGroupService.expanderGroupStateChangedSubject$.subscribe(expand => {
				this.isExpanded = expand;
				this.changeDetectorRef.markForCheck();
			});
		}
		else if (this.accordionIndex !== undefined && this.accordionService !== null) {
			this.subscription = this.accordionService.expanderExpanded$.pipe(
				filter((index: number) => index !== this.accordionIndex)
			).subscribe(() => {
				this.isExpanded = false;
				this.changeDetectorRef.markForCheck();
			});
		}
	}

	ngOnDestroy(): void {
		if (this.subscription) this.subscription.unsubscribe();
	}

	toggle() {
		this.isExpanded = !this.isExpanded;
		if (this.accordionIndex !== undefined && this.accordionService !== undefined) this.accordionService.expanderExpanded(this.accordionIndex);
	}
}