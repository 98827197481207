import { createAction, props } from '@ngrx/store';
import { TokenInfo } from 'src/app/services/auth.service';
import { RecentUser } from './auth.reducer';

export enum AuthActionTypes {
	LoginRequired = '[Auth Guard] Login Required',
	LoginInitiated = '[Login Guard] Login Initiated',
	RecentUsersLoaded = '[Auth Effect] Recent Users Loaded',
	NewUserSelected = '[Login Page] New User Selected',
	RecentUserSelected = '[Login Page] Recent User Selected',
	UsernameEntered = '[Login Page] Username Entered',
	UsernameCancelled = '[Login Page] Username Cancelled',
	PinRequired = '[Auth Effect] Pin Required',
	PasswordRequired = '[Auth Effect] Password Required',
	PasswordEntered = '[Login Page] Password Entered',
	PasswordCancelled = '[Login Page] Password Cancelled',
	VerificationCodeRequired = '[Auth Service] Verification Code Required',
	AuthenticatePasswordSucceeded = '[Auth Service] Authenticate Password Succeeded',
	AuthenticatePasswordFailed = '[Auth Service] Authenticate Password Failed',
	AuthenticatePasswordCancelled = '[Login Page] Authenticate Password Cancelled',
	ResendCodeRequested = '[Login Page] Resend Code Requested',
	ResendCodeSucceeded = '[Auth Service] Resend Code Succeeded',
	ResendCodeFailed = '[Auth Service] Resend Code Failed',
	VerificationCodeEntered = '[Login Page] Verification Code Entered',
	VerifyCodeSucceeded = '[Auth Service] Verify Code Succeeded',
	VerifyCodeFailed = '[Auth Service] Verify Code Failed',
	PinEntered = '[Login Page] Pin Entered',
	PinCancelled = '[Login Page] Pin Cancelled',
	AuthenticatePinSucceeded = '[Auth Service] Authenticate Pin Succeeded',
	AuthenticatePinFailed = '[Auth Service] Authenticate Pin Failed',
	LoginSucceeded = '[Auth Effect] Login Succeeded',
	TokenRefreshSucceeded = '[Auth Interceptor] Token Refresh Succeeded',
	TokenRefreshFailed = '[Auth Interceptor] Token Refresh Failed',
	LogoutInitiated = '[App] Logout Initiated',
	ClearStateRequested = '[Auth Effect] Clear State Requested',
	RecentUsersUpdated = '[Login Page] Recent Users Updated'
}

export const loginRequired = createAction(
	AuthActionTypes.LoginRequired,
	props<{ requestedUrl: string }>()
)

export const loginInitiated = createAction(
	AuthActionTypes.LoginInitiated
)

export const recentUsersLoaded = createAction(
	AuthActionTypes.RecentUsersLoaded,
	props<{ recentUsers: RecentUser[] }>()
)

export const newUserSelected = createAction(
	AuthActionTypes.NewUserSelected
);

export const recentUserSelected = createAction(
	AuthActionTypes.RecentUserSelected,
	props<{ username: string }>()
);

export const usernameEntered = createAction(
	AuthActionTypes.UsernameEntered,
	props<{ username: string }>()
);

export const usernameCancelled = createAction(
	AuthActionTypes.UsernameCancelled
);

export const pinRequired = createAction(
	AuthActionTypes.PinRequired
);

export const passwordRequired = createAction(
	AuthActionTypes.PasswordRequired
);

export const passwordEntered = createAction(
	AuthActionTypes.PasswordEntered,
	props<{ password: string }>()
);

export const passwordCancelled = createAction(
	AuthActionTypes.PasswordCancelled
);

export const verificationCodeRequired = createAction(
	AuthActionTypes.VerificationCodeRequired,
	props<{ sessionId: string }>()
)

export const authenticatePasswordSucceeded = createAction(
	AuthActionTypes.AuthenticatePasswordSucceeded,
	props<{ tokenInfo: TokenInfo }>()
)

export const authenticatePasswordFailed = createAction(
	AuthActionTypes.AuthenticatePasswordFailed
)

export const authenticatePasswordCancelled = createAction(
	AuthActionTypes.AuthenticatePasswordCancelled
)

export const resendCodeRequested = createAction(
	AuthActionTypes.ResendCodeRequested
)

export const resendCodeSucceeded = createAction(
	AuthActionTypes.ResendCodeSucceeded
)

export const verificationCodeEntered = createAction(
	AuthActionTypes.VerificationCodeEntered,
	props<{ verificationCode: string }>()
)

export const verifyCodeSucceeded = createAction(
	AuthActionTypes.VerifyCodeSucceeded,
	props<{ tokenInfo: TokenInfo }>()
)

export const verifyCodeFailed = createAction(
	AuthActionTypes.VerifyCodeFailed
)

export const pinEntered = createAction(
	AuthActionTypes.PinEntered,
	props<{ pin: number }>()
);

export const pinCancelled = createAction(
	AuthActionTypes.PinCancelled
);

export const authenticatePinSucceeded = createAction(
	AuthActionTypes.AuthenticatePinSucceeded,
	props<{ tokenInfo: TokenInfo }>()
)

export const authenticatePinFailed = createAction(
	AuthActionTypes.AuthenticatePinFailed
)

export const tokenRefreshSucceeded = createAction(
	AuthActionTypes.TokenRefreshSucceeded,
	props<{ tokenInfo: TokenInfo }>()
)

export const tokenRefreshFailed = createAction(
	AuthActionTypes.TokenRefreshFailed,
	props<{ statusCode: number }>()
)

export const logoutInitiated = createAction(
	AuthActionTypes.LogoutInitiated
)

export const clearStateRequested = createAction(
	AuthActionTypes.ClearStateRequested
)

export const recentUsersUpdated = createAction(
	AuthActionTypes.RecentUsersUpdated,
	props<{ recentUsers: RecentUser[] }>()
)