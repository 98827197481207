<ion-header>
	<ion-toolbar>
		<ion-title>Edit Action</ion-title>
		<ion-buttons slot="end">
			<ion-button (click)="close()" color="primary">
				<ion-icon slot="icon-only" name="close-sharp"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
<div class="container" *ngrxLet="selectedSlotPayload$ as vm">
	@if (vm.selectedItem && formGroup) {
		<div class="scroll-vertical">
			<ng-container *ngComponentOutlet="getSlotEditorControlsComponent(vm.selectedItem!.actionTypeName); inputs: { slot: vm.selectedSlot, form: formGroup, item: vm.selectedItem }"></ng-container>
		</div>
		<div class="footer-box app-padding">
			@if (formGroup.controls['isComplete'].value) {
				<app-button fill="solid" (click)="toggleComplete()">Complete<app-svg-icon name="checkmark-sharp"></app-svg-icon></app-button>
			}
			@else {
				<app-button (click)="toggleComplete()">Complete</app-button>
			}
			@if (formGroup.controls['isSkipped'].value) {
				<app-button fill="solid" (click)="toggleSkipped()">Skip<app-svg-icon name="checkmark-sharp"></app-svg-icon></app-button>
			}
			@else {
				<app-button (click)="toggleSkipped()">Skip</app-button>
			}
		</div>
	}
</div>