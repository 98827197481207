import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentManifest, TaskManifest, ToDo } from '../types/aggregate-graph.types';

export interface PublishedTaskDefinition {
	id: string;
	displayLabel: string;
}

export interface CreateTaskResponse {
	taskId: string;
	taskManifest: TaskManifest;
	requestDocumentInstanceId: string;
	requestDocumentManifest: DocumentManifest;
}

@Injectable({
	providedIn: 'root'
})
export class TasksService {

	constructor(private httpClient: HttpClient) {
	}

	getLatestPublishedTaskDefinitionsForVisit(id: string) {
		return this.httpClient.get<PublishedTaskDefinition[]>(`/api/tasks/types-for-visit/${id}`);
	}

	createTask(id: string, taskDefinitionId: string) {
		return this.httpClient.post<CreateTaskResponse>(`/api/tasks`, { visitId: id, taskDefinitionId: taskDefinitionId });
	}

	updateSummary(id: string, summary: string) {
		return this.httpClient.put<string>(`/api/tasks/${id}/summary`, JSON.stringify(summary), { headers: { 'Content-Type': 'application/json' } });
	}

	updateUrgency(taskId: string, urgent: boolean, urgentReason: string) {
		return this.httpClient.put<void>(`/api/tasks/${taskId}/urgency`, { urgent, urgentReason });
	}

	shareTask(taskId: string, toRecipientIdentifiers: number[], fromRecipientId: number | null): Observable<void> {
		return this.httpClient.post<void>(`/api/tasks/${taskId}/shared-teams`, {
			fromTaskRecipientId: fromRecipientId,
			toTaskRecipients: toRecipientIdentifiers
		});
	}

	recallTask(taskId: string, reason: string): Observable<void> {
		return this.httpClient.post<void>(`/api/tasks/${taskId}/recall`, { reason: reason });
	}

	completeTask(taskId: string, recipientId: number): Observable<void> {
		return this.httpClient.post<void>(`/api/tasks/${taskId}/complete/${recipientId}`, null);
	}

	rejectTask(taskId: string, recipientId: number, reason: string): Observable<void> {
		return this.httpClient.post<void>(`/api/tasks/${taskId}/reject/${recipientId}`, { reason: reason });
	}

	removeTask(taskId: string, recipientId: number): Observable<void> {
		return this.httpClient.post<void>(`/api/tasks/${taskId}/remove/${recipientId}`, null);
	}

	copyTask(taskId: string) {
		return this.httpClient.post<CreateTaskResponse>(`/api/tasks/${taskId}/copy`, { taskId: taskId});
	}
	
	scheduleTask(taskId: string, recipientId: number, date: string): Observable<void> {
		return this.httpClient.put<void>(`/api/tasks/${taskId}/scheduled-date`, { date: date, taskRecipientId: recipientId });
	}

	updateTodo(taskId: string, taskRecipientId: number, todo: ToDo): Observable<void> {
		return this.httpClient.put<void>(`/api/tasks/${taskId}/todo`, { taskRecipientId: taskRecipientId, todo: todo });
	}
}