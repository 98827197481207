import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { HeadingComponent } from 'src/app/components/heading/heading.component';
import { UnitQuantity, UnitQuantityControl } from 'src/app/form-controls/unit-quantity/unit-quantity.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { IvtfSupplementationAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';
import { ActionFormComponent } from '../../action-form/action-form.component';

interface IvtfSupplementationActionFormGroup {
	dose: FormControl<UnitQuantity | null>;
}

@Component({
	selector: 'app-ivtf-supplementation-action-controls',
	templateUrl: './ivtf-supplementation-action-controls.component.html',
	styleUrls: ['../../../common.scss', './ivtf-supplementation-action-controls.component.scss'],
	standalone: true,
	imports: [HeadingComponent, ReactiveFormsModule, UnitQuantityControl, CommonActionControlsComponent, ActionFormComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IvtfSupplementationActionControlsComponent implements SheetActionControlsBase, OnInit {

	slot!: Slot | null;
	action!: IvtfSupplementationAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as IvtfSupplementationAction;
	}

	@Input() form!: FormGroup<IvtfSupplementationActionFormGroup>;
	@Input() item!: Row;

	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.form.addControl('dose', this.formBuilder.control(this.action?.dose ?? null));
	}
}