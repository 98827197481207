<app-select class="validatable-group-control" [readOnly]="readOnly" [class.no-events]="bodyPartsArray.value?.length" label="Modality" [placeholder]="modalityPlaceholder"
			[items]="selectItems" [saveValue]="true" [formControl]="modalityControl" (click)="controlClicked()">
</app-select>
@for (formGroup of bodyPartsArray.controls; track formGroup; let index = $index) {
	<app-textarea hideEmptyValue class="validatable-control" debounce [readOnly]="readOnly" [placeholder]="notePlaceholder" [label]="formGroup.value.name"
				  [formControl]="getNotesControl(formGroup)" (click)="controlClicked()" @enterLeave [@.disabled]="readOnly">
		@if (!readOnly) {
			<app-svg-icon class="remove" name="close-sharp" (click)="removeBodyPart(index)"></app-svg-icon>
		}
	</app-textarea>
}
@if (!readOnly && this.formGroup.value.modality) {
	<app-item class="rounded-border-box add" (click)="presentAddBodyPartModal()" @enterLeave>
		<app-svg-icon name="add-circle-outline"></app-svg-icon>
		<app-item-label>Body region</app-item-label>
	</app-item>
}