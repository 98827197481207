<ion-header>
	<ion-toolbar>
		<ion-title>Edit Item</ion-title>
		<ion-buttons slot="end">
			<ion-button (click)="close()" color="primary">
				<ion-icon slot="icon-only" name="close-sharp"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
<div class="container">
	<div class="scroll-vertical">
		@if (config.allowCustomName) {
			<div class="form-section app-margin-horizontal app-padding" [formGroup]="itemPropertiesFormGroup">
				<app-text-input [label]="config.customNameLabel" placeholder="name..." formControlName="name"></app-text-input>
			</div>
		}
		<ng-container *ngComponentOutlet="getItemEditorFormComponent(); inputs: { instructionTypeName: instructionTypeName, item: item, minDate: minDate, maxDate: maxDate, instructionsFormArray: instructionsFormArray, itemConfig: config }"></ng-container>
		<div class="form-section app-margin-horizontal app-padding" [formGroup]="itemPropertiesFormGroup">
			<div class="form-group">
				<app-textarea label="Notes" placeholder="text..." formControlName="notes"></app-textarea>
			</div>
		</div>
	</div>
	<div class="footer-box app-padding">
		@if (item) {
			<app-button fill="solid" colour="critical-1" [disabled]="item.isRemoved" (click)="removeItem()">Remove</app-button>
		}
		<app-button fill="solid" (click)="saveInstructionsPayload()">Save</app-button>
	</div>
</div>
