<app-action-form [item]="item" [slot]="slot" [form]="form">
	<div class="form-section app-margin-horizontal app-padding">
		<div class="form-group" [formGroup]="form">
			<app-checkbox label="Catheter check" formControlName="catheterCheck"></app-checkbox>
			<app-checkbox label="Surgical site check" formControlName="surgicalSiteCheck"></app-checkbox>
			<app-checkbox label="Medication" formControlName="medication"></app-checkbox>
			<app-checkbox label="Schedule an authorisation (clinician) item for the new team" formControlName="scheduleAuthorisation"></app-checkbox>
		</div>
		<app-common-action-controls [action]="action" [form]="form"></app-common-action-controls>
	</div>
</app-action-form>
