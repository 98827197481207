import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SelectControl, SelectItem } from 'src/app/form-controls/select/select.control';
import { UnitQuantity, UnitQuantityControl } from 'src/app/form-controls/unit-quantity/unit-quantity.control';
import { unitQuantityRequired } from 'src/app/form-validators/unit-quantity-required.validator';
import { SheetItemService } from 'src/app/services/sheet-item.service';
import { SheetItemDetailConfig, SheetItemFormBase } from 'src/app/services/sheets-renderer.service';
import { Row } from 'src/app/types/aggregate-graph.types';
import { FluidsInfusionInstruction, MedicationRoute } from 'src/app/types/sheet-instruction.types';
import { InstructionsFormComponent } from '../../instructions-form/instructions-form.component';

interface FuildsInfusionInstructionFormGroup {
	rate: FormControl<UnitQuantity | null>;
	totalRate: FormControl<UnitQuantity | null>;
	medicationRoute: FormControl<MedicationRoute | null>;
}

@Component({
	selector: 'app-fluids-infusion-item-form',
	templateUrl: './fluids-infusion-item-form.component.html',
	styleUrl: './fluids-infusion-item-form.component.scss',
	standalone: true,
	imports: [ReactiveFormsModule, InstructionsFormComponent, UnitQuantityControl, SelectControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuildsInfusionItemFormComponent implements SheetItemFormBase, OnInit {

	@Input() instructionTypeName!: string;
	@Input() item!: Row | null;
	@Input() minDate!: string;
	@Input() maxDate!: string;
	@Input() instructionsFormArray!: FormArray;
  	@Input() itemConfig!: SheetItemDetailConfig;

	medicationRoutes: SelectItem[] = [
		{ text: 'IV', value: MedicationRoute.Iv },
		{ text: 'Intraosseous', value: MedicationRoute.Intraosseous }
	];

	constructor(private sheetItemService: SheetItemService, private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.sheetItemService.getInstructions(this.item, this.instructionTypeName, this.itemConfig.scheduleConfig.defaultScheduleType, this.itemConfig.scheduleConfig.defaultRepeatingScheduleFrequency).forEach(instruction => {
			const group: FormGroup<FuildsInfusionInstructionFormGroup> = this.sheetItemService.buildExistingInstructionGroup(this.instructionTypeName, instruction);
			const FluidsInfusionInstruction = instruction as FluidsInfusionInstruction;
			group.addControl('rate', this.formBuilder.control(FluidsInfusionInstruction.rate ?? null, { validators: unitQuantityRequired() }));
			group.addControl('totalRate', this.formBuilder.control(FluidsInfusionInstruction.totalRate ?? null, { validators: unitQuantityRequired() }));
			group.addControl('medicationRoute', this.formBuilder.control(FluidsInfusionInstruction.medicationRoute ?? null, { validators: Validators.required }));
			this.instructionsFormArray.push(group);
		});
	}

	addInstruction() {
		const group: FormGroup<FuildsInfusionInstructionFormGroup> = this.sheetItemService.getNewInstructionGroup(this.instructionTypeName, this.itemConfig.scheduleConfig.defaultScheduleType, this.itemConfig.scheduleConfig.defaultRepeatingScheduleFrequency);
		group.addControl('rate', this.formBuilder.control(null, { validators: unitQuantityRequired() }));
		group.addControl('totalRate', this.formBuilder.control(null, { validators: unitQuantityRequired() }));
		group.addControl('medicationRoute', this.formBuilder.control(null, { validators: Validators.required }));
		this.instructionsFormArray.push(group);
	}
}