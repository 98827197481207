import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonButton, IonButtons, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonSearchbar, IonTitle, IonToolbar, ModalController } from '@ionic/angular/standalone';
import { LetDirective } from '@ngrx/component';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { InformativeComponent } from 'src/app/components/informative/informative.component';
import { ItemLabelComponent } from 'src/app/components/item-label/item-label.component';
import { ItemComponent } from 'src/app/components/item/item.component';
import { SpinnerComponent } from 'src/app/components/spinner/spinner.component';
import { NavigableDirective } from 'src/app/directives/navigable.directive';
import { SearchedUser } from 'src/app/services/users.service';
import { AppState } from 'src/app/store/reducers';
import { appendResultsRequested, newSearchRequested, resultsCleared } from 'src/app/store/user-search/user-search.actions';
import { morePagesExist, newSearchInProgress, queryTerm, searchResults } from 'src/app/store/user-search/user-search.selectors';
import { VisitParticipationType } from 'src/app/types/aggregate-graph.types';

@Component({
	templateUrl: './user-search.modal.html',
	styleUrl: './user-search.modal.scss',
	standalone: true,
	imports: [
		LetDirective, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonSearchbar, IonInfiniteScroll, IonInfiniteScrollContent,
		ItemComponent, ItemLabelComponent, SpinnerComponent, InformativeComponent, NavigableDirective
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSearchModal implements OnInit {

	@Input() participationTypeFilters: VisitParticipationType[] = [];
	@ViewChild(IonSearchbar, { static: false }) searchbar!: IonSearchbar;
	@ViewChild(IonInfiniteScroll, { static: false }) infiniteScroll!: IonInfiniteScroll;

	queryTerm$: Observable<string>;
	newSearchInProgress$: Observable<boolean>;
	searchResults$: Observable<SearchedUser[]>;
	morePagesExist$: Observable<boolean>;

	constructor(private modalController: ModalController, private store: Store<AppState>) {
		this.queryTerm$ = this.store.pipe(select(queryTerm));
		this.newSearchInProgress$ = this.store.pipe(select(newSearchInProgress));
		this.searchResults$ = this.store.pipe(select(searchResults)).pipe(tap(() => { if (this.infiniteScroll) this.infiniteScroll.complete() }));
		this.morePagesExist$ = this.store.pipe(select(morePagesExist));
	}

	ngOnInit() {
		setTimeout(() => this.searchbar.setFocus(), 500);
	}

	queryTermChanged(event: Event) {
		const queryTerm = (event as CustomEvent).detail.value.trim();
		if (queryTerm) {
			this.store.dispatch(newSearchRequested({ queryTerm: queryTerm, participationTypeFilters: this.participationTypeFilters }));
		}
		else {
			this.store.dispatch(resultsCleared());
		}
	}

	appendResults() {
		this.store.dispatch(appendResultsRequested());
	}

	close() {
		this.store.dispatch(resultsCleared());
		this.modalController.dismiss();
	}

	userSelected(user: SearchedUser) {
		this.modalController.dismiss({ user: user });
	}
}