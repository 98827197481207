import { createReducer, on } from '@ngrx/store';
import { formatISO } from 'date-fns';
import { hubConnectionClosed } from '../aggregate-graph-hub/aggregate-graph-hub.actions';
import { clearStateRequested } from '../auth/auth.actions';
import * as serviceGraphActions from './service-graph.actions';

export interface State {
	serviceId: string;
	subscribedDate: string;
	serviceLoaded: boolean;
}

export const initialState: State = {
	serviceId: '',
	subscribedDate: '',
	serviceLoaded: false
}

export const serviceGraphReducer = createReducer(
	initialState,
	on(serviceGraphActions.subscribeServiceRequested, (state, { serviceId }) => ({
		...state,
		serviceId: serviceId.toLocaleLowerCase(), // sometimes users paste in upper case id
		serviceLoaded: false
	})),
	on(serviceGraphActions.serviceGraphGenerated, (state) => ({
		...state,
		subscribedDate: formatISO(new Date())
	})),
	on(serviceGraphActions.serviceGraphLoaded, (state) => ({
		...state,
		serviceLoaded: true
	})),
	on(serviceGraphActions.reloadServiceRequested, (state) => ({
		...state,
		subscribedDate: '',
		serviceLoaded: false
	})),
	on(hubConnectionClosed, (state) => ({
		...state,
		subscribedDate: ''
	})),
	on(serviceGraphActions.unsubscribeServiceRequested, () => initialState),
	on(clearStateRequested, () => initialState)
);