@for (item of instructionsFormArray.controls; track item; let index = $index; let count = $count) {
	<div *ngrxLet="getInstructionFormGroup(index) as instructionFormGroup" @enterLeave>
		<app-heading class="app-margin-horizontal" background="primary-3" size="small">
			<span>Schedule {{ count > 1 ? index + 1 : ""}}</span>
			@if(count > 1) {
				<app-svg-icon class="remove" name="close-sharp" (click)="removeInstruction(index)"></app-svg-icon>
			}
		</app-heading>
		<div class="form-section app-margin-horizontal app-padding">
			<ng-container *ngTemplateOutlet="preScheduleInstructionTemplate; context: { instructionFormGroup: instructionFormGroup }"></ng-container>
			<app-schedule-controls [instructionFormGroup]="instructionFormGroup" [minDate]="minDate" [maxDate]="maxDate" [config]="scheduleConfig"></app-schedule-controls>
			<ng-container *ngTemplateOutlet="postScheduleInstructionTemplate; context: { instructionFormGroup: instructionFormGroup }"></ng-container>
		</div>
	</div>
}
<app-item appNavigable class="add no-select" (click)="addInstruction()">
	<app-item-value value="Add schedule"></app-item-value>
	<app-svg-icon name="add-circle-outline"></app-svg-icon>
</app-item>