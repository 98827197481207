<app-action-form [item]="item" [slot]="slot" [form]="form">
	<div class="form-section app-margin-horizontal app-padding">
		<div class="form-group" [formGroup]="form">
			<app-datetime label="Date placed" dateFormat="date-time" [displayRelative]="true" [minuteValues]="minuteValues" formControlName="datePlaced"></app-datetime>
			<app-text-input label="Day" unitLabel="" placeholder="Day" inputMode="numeric" formControlName="day"></app-text-input> 
			<app-select label="Catheter site" placeholder="Catheter site" [items]="catheterSiteItems" [saveValue]="true" formControlName="catheterSite"></app-select>
		</div>
		<app-common-action-controls [action]="action" [form]="form"></app-common-action-controls>
	</div>
</app-action-form>

