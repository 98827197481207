import { ErrorHandler, Injectable } from '@angular/core';
import { AlertService } from './services/alert.service';
import { ClientLoggingService } from './services/client-logging.service';
import { ErrorService } from './services/error.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

	constructor(private alertService: AlertService, private errorService: ErrorService, private clientLoggingService: ClientLoggingService) {
	}

	// note: this error handler does not catch HttpClient errors, http interceptors should be used for HttpClient errors
	handleError(error: any) {
		this.alertService.displayToastAlert('Unexpected error occurred');
		console.error(this.errorService.getErrorMessage(error));

		try {
			this.clientLoggingService.logGlobalError(this.errorService.getErrorMessage(error)).subscribe();

		} catch (err) {
			console.error(this.errorService.getErrorMessage(err));
		}
	}
}
