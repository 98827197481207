import { Pipe, PipeTransform } from '@angular/core';
import { LubricatedEye } from '../types/sheet-instruction.types';

@Pipe({
	name: 'lubricatedEyeName',
	standalone: true
})
export class LubricatedEyeNamePipe implements PipeTransform {

	constructor() {
	}

	transform(eye: LubricatedEye | null): string {
		switch(eye) {
			case LubricatedEye.Both: return 'Both eyes';
			case LubricatedEye.Left: return 'Left eye';
			case LubricatedEye.Right: return 'Right eye';
			default: return '';
		}
	}
}