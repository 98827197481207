import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SelectControl, SelectItem } from 'src/app/form-controls/select/select.control';
import { Unit } from 'src/app/form-controls/shared/popovers/unit-popover/unit.popover';
import { UnitQuantity, UnitQuantityControl } from 'src/app/form-controls/unit-quantity/unit-quantity.control';
import { UnitValue, UnitValueControl } from 'src/app/form-controls/unit-value/unit-value.control';
import { SheetItemService } from 'src/app/services/sheet-item.service';
import { SheetItemDetailConfig, SheetItemFormBase } from 'src/app/services/sheets-renderer.service';
import { Row } from 'src/app/types/aggregate-graph.types';
import { MedicationInfusionDoseUnit, MedicationInfusionTotalDoseUnit } from 'src/app/types/sheet-action-aggregate-graph.types';
import { MedicationInfusionDilutionUnits, MedicationInfusionRedLabelInstructionV2, MedicationInfusionRoute } from 'src/app/types/sheet-instruction.types';
import { InstructionsFormComponent } from '../../instructions-form/instructions-form.component';

interface MedicationInfusionRedLabelInstructionFormGroup {
	dose: FormControl<UnitValue | null>;
	totalDose: FormControl<UnitValue | null>;
	rate: FormControl<UnitValue | null>;
	dilution: FormControl<UnitQuantity | null>;
	medicationRoute: FormControl<MedicationInfusionRoute | null>;
}

@Component({
	selector: 'app-medication-infusion-red-label-item-form',
	templateUrl: './medication-infusion-red-label-item-form-v2.component.html',
	styleUrl: './medication-infusion-red-label-item-form-v2.component.scss',
	standalone: true,
	imports: [ReactiveFormsModule, InstructionsFormComponent, UnitQuantityControl, SelectControl, UnitValueControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MedicationInfusionRedLabelItemFormComponentV2 implements SheetItemFormBase, OnInit {

	@Input() instructionTypeName!: string;
	@Input() item!: Row | null;
	@Input() minDate!: string;
	@Input() maxDate!: string;
	@Input() instructionsFormArray!: FormArray;
  	@Input() itemConfig!: SheetItemDetailConfig;

	medicationRoutes: SelectItem[] = [
		{ text: 'IV', value: MedicationInfusionRoute.Iv },
	];

	doseUnits: Unit[] = [
		{ label: MedicationInfusionDoseUnit.MgKgHr },
		{ label: MedicationInfusionDoseUnit.UgKgHr }
	];

	totalDoseUnits: Unit[] = [
		{ label: MedicationInfusionTotalDoseUnit.MgHr },
		{ label: MedicationInfusionTotalDoseUnit.UgHr }
	];

	dilutionUnits: Unit[] = [
		{ label: MedicationInfusionDilutionUnits.mgMl },
		{ label: MedicationInfusionDilutionUnits.mEqMl },
		{ label: MedicationInfusionDilutionUnits.μgMl }	
	];

	constructor(private sheetItemService: SheetItemService, private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.sheetItemService.getInstructions(this.item, this.instructionTypeName, this.itemConfig.scheduleConfig.defaultScheduleType, this.itemConfig.scheduleConfig.defaultRepeatingScheduleFrequency).forEach(instruction => {
			const group: FormGroup<MedicationInfusionRedLabelInstructionFormGroup> = this.sheetItemService.buildExistingInstructionGroup(this.instructionTypeName, instruction);
			const medicationInfusionRedLabelInstruction = instruction as MedicationInfusionRedLabelInstructionV2;
			group.addControl('dose', this.formBuilder.control(medicationInfusionRedLabelInstruction.dose ?? null));
			group.addControl('totalDose', this.formBuilder.control(medicationInfusionRedLabelInstruction.totalDose ?? null));
			group.addControl('rate', this.formBuilder.control(medicationInfusionRedLabelInstruction.rate ?? null));
			group.addControl('dilution', this.formBuilder.control(medicationInfusionRedLabelInstruction.dilution ?? null));
			group.addControl('medicationRoute', this.formBuilder.control(MedicationInfusionRoute.Iv));
			this.instructionsFormArray.push(group);
		});
	}

	addInstruction() {
		const group: FormGroup<MedicationInfusionRedLabelInstructionFormGroup> = this.sheetItemService.getNewInstructionGroup(this.instructionTypeName, this.itemConfig.scheduleConfig.defaultScheduleType, this.itemConfig.scheduleConfig.defaultRepeatingScheduleFrequency);
		group.addControl('dose', this.formBuilder.control(null));
		group.addControl('totalDose', this.formBuilder.control(null));
		group.addControl('rate', this.formBuilder.control(null));
		group.addControl('dilution', this.formBuilder.control(null));
		group.addControl('medicationRoute', this.formBuilder.control(MedicationInfusionRoute.Iv));
		this.instructionsFormArray.push(group);
	}
}