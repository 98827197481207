import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DateTimeControl } from 'src/app/form-controls/datetime/datetime.control';
import { SelectControl, SelectItem } from 'src/app/form-controls/select/select.control';
import { TextInputControl } from 'src/app/form-controls/text-input/text-input.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { CatheterSite, CheckIvCatheterAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { fiveMinuteIntervalMinuteValues } from 'src/app/types/sheet.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface CheckIvCatheterActionFormGroup {
	datePlaced: FormControl<string | null>;
	day: FormControl<number | null>;
	catheterSite: FormControl<CatheterSite | null>;
}

@Component({
	selector: 'app-check-iv-catheter-action-controls',
	templateUrl: './check-iv-catheter-action-controls.component.html',
	styleUrls: ['../../../common.scss', './check-iv-catheter-action-controls.component.scss'],
	standalone: true,
	imports: [ReactiveFormsModule, CommonActionControlsComponent, ActionFormComponent, SelectControl, DateTimeControl, TextInputControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckIvCatheterActionControlsComponent implements SheetActionControlsBase, OnInit {

	slot!: Slot | null;
	action!: CheckIvCatheterAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as CheckIvCatheterAction;
	}

	@Input() form!: FormGroup<CheckIvCatheterActionFormGroup>;
	@Input() item!: Row | null;

	catheterSiteItems: SelectItem[] = [
		{ text: 'Left Cephalic', value: CatheterSite.LeftCephalic },
		{ text: 'Right Cephalic', value: CatheterSite.RightCephalic },
		{ text: 'Left Saphenous', value: CatheterSite.LeftSaphenous },
		{ text: 'Right Saphenous', value: CatheterSite.RightSaphenous },
		{ text: 'Left Jugular', value: CatheterSite.LeftJugular },
		{ text: 'Right Jugular', value: CatheterSite.RightJugular }
	];

	constructor(private formBuilder: FormBuilder) {
	}

	minuteValues = fiveMinuteIntervalMinuteValues;

	ngOnInit() {
		this.form.addControl('datePlaced', this.formBuilder.control(this.action?.datePlaced ?? null));
		this.form.addControl('day', this.formBuilder.control(this.action?.day ?? null));
		this.form.addControl('catheterSite', this.formBuilder.control(this.action?.catheterSite ?? null));
	}
}
