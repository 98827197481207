import { createAction, props } from '@ngrx/store';
import { SearchedSheetItem } from 'src/app/services/sheets.service';

export enum SheetItemSearchActionTypes {
	NewSearchRequested = '[Sheet Item Search Modal] New Search Requested',
	NewSearchSucceeded = '[Sheet Item Search Effect] New Search Succeeded',
	NewSearchFailed = '[Sheet Item Search Effect] New Search Failed',
	AppendResultsRequested = '[Sheet Item Search Modal] Append Results Requested',
	AppendResultsSucceeded = '[Sheet Item Search Effect] Append Results Succeeded',
	AppendResultsFailed = '[Sheet Item Search Effect] Append Results Failed',
	ResultsCleared = '[Sheet Item Search Modal] Results Cleared',
	SearchedSheetItemSelected = '[Sheet Item Search Modal] Searched Sheet Item Selected'
}

export const newSearchRequested = createAction(
	SheetItemSearchActionTypes.NewSearchRequested,
	props<{ queryTerm: string }>()
)

export const newSearchSucceeded = createAction(
	SheetItemSearchActionTypes.NewSearchSucceeded,
	props<{ searchResults: SearchedSheetItem[] }>()
)

export const newSearchFailed = createAction(
	SheetItemSearchActionTypes.NewSearchFailed,
	props<{ statusCode: number }>()
)

export const appendResultsRequested = createAction(
	SheetItemSearchActionTypes.AppendResultsRequested
)

export const appendResultsSucceeded = createAction(
	SheetItemSearchActionTypes.AppendResultsSucceeded,
	props<{ searchResults: SearchedSheetItem[] }>()
)

export const appendResultsFailed = createAction(
	SheetItemSearchActionTypes.AppendResultsFailed,
	props<{ statusCode: number }>()
)

export const resultsCleared = createAction(
	SheetItemSearchActionTypes.ResultsCleared
)

export const searchedSheetItemSelected = createAction(
	SheetItemSearchActionTypes.SearchedSheetItemSelected,
	props<{ instructionTypeName: string }>()
)