export const visitLabel = 'visit';
export const sheetLabel = 'sheet';
export const tasksLabel = 'tasks';
export const commsLabel = 'comms';

export const segmentLabels = {
	visit: visitLabel,
	sheet: sheetLabel,
	tasks: tasksLabel,
	comms: commsLabel
}

export enum SelectedPatientSegmentPanel {
	None,
	Visit,
	Sheet,
	Tasks,
	Comms
}

export enum CommsPanelChildType {
	None,
	ReferringVet,
	OwnerInformation
}