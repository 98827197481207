import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular/standalone';
import { createEffect } from '@ngrx/effects';
import { filter, from, map, merge, pairwise, startWith } from 'rxjs';
import * as platformActions from './platform.actions';

export const MOBILE_BREAKPOINT = 750;
export const MENU_BAR_BREAKPOINT = 950;
export const DESKTOP_BREAKPOINT = 1500;

@Injectable()
export class PlatformEffects {

	constructor(private platform: Platform) {
	}

	brekpointHandler$ = createEffect(() =>
		merge(
			from(this.platform.ready()),
			this.platform.resize.pipe(
				map(() => this.platform.width()),
				startWith(this.platform.width()),
				pairwise(),
				filter(([oldWidth, newWidth]) =>
					this.boundaryCrossed(oldWidth, newWidth, DESKTOP_BREAKPOINT)
					||
					this.boundaryCrossed(oldWidth, newWidth, MENU_BAR_BREAKPOINT)
					||
					this.boundaryCrossed(oldWidth, newWidth, MOBILE_BREAKPOINT)
				)
			)
		).pipe(
			map(() => platformActions.breakpointChanged({ breakpoint: this.platform.width() }))
		)
	);

	private boundaryCrossed(oldWidth: number, newWidth: number, boundary: number): boolean {
		return (oldWidth < boundary && newWidth >= boundary) || (oldWidth >= boundary && newWidth < boundary);
	}
}