import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SelectControl, SelectItem } from 'src/app/form-controls/select/select.control';
import { UnitQuantity, UnitQuantityControl } from 'src/app/form-controls/unit-quantity/unit-quantity.control';
import { unitQuantityRequired } from 'src/app/form-validators/unit-quantity-required.validator';
import { SheetItemService } from 'src/app/services/sheet-item.service';
import { SheetItemDetailConfig, SheetItemFormBase } from 'src/app/services/sheets-renderer.service';
import { Row } from 'src/app/types/aggregate-graph.types';
import { OxygenInstruction, OxygenMethod } from 'src/app/types/sheet-instruction.types';
import { InstructionsFormComponent } from '../../instructions-form/instructions-form.component';

interface OxygenInstructionFormGroup {
	percentageToGive: FormControl<UnitQuantity | null>;
	oxygenMethod: FormControl<OxygenMethod | null>;
}

@Component({
	selector: 'app-oxygen-item-form',
	templateUrl: './oxygen-item-form.component.html',
	styleUrl: './oxygen-item-form.component.scss',
	standalone: true,
	imports: [ReactiveFormsModule, InstructionsFormComponent, UnitQuantityControl, SelectControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OxygenItemFormComponent implements SheetItemFormBase, OnInit {

	@Input() instructionTypeName!: string;
	@Input() item!: Row | null;
	@Input() minDate!: string;
	@Input() maxDate!: string;
	@Input() instructionsFormArray!: FormArray;
  	@Input() itemConfig!: SheetItemDetailConfig;

	  oxygenMethods: SelectItem[] = [
		{ text: 'CPAP', value: OxygenMethod.Cpap },
		{ text: 'High flow', value: OxygenMethod.HighFlow },
		{ text: 'Nasal prongs', value: OxygenMethod.NasalProngs },
		{ text: 'Oxygen kennel', value: OxygenMethod.OxygenKennel },
	];



	constructor(private sheetItemService: SheetItemService, private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.sheetItemService.getInstructions(this.item, this.instructionTypeName, this.itemConfig.scheduleConfig.defaultScheduleType, this.itemConfig.scheduleConfig.defaultRepeatingScheduleFrequency).forEach(instruction => {
			const group: FormGroup<OxygenInstructionFormGroup> = this.sheetItemService.buildExistingInstructionGroup(this.instructionTypeName, instruction);
			const oxygenInstruction = instruction as OxygenInstruction;
			group.addControl('percentageToGive', this.formBuilder.control(oxygenInstruction.percentageToGive ?? null, { validators: unitQuantityRequired() }));
			group.addControl('oxygenMethod', this.formBuilder.control(oxygenInstruction.oxygenMethod ?? null, Validators.required));
			this.instructionsFormArray.push(group);
		});
	}

	addInstruction() {
		const group: FormGroup<OxygenInstructionFormGroup> = this.sheetItemService.getNewInstructionGroup(this.instructionTypeName, this.itemConfig.scheduleConfig.defaultScheduleType, this.itemConfig.scheduleConfig.defaultRepeatingScheduleFrequency);
		group.addControl('percentageToGive', this.formBuilder.control(null, { validators: unitQuantityRequired() }));
		group.addControl('oxygenMethod', this.formBuilder.control(null, Validators.required));
		this.instructionsFormArray.push(group);
	}
}