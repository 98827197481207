import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { HeadingComponent } from 'src/app/components/heading/heading.component';
import { CheckboxControl } from 'src/app/form-controls/checkbox/checkbox.control';
import { TextInputControl } from 'src/app/form-controls/text-input/text-input.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { PainScoreDogAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';

interface PainScoreDogActionFormGroup {
	inKennelQuiet: FormControl<boolean | null>;
	inKennelCrying: FormControl<boolean | null>;
	inKennelGroaning: FormControl<boolean | null>;
	inKennelScreaming: FormControl<boolean | null>;
	inKennelIgnoringWound: FormControl<boolean | null>;
	inKennelLookingAtWound: FormControl<boolean | null>;
	inKennelLickingWound: FormControl<boolean | null>;
	inKennelRubbingWound: FormControl<boolean | null>;
	inKennelChewingWound: FormControl<boolean | null>;
	onLeadNormal: FormControl<boolean | null>;
	onLeadLame: FormControl<boolean | null>;
	onLeadSlow: FormControl<boolean | null>;
	onLeadStiff: FormControl<boolean | null>;
	onLeadRefusesToMove: FormControl<boolean | null>;
	onPressureDoNothing: FormControl<boolean | null>;
	onPressureLookAround: FormControl<boolean | null>;
	onPressureFlinch: FormControl<boolean | null>;
	onPressureGrowl: FormControl<boolean | null>;
	onPressureSnap: FormControl<boolean | null>;
	onPressureCry: FormControl<boolean | null>;
	overallHappy: FormControl<boolean | null>;
	overallQuiet: FormControl<boolean | null>;
	overallIndifferent: FormControl<boolean | null>;
	overallNervous: FormControl<boolean | null>;
	overallDepressed: FormControl<boolean | null>;
	overallComfortable: FormControl<boolean | null>;
	overallUnsettled: FormControl<boolean | null>;
	overallRestless: FormControl<boolean | null>;
	overallHunched: FormControl<boolean | null>;
	overallRigid: FormControl<boolean | null>;
	totalScore: FormControl<number | null>;

}

@Component({
	selector: 'app-pain-score-dog-action-controls',
	templateUrl: './pain-score-dog-action-controls.component.html',
	styleUrls: ['../../../common.scss', './pain-score-dog-action-controls.component.scss'],
	standalone: true,
	imports: [HeadingComponent, CommonActionControlsComponent, ActionFormComponent, ReactiveFormsModule, CheckboxControl, TextInputControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PainScoreDogActionControlsComponent implements SheetActionControlsBase {

	slot!: Slot | null;
	action!: PainScoreDogAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as PainScoreDogAction;
	}

	@Input() form!: FormGroup<PainScoreDogActionFormGroup>;
	@Input() item!: Row | null;

	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.form.addControl('inKennelQuiet', this.formBuilder.control(this.action?.inKennelQuiet ?? null));
		this.form.addControl('inKennelCrying', this.formBuilder.control(this.action?.inKennelCrying ?? null));
		this.form.addControl('inKennelGroaning', this.formBuilder.control(this.action?.inKennelGroaning ?? null));
		this.form.addControl('inKennelScreaming', this.formBuilder.control(this.action?.inKennelScreaming ?? null));
		this.form.addControl('inKennelIgnoringWound', this.formBuilder.control(this.action?.inKennelIgnoringWound ?? null));
		this.form.addControl('inKennelLookingAtWound', this.formBuilder.control(this.action?.inKennelLookingAtWound ?? null));
		this.form.addControl('inKennelLickingWound', this.formBuilder.control(this.action?.inKennelLickingWound ?? null));
		this.form.addControl('inKennelRubbingWound', this.formBuilder.control(this.action?.inKennelRubbingWound ?? null));
		this.form.addControl('inKennelChewingWound', this.formBuilder.control(this.action?.inKennelChewingWound ?? null));
		this.form.addControl('onLeadNormal', this.formBuilder.control(this.action?.onLeadNormal ?? null));
		this.form.addControl('onLeadLame', this.formBuilder.control(this.action?.onLeadLame ?? null));
		this.form.addControl('onLeadSlow', this.formBuilder.control(this.action?.onLeadSlow ?? null));
		this.form.addControl('onLeadStiff', this.formBuilder.control(this.action?.onLeadStiff ?? null));
		this.form.addControl('onLeadRefusesToMove', this.formBuilder.control(this.action?.onLeadRefusesToMove ?? null));
		this.form.addControl('onPressureDoNothing', this.formBuilder.control(this.action?.onPressureDoNothing ?? null));
		this.form.addControl('onPressureLookAround', this.formBuilder.control(this.action?.onPressureLookAround ?? null));
		this.form.addControl('onPressureFlinch', this.formBuilder.control(this.action?.onPressureFlinch ?? null));
		this.form.addControl('onPressureGrowl', this.formBuilder.control(this.action?.onPressureGrowl ?? null));
		this.form.addControl('onPressureSnap', this.formBuilder.control(this.action?.onPressureSnap ?? null));
		this.form.addControl('onPressureCry', this.formBuilder.control(this.action?.onPressureCry ?? null));
		this.form.addControl('overallHappy', this.formBuilder.control(this.action?.overallHappy ?? null));
		this.form.addControl('overallQuiet', this.formBuilder.control(this.action?.overallQuiet ?? null));
		this.form.addControl('overallIndifferent', this.formBuilder.control(this.action?.overallIndifferent ?? null));
		this.form.addControl('overallNervous', this.formBuilder.control(this.action?.overallNervous ?? null));
		this.form.addControl('overallDepressed', this.formBuilder.control(this.action?.overallDepressed ?? null));
		this.form.addControl('overallComfortable', this.formBuilder.control(this.action?.overallComfortable ?? null));
		this.form.addControl('overallUnsettled', this.formBuilder.control(this.action?.overallUnsettled ?? null));
		this.form.addControl('overallRestless', this.formBuilder.control(this.action?.overallRestless ?? null));
		this.form.addControl('overallHunched', this.formBuilder.control(this.action?.overallHunched ?? null));
		this.form.addControl('overallRigid', this.formBuilder.control(this.action?.overallRigid ?? null));
		this.form.addControl('totalScore', this.formBuilder.control(this.action?.totalScore ?? null));
	}
}