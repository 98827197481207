import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// this validator checks for strict equality against another control. If both controls have the same value, it will pass validation

export function valuesMustMatch(firstChildControlName: string, secondChildControlName: string, useStrictEquality: boolean = true): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {

		const firstChildControl = control.get(firstChildControlName);
		const secondChildControl = control.get(secondChildControlName);

		if (!firstChildControl || !secondChildControl) throw new Error('The doValuesMatch validator requires two valid controls to compare');

		if (useStrictEquality) {
			if (firstChildControl.value !== secondChildControl.value) return { isStrictMatch: false, firstControlValue: firstChildControl.value, secondControlValue: secondChildControl.value };
		}
		else {
			if (!!firstChildControl.value != !!secondChildControl.value) return { isMatch: false, firstControlValue: firstChildControl.value, secondControlValue: secondChildControl.value };
		}

		return null;
	}
};