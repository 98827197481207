import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface ImagingModalityBodyPart {
	id: number;
	modalityId: number;
	modality: string;
	name: string;
}

@Injectable({
	providedIn: 'root'
})
export class BodyPartsService {

	constructor(private httpClient: HttpClient) {
	}

	queryBodyParts(queryTerm: string, modality: string, skip: number, take: number) {
		return this.httpClient.get<ImagingModalityBodyPart[]>(`/api/body-parts?queryTerm=${queryTerm}&modality=${modality}&skip=${skip}&take=${take}`);
	}
}