import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SelectControl, SelectItem } from 'src/app/form-controls/select/select.control';
import { TextInputControl } from 'src/app/form-controls/text-input/text-input.control';
import { TextAreaControl } from 'src/app/form-controls/textarea/textarea.control';
import { UnitQuantity, UnitQuantityControl } from 'src/app/form-controls/unit-quantity/unit-quantity.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { Bilirubin, Erythrocytes, Glucose, Haemoglobin, Ketones, Leukoctes, MethodOfCollection, Nitrate, PH, Protein, UrinalysisAction, Urobilinogen } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface UrinalysisActionFormGroup {
	volume: FormControl<UnitQuantity | null>;
	methodOfCollection: FormControl<MethodOfCollection | null>;
	leukoctes: FormControl<Leukoctes | null>;
	nitrate: FormControl<Nitrate | null>;
	pH: FormControl<PH | null>;
	protein: FormControl<Protein | null>;
	glucose: FormControl<Glucose | null>;
	ketones: FormControl<Ketones | null>;
	urobilinogen: FormControl<Urobilinogen | null>;
	bilirubin: FormControl<Bilirubin | null>;
	erythrocytes: FormControl<Erythrocytes | null>;
	haemoglobin: FormControl<Haemoglobin | null>;
	urineSpecificGravity: FormControl<number | null>;
	sedimentExam: FormControl<string | null>;
}

@Component({
	selector: 'app-urinalysis-action-controls',
	templateUrl: './urinalysis-action-controls.component.html',
	styleUrls: ['../../../common.scss', './urinalysis-action-controls.component.scss'],
	standalone: true,
	imports: [ReactiveFormsModule, UnitQuantityControl, SelectControl, TextInputControl, TextAreaControl, CommonActionControlsComponent, ActionFormComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UrinalysisActionControlsComponent implements SheetActionControlsBase, OnInit {

	slot!: Slot | null;
	action!: UrinalysisAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as UrinalysisAction;
	}

	@Input() form!: FormGroup<UrinalysisActionFormGroup>;
	@Input() item!: Row | null;
	
	methodOfCollectionItems: SelectItem[] = [
		{ text: 'Cysto', value: MethodOfCollection.Cysto },
		{ text: 'Free catch', value: MethodOfCollection.FreeCatch },
	];
	leukoctesItems: SelectItem[] = [
		{ text: 'Neg', value: Leukoctes.Negative },
		{ text: '25', value: Leukoctes.TwentyFive },
		{ text: '75', value: Leukoctes.SeventyFive },
		{ text: '100', value: Leukoctes.OneHundred }
	];
	nitrateItems: SelectItem[] = [
		{ text: 'Neg', value: Nitrate.Negative },
		{ text: 'Pos', value: Nitrate.Positive }
	];
	phItems: SelectItem[] = [
		{ text: '5', value: PH.Five },
		{ text: '6', value: PH.Six },
		{ text: '7', value: PH.Seven },
		{ text: '8', value: PH.Eight },
		{ text: '9', value: PH.Nine }
	];
	proteinItems: SelectItem[] = [
		{ text: 'Neg', value: Protein.Negative },
		{ text: '30', value: Protein.Thirty },
		{ text: '100', value: Protein.OneHundred },
		{ text: '500', value: Protein.FiveHundred }
	];
	glucoseItems: SelectItem[] = [
		{ text: 'Norm', value: Glucose.Normal },
		{ text: '50', value: Glucose.Fifty },
		{ text: '100', value: Glucose.OneHundred },
		{ text: '300', value: Glucose.ThreeHundred },
		{ text: '1000', value: Glucose.OneThousand }
	];
	ketonesItems: SelectItem[] = [
		{ text: 'Neg', value: Ketones.Negative },
		{ text: '1+', value: Ketones.OnePlus },
		{ text: '2+', value: Ketones.TwoPlus },
		{ text: '3+', value: Ketones.ThreePlus }
	];
	urobilinogenItems: SelectItem[] = [
		{ text: 'Norn', value: Urobilinogen.Normal },
		{ text: '1+', value: Urobilinogen.OnePlus },
		{ text: '2+', value: Urobilinogen.TwoPlus },
		{ text: '3+', value: Urobilinogen.ThreePlus },
		{ text: '4+', value: Urobilinogen.FourPlus }
	];
	bilirubinItems: SelectItem[] = [
		{ text: 'Neg', value: Bilirubin.Negative },
		{ text: '1+', value: Bilirubin.OnePlus },
		{ text: '2+', value: Bilirubin.TwoPlus },
		{ text: '3+', value: Bilirubin.ThreePlus }
	];
	erythrocytesItems: SelectItem[] = [
		{ text: 'Neg', value: Erythrocytes.Negative },
		{ text: '1+', value: Erythrocytes.OnePlus },
		{ text: '2+', value: Erythrocytes.TwoPlus },
		{ text: '3+', value: Erythrocytes.ThreePlus },
		{ text: '4+', value: Erythrocytes.FourPlus }
	];
	haemoglobinItems: SelectItem[] = [
		{ text: 'Pos', value: Haemoglobin.Positive },
		{ text: '1+', value: Haemoglobin.OnePlus },
		{ text: '2+', value: Haemoglobin.TwoPlus },
		{ text: '3+', value: Haemoglobin.ThreePlus },
		{ text: '4+', value: Haemoglobin.FourPlus }
	];

	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.form.addControl('volume', this.formBuilder.control(this.action?.volume ?? null));
		this.form.addControl('methodOfCollection', this.formBuilder.control(this.action?.methodOfCollection ?? null));
		this.form.addControl('leukoctes', this.formBuilder.control(this.action?.leukoctes ?? null));
		this.form.addControl('nitrate', this.formBuilder.control(this.action?.nitrate ?? null));
		this.form.addControl('pH', this.formBuilder.control(this.action?.pH ?? null));
		this.form.addControl('protein', this.formBuilder.control(this.action?.protein ?? null));
		this.form.addControl('glucose', this.formBuilder.control(this.action?.glucose ?? null));
		this.form.addControl('ketones', this.formBuilder.control(this.action?.ketones ?? null));
		this.form.addControl('urobilinogen', this.formBuilder.control(this.action?.urobilinogen ?? null));
		this.form.addControl('bilirubin', this.formBuilder.control(this.action?.bilirubin ?? null));
		this.form.addControl('erythrocytes', this.formBuilder.control(this.action?.erythrocytes ?? null));
		this.form.addControl('haemoglobin', this.formBuilder.control(this.action?.haemoglobin ?? null));
		this.form.addControl('urineSpecificGravity', this.formBuilder.control(this.action?.urineSpecificGravity ?? null));
		this.form.addControl('sedimentExam', this.formBuilder.control(this.action?.sedimentExam ?? null));
	}
}
