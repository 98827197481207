import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SheetItemComponentBase } from 'src/app/services/sheets-renderer.service';
import { Row } from 'src/app/types/aggregate-graph.types';
import { ItemContentComponent } from '../../item-content/item-content.component';

import { InstructionBoxComponent } from '../../instruction-box/instruction-box.component';
import { SkincareInstructions, SkincareLevel } from 'src/app/types/sheet-instruction.types';

@Component({
	selector: 'app-skincare-item',
	templateUrl: './skincare-item.component.html',
	styleUrl: './skincare-item.component.scss',
	standalone: true,
	imports: [ItemContentComponent, InstructionBoxComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkincareItemComponent implements SheetItemComponentBase {

	row!: Row;
	instructions!: SkincareInstructions;
	skincareLevel!: string[];
    @Input() startLabel!: string;
    @Input() endLabel!: string;
	@Input('row') set updateRow(row: Row) {
		this.row = row;
		this.instructions = row.instructions as SkincareInstructions;
		this.skincareLevel = Object.values(this.instructions).map(instruction => this.getskincareLevel(instruction.skincareLevel));
	}
	
	constructor() {
	}

	private getskincareLevel(skincareLevel: SkincareLevel | null) {
		switch (skincareLevel) {
			case SkincareLevel.Preventative: return 'IAD skin care preventative';
			case SkincareLevel.One: return 'IAD skin care category 1';
			case SkincareLevel.Two:	return 'IAD skin care category 2';
			case SkincareLevel.Three: return 'IAD skin care category 3';
			default: return '';
		}
	}
}