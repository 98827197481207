<div class="form-group" [formGroup]="scheduleFormGroup" *ngrxLet="scheduleFormGroup.controls['scheduleTypeName'].value as scheduleTypeName">
    @if (config.showFrequencyControl) {
		<app-select label="Frequency" [saveValue]="true" [items]="frequencyItems" formControlName="scheduleTypeName" (selectionChange)="frequencyChanged($event)"></app-select>
	}
	@if (scheduleTypeName === ScheduleType.OneOff) {
		<div class="animated" @enterReplace>
			<app-datetime label="At" dateFormat="date-time" [displayRelative]="true" [minuteValues]="minuteValues" [minDate]="minDate" [maxDate]="maxDate" formControlName="start"></app-datetime>
			@if (config.showTimeToCompleteControl) {
				<app-unit-quantity class="validatable-control" [label]="config.timeToCompleteControlLabel" [units]="timeToCompleteUnits" inputMode="numeric" [formControl]="timeToCompleteControl"></app-unit-quantity>
			}
		</div>
	}
	@if (scheduleTypeName === ScheduleType.Repeating) {
		<div class="animated" @enterReplace>
			<app-unit-quantity class="validatable-control" [label]="config.repeatEveryControlLabel" [units]="repeatEveryUnits" inputMode="numeric" [formControl]="repeatEveryControl"></app-unit-quantity>
			<app-datetime label="Start At" dateFormat="date-time" [displayRelative]="true" [minuteValues]="minuteValues" [minDate]="minDate" [maxDate]="maxDate" formControlName="start"></app-datetime>
			<app-datetime [class.app-invalid]="scheduleFormGroup.hasError('isBefore')" label="End At" placeholder="choose..." dateFormat="date-time" [showClearButton]="true" [displayRelative]="true" [minuteValues]="minuteValues" [minDate]="minDate" [maxDate]="maxDate" formControlName="last"></app-datetime>
			@if (config.showTimeToCompleteControl) {
				<app-unit-quantity class="validatable-control" [label]="config.timeToCompleteControlLabel" [units]="timeToCompleteUnits" inputMode="numeric" [formControl]="timeToCompleteControl"></app-unit-quantity>
			}
		</div>
	}
</div>
