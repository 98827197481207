import { createReducer, on } from '@ngrx/store';
import * as platformActions from './platform.actions';

export interface State {
	breakpoint: number;
}

export const initialState: State = {
	breakpoint: 0
}

export const platformReducer = createReducer(
	initialState,
	on(platformActions.breakpointChanged, (state, { breakpoint }) => ({
		...state,
		breakpoint: breakpoint
	}))
);