import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SheetItemComponentBase } from 'src/app/services/sheets-renderer.service';
import { Row } from 'src/app/types/aggregate-graph.types';
import { IvtfSupplementationInstructions } from 'src/app/types/sheet-instruction.types';
import { InstructionBoxComponent } from '../../instruction-box/instruction-box.component';
import { ItemContentComponent } from '../../item-content/item-content.component';

@Component({
	selector: 'app-ivtf-supplementation-item',
	templateUrl: './ivtf-supplementation-item.component.html',
	styleUrl: './ivtf-supplementation-item.component.scss',
	standalone: true,
	imports: [ItemContentComponent, InstructionBoxComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IvtfSupplementationItemComponent implements SheetItemComponentBase {

	row!: Row;
	instructions!: IvtfSupplementationInstructions;
	@Input() startLabel!: string;
	@Input() endLabel!: string;
	@Input('row') set updateRow(row: Row) {
		this.row = row;
		this.instructions = row.instructions as IvtfSupplementationInstructions;
	}

	constructor() {
	}
}