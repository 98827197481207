import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TextInputControl } from 'src/app/form-controls/text-input/text-input.control';
import { SheetItemService } from 'src/app/services/sheet-item.service';
import { SheetItemDetailConfig, SheetItemFormBase } from 'src/app/services/sheets-renderer.service';
import { Row } from 'src/app/types/aggregate-graph.types';
import { TransferInstructionV2 } from 'src/app/types/sheet-instruction.types';
import { InstructionsFormComponent } from '../../instructions-form/instructions-form.component';

interface TransferInstructionFormGroup {
	sendingService: FormControl<string | null>;
	receivingService: FormControl<string | null>;
}

@Component({
	selector: 'app-transfer-item-form',
	templateUrl: './transfer-item-form-v2.component.html',
	styleUrl: './transfer-item-form-v2.component.scss',
	standalone: true,
	imports: [ReactiveFormsModule, InstructionsFormComponent, TextInputControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransferItemFormComponentV2 implements SheetItemFormBase, OnInit {

	@Input() instructionTypeName!: string;
	@Input() item!: Row | null;
	@Input() minDate!: string;
	@Input() maxDate!: string;
	@Input() instructionsFormArray!: FormArray;
	@Input() itemConfig!: SheetItemDetailConfig;


	constructor(private sheetItemService: SheetItemService, private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.sheetItemService.getInstructions(this.item, this.instructionTypeName, this.itemConfig.scheduleConfig.defaultScheduleType, this.itemConfig.scheduleConfig.defaultRepeatingScheduleFrequency).forEach(instruction => {
			const group: FormGroup<TransferInstructionFormGroup> = this.sheetItemService.buildExistingInstructionGroup(this.instructionTypeName, instruction);
			const transferInstruction = instruction as TransferInstructionV2;
			group.addControl('sendingService', this.formBuilder.control(transferInstruction.sendingService ?? null));
			group.addControl('receivingService', this.formBuilder.control(transferInstruction.receivingService ?? null));
			this.instructionsFormArray.push(group);
		});
	}

	addInstruction() {
		const group: FormGroup<TransferInstructionFormGroup> = this.sheetItemService.getNewInstructionGroup(this.instructionTypeName, this.itemConfig.scheduleConfig.defaultScheduleType, this.itemConfig.scheduleConfig.defaultRepeatingScheduleFrequency);
		group.addControl('sendingService', this.formBuilder.control(null));
		group.addControl('receivingService', this.formBuilder.control(null));
		this.instructionsFormArray.push(group);
	}
}