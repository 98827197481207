import { createSelector } from '@ngrx/store';
import { Client, ClientReferenceType, Patient, Visit } from 'src/app/types/aggregate-graph.types';
import { AppState } from '../reducers';

export const selectPatientId = (state: AppState) => state.patientGraph.patientId;
export const selectTaskId = (state: AppState) => state.patientGraph.taskId;
export const selectServiceId = (state: AppState) => state.patientGraph.serviceId;
export const selectPatientLoaded = (state: AppState) => state.patientGraph.patientLoaded;
export const selectPatient = (state: AppState) => state.patientGraph.patient;
export const selectClients = (state: AppState) => state.patientGraph.clients;
export const selectVisits = (state: AppState) => state.patientGraph.visits;

export const patientId = createSelector(
	selectPatientId,
	(patientId) => patientId
);

export const serviceId = createSelector(
	selectServiceId,
	(serviceId) => serviceId
);

export const patient = createSelector(
	selectPatient,
	(patient) => patient
);

export interface EntityIdentifiersPayload {
	patientId: string;
	taskId: string;
	serviceId: string;
}

export const entityIdentifiers = createSelector(
	patientId,
	selectTaskId,
	serviceId,
	(patientId, taskId, serviceId): EntityIdentifiersPayload => ({ patientId, taskId, serviceId })
);

export const patientLoaded = createSelector(
	selectPatientLoaded,
	(patientLoaded) => patientLoaded
);

export const ownersBySurnameAsc = createSelector(
	selectClients,
	(clients) => clients.filter(c => c.referenceType === ClientReferenceType.Owner).sort((a: Client, b: Client) => a.contactSurname.localeCompare(b.contactSurname))
);

export interface PatientPayload {
	patientLoaded: boolean;
	patient: Patient | null;
	ownerSurname: string;
}

export const patientPayload = createSelector(
	patientLoaded,
	patient,
	ownersBySurnameAsc,
	(patientLoaded, patient, owners): PatientPayload => ({ patientLoaded, patient, ownerSurname: owners.length ? owners[0].contactSurname : '' })
);

export const visitsByVisitNumberDesc = createSelector(
	selectVisits,
	(visits) => [...visits]
		.sort((a: Visit, b: Visit) => b.admissionDate.localeCompare(a.admissionDate))
		.map((visit: Visit, index: number, visits: Visit[]) => {
			const clonedVisit: Visit = { ...visit, visitNumber: visits.length - index };
			return clonedVisit;
		})
);

export interface CurrentVisitPayload {
	patientLoaded: boolean;
	patient: Patient | null;
	visit: Visit | null;
}

export const currentVisitPayload = createSelector(
	patientLoaded,
	patient,
	visitsByVisitNumberDesc,
	(patientLoaded, patient, visits): CurrentVisitPayload => ({
		patientLoaded,
		patient,
		visit: visits.length ? visits[0] : null
	})
);

export interface ReferringVetByVisitNumber {
	visitNumber: number;
	admissionDate: string;
	referringVet: Client | undefined;
}

export const referringVetsByVisitNumberDesc = createSelector(
	selectClients,
	visitsByVisitNumberDesc,
	(clients, visits): ReferringVetByVisitNumber[] => visits.map(v => ({ visitNumber: v.visitNumber, admissionDate: v.admissionDate, referringVet: clients.find(c => c.referenceId === v.id) }))
);