import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers';

export const selectQueryTerm = (state: AppState) => state.sheetItemSearch.queryTerm;
export const selectResultsPerAppend = (state: AppState) => state.sheetItemSearch.resultsPerAppend;
export const selectAppendsRetrieved = (state: AppState) => state.sheetItemSearch.appendsRetrieved;
export const selectNewSearchInProgress = (state: AppState) => state.sheetItemSearch.newSearchInProgress;
export const selectSearchResults = (state: AppState) => state.sheetItemSearch.searchResults;
export const selectLastResultsRetrievedCount = (state: AppState) => state.sheetItemSearch.lastResultsRetrievedCount;

export const queryTerm = createSelector(selectQueryTerm, (queryTerm: string) => queryTerm);

export const searchParams = createSelector(
	queryTerm,
	selectResultsPerAppend,
	selectAppendsRetrieved,
	(queryTerm, resultsPerAppend, appendsRetrieved) => ({ queryTerm, resultsPerAppend, appendsRetrieved })
);

export const newSearchInProgress = createSelector(selectNewSearchInProgress, (newSearchInProgress) => newSearchInProgress);

export const searchResults = createSelector(selectSearchResults, (searchResults) => searchResults);

export const morePagesExist = createSelector(selectLastResultsRetrievedCount, selectResultsPerAppend, (lastResultsRetrievedCount, resultsPerAppend) =>
	lastResultsRetrievedCount === resultsPerAppend
);