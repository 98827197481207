import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AlertController, IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar, ModalController } from '@ionic/angular/standalone';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ButtonComponent } from 'src/app/components/button/button.component';
import { AccordionDirective } from 'src/app/components/expander/accordion.directive';
import { expanderEnterLeave } from 'src/app/components/expander/expander.animations';
import { ExpanderComponent } from 'src/app/components/expander/expander.component';
import { InformativeComponent } from 'src/app/components/informative/informative.component';
import { ItemCheckboxComponent } from 'src/app/components/item-checkbox/item-checkbox.component';
import { ItemLabelComponent } from 'src/app/components/item-label/item-label.component';
import { ItemComponent } from 'src/app/components/item/item.component';
import { SpinnerComponent } from 'src/app/components/spinner/spinner.component';
import { SvgIconComponent } from 'src/app/components/svg-icon/svg-icon.component';
import { NavigableDirective } from 'src/app/directives/navigable.directive';
import { UserSearchModal } from 'src/app/modals/user-search/user-search.modal';
import { TeamMember } from 'src/app/services/teams.service';
import { SearchedUser } from 'src/app/services/users.service';
import { AppState } from 'src/app/store/reducers';
import { selectedTeam } from 'src/app/store/service-graph/service-graph.selectors';
import { addTeamMemberRequested, removeTeamMemberRequested, serviceSettingsUnloaded, setCanEditMemberListStateRequested } from 'src/app/store/service-settings/service-settings.actions';
import { serviceSettingsLoaded, teamMembers } from 'src/app/store/service-settings/service-settings.selectors';
import { Team } from 'src/app/types/aggregate-graph.types';
import { filterUndefinedOrNull } from 'src/app/types/rxjs-custom-operator.types';

@Component({
	templateUrl: './service-membership.modal.html',
	styleUrl: './service-membership.modal.scss',
	standalone: true,
	imports: [
		LetDirective, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, ItemComponent, ItemLabelComponent, ItemCheckboxComponent, SvgIconComponent, ButtonComponent,
		SpinnerComponent, ExpanderComponent, AccordionDirective, InformativeComponent, NavigableDirective
	],
	animations: [expanderEnterLeave],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceMembershipModal {

	selectedTeam$: Observable<Team>;
	serviceSettingsLoaded$: Observable<boolean>;
	teamMembers$: Observable<TeamMember[]>;

	private selectedTeam!: Team;
	private teamMembers!: TeamMember[];

	constructor(private store: Store<AppState>, private modalController: ModalController, private alertController: AlertController) {
		this.selectedTeam$ = this.store.select(selectedTeam).pipe(filterUndefinedOrNull(), tap(selectedTeam => this.selectedTeam = selectedTeam));
		this.serviceSettingsLoaded$ = this.store.select(serviceSettingsLoaded);
		this.teamMembers$ = this.store.select(teamMembers).pipe(tap(teamMembers => this.teamMembers = teamMembers));
	}

	//#region Adding & Removing Team Members

	async presentUserSearchModal() {
		const modal = await this.modalController.create({
			component: UserSearchModal,
			cssClass: 'stacked-modal',
			backdropDismiss: false
		});
		await modal.present();
		const { data } = await modal.onWillDismiss();
		if (!data) return;
		const user: SearchedUser = data.user;
		if (this.teamMembers.some(teamMember => teamMember.userId === user.id)) {
			const alert = await this.alertController.create({
				subHeader: 'User is already a team member',
				buttons: ['Ok'],
				backdropDismiss: false
			});
			await alert.present();
		}
		else {
			this.store.dispatch(addTeamMemberRequested({ serviceId: this.selectedTeam.id, userId: user.id }));
		}
	}

	removeTeamMember(userId: number) {
		this.store.dispatch(removeTeamMemberRequested({ serviceId: this.selectedTeam.id, userId: userId }));
	}

	//#endregion

	//#region Toggling "Can Edit Member List"

	toggleCanEditMemberList(teamMember: TeamMember) {
		if (!this.selectedTeam.userIsTeamAdministrator) return;
		this.store.dispatch(setCanEditMemberListStateRequested({ serviceId: this.selectedTeam.id, userId: teamMember.userId, canEditMemberList: !teamMember.canEditMemberList }));
	}

	//#endregion

	async close() {
		await this.modalController.dismiss();
		this.store.dispatch(serviceSettingsUnloaded());
	}
}
