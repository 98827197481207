<app-action-form [item]="item" [slot]="slot" [form]="form">
	<div class="form-section app-margin-horizontal app-padding">
		<div class="form-group" [formGroup]="form">
			<app-datetime label="Date and time of death" dateFormat="date-time" [displayRelative]="true" [minuteValues]="minuteValues" formControlName="timeOfDeath"></app-datetime>
			<app-text-input label="Euthanased by" formControlName="euthanasedBy"></app-text-input> 
			<app-select label="Priced " placeholder="Priced?" [items]="priced" [saveValue]="true" formControlName="priced"></app-select>
		</div>
		<app-common-action-controls [action]="action" [form]="form"></app-common-action-controls>
	</div>
</app-action-form>

