import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { HeadingComponent } from 'src/app/components/heading/heading.component';
import { CheckboxControl } from 'src/app/form-controls/checkbox/checkbox.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { ToGoHomeAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';

interface ToGoHomeActionFormGroup {
	medicationsToGoHome: FormControl<boolean | null>;
	dischargeTimeOnRx: FormControl<boolean | null>;
	writeCheckPrintCris: FormControl<boolean | null>;
	dischargeDateOnDischargeReport: FormControl<boolean | null>;
	completePricing: FormControl<boolean | null>;
	filePricingWithAccounts: FormControl<boolean | null>;
	medicationsReady: FormControl<boolean | null>;
	medicationsCollected: FormControl<boolean | null>;
	returnUnwantedMedications: FormControl<boolean | null>;
	addTimeToRemoveIv: FormControl<boolean | null>;
	removeIvIfNecessary: FormControl<boolean | null>;
	collectPatientItems: FormControl<boolean | null>;
	checkWoundsDressingBandages: FormControl<boolean | null>;
	batheOrGroom: FormControl<boolean | null>;
	checkBusterCollar: FormControl<boolean | null>;
}

@Component({
	selector: 'app-to-go-home-action-controls',
	templateUrl: './to-go-home-action-controls.component.html',
	styleUrls: ['../../../common.scss', './to-go-home-action-controls.component.scss'],
	standalone: true,
	imports: [HeadingComponent, CommonActionControlsComponent, ActionFormComponent, ReactiveFormsModule, CheckboxControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToGoHomeActionControlsComponent implements SheetActionControlsBase {

	slot!: Slot | null;
	action!: ToGoHomeAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as ToGoHomeAction;
	}

	@Input() form!: FormGroup<ToGoHomeActionFormGroup>;
	@Input() item!: Row | null;
	
	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.form.addControl('medicationsToGoHome', this.formBuilder.control(this.action?.medicationsToGoHome ?? null));
		this.form.addControl('dischargeTimeOnRx', this.formBuilder.control(this.action?.dischargeTimeOnRx ?? null));
		this.form.addControl('writeCheckPrintCris', this.formBuilder.control(this.action?.writeCheckPrintCris ?? null));
		this.form.addControl('dischargeDateOnDischargeReport', this.formBuilder.control(this.action?.dischargeDateOnDischargeReport ?? null));
		this.form.addControl('completePricing', this.formBuilder.control(this.action?.completePricing ?? null));
		this.form.addControl('filePricingWithAccounts', this.formBuilder.control(this.action?.filePricingWithAccounts ?? null));
		this.form.addControl('medicationsReady', this.formBuilder.control(this.action?.medicationsReady ?? null));
		this.form.addControl('medicationsCollected', this.formBuilder.control(this.action?.medicationsCollected ?? null));
		this.form.addControl('returnUnwantedMedications', this.formBuilder.control(this.action?.returnUnwantedMedications ?? null));
		this.form.addControl('addTimeToRemoveIv', this.formBuilder.control(this.action?.addTimeToRemoveIv ?? null));
		this.form.addControl('removeIvIfNecessary', this.formBuilder.control(this.action?.removeIvIfNecessary ?? null));
		this.form.addControl('collectPatientItems', this.formBuilder.control(this.action?.collectPatientItems ?? null));
		this.form.addControl('checkWoundsDressingBandages', this.formBuilder.control(this.action?.checkWoundsDressingBandages ?? null));
		this.form.addControl('batheOrGroom', this.formBuilder.control(this.action?.batheOrGroom ?? null));
		this.form.addControl('checkBusterCollar', this.formBuilder.control(this.action?.checkBusterCollar ?? null));
	}
}