<ion-header>
	<ion-toolbar>
		<ion-title>Service Membership</ion-title>
		<ion-buttons slot="end">
			<ion-button (click)="close()" color="primary">
				<ion-icon slot="icon-only" name="close-sharp"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
<ng-container *ngrxLet="{ selectedTeam: selectedTeam$, serviceSettingsLoaded: serviceSettingsLoaded$, teamMembers: teamMembers$ } as vm; suspenseTpl: loading">
	@if(vm.serviceSettingsLoaded) {
		<div class="scroll-vertical app-padding">
			<p class="first-paragraph">The following users are members of this service. They are authorised to process service tasks.</p>
			<div appAccordion class="member-container">
				@for(teamMember of vm.teamMembers; track teamMember.userId; let index = $index){
					<app-expander [accordionIndex]="index" @expanderEnterLeave>
						<app-item-label>{{teamMember.fullName}}</app-item-label>
						@if(teamMember.isTeamAdministrator){
							<app-informative>Cannot edit service administrators</app-informative>
						}
						@else{
							<div class="expander-content">
								<app-item [class.hoverable-element]="vm.selectedTeam.userIsTeamAdministrator" (click)="toggleCanEditMemberList(teamMember)">
									<app-item-label [class.disabled-label]="!vm.selectedTeam.userIsTeamAdministrator">Manage users</app-item-label>
									<app-item-checkbox [checked]="teamMember.canEditMemberList" [disabled]="!vm.selectedTeam.userIsTeamAdministrator"></app-item-checkbox>
								</app-item>
								<div class="button-container">
									<app-button fill="clear" (click)="removeTeamMember(teamMember.userId)">Remove member</app-button>
								</div>
							</div>
						}
					</app-expander>
				}
			</div>
			<app-item appNavigable (click)="presentUserSearchModal()">
				<app-item-label>Add member</app-item-label>
				<app-svg-icon name="add-circle-outline"></app-svg-icon>
			</app-item>
		</div>
	}
	@else{
		<app-spinner class="margin-top"></app-spinner>
	}
</ng-container>
<ng-template #loading>
	<app-spinner class="margin-top"></app-spinner>
</ng-template>