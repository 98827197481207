import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers';

export const selectSegmentLabel = (state: AppState) => state.patient.segmentLabel;
export const selectPatientSelected = (state: AppState) => state.patient.patientSelected;
export const selectSelectedPatientSegmentPanel = (state: AppState) => state.patient.selectedPatientSegmentPanel;
export const selectSelectedCommsPanelChildType = (state: AppState) => state.patient.selectedCommsPanelChildType;

export const segmentLabel = createSelector(
	selectSegmentLabel,
	(label) => label
);

export const patientSelected = createSelector(
	selectPatientSelected,
	(patientSelected) => patientSelected
);

export interface PatientStatusPayload {
	segmentLabel: string;
	patientSelected: boolean;
}

export const patientStatus = createSelector(
	segmentLabel,
	patientSelected,
	(segmentLabel, patientSelected): PatientStatusPayload => ({ segmentLabel, patientSelected })
);

export const selectedPatientSegmentPanel = createSelector(
	selectSelectedPatientSegmentPanel,
	(selectedPatientSegmentPanel) => selectedPatientSegmentPanel
);

export const selectedCommsPanelChildType = createSelector(
	selectSelectedCommsPanelChildType,
	(childType) => childType
);

export const selectedCommsPanelPayload = createSelector(
	selectedCommsPanelChildType,
	segmentLabel,
	(selectedCommsPanelChildType, segmentLabel) => ({ selectedCommsPanelChildType, segmentLabel })
);