import { createReducer, on } from '@ngrx/store';
import { formatISO } from 'date-fns';
import { ServiceTask } from 'src/app/types/aggregate-graph.types';
import { ServiceTaskTab } from 'src/app/types/service-task.types';
import { clearStateRequested } from '../auth/auth.actions';
import * as serviceGraphActions from '../service-graph/service-graph.actions';
import * as serviceTaskActions from './service-task.actions';

export interface State {
	inboxServiceTasks: ServiceTask[];
	sortInboxAscending: boolean;
	inboxDateFilter: string | null;
	scheduledServiceTasks: ServiceTask[];
	sortScheduledAscending: boolean;
	scheduledDateFilter: string;
	selectedServiceTaskTab: ServiceTaskTab;
}

export const initialState: State = {
	inboxServiceTasks: [],
	sortInboxAscending: true,
	inboxDateFilter: null,
	scheduledServiceTasks: [],
	sortScheduledAscending: true,
	scheduledDateFilter: '',
	selectedServiceTaskTab: ServiceTaskTab.Inbox
}

export const serviceTaskReducer = createReducer(
	initialState,
	on(serviceTaskActions.serviceTasksLoaded, (state, { inboxServiceTasks, scheduledServiceTasks }) => ({
		...state,
		inboxServiceTasks: inboxServiceTasks,
		scheduledServiceTasks: scheduledServiceTasks,
		inboxDateFilter: null,
		scheduledDateFilter: formatISO(new Date(), { representation: 'date' })
	})),
	on(serviceTaskActions.inboxServiceTaskAdded, (state, { serviceTask }) => ({
		...state,
		inboxServiceTasks: [...state.inboxServiceTasks, serviceTask]
	})),
	on(serviceTaskActions.inboxServiceTaskUpdated, (state, { partialServiceTask }) => {
		const serviceTask = state.inboxServiceTasks.find(st => st.id === partialServiceTask.id);
		if (!serviceTask) return { ...state };
		return {
			...state,
			inboxServiceTasks: [...state.inboxServiceTasks.filter(st => st.id !== partialServiceTask.id), { ...serviceTask, ...partialServiceTask }]
		}
	}),
	on(serviceTaskActions.inboxServiceTaskRemoved, (state, { id }) => ({
		...state,
		inboxServiceTasks: state.inboxServiceTasks.filter(st => st.id !== id)
	})),
	on(serviceTaskActions.inboxSortToggled, (state) => ({
		...state,
		sortInboxAscending: !state.sortInboxAscending
	})),
	on(serviceTaskActions.inboxDateFilterChanged, (state, { dateFilter }) => ({
		...state,
		inboxDateFilter: dateFilter
	})),
	on(serviceTaskActions.scheduledDateFilterChanged, (state, { dateFilter }) => ({
		...state,
		scheduledDateFilter: dateFilter
	})),
	on(serviceTaskActions.scheduledServiceTaskAdded, (state, { serviceTask }) => ({
		...state,
		scheduledServiceTasks: [...state.scheduledServiceTasks, serviceTask]
	})),
	on(serviceTaskActions.scheduledServiceTaskUpdated, (state, { partialServiceTask }) => {
		const serviceTask = state.scheduledServiceTasks.find(st => st.id === partialServiceTask.id);
		if (!serviceTask) return { ...state };
		return {
			...state,
			scheduledServiceTasks: [...state.scheduledServiceTasks.filter(st => st.id !== partialServiceTask.id), { ...serviceTask, ...partialServiceTask }]
		}
	}),
	on(serviceTaskActions.scheduledServiceTaskRemoved, (state, { id }) => ({
		...state,
		scheduledServiceTasks: state.scheduledServiceTasks.filter(st => st.id !== id)
	})),
	on(serviceTaskActions.scheduledSortToggled, (state) => ({
		...state,
		sortScheduledAscending: !state.sortScheduledAscending
	})),
	on(serviceTaskActions.serviceTaskTabChanged, (state, { selectedTab }) => ({
		...state,
		selectedServiceTaskTab: selectedTab
	})),
	on(serviceGraphActions.unsubscribeServiceRequested, () => initialState),
	on(clearStateRequested, () => initialState)
);
