import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IonRippleEffect } from '@ionic/angular/standalone';
import { CoercionService } from 'src/app/services/coercion.service';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrl: './button.component.scss',
	standalone: true,
	imports: [IonRippleEffect],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {

	@Input() fill: 'outline' | 'solid' | 'clear' = 'outline';
	@Input() colour: 'primary-1' | 'critical-1' = 'primary-1';

	disabled: boolean = false;
	
	@Input('disabled') set updateDisabled(value: any) {
		this.disabled = this.coercionService.coerceToBoolean(value);
	}

	get buttonClasses() { return this.disabled ? 'disabled outline' : `navigable-element ion-activatable ${this.fill} ${this.colour}` }

	constructor(private coercionService: CoercionService) {
	}
}