import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SelectControl, SelectItem } from 'src/app/form-controls/select/select.control';
import { SheetItemService } from 'src/app/services/sheet-item.service';
import { SheetItemDetailConfig, SheetItemFormBase } from 'src/app/services/sheets-renderer.service';
import { Row } from 'src/app/types/aggregate-graph.types';
import { EyeLubricationInstruction, LubricatedEye } from 'src/app/types/sheet-instruction.types';
import { InstructionsFormComponent } from '../../instructions-form/instructions-form.component';

interface EyeLubricationInstructionFormGroup {
	lubricatedEye: FormControl<LubricatedEye | null>;
}

@Component({
	selector: 'app-eye-lubrication-item-form',
	templateUrl: './eye-lubrication-item-form.component.html',
	styleUrl: './eye-lubrication-item-form.component.scss',
	standalone: true,
	imports: [ReactiveFormsModule, InstructionsFormComponent, SelectControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EyeLubricationItemFormComponent implements SheetItemFormBase, OnInit {

	@Input() instructionTypeName!: string;
	@Input() item!: Row | null;
	@Input() minDate!: string;
	@Input() maxDate!: string;
	@Input() instructionsFormArray!: FormArray;
	@Input() itemConfig!: SheetItemDetailConfig;

	lubricatedEyes: SelectItem[] = [
		{ text: 'Left', value: LubricatedEye.Left },
		{ text: 'Right', value: LubricatedEye.Right },
		{ text: 'Both', value: LubricatedEye.Both },
	
	];

	constructor(private sheetItemService: SheetItemService, private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.sheetItemService.getInstructions(this.item, this.instructionTypeName, this.itemConfig.scheduleConfig.defaultScheduleType, this.itemConfig.scheduleConfig.defaultRepeatingScheduleFrequency).forEach(instruction => {
			const group: FormGroup<EyeLubricationInstructionFormGroup> = this.sheetItemService.buildExistingInstructionGroup(this.instructionTypeName, instruction);
			const eyeLubricationInstruction = instruction as EyeLubricationInstruction;
			group.addControl('lubricatedEye', this.formBuilder.control(eyeLubricationInstruction.lubricatedEye ?? null));
			this.instructionsFormArray.push(group);
		});
	}

	addInstruction() {
		const group: FormGroup<EyeLubricationInstructionFormGroup> = this.sheetItemService.getNewInstructionGroup(this.instructionTypeName, this.itemConfig.scheduleConfig.defaultScheduleType, this.itemConfig.scheduleConfig.defaultRepeatingScheduleFrequency);
		group.addControl('lubricatedEye', this.formBuilder.control(null));
		this.instructionsFormArray.push(group);
	}
}