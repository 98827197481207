<ion-header>
	<ion-toolbar>
		<ion-title>Select items</ion-title>
		<ion-buttons slot="end">
			<ion-button (click)="close()" color="primary">
				<ion-icon slot="icon-only" name="close-sharp"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
<div class="scroll-vertical app-padding-horizontal ion-content-scroll-host">
	@for (item of items; track item.id; let last = $last) {
		@if (item.type === 'header') {
            <app-heading background="secondary-3">{{ item.label }}</app-heading>
        }
        @else {
			<app-item appNavigable="item.type === 'option'" (click)="itemSelected(item)" [underlined]="!last">
				<app-item-label>{{ item.label }}</app-item-label>
				@if (isSelected(item)) {
					<app-svg-icon name="checkmark-sharp"></app-svg-icon>
				}
			</app-item>
		}
	}
</div>