import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { formatISO } from 'date-fns';
import { from, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { DraftTaskModal } from 'src/app/pages/patient/components/tasks-panel/modals/draft-task/draft-task.modal';
import { DocumentInstancesService } from 'src/app/services/document-instances.service';
import { ErrorService } from 'src/app/services/error.service';
import { TasksService } from 'src/app/services/tasks.service';
import { clientErrored } from '../error/error.actions';
import * as patientGraphActions from '../patient-graph/patient-graph.actions';
import { isMobile } from '../platform/platform.selectors';
import { AppState } from '../reducers';
import * as patientTaskActions from './patient-task.actions';
import { selectedTaskDocuments, selectedTaskId } from './patient-task.selectors';

@Injectable()
export class PatientTaskEffects {

	constructor(
		private actions$: Actions, private tasksService: TasksService, private documentInstancesService: DocumentInstancesService,
		private store: Store<AppState>, private errorService: ErrorService, private modalController: ModalController) {
	}

	subscribePatientRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientGraphActions.subscribePatientRequested),
			concatLatestFrom(() => this.store.select(selectedTaskId)),
			filter(([action, selectedTaskId]) => action.taskId !== selectedTaskId),
			map(([action,]) => patientTaskActions.subscribeVisitTaskRequested({ taskId: action.taskId }))
		)
	);

	patientGraphLoaded$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientGraphActions.patientGraphLoaded),
			concatLatestFrom(() => [this.store.select(selectedTaskId), this.store.select(isMobile)]),
			// on mobile, when a task is selected from service tasks view, the task document is not loaded until the user clicks it (larger than mobile, it is loaded immediately)
			filter(([action, selectedTaskId, isMobile]) => !isMobile && action.tasks.some(task => task.id === selectedTaskId)),
			map(([action, selectedTaskId]) => patientTaskActions.subscribedVisitTaskLoaded({ taskDocumentId: action.taskDocuments.find(td => td.taskId === selectedTaskId && td.isRequestDocument)?.id ?? '' }))
		)
	);

	showLoadedVisitTaskRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientTaskActions.showLoadedVisitTaskRequested),
			concatLatestFrom(() => [this.store.select(selectedTaskDocuments), this.store.select(isMobile)]),
			// on mobile, when a task is selected from service tasks view, the task document is not loaded until the user clicks it (larger than mobile, it is loaded immediately)
			filter(([, , isMobile]) => !isMobile),
			map(([action, taskDocuments]) => patientTaskActions.showLoadedVisitTaskCompleted({ taskDocumentId: taskDocuments.find(td => td.taskId === action.taskId && td.isRequestDocument)?.id ?? '' }))
		)
	);

	addVisitTaskRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientTaskActions.addVisitTaskRequested),
			mergeMap(action => from(this.modalController.create({ component: DraftTaskModal, backdropDismiss: false })).pipe(
				switchMap(modal => from(modal.present()).pipe(
					switchMap(() => this.tasksService.createTask(action.visitId, action.taskDefinitionId).pipe(
						map(response => patientTaskActions.addVisitTaskCompleted({ taskId: response.taskId })),
						catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Add task failed', errorMessage: this.errorService.getErrorMessage(error) })))
					))
				))
			))
		)
	);

	taskDocumentAdded$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientGraphActions.taskDocumentAdded),
			concatLatestFrom(() => [this.store.select(selectedTaskId), this.store.select(isMobile)]),
			// on mobile, when a task is selected from service tasks view, the task document is not loaded until the user clicks it (larger than mobile, it is loaded immediately)
			filter(([action, addedTaskId, isMobile]) => !isMobile && action.taskDocument.taskId === addedTaskId),
			map(([action]) => patientTaskActions.addedVisitTaskLoaded({ taskDocumentId: action.taskDocument.id }))
		)
	);

	editTaskRequestDocumentRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientTaskActions.editTaskRequestDocumentRequested),
			exhaustMap(() => from(this.modalController.create({ component: DraftTaskModal, cssClass: 'task-document-modal', backdropDismiss: false })).pipe(
				switchMap(modal => from(modal.present()))
			))
		),
		{ dispatch: false }
	);

	shareDraftTaskRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientTaskActions.shareDraftTaskRequested),
			exhaustMap(action => this.tasksService.shareTask(action.taskId, action.recipientIdentifiers, null).pipe(
				map(() => patientTaskActions.shareDraftTaskCompleted({ taskId: action.taskId, firstSharedDate: formatISO(new Date()) })),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Share draft task failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	saveTaskDocumentWidgetValue$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientTaskActions.setTaskDocumentWidgetValueRequested),
			concatMap(action => this.documentInstancesService.setWidgetValue(action.documentInstanceId, action.widgetId, action.value).pipe(
				map(() => patientTaskActions.setTaskDocumentWidgetValueCompleted()),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Set task document widget value failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	recallTaskRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientTaskActions.recallTaskRequested),
			exhaustMap(action => this.tasksService.recallTask(action.taskId, action.reason).pipe(
				map(() => patientTaskActions.recallTaskCompleted()),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Recall task failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	completeTaskRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientTaskActions.completeTaskRequested),
			exhaustMap(action => this.tasksService.completeTask(action.taskId, action.recipientId).pipe(
				map(() => patientTaskActions.completeTaskCompleted()),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Complete task failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	rejectTaskRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientTaskActions.rejectTaskRequested),
			exhaustMap(action => this.tasksService.rejectTask(action.taskId, action.recipientId, action.reason).pipe(
				map(() => patientTaskActions.rejectTaskCompleted()),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Reject task failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	copyVisitTaskRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientTaskActions.copyVisitTaskRequested),
			mergeMap(action => from(this.modalController.create({ component: DraftTaskModal, backdropDismiss: false })).pipe(
				switchMap(modal => from(modal.present()).pipe(
					switchMap(() => this.tasksService.copyTask(action.taskId).pipe(
						map(response => patientTaskActions.copyVisitTaskCompleted({ taskId: response.taskId })),
						catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Copy task failed', errorMessage: this.errorService.getErrorMessage(error) })))
					))
				))
			))
		)
	);

	scheduleTaskRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientTaskActions.scheduleTaskRequested),
			mergeMap(action => this.tasksService.scheduleTask(action.taskId, action.recipientId, action.date).pipe(
				map(() => patientTaskActions.scheduleTaskCompleted()),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Schedule task failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	updateSummaryRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientTaskActions.updateSummaryRequested),
			mergeMap(action => this.tasksService.updateSummary(action.taskId, action.summary).pipe(
				map(() => patientTaskActions.updateUrgencyCompleted()),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Update summary failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	updateUrgencyRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientTaskActions.updateUrgencyRequested),
			mergeMap(action => this.tasksService.updateUrgency(action.taskId, action.urgent, action.urgentReason).pipe(
				map(() => patientTaskActions.updateUrgencyCompleted()),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Update urgency failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	updateTodoRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientTaskActions.updateTodoRequested),
			mergeMap(action => this.tasksService.updateTodo(action.taskId, action.taskRecipientId, action.todo).pipe(
				map(() => patientTaskActions.updateTodoCompleted()),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Update todo failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	shareTaskRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientTaskActions.shareTaskRequested),
			exhaustMap(action => this.tasksService.shareTask(action.taskId, action.toRecipientId, action.fromRecipientId ? action.fromRecipientId : null).pipe(
				map(() => patientTaskActions.shareTaskCompleted()),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Share task failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);
}