import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers';

export const selectResultsPerAppend = (state: AppState) => state.patientSearch.resultsPerAppend;
export const selectQueryTerm = (state: AppState) => state.patientSearch.queryTerm;
export const selectIsSearching = (state: AppState) => state.patientSearch.isSearching;
export const selectSearchResults = (state: AppState) => state.patientSearch.searchResults;
export const selectAppendsRetrieved = (state: AppState) => state.patientSearch.appendsRetrieved;
export const selectLastResultsRetrievedCount = (state: AppState) => state.patientSearch.lastResultsRetrievedCount;

export const queryTerm = createSelector(
	selectQueryTerm,
	(queryTerm) => queryTerm
);

export const searchParams = createSelector(
	queryTerm,
	selectResultsPerAppend,
	selectAppendsRetrieved,
	(queryTerm, resultsPerAppend, appendsRetrieved) => ({
		queryTerm: queryTerm,
		resultsPerAppend: resultsPerAppend,
		appendsRetrieved: appendsRetrieved
	})
);

export const isSearching = createSelector(
	selectIsSearching,
	(isSearching) => isSearching
);

export const searchResults = createSelector(
	selectSearchResults,
	(searchResults) => searchResults
);

export const morePagesExist = createSelector(
	searchResults,
	selectResultsPerAppend,
	selectLastResultsRetrievedCount,
	(searchResults, resultsPerAppend, lastResultsRetrievedCount) => searchResults.length === 0 || lastResultsRetrievedCount === resultsPerAppend
);