import { Injectable } from '@angular/core';
import { ModalityBodyPartsPickerValue } from '../form-controls/modality-body-parts-picker/modality-body-parts-picker.control';
import { UserSignature } from '../form-controls/user-signature/user-signature.control';

interface ListyPickerValue {
	subsets: { id: number, label: string }[];
	selections: { itemId: number, itemLabel: string, text: string }[];
}

@Injectable({
	providedIn: 'root'
})
export class WidgetValueMapperService {

	constructor() {
	}

	mapWidgetValue(widgetType: string, widgetValue: any): any {
		switch (widgetType) {
			case 'group-repeater':
				return this.mapOldGroupRepeaterToNewGroupRepeater(widgetValue);
			case 'listy-picker':
				return this.mapListyPickerToModalityBodyPartsPicker(widgetValue);
			case 'user-signature':
				return this.mapOldUserSignatureToNewUserSignature(widgetValue);
			default:
				return widgetValue;
		}
	}

	private mapOldGroupRepeaterToNewGroupRepeater(values: any[] | null): any {
		// the widget value can be:
		//   a) an array of arrays (original implementation, rendered as FormArray of FormArrays).
		//   b) an array of objects (current implementation, rendered as FormArray of FormGroups).
		//   note: if array of arrays, need to map them to an array of objects, where each object has a property per widget. In the old implementation the index is the property name,
		//         in the current implementation it is the id.
		if (!values) return null;

		values = values || [];

		// ensure the values are in the FormArray of FormGroups structure (array of objects)
		if (values.length && Array.isArray(values[0])) {
			const groupValues: { [id: number]: object }[] = [];

			values.forEach((v) => {
				const value: object[] = v as [];
				let groupValue: { [key: number]: object } = {};

				value.forEach((item, index) => {
					groupValue[index] = item;
				});

				groupValues.push(groupValue);
			});

			return groupValues;
		}

		return values;
	}

	private mapListyPickerToModalityBodyPartsPicker(value: ListyPickerValue | ModalityBodyPartsPickerValue | null): ModalityBodyPartsPickerValue | null {
		// modality body parts were moved from listy to elastic. The value types were changed, so need to map if the old type is received
		if (value) {
			if (value.hasOwnProperty('subsets')) {
				const oldValue = value as ListyPickerValue;
				const newValue: ModalityBodyPartsPickerValue = {
					modality: oldValue.subsets.at(0)?.label ?? null,
					bodyParts: oldValue.selections.map(selection => ({ id: selection.itemId, name: selection.itemLabel, notes: selection.text }))
				};
				return newValue;
			}
			return value as ModalityBodyPartsPickerValue;
		}
		return null;
	}

	private mapOldUserSignatureToNewUserSignature(value: boolean | UserSignature | null): UserSignature | null {
		// in old IA user signature was just a boolean primitive. Now it is an object of interface UserSignature, so old boolean needs to be mapped with empty UserInfo
		if (typeof value === 'boolean') {
			const userSignature: UserSignature | null = value ? { fullName: null, initials: null } : null;
			return userSignature;
		}
		return value;
	}
}