import { createAction, props } from '@ngrx/store';
import { CommsPanelChildType } from 'src/app/types/patient.types';

export enum PatientActionTypes {
	PatientSelected = '[Effect] Patient Selected',
	PatientDeselected = '[Effect] Patient Deselected',
	SegmentChanged = '[Any] Segment Changed',
	CommsPanelChildSelected = '[Component] Comms Panel Child Selected',
	SaveProblemsRequested = '[Component] Save Problems Requested',
	SaveAlertsRequested = '[Component] Save Alerts Requested'
}

export const patientSelected = createAction(
	PatientActionTypes.PatientSelected,
	props<{ segmentLabel: string }>()
)

export const patientDeselected = createAction(
	PatientActionTypes.PatientDeselected
)

export const segmentChanged = createAction(
	PatientActionTypes.SegmentChanged,
	props<{ label: string }>()
)

export const commsPanelChildSelected = createAction(
	PatientActionTypes.CommsPanelChildSelected,
	props<{ childType: CommsPanelChildType }>()
)

export const saveProblemsRequested = createAction(
	PatientActionTypes.SaveProblemsRequested,
	props<{ patientId: string, problems: string }>()
)

export const saveAlertsRequested = createAction(
	PatientActionTypes.SaveAlertsRequested,
	props<{ patientId: string, alerts: string }>()
)