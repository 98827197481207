import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class DocumentInstancesService {

	constructor(private httpClient: HttpClient) {
	}

	setWidgetValue(id: string, widgetId: number, value: any) {
		return this.httpClient.put<void>(`/api/document-instances/${id}/widgets/${widgetId}`, { widgetValueJson: JSON.stringify(value) });
	}
}