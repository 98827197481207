import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '../services/date.service';

@Pipe({
	name: 'isoDateToRelative',
	standalone: true
})
export class IsoDateToRelativePipe implements PipeTransform {

	constructor(private dateService: DateService) { }

	transform(value: string | null | undefined, format: string = 'dd/MM/yyyy', includeTime: boolean = false): string | null | undefined {
		if (!value) return value;
		const date = this.dateService.formatAsDay(value, format);
		if (includeTime) {
			return `${date} ${this.dateService.format(value, 'HH:mm')}`;
		} else {
			return date;
		}
	}
}
