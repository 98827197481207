import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { HeadingComponent } from 'src/app/components/heading/heading.component';
import { UnitQuantity, UnitQuantityControl } from 'src/app/form-controls/unit-quantity/unit-quantity.control';

import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { MedicationInfusionAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface MedicationInfusionActionFormGroup {
	rate: FormControl<UnitQuantity | null>;
}

@Component({
	selector: 'app-medication-infusion-action-controls',
	templateUrl: './medication-infusion-action-controls.component.html',
	styleUrls: ['../../../common.scss', './medication-infusion-action-controls.component.scss'],
	standalone: true,
	imports: [ReactiveFormsModule, UnitQuantityControl, CommonActionControlsComponent, ActionFormComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MedicationInfusionActionControlsComponent implements SheetActionControlsBase, OnInit {

	slot!: Slot | null;
	action!: MedicationInfusionAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as MedicationInfusionAction;
	}

	@Input() form!: FormGroup<MedicationInfusionActionFormGroup>;
	@Input() item!: Row | null;
	
	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.form.addControl('rate', this.formBuilder.control(this.action?.rate ?? null));
	}
}