<app-action-form [item]="item" [slot]="slot" [form]="form">
	<div class="form-section app-margin-horizontal app-padding">
        <div class="form-group" [formGroup]="form">
            <app-unit-quantity label="Heart rate" unitLabel="bpm" placeholder="Value" inputMode="numeric" formControlName="beatsPerMinute"></app-unit-quantity>
            <app-select label="Heart rhythm" placeholder="choose..." [items]="heartRhythmItems" [saveValue]="true" formControlName="rhythm"></app-select>
            <app-select label="Murmur" placeholder="choose..." [items]="murmurItems" [saveValue]="true" formControlName="murmur"></app-select>
            <app-select label="Synchronous pulses" placeholder="choose..." [items]="synchronousPulsesItems" [saveValue]="true" formControlName="synchronousPulses"></app-select>
            <app-select label="Pulse quality" placeholder="choose..." [items]="pulseQualityItems" [saveValue]="true" formControlName="pulseQuality"></app-select>
            <app-text-input label="Pulse location" placeholder="Value" formControlName="pulseLocation"></app-text-input>
        </div>
        <app-common-action-controls [action]="action" [form]="form"></app-common-action-controls>
    </div>
</app-action-form>
