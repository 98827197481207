import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SheetItemComponentBase } from 'src/app/services/sheets-renderer.service';
import { Row } from 'src/app/types/aggregate-graph.types';
import { FluidsSubcutaneousInstructions } from 'src/app/types/sheet-instruction.types';
import { InstructionBoxComponent } from '../../instruction-box/instruction-box.component';
import { ItemContentComponent } from '../../item-content/item-content.component';

@Component({
	selector: 'app-fluids-subcutaneous-item',
	templateUrl: './fluids-subcutaneous-item.component.html',
	styleUrl: './fluids-subcutaneous-item.component.scss',
	standalone: true,
	imports: [ItemContentComponent, InstructionBoxComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FluidsSubcutaneousItemComponent implements SheetItemComponentBase {

	row!: Row;
	instructions!: FluidsSubcutaneousInstructions;
	@Input() startLabel!: string;
	@Input() endLabel!: string;
	@Input('row') set updateRow(row: Row) {
		this.row = row;
		this.instructions = row.instructions as FluidsSubcutaneousInstructions;
	}

	constructor() {
	}
}