import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { AlertController, IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar, ModalController } from '@ionic/angular/standalone';
import { LetDirective } from '@ngrx/component';
import { Store, select } from '@ngrx/store';
import { Observable, take } from 'rxjs';
import { ButtonComponent } from 'src/app/components/button/button.component';
import { HeadingComponent } from 'src/app/components/heading/heading.component';
import { SpinnerComponent } from 'src/app/components/spinner/spinner.component';
import { shareDraftTaskRequested } from 'src/app/store/patient-task/patient-task.actions';
import { selectedTask, selectedTaskRequestDocument } from 'src/app/store/patient-task/patient-task.selectors';
import { AppState } from 'src/app/store/reducers';
import { ProfileInfo, profileInfo } from 'src/app/store/user-graph/user-graph.selectors';
import { PatientRecordTask, TaskDocument, TaskRecipient } from 'src/app/types/aggregate-graph.types';
import { filterUndefinedOrNull } from 'src/app/types/rxjs-custom-operator.types';
import { TaskDocumentComponent } from '../../components/task-document/task-document.component';
import { ShareTaskModal } from '../share-task/share-task.modal';

@Component({
	templateUrl: './draft-task.modal.html',
	styleUrl: './draft-task.modal.scss',
	standalone: true,
	imports: [LetDirective, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, SpinnerComponent, HeadingComponent, TaskDocumentComponent, ButtonComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DraftTaskModal {

	task$: Observable<PatientRecordTask>;
	taskDocument$: Observable<TaskDocument>;
	profileInfo$: Observable<ProfileInfo>;

	@ViewChild(TaskDocumentComponent) taskDocumentComponent!: TaskDocumentComponent;

	constructor(private store: Store<AppState>, private modalController: ModalController, private alertController: AlertController) {
		this.task$ = this.store.pipe(select(selectedTask), filterUndefinedOrNull(), take(1));
		this.taskDocument$ = this.store.pipe(select(selectedTaskRequestDocument), filterUndefinedOrNull(), take(1));
		this.profileInfo$ = this.store.pipe(select(profileInfo), take(1));
	}

	close() {
		this.modalController.dismiss();
	}

	async shareTaskClicked(profileInfo: ProfileInfo, task: PatientRecordTask) {
		if (!profileInfo.isClinician && !profileInfo.isSystemAdministrator) {
			const alert = await this.alertController.create({
				header: 'Not Authorised',
				subHeader: 'Only clinicians can send tasks',
				buttons: ['Ok'],
				backdropDismiss: false
			});
			alert.present();
		}
		else if (this.taskDocumentComponent.documentOrTaskIsInvalid()) {
			const alert = await this.alertController.create({
				header: 'Warning',
				subHeader: 'Sharing an invalid task may cause it to be rejected by the recipient. Share anyway?',
				buttons: [
					'No',
					{
						text: 'Yes',
						handler: () => {
							this.initiateSharing(task);
						}
					}
				],
				backdropDismiss: false
			});
			alert.present();
		}
		else {
			this.initiateSharing(task);
		}
	}

	private async initiateSharing(task: PatientRecordTask) {
		const modal = await this.modalController.create({
			component: ShareTaskModal,
			componentProps: {
				recipients: task.manifest.initialRecipientIds.map((id) => task.manifest.allRecipients[id])
			},
			cssClass: 'stacked-modal',
			backdropDismiss: false,
			showBackdrop: true
		});
		await modal.present();
		const { data } = await modal.onWillDismiss();
		if (!data) return;

		this.store.dispatch(shareDraftTaskRequested({ taskId: task.id, recipientIdentifiers: (data.recipients as TaskRecipient[]).map(recipient => recipient.id.id) }));

		await modal.onDidDismiss();
		this.modalController.dismiss();
	}
}
