import { createAction, props } from '@ngrx/store';
import { ToDo } from 'src/app/types/aggregate-graph.types';

export enum PatientTaskActionTypes {
	SubscribeVisitTaskRequested = '[Effect] Subscribe Visit Task Requested',
	SubscribedVisitTaskLoaded = '[Effect] Subscribed Visit Task Loaded',
	AddVisitTaskRequested = '[Component] Add Visit Task Requested',
	AddVisitTaskCompleted = '[Effect] Add Visit Task Completed',
	AddedVisitTaskLoaded = '[Effect] Added Visit Task Loaded',
	ShowLoadedVisitTaskRequested = '[Component] Show Loaded Visit Task Requested',
	ShowLoadedVisitTaskCompleted = '[Effect] Show Loaded Visit Task Completed',
	HideLoadedVisitTaskRequested = '[Component] Hide Loaded Visit Task Requested',
	VisitTaskDocumentSelected = '[Component] Visit Task Document Selected',
	VisitTaskDocumentDeselected = '[Component] Visit Task Document Deselected',
	EditTaskRequestDocumentRequested = '[Component] Edit Task Request Document Requested',
	ShareDraftTaskRequested = '[Component] Share Draft Task Requested',
	ShareDraftTaskCompleted = '[Effect] Share Draft Task Completed',
	SetTaskDocumentWidgetValueRequested = '[Component] Set Task Document Widget Value Requested',
	SetTaskDocumentWidgetValueCompleted = '[Effect] Set Task Document Widget Value Completed',
	RecallTaskRequested = '[Component] Recall Task Requested',
	RecallTaskCompleted = '[Effect] Recall Task Completed',
	CompleteTaskRequested = '[Component] Complete Task Requested',
	CompleteTaskCompleted = '[Effect] Complete Task Completed',
	RejectTaskRequested = '[Component] Reject Task Requested',
	RejectTaskCompleted = '[Effect] Reject Task Completed',
	CopyVisitTaskRequested = '[Component] Copy Visit Task Requested',
	CopyVisitTaskCompleted = '[Effect] Copy Visit Task Completed',
	ScheduleTaskRequested = '[Component] Schedule Task Requested',
	ScheduleTaskCompleted = '[Effect] Schedule Task Completed',
	UpdateSummaryRequested = '[Component] Update Summary Requested',
	UpdateUrgencyRequested = '[Component] Update Urgency Requested',
	UpdateUrgencyCompleted = '[Effect] Update Urgency Completed',
	UpdateTodoRequested = '[Component] Update Todo Requested',
	UpdateTodoCompleted = '[Effect] Update Todo Completed',
	ShareTaskRequested = '[Component] Share Task Requested',
	ShareTaskCompleted = '[Effect] Share Task Completed'
}

export const subscribeVisitTaskRequested = createAction(
	PatientTaskActionTypes.SubscribeVisitTaskRequested,
	props<{ taskId: string }>()
)

export const subscribedVisitTaskLoaded = createAction(
	PatientTaskActionTypes.SubscribedVisitTaskLoaded,
	props<{ taskDocumentId: string }>()
)

export const addVisitTaskRequested = createAction(
	PatientTaskActionTypes.AddVisitTaskRequested,
	props<{ visitId: string, taskDefinitionId: string }>()
)

export const addVisitTaskCompleted = createAction(
	PatientTaskActionTypes.AddVisitTaskCompleted,
	props<{ taskId: string }>()
)

export const addedVisitTaskLoaded = createAction(
	PatientTaskActionTypes.AddedVisitTaskLoaded,
	props<{ taskDocumentId: string }>()
)

export const showLoadedVisitTaskRequested = createAction(
	PatientTaskActionTypes.ShowLoadedVisitTaskRequested,
	props<{ taskId: string }>()
)

export const showLoadedVisitTaskCompleted = createAction(
	PatientTaskActionTypes.ShowLoadedVisitTaskCompleted,
	props<{ taskDocumentId: string }>()
)

export const hideLoadedVisitTaskRequested = createAction(
	PatientTaskActionTypes.HideLoadedVisitTaskRequested
)

export const visitTaskDocumentSelected = createAction(
	PatientTaskActionTypes.VisitTaskDocumentSelected,
	props<{ taskDocumentId: string }>()
)

export const visitTaskDocumentDeselected = createAction(
	PatientTaskActionTypes.VisitTaskDocumentDeselected
)

export const editTaskRequestDocumentRequested = createAction(
	PatientTaskActionTypes.EditTaskRequestDocumentRequested
)

export const shareDraftTaskRequested = createAction(
	PatientTaskActionTypes.ShareDraftTaskRequested,
	props<{ taskId: string, recipientIdentifiers: number[] }>()
)

export const shareDraftTaskCompleted = createAction(
	PatientTaskActionTypes.ShareDraftTaskCompleted,
	props<{ taskId: string, firstSharedDate: string }>()
)

export const setTaskDocumentWidgetValueRequested = createAction(
	PatientTaskActionTypes.SetTaskDocumentWidgetValueRequested,
	props<{ documentInstanceId: string, widgetId: number, value: any }>()
)

export const setTaskDocumentWidgetValueCompleted = createAction(
	PatientTaskActionTypes.SetTaskDocumentWidgetValueCompleted
)

export const recallTaskRequested = createAction(
	PatientTaskActionTypes.RecallTaskRequested,
	props<{ taskId: string, reason: string }>()
)

export const recallTaskCompleted = createAction(
	PatientTaskActionTypes.RecallTaskCompleted
)

export const completeTaskRequested = createAction(
	PatientTaskActionTypes.CompleteTaskRequested,
	props<{ taskId: string, recipientId: number }>()
)

export const completeTaskCompleted = createAction(
	PatientTaskActionTypes.CompleteTaskCompleted
)

export const rejectTaskRequested = createAction(
	PatientTaskActionTypes.RejectTaskRequested,
	props<{ taskId: string, recipientId: number, reason: string }>()
)

export const rejectTaskCompleted = createAction(
	PatientTaskActionTypes.RejectTaskCompleted
)

export const copyVisitTaskRequested = createAction(
	PatientTaskActionTypes.CopyVisitTaskRequested,
	props<{ taskId: string }>()
)

export const copyVisitTaskCompleted = createAction(
	PatientTaskActionTypes.CopyVisitTaskCompleted,
	props<{ taskId: string }>()
)

export const scheduleTaskRequested = createAction(
	PatientTaskActionTypes.ScheduleTaskRequested,
	props<{ taskId: string, recipientId: number, date: string }>()
)

export const scheduleTaskCompleted = createAction(
	PatientTaskActionTypes.ScheduleTaskCompleted
)

export const updateSummaryRequested = createAction(
	PatientTaskActionTypes.UpdateSummaryRequested,
	props<{ taskId: string, summary: string }>()
)

export const updateUrgencyRequested = createAction(
	PatientTaskActionTypes.UpdateUrgencyRequested,
	props<{ taskId: string, urgent: boolean, urgentReason: string }>()
)

export const updateUrgencyCompleted = createAction(
	PatientTaskActionTypes.UpdateUrgencyCompleted
)

export const updateTodoRequested = createAction(
	PatientTaskActionTypes.UpdateTodoRequested,
	props<{ taskId: string, taskRecipientId: number, todo: ToDo }>()
)

export const updateTodoCompleted = createAction(
	PatientTaskActionTypes.UpdateTodoCompleted
)

export const shareTaskRequested = createAction(
	PatientTaskActionTypes.ShareTaskRequested,
	props<{ taskId: string, toRecipientId: number[], fromRecipientId?: number }>()
)

export const shareTaskCompleted = createAction(
	PatientTaskActionTypes.ShareTaskCompleted
)