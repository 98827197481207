import { Pipe, PipeTransform } from '@angular/core';
import { ServicesService } from '../services/services.service';

@Pipe({
	name: 'serviceName',
	standalone: true
})
export class ServiceNamePipe implements PipeTransform {

	constructor(private servicesService: ServicesService) {
	}

	transform(serviceId: string | undefined): string {
		return this.servicesService.getService(serviceId || '')?.name ?? '';
	}
}