import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, exhaustMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { BodyPartsService } from 'src/app/services/body-parts.service';
import { clientErrored } from '../error/error.actions';
import { AppState } from '../reducers';
import * as bodyPartsSearchActions from './body-parts-search.actions';
import { searchParams } from './body-parts-search.selectors';

@Injectable()
export class BodyPartsSearchEffects {

	constructor(private actions$: Actions, private store: Store<AppState>, private bodyPartsService: BodyPartsService) {
	}

	newSearchRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(bodyPartsSearchActions.newSearchRequested),
			withLatestFrom(this.store.pipe(select(searchParams))),
			switchMap(([payload, searchParams]) => {
				return this.bodyPartsService.queryBodyParts(payload.queryTerm, payload.modality, searchParams.appendsRetrieved * searchParams.resultsPerAppend, searchParams.resultsPerAppend).pipe(
					map(searchResults => (bodyPartsSearchActions.newSearchSucceeded({ searchResults: searchResults }))),
					catchError((error: HttpErrorResponse) => [
						bodyPartsSearchActions.newSearchFailed({ statusCode: error.status }),
						clientErrored({ toastMessage: 'Body parts search error', errorMessage: error.message })
					])
				)
			})
		)
	);

	appendResultsRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(bodyPartsSearchActions.appendResultsRequested),
			withLatestFrom(this.store.pipe(select(searchParams))),
			exhaustMap(([, searchParams]) => this.bodyPartsService.queryBodyParts(searchParams.queryTerm, searchParams.modality, (searchParams.appendsRetrieved + 1) * searchParams.resultsPerAppend, searchParams.resultsPerAppend).pipe(
				map(searchResults => (bodyPartsSearchActions.appendResultsSucceeded({ searchResults: searchResults }))),
				catchError((error: HttpErrorResponse) => [
					bodyPartsSearchActions.appendResultsFailed({ statusCode: error.status }),
					clientErrored({ toastMessage: 'Body parts search append error', errorMessage: error.message })
				])
			))
		)
	);
}