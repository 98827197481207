import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface PatientSignalment {
	birthDate: string | null | undefined;
	species: string;
	breed: string;
	sex: string; // can be empty string
	isNeutered: boolean | null | undefined;
}

export interface SearchedPatient extends PatientSignalment {
	patientId: string;
	caseNumber: string;
	ownerClientId: string;
	firstName: string;
	ownerSurname: string;
	fullName: string;
}

@Injectable({
	providedIn: 'root'
})
export class PatientsService {

	constructor(private httpClient: HttpClient) {
	}

	queryPatients(queryTerm: string, skip: number, take: number) {
		return this.httpClient.get<SearchedPatient[]>(`/api/patients?queryTerm=${queryTerm}&skip=${skip}&take=${take}`);
	}

	setBloodType(id: string, bloodType: string) {
		return this.httpClient.put<void>(`/api/patients/${id}/blood-type`, JSON.stringify(bloodType), { headers: { 'Content-Type': 'application/json' } });
	}

	setProblems(id: string, problems: string) {
		return this.httpClient.put<void>(`/api/patients/${id}/problems`, JSON.stringify(problems), { headers: { 'Content-Type': 'application/json' } });
	}

	setAlerts(id: string, alerts: string) {
		return this.httpClient.put<void>(`/api/patients/${id}/alerts`, JSON.stringify(alerts), { headers: { 'Content-Type': 'application/json' } });
	}
}