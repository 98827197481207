import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { LetDirective } from '@ngrx/component';
import { enterLeave } from 'src/app/animations/common.animations';
import { HeadingComponent } from 'src/app/components/heading/heading.component';
import { ItemValueComponent } from 'src/app/components/item-value/item-value.component';
import { ItemComponent } from 'src/app/components/item/item.component';
import { SvgIconComponent } from 'src/app/components/svg-icon/svg-icon.component';
import { NavigableDirective } from 'src/app/directives/navigable.directive';
import { SheetItemScheduleConfig } from 'src/app/services/sheets-renderer.service';
import { ScheduleControlsComponent } from '../schedule-controls/schedule-controls.component';
@Component({
	selector: 'app-instructions-form',
	templateUrl: './instructions-form.component.html',
	styleUrls: ['./instructions-form.component.scss', '../../common.scss'],
	standalone: true,
	imports: [
		ReactiveFormsModule, LetDirective, HeadingComponent, NgTemplateOutlet, ScheduleControlsComponent, ItemComponent, NavigableDirective, ItemValueComponent, SvgIconComponent
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [enterLeave]
})
export class InstructionsFormComponent {

	@Input() instructionTypeName!: string;
	@Input() instructionsFormArray!: FormArray;
	@Input() minDate!: string;
	@Input() maxDate!: string;
	@Input() scheduleConfig!: SheetItemScheduleConfig;

	// Only need to set this if there is a custom pre-schedule controls template (if it is undefined it does not get rendered).
	// In these templates, the instruction FormGroup is passed in as a context variable via this component.
	// Any non-form properties and methods the template needs can be accessed via the *-item-form component.
	@Input() preScheduleInstructionTemplate!: TemplateRef<any>;
	@Input() postScheduleInstructionTemplate!: TemplateRef<any>;

	@Output() addInstructionClicked: EventEmitter<void> = new EventEmitter();


	constructor() {}

	getInstructionFormGroup(index: number) {
		return this.instructionsFormArray.at(index) as FormGroup;
	}

	addInstruction() {
		this.addInstructionClicked.emit();
	}

	removeInstruction(index: number) {
		this.instructionsFormArray.removeAt(index);
	}
}