import * as fromRouter from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';
import * as fromAggregateGraphHub from './aggregate-graph-hub/aggregate-graph-hub.reducer';
import * as fromAuth from './auth/auth.reducer';
import * as fromBodyPartsSearch from './body-parts-search/body-parts-search.reducer';
import * as fromClient from './client/client.reducer';
import * as fromMenuSelection from './menu-selection/menu-selection.reducer';
import * as fromPatientGraph from './patient-graph/patient-graph.reducer';
import * as fromPatientSearch from './patient-search/patient-search.reducer';
import * as fromPatientTask from './patient-task/patient-task.reducer';
import * as fromPatient from './patient/patient.reducer';
import * as fromPlatform from './platform/platform.reducer';
import * as fromServiceGraph from './service-graph/service-graph.reducer';
import * as fromServiceSettings from './service-settings/service-settings.reducer';
import * as fromServiceTask from './service-task/service-task.reducer';
import * as fromSheetGraph from './sheet-graph/sheet-graph.reducer';
import * as fromSheetActionSearch from './sheet-item-search/sheet-item-search.reducer';
import * as fromSheetReducer from './sheet/sheet.reducer';
import * as fromUserGraph from './user-graph/user-graph.reducer';
import * as fromUserSearch from './user-search/user-search.reducer';

export interface AppState {
	aggregateGraphHub: fromAggregateGraphHub.State;
	auth: fromAuth.State;
	bodyPartsSearch: fromBodyPartsSearch.State;
	client: fromClient.State;
	menuSelection: fromMenuSelection.State;
	patient: fromPatient.State;
	patientGraph: fromPatientGraph.State;
	patientSearch: fromPatientSearch.State;
	patientTask: fromPatientTask.State;
	platform: fromPlatform.State;
	router: fromRouter.RouterReducerState;
	serviceGraph: fromServiceGraph.State;
	serviceSettings: fromServiceSettings.State;
	serviceTask: fromServiceTask.State;
	sheet: fromSheetReducer.State;
	sheetGraph: fromSheetGraph.State;
	sheetItemSearch: fromSheetActionSearch.State;
	userGraph: fromUserGraph.State;
	userSearch: fromUserSearch.State;
}

export const reducers: ActionReducerMap<AppState> = {
	aggregateGraphHub: fromAggregateGraphHub.aggregateGraphHubReducer,
	auth: fromAuth.authReducer,
	bodyPartsSearch: fromBodyPartsSearch.bodyPartsSearchReducer,
	client: fromClient.clientReducer,
	menuSelection: fromMenuSelection.menuSelectionReducer,
	patient: fromPatient.patientReducer,
	patientGraph: fromPatientGraph.patientGraphReducer,
	patientSearch: fromPatientSearch.patientSearchReducer,
	patientTask: fromPatientTask.patientTaskReducer,
	platform: fromPlatform.platformReducer,
	router: fromRouter.routerReducer,
	serviceGraph: fromServiceGraph.serviceGraphReducer,
	serviceSettings: fromServiceSettings.serviceSettingsReducer,
	serviceTask: fromServiceTask.serviceTaskReducer,
	sheet: fromSheetReducer.sheetReducer,
	sheetGraph: fromSheetGraph.sheetGraphReducer,
	sheetItemSearch: fromSheetActionSearch.sheetItemSearchReducer,
	userGraph: fromUserGraph.userGraphReducer,
	userSearch: fromUserSearch.userSearchReducer
};