import { JsonPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AlertController } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { enterLeave } from 'src/app/animations/common.animations';
import { ExpanderGroupService } from 'src/app/components/expander/expander-group.service';
import { ExpanderComponent } from 'src/app/components/expander/expander.component';
import { HeadingComponent } from 'src/app/components/heading/heading.component';
import { ItemButtonComponent } from 'src/app/components/item-button/item-button.component';
import { ItemLabelComponent } from 'src/app/components/item-label/item-label.component';
import { ItemComponent } from 'src/app/components/item/item.component';
import { CheckboxControl } from 'src/app/form-controls/checkbox/checkbox.control';
import { DateTimeControl } from 'src/app/form-controls/datetime/datetime.control';
import { GroupRepeaterControl } from 'src/app/form-controls/group-repeater/group-repeater.control';
import { ModalityBodyPartsPickerControl } from 'src/app/form-controls/modality-body-parts-picker/modality-body-parts-picker.control';
import { MultiCheckboxControl } from 'src/app/form-controls/multi-checkbox/multi-checkbox.control';
import { MultiItemPickerControl } from 'src/app/form-controls/multi-item-picker/multi-item-picker.control';
import { MultiTextInputControl } from 'src/app/form-controls/multi-text-input/multi-text-input.control';
import { SelectWithInfoControl } from 'src/app/form-controls/select-with-info/select-with-info.control';
import { SelectControl } from 'src/app/form-controls/select/select.control';
import { TextInputControl } from 'src/app/form-controls/text-input/text-input.control';
import { TextAreaControl } from 'src/app/form-controls/textarea/textarea.control';
import { UserPickerControl } from 'src/app/form-controls/user-picker/user-picker.control';
import { UserSignatureControl } from 'src/app/form-controls/user-signature/user-signature.control';
import { valuesMustMatch } from 'src/app/form-validators/values-must-match.validator';
import { TaskDocumentService, TaskDocumentViewModel } from 'src/app/services/task-document.service';
import { WidgetMetadataMapperService } from 'src/app/services/widget-metadata-mapper.service';
import { editTaskRequestDocumentRequested, updateSummaryRequested, updateUrgencyRequested } from 'src/app/store/patient-task/patient-task.actions';
import { AppState } from 'src/app/store/reducers';
import { PatientRecordTask, TaskDocument } from 'src/app/types/aggregate-graph.types';

interface SummaryFormGroup {
	summary: FormControl<string | null>;
}

interface UrgentFormGroup {
	urgent: FormControl<boolean>;
	urgentReason: FormControl<string>;
}

@Component({
	selector: 'app-task-document',
	templateUrl: './task-document.component.html',
	styleUrl: './task-document.component.scss',
	standalone: true,
	imports: [
		NgTemplateOutlet, ItemComponent, ItemButtonComponent, ItemLabelComponent, ExpanderComponent, ReactiveFormsModule, JsonPipe,
		HeadingComponent, TextInputControl, TextAreaControl, CheckboxControl, DateTimeControl, SelectControl, SelectWithInfoControl,
		UserPickerControl, UserSignatureControl, MultiTextInputControl, MultiCheckboxControl, MultiItemPickerControl,
		ModalityBodyPartsPickerControl, GroupRepeaterControl
	],
	providers: [ExpanderGroupService],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [enterLeave]
})
export class TaskDocumentComponent implements OnChanges, OnDestroy {

	@Input() task!: PatientRecordTask;
	@Input() taskDocument!: TaskDocument;
	@Input() showEditButton: boolean = true;
	@Input() canEdit: boolean = false;
	@Input() isReadOnly: boolean = true;

	viewModel!: TaskDocumentViewModel;

	summaryForm: FormGroup<SummaryFormGroup> | undefined;
	urgentForm: FormGroup<UrgentFormGroup> | undefined;
	taskPropertiesForm: FormGroup = this.formBuilder.group({});

	constructor(
		private taskDocumentService: TaskDocumentService, private formBuilder: FormBuilder, private expanderGroupService: ExpanderGroupService,
		private alertController: AlertController, private store: Store<AppState>, public widgetMetadataMapperService: WidgetMetadataMapperService) {
	}

	private isBuilt: boolean = false;

	ngOnChanges(changes: SimpleChanges) {
		if (!this.isBuilt) {
			this.viewModel = this.taskDocumentService.buildViewModel(this.taskDocument, this.isReadOnly);

			if (this.task.manifest.summarySettings.userCanEdit) {
				
				this.summaryForm = this.formBuilder.group({
					summary: this.formBuilder.control(this.task.summary, { validators: Validators.required })
				});

				if (!this.isReadOnly) {
					this.summaryForm.valueChanges.subscribe(value => {
						this.store.dispatch(updateSummaryRequested({ taskId: this.task.id, summary: value.summary ?? '' }));
					});
				}

				this.addToTaskPropertiesForm('summary', this.summaryForm);
			}

			if (this.task.manifest.canBeUrgent) {

				this.urgentForm = this.formBuilder.nonNullable.group({
					urgent: this.formBuilder.nonNullable.control<boolean>(this.task.isUrgent),
					urgentReason: this.formBuilder.nonNullable.control<string>(this.task.urgentReason)
				}, { validators: valuesMustMatch('urgent', 'urgentReason', false) });
				
				if (!this.isReadOnly) {
					this.urgentForm.valueChanges.subscribe(value => {
						this.store.dispatch(updateUrgencyRequested({ taskId: this.task.id, urgent: value.urgent ?? false, urgentReason: value.urgentReason ?? '' }));
					});
				}

				this.addToTaskPropertiesForm('urgent', this.urgentForm);
			}

			this.isBuilt = true;
		}
		else if (this.isReadOnly) {
			if (changes['task']) {
				if (this.summaryForm) this.summaryForm.setValue({ summary: this.task.summary });
				if (this.urgentForm) this.urgentForm.setValue({ urgent: this.task.isUrgent, urgentReason: this.task.urgentReason });
			}
			if (changes['taskDocument']) this.taskDocumentService.updateWidgets(this.taskDocument, this.viewModel);
		}
	}

	private addToTaskPropertiesForm(key: string, group: FormGroup) {
		this.taskPropertiesForm.addControl(key, group);
	}

	public documentOrTaskIsInvalid(): boolean {
		return this.viewModel.form.invalid || this.taskPropertiesForm.invalid;
	}

	expandCollapseSections(expand: boolean) {
		this.expanderGroupService.expandCollapseAll(expand);
	}

	async editDocument() {
		if (this.canEdit) {
			const alert = await this.alertController.create({
				header: 'Forbidden',
				subHeader: 'Cannot edit the document once the task has been shared',
				buttons: ['Ok'],
				backdropDismiss: false
			});
			alert.present();
		}
		else {
			this.store.dispatch(editTaskRequestDocumentRequested());
		}
	}

	getWidgetControl(widgetId: number) {
		return this.viewModel.widgetControls[widgetId] as FormControl<any>;
	}

	ngOnDestroy(): void {
		this.viewModel.subscriptionDestroyer.next();
		this.viewModel.subscriptionDestroyer.complete();
	}
}