import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UnitQuantity, UnitQuantityControl } from 'src/app/form-controls/unit-quantity/unit-quantity.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { MedicationInfusionRedLabelAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';
import { UserSignature, UserSignatureControl } from 'src/app/form-controls/user-signature/user-signature.control';

interface MedicationInfusionRedLabelActionFormGroup {
	rate: FormControl<UnitQuantity | null>;
	approvalOne: FormControl<UserSignature | null>;
	approvalTwo: FormControl<UserSignature | null>;
}

@Component({
	selector: 'app-medication-infusion-red-label-action-controls',
	templateUrl: './medication-infusion-red-label-action-controls.component.html',
	styleUrls: ['../../../common.scss', './medication-infusion-red-label-action-controls.component.scss'],
	standalone: true,
	imports: [ReactiveFormsModule, UnitQuantityControl, CommonActionControlsComponent, ActionFormComponent, UserSignatureControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MedicationInfusionRedLabelActionControlsComponent implements SheetActionControlsBase, OnInit {

	slot!: Slot | null;
	action!: MedicationInfusionRedLabelAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as MedicationInfusionRedLabelAction;
	}

	@Input() form!: FormGroup<MedicationInfusionRedLabelActionFormGroup>;
	@Input() item!: Row | null;
	
	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.form.addControl('rate', this.formBuilder.control(this.action?.rate ?? null));
		this.form.addControl('approvalOne', this.formBuilder.control(this.action?.approvalOne ?? null));
		this.form.addControl('approvalTwo', this.formBuilder.control(this.action?.approvalTwo ?? null));
	}
}