import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SheetItemComponentBase } from 'src/app/services/sheets-renderer.service';
import { Row } from 'src/app/types/aggregate-graph.types';
import { ItemContentComponent } from '../../item-content/item-content.component';

@Component({
	selector: 'app-authorisation-clinician-item',
	templateUrl: './authorisation-clinician-item.component.html',
	styleUrl: './authorisation-clinician-item.component.scss',
	standalone: true,
	imports: [ItemContentComponent,],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthorisationClinicianItemComponent implements SheetItemComponentBase {

	@Input() row!: Row;
	@Input() startLabel!: string;
	@Input() endLabel!: string;

	constructor() {
	}
}