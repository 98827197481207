import { Routes } from '@angular/router';
import { canActivateHome } from './guards/home-guard';
import { canActivateLogin } from './guards/login-guard';

export const routePaths = {
	login: 'login',
	patient: 'patient',
	patientList: 'patient-list',
	service: 'service',
	task: 'task'
}

export const routeParams = {
	patientId: 'patientId',
	patientListId: 'patientListId',
	serviceId: 'serviceId',
	taskId: 'taskId'
}

export const routes: Routes = [
	{ path: routePaths.login, canActivate: [canActivateLogin], loadComponent: () => import('./pages/login/login.page').then((m) => m.LoginPage) },
	{
		path: '',
		loadComponent: () => import('./pages/home/home.page').then((m) => m.HomePage),
		canActivate: [canActivateHome],
		children: [
			{ path: `${routePaths.patient}/:${routeParams.patientId}`, loadComponent: () => import('./pages/patient/patient.page').then((m) => m.PatientPage) },
			{ path: `${routePaths.patientList}/:${routeParams.patientListId}`, loadComponent: () => import('./pages/patient-list/patient-list.page').then((m) => m.PatientListPage) },
			{ path: `${routePaths.patientList}/:${routeParams.patientListId}/${routePaths.patient}/:${routeParams.patientId}`, loadComponent: () => import('./pages/patient/patient.page').then((m) => m.PatientPage) },
			{ path: `${routePaths.service}/:${routeParams.serviceId}`, loadComponent: () => import('./pages/service/service.page').then((m) => m.ServicePage) },
			{ path: `${routePaths.service}/:${routeParams.serviceId}/${routePaths.patient}/:${routeParams.patientId}/${routePaths.task}/:${routeParams.taskId}`, loadComponent: () => import('./pages/patient/patient.page').then((m) => m.PatientPage) },
			{ path: '**', loadComponent: () => import('./pages/page-not-found/page-not-found.page').then((m) => m.PageNotFoundPage) }
		]
	}
];