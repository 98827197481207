<p class="form-control-label validation-group-label">{{ label }}</p>
@for (formGroup of formArray.controls; track formGroup; let index = $index; let first = $first) {
	<app-textarea hideEmptyValue class="validatable-control" debounce [readOnly]="readOnly" [placeholder]="getPlaceholder(formGroup.value.id)" [label]="formGroup.value.label"
				  [formControl]="getNoteControl(formGroup)" (click)="controlClicked()" @enterLeave [@.disabled]="readOnly">
		@if (!readOnly) {
			@if (!first) {
				<app-svg-icon class="button" name="arrow-up" (click)="moveUp(index)"></app-svg-icon>
			}
			<app-svg-icon class="button" name="close-sharp" (click)="removeItem(index)"></app-svg-icon>
		}
	</app-textarea>
}
@if (!readOnly) {
	<app-item class="rounded-border-box add" (click)="presentAddItemModal()" @enterLeave>
		<app-svg-icon name="add-circle-outline"></app-svg-icon>
		<app-item-label>{{ addLabel }}</app-item-label>
	</app-item>
}