import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { UnitValue } from '../form-controls/unit-value/unit-value.control';

// this validator is specifically for validating the unit value control. It validates that the control value (UnitValue object) is not null,
// and that it has both a unit and a value specified

export function unitValueRequired(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {

		if (control.value === null) return { unitValueRequired: true };

		const unitValue = control.value as UnitValue;
		if (unitValue.unit === null || unitValue.value === null) return { unitValueRequired: true, unitPresent: unitValue.unit !== null, valuePresent: unitValue.value !== null };

		return null;
	}
};