import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import { routePaths } from '../app.routes';
import { loginRequired } from '../store/auth/auth.actions';
import { hasRefreshToken } from '../store/auth/auth.selectors';
import { AppState } from '../store/reducers';

export const canActivateHome: CanActivateFn = (activatedRoute: ActivatedRouteSnapshot, routerState: RouterStateSnapshot) => {
	var store = inject(Store<AppState>);
	var navController = inject(NavController);
	return store.select(hasRefreshToken).pipe(
		take(1),
		tap(hasRefreshToken => {
			if (!hasRefreshToken) {
				navController.navigateRoot([`/${routePaths.login}`])
				store.dispatch(loginRequired({ requestedUrl: routerState.url }));
			}
		})
	);
};