import { createReducer, on } from '@ngrx/store';
import { SearchedPatient } from 'src/app/services/patients.service';
import { Team } from 'src/app/types/aggregate-graph.types';
import * as authActions from '../auth/auth.actions';
import * as userGraphActions from './user-graph.actions';

export interface State {
	accountDeactivated: boolean;
	clientSessionId: string;
	userId: number;
	fullName: string;
	initials: string;
	isSystemAdministrator: boolean;
	isClinician: boolean;
	isStudent: boolean;
	patientListId: string;
	selectedTeamIds: string[];
	searchedPatients: SearchedPatient[];
	teams: Team[];
}

export const initialState: State = {
	accountDeactivated: false,
	clientSessionId: '',
	userId: 0,
	fullName: '',
	initials: '',
	isSystemAdministrator: false,
	isClinician: false,
	isStudent: false,
	patientListId: '',
	selectedTeamIds: [],
	searchedPatients: [],
	teams: []
}

export const userGraphReducer = createReducer(
	initialState,
	on(userGraphActions.userGraphLoaded, (state, { user, teams }) => ({
		...state,
		accountDeactivated: !user.isActive,
		userId: user.userId,
		fullName: user.fullName,
		initials: user.initials,
		isSystemAdministrator: user.isSystemAdministrator,
		isClinician: user.isClinician,
		isStudent: user.isStudent,
		patientListId: user.patientListId,
		selectedTeamIds: user.selectedTeamIds,
		searchedPatients: user.searchedPatients,
		teams: teams
	})),
	on(userGraphActions.userUpdated, (state, { partialUser }) => ({
		...state,
		...partialUser,
		accountDeactivated: partialUser.isActive === undefined ? state.accountDeactivated : !partialUser.isActive,
	})),
	on(userGraphActions.teamUpdated, (state, { partialTeam }) => {
		const teamToUpdate = state.teams.find(t => t.id === partialTeam.id);
		if (teamToUpdate === undefined) return { ...state };
		return {
			...state,
			teams: [...state.teams.filter(t => t.id !== partialTeam.id), { ...teamToUpdate, ...partialTeam }]
		};
	}),
	on(userGraphActions.teamAdded, (state, { team }) => ({
		...state,
		teams: [...state.teams, team]
	})),
	on(userGraphActions.teamSelected, (state, { teamId }) => ({
		...state,
		selectedTeamIds: state.selectedTeamIds.includes(teamId) ? state.selectedTeamIds : [...state.selectedTeamIds, teamId]
	})),
	on(userGraphActions.teamDeselected, (state, { teamId }) => ({
		...state,
		selectedTeamIds: state.selectedTeamIds.includes(teamId) ? state.selectedTeamIds.filter(id => id !== teamId) : state.selectedTeamIds
	})),
	on(userGraphActions.searchedPatientAdded, (state, { patient }) => ({
		...state,
		searchedPatients: [patient, ...state.searchedPatients.filter(p => p.patientId !== patient.patientId)].slice(0, 10)
	})),
	on(authActions.clearStateRequested, () => initialState)
);