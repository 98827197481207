import { createAction, props } from '@ngrx/store';
import { SearchedUser } from 'src/app/services/users.service';
import { VisitParticipationType } from 'src/app/types/aggregate-graph.types';

export enum UserSearchActionTypes {
	NewSearchRequested = '[User Search Modal] New Search Requested',
	NewSearchSucceeded = '[User Search Effect] New Search Succeeded',
	NewSearchFailed = '[User Search Effect] New Search Failed',
	AppendResultsRequested = '[User Search Modal] Append Results Requested',
	AppendResultsSucceeded = '[User Search Effect] Append Results Succeeded',
	AppendResultsFailed = '[User Search Effect] Append Results Failed',
	ResultsCleared = '[User Search Modal] Results Cleared',
	UserSelectionFinished = '[User Search Effect] User Selection Finished'
}

export const newSearchRequested = createAction(
	UserSearchActionTypes.NewSearchRequested,
	props<{ queryTerm: string, participationTypeFilters: VisitParticipationType[] }>()
)

export const newSearchSucceeded = createAction(
	UserSearchActionTypes.NewSearchSucceeded,
	props<{ searchResults: SearchedUser[] }>()
)

export const newSearchFailed = createAction(
	UserSearchActionTypes.NewSearchFailed,
	props<{ statusCode: number }>()
)

export const appendResultsRequested = createAction(
	UserSearchActionTypes.AppendResultsRequested
)

export const appendResultsSucceeded = createAction(
	UserSearchActionTypes.AppendResultsSucceeded,
	props<{ searchResults: SearchedUser[] }>()
)

export const appendResultsFailed = createAction(
	UserSearchActionTypes.AppendResultsFailed,
	props<{ statusCode: number }>()
)

export const resultsCleared = createAction(
	UserSearchActionTypes.ResultsCleared
)

export const userSelectionFinished = createAction(
	UserSearchActionTypes.UserSelectionFinished
)