import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { addDays, formatISO, isAfter, subSeconds } from 'date-fns';
import { EMPTY, bufferCount, catchError, concatMap, delay, exhaustMap, expand, filter, from, map, mergeMap, of, switchMap, take } from 'rxjs';
import { stackedGridItemSwitchedDuration } from 'src/app/animations/common.animations';
import { ItemSearchModal } from 'src/app/pages/patient/components/sheet-panel/components/sheet-grid/modals/item-search/item-search.modal';
import { SheetActionEditorModal } from 'src/app/pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-action-editor/sheet-action-editor.modal';
import { SheetItemEditorModal } from 'src/app/pages/patient/components/sheet-panel/components/sheet-grid/modals/sheet-item-editor/sheet-item-editor.modal';
import { ErrorService } from 'src/app/services/error.service';
import { SheetsService } from 'src/app/services/sheets.service';
import { SheetViewSelector, SheetViewStatus } from 'src/app/types/sheet.types';
import { clientErrored } from '../error/error.actions';
import { patientGraphLoaded } from '../patient-graph/patient-graph.actions';
import { currentVisitPayload, patientPayload } from '../patient-graph/patient-graph.selectors';
import { AppState } from '../reducers';
import { rowChanged, sheetResized } from '../sheet-graph/sheet-graph.actions';
import { sheet, sheetId } from '../sheet-graph/sheet-graph.selectors';
import { searchedSheetItemSelected } from '../sheet-item-search/sheet-item-search.actions';
import * as sheetActions from './sheet.actions';
import { getSheetEndDate } from './sheet.reducer';
import { loadedActionPayload, selectedDate, selectedGranularity, sheetActionNotes, sheetEndDate, sheetResizeActive, viewSelector, viewStatus } from './sheet.selectors';


@Injectable()
export class SheetEffects {

	constructor(
		private actions$: Actions, private modalController: ModalController, private sheetsService: SheetsService, private errorService: ErrorService, private store: Store<AppState>, private alertController: AlertController) {
	}

	patientGraphLoaded$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientGraphLoaded),
			concatLatestFrom(() => this.store.select(currentVisitPayload)),
			filter(([, currentVisitPayload]) => !!currentVisitPayload.visit),
			map(([, currentVisitPayload]) => sheetActions.sheetLoadInitiated({ visitId: currentVisitPayload.visit!.id, sheetId: currentVisitPayload.visit!.sheetId }))
		)
	);

	selectDayRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetActions.selectDayRequested),
			concatLatestFrom(() => [this.store.select(sheetEndDate), this.store.select(sheetId)]),
			map(([payload, sheetEndDate, sheetId]) => ({ requestedDate: payload.date, sheetEndDate, sheetId })),
			delay(stackedGridItemSwitchedDuration), // wait for stacked-grid animations to complete
			map(payload => {
				if (isAfter(payload.requestedDate, payload.sheetEndDate)) {
					return sheetActions.updateScheduledUntilDateRequested({ sheetId: payload.sheetId, requestedDate: payload.requestedDate });
				}
				return sheetActions.selectDayCompleted({ date: payload.requestedDate });
			})
		)
	);

	updateScheduledUntilDateRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetActions.updateScheduledUntilDateRequested),
			concatMap((payload) => this.sheetsService.updateScheduledUntilDate(payload.sheetId, formatISO(addDays(subSeconds(payload.requestedDate, 1), 1))).pipe(
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Update scheduled until date failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		),
		{ dispatch: false }
	);

	sheetResized$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetResized),
			concatLatestFrom(() => [this.store.select(sheetResizeActive), this.store.select(sheet)]),
			filter(([, sheetResizeActive, sheet]) => sheetResizeActive && !!sheet),
			map(([payload, , sheet]) => ({ rowCount: sheet?.rows.length ?? 0, sheetEndDate: getSheetEndDate(sheet?.sheetStartDate!, payload.partialSheet.slotsPerDay?.length!) })),
			switchMap(data => {
				if (data.rowCount === 0) return of(sheetActions.updateScheduledUntilDateCompleted({ sheetEndDate: data.sheetEndDate }));
				// wait for all the rows to be received
				return this.actions$.pipe(
					ofType(rowChanged),
					bufferCount(data.rowCount),
					map(() => sheetActions.updateScheduledUntilDateCompleted({ sheetEndDate: data.sheetEndDate }))
				);
			})
		)
	);

	presentItemSearchModalRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetActions.presentItemSearchModalRequested),
			concatLatestFrom(() => this.store.select(sheet)),
			map(([, sheet]) => {
				if (sheet?.isFinalised) return sheetActions.finalisedSheetEditAttempted();
				return sheetActions.presentItemSearchModalAllowed();
			})
		)
	);

	presentItemSearchModalAllowed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetActions.presentItemSearchModalAllowed),
			switchMap(() => from(this.modalController.create({
				component: ItemSearchModal,
				backdropDismiss: false
			})).pipe(
				map(modal => modal.present()))
			)
		),
		{ dispatch: false }
	);

	searchedSheetItemSelected$ = createEffect(() =>
		this.actions$.pipe(
			ofType(searchedSheetItemSelected),
			concatLatestFrom(() => this.store.select(sheet)),
			switchMap(([payload, sheet]) => from(this.modalController.create({
				component: SheetItemEditorModal,
				componentProps: {
					instructionTypeName: payload.instructionTypeName,
					item: null,
					minDate: sheet!.sheetStartDate,
					maxDate: sheet!.maxScheduledUntilDate
				},
				backdropDismiss: false
			})).pipe(
				map(modal => modal.present()),
				switchMap(() => of(sheetActions.loadSheetItemEditorCompleted()))
			))
		)
	);

	addSheetItemInstructionsRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetActions.addSheetItemInstructionsRequested),
			concatLatestFrom(() => this.store.select(sheetId)),
			mergeMap(([payload, sheetId]) => this.sheetsService.addItemInstructions(sheetId, payload.instructions).pipe(
				mergeMap((itemId) => {
					const actions: any[] = [];
					if (payload.itemProperties.name !== null || payload.itemProperties.notes !== null) {
						actions.push(sheetActions.setItemPropertiesRequested({ sheetId: sheetId, itemId: itemId, itemProperties: payload.itemProperties }));
					}
					actions.push(sheetActions.addSheetItemInstructionsCompleted());
					return actions;
				}),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Add sheet item failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	setItemPropertiesRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetActions.setItemPropertiesRequested),
			switchMap((payload) => this.sheetsService.setItemProperties(payload.sheetId, payload.itemId, payload.itemProperties).pipe(
				map(() => sheetActions.setItemPropertiesCompleted()),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Set item properties failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	loadSheetItemEditorRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetActions.loadSheetItemEditorRequested),
			concatLatestFrom(() => this.store.select(sheet)),
			switchMap(([payload, sheet]) => {
				if (sheet?.isFinalised) return of(sheetActions.finalisedSheetEditAttempted());
				return from(this.modalController.create({
					component: SheetItemEditorModal,
					componentProps: {
						sheetId: sheet!.id,
						instructionTypeName: payload.item.instructionTypeName,
						item: payload.item,
						minDate: sheet!.sheetStartDate,
						maxDate: sheet!.maxScheduledUntilDate
					},
					backdropDismiss: false
				})).pipe(
					map(modal => modal.present()),
					switchMap(() => of(sheetActions.loadSheetItemEditorCompleted()))
				)
			}))
	);

	updateSheetItemInstructionsRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetActions.updateSheetItemInstructionsRequested),
			concatLatestFrom(() => this.store.select(sheetId)),
			mergeMap(([payload, sheetId]) => this.sheetsService.updateItemInstructions(sheetId, payload.itemId, payload.instructions).pipe(
				mergeMap(() => {
					const actions: any[] = [];
					if (payload.itemProperties.name !== null || payload.itemProperties.notes !== null) {
						actions.push(sheetActions.setItemPropertiesRequested({ sheetId: sheetId, itemId: payload.itemId, itemProperties: payload.itemProperties }));
					}
					actions.push(sheetActions.updateSheetItemInstructionsCompleted());
					return actions;
				}),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Update sheet item instructions failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	loadSheetSlotEditorRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetActions.loadSheetSlotEditorRequested),
			concatLatestFrom(() => this.store.select(sheet)),
			switchMap(([, sheet]) => {
				if (sheet?.isFinalised) return of(sheetActions.finalisedSheetEditAttempted());
				return from(this.modalController.create({
					component: SheetActionEditorModal,
					backdropDismiss: false
				})).pipe(
					map(modal => modal.present()),
					switchMap(() => of(sheetActions.loadSheetSlotEditorCompleted()))
				)
			}))
	);

	saveSheetSlotActionRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetActions.saveSheetSlotActionRequested),
			concatLatestFrom(() => [this.store.select(sheetId), this.store.select(selectedGranularity)]),
			concatMap(([payload, sheetId, selectedGranularity]) => {
				if (payload.isAddition) {
					return this.sheetsService.addSlotAction(sheetId, payload.rowId, payload.action, selectedGranularity).pipe(
						map(actionId => sheetActions.addSheetSlotActionCompleted({ actionId })),
						catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Add sheet action failed', errorMessage: this.errorService.getErrorMessage(error) })))
					);
				}
				return of({ payload, sheetId }).pipe(
					// expand will recurse until EMPTY is returned (more precisely, until the observable that is fed back into expand completes, which EMPTY does, it completes immediately)
					expand(({ payload, sheetId }) => {
						if (payload.action.id !== null && payload.action.id !== -1) {
							// the action has a valid id, no need to recurse
							return EMPTY;
						}
						// recurse if the action id is not set
						return this.store.select(loadedActionPayload).pipe(
							take(1),
							switchMap(loadedActionPayload => {
								if (!loadedActionPayload.accessToken) return EMPTY; // guard against user being logged out (otherwise this will recurse forever)
								if (loadedActionPayload.selectedActionId === null) {
									// recurse if the id is not loaded
									return of(({ payload, sheetId })).pipe(delay(5000));
								}
								return of({ payload: { ...payload, action: { ...payload.action, id: loadedActionPayload.selectedActionId } }, sheetId });
							})
						)
					}),
					// because expand emits to the output as well as recursing to the input, need filter out emitted values until the action id is set
					filter(({ payload }) => payload.action.id !== null && payload.action.id !== -1),
					switchMap(({ payload, sheetId }) => this.sheetsService.updateSlotAction(sheetId, payload.rowId, payload.action).pipe(
						map(() => sheetActions.updateSheetSlotActionCompleted()),
						catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Update sheet action failed', errorMessage: this.errorService.getErrorMessage(error) })))
					))
				);
			})
		)
	);

	removeSheetItemRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetActions.removeSheetItemRequested),
			mergeMap(payload => this.sheetsService.removeItem(payload.sheetId, payload.itemId).pipe(
				map(() => sheetActions.removeSheetItemCompleted()),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Remove sheet item failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	viewSelectorChanged$ = createEffect(() =>
		this.store.select(viewSelector).pipe(
			concatLatestFrom(() => this.store.select(sheetId)),
			filter(([, sheetId]) => !!sheetId),
			map(([viewSelector, sheetId]) => {
				if (viewSelector === SheetViewSelector.ActionNotes) return sheetActions.loadSheetActionNotesRequested({ sheetId: sheetId })
				else return sheetActions.sheetActionNotesCleared();
			})
		)
	);

	changeViewStatusRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetActions.changeViewStatusRequested),
			concatLatestFrom(() => [this.store.select(sheet), this.store.select(sheetActionNotes)]),
			map(([payload, sheet, actionNotes]) => sheetActions.changeViewStatusCompleted({ viewStatus: payload.viewSelector === SheetViewSelector.Sheet ? (sheet ? SheetViewStatus.Sheet : SheetViewStatus.NoSheet) : (actionNotes?.length ? SheetViewStatus.ActionNotes : SheetViewStatus.NoActionNotes) }))
		)
	);

	selectedDateChanged$ = createEffect(() =>
		this.store.select(selectedDate).pipe(
			concatLatestFrom(() => [this.store.select(sheetId), this.store.select(viewStatus)]),
			filter(([sheetId, , selectedView]) => !!sheetId && selectedView === SheetViewStatus.ActionNotes),
			map(([, sheetId]) => sheetActions.loadSheetActionNotesRequested({ sheetId: sheetId }))
		)
	);

	loadSheetActionNotesRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetActions.loadSheetActionNotesRequested),
			concatLatestFrom(() => this.store.select(selectedDate)),
			concatMap(([request, selectedDate]) => this.sheetsService.getActionNotes(request.sheetId, selectedDate, formatISO(addDays(selectedDate, 1))).pipe(
				map((actionNotes) => sheetActions.loadSheetActionNotesCompleted({ actionNotes: actionNotes })),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Load sheet action notes failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	exportSheetRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetActions.exportSheetRequested),
			concatLatestFrom(() => [this.store.select(sheetId), this.store.select(selectedGranularity), this.store.select(patientPayload)]),
			concatMap(([, sheetId, granularity, patientPayload]) => this.sheetsService.exportSheet(sheetId, granularity).pipe(
				map((blob) => {
					if (blob.body) {
						const filename = patientPayload.patient!.caseNumber;
						const a = document.createElement('a');
						a.download = filename;
						a.href = window.URL.createObjectURL(blob.body);
						a.click();
						window.URL.revokeObjectURL(a.href);
						a.remove();
						return sheetActions.exportSheetCompleted();
					}
					return clientErrored({ toastMessage: 'Export sheet failed', errorMessage: 'Sheet blob was null' });
				}),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Export sheet failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	finalisedSheetEditAttempted$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetActions.finalisedSheetEditAttempted),
			exhaustMap(() => from(this.alertController.create({
				subHeader: 'Sheet editing is locked because the visit has ended.',
				buttons: ['Ok'],
				backdropDismiss: false
			})).pipe(
				switchMap(alert => from(alert.present()))
			))),
		{ dispatch: false }
	)
}
