import { createReducer, on } from '@ngrx/store';
import { clearStateRequested } from '../auth/auth.actions';
import * as patientActions from '../patient/patient.actions';
import * as patientTaskActions from './patient-task.actions';

export interface State {
	selectedTaskId: string;
	selectedTaskDocumentId: string;
}

export const initialState: State = {
	selectedTaskId: '',
	selectedTaskDocumentId: ''
}

export const patientTaskReducer = createReducer(
	initialState,
	on(patientTaskActions.subscribeVisitTaskRequested, (state, { taskId }) => ({
		...state,
		selectedTaskId: taskId,
		selectedTaskDocumentId: ''
	})),
	on(patientTaskActions.subscribedVisitTaskLoaded, (state, { taskDocumentId }) => ({
		...state,
		selectedTaskDocumentId: taskDocumentId
	})),
	on(patientTaskActions.addVisitTaskRequested, (state) => ({
		...state,
		selectedTaskId: '',
		selectedTaskDocumentId: ''
	})),
	on(patientTaskActions.addVisitTaskCompleted, (state, { taskId }) => ({
		...state,
		selectedTaskId: taskId
	})),
	on(patientTaskActions.addedVisitTaskLoaded, (state, { taskDocumentId }) => ({
		...state,
		selectedTaskDocumentId: taskDocumentId
	})),
	on(patientTaskActions.showLoadedVisitTaskRequested, (state, { taskId }) => ({
		...state,
		selectedTaskId: taskId,
		selectedTaskDocumentId: ''
	})),
	on(patientTaskActions.showLoadedVisitTaskCompleted, (state, { taskDocumentId }) => ({
		...state,
		selectedTaskDocumentId: taskDocumentId
	})),
	on(patientTaskActions.hideLoadedVisitTaskRequested, (state) => ({
		...state,
		selectedTaskId: '',
		selectedTaskDocumentId: ''
	})),
	on(patientTaskActions.visitTaskDocumentSelected, (state, { taskDocumentId }) => ({
		...state,
		selectedTaskDocumentId: taskDocumentId
	})),
	on(patientTaskActions.visitTaskDocumentDeselected, (state) => ({
		...state,
		selectedTaskDocumentId: ''
	})),
	on(patientTaskActions.copyVisitTaskRequested, (state) => ({
		...state,
		selectedTaskId: '',
		selectedTaskDocumentId: ''
	})),
	on(patientTaskActions.copyVisitTaskCompleted, (state, { taskId }) => ({
		...state,
		selectedTaskId: taskId
	})),
	on(patientActions.patientDeselected, () => initialState),
	on(clearStateRequested, () => initialState)
);
