import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { merge } from 'rxjs';
import { TextAreaControl } from 'src/app/form-controls/textarea/textarea.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { SoapAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface SoapActionFormGroup {
	subjective: FormControl<string | null>;
	objective: FormControl<string | null>;
	assessment: FormControl<string | null>;
	plan: FormControl<string | null>;
	notes: FormControl<string | null>;
}

@Component({
	selector: 'app-soap-action-controls',
	templateUrl: './soap-action-controls.component.html',
	styleUrls: ['../../../common.scss', './soap-action-controls.component.scss'],
	standalone: true,
	imports: [ReactiveFormsModule, TextAreaControl, CommonActionControlsComponent, ActionFormComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SoapActionControlsComponent implements SheetActionControlsBase, OnInit {

	slot!: Slot | null;
	action!: SoapAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as SoapAction;
	}

	@Input() form!: FormGroup<SoapActionFormGroup>;
	@Input() item!: Row | null;

	constructor(private formBuilder: FormBuilder, private destroyRef: DestroyRef) {
	}

	ngOnInit() {
		const subjectiveControl = this.formBuilder.control(this.action?.subjective ?? null);
		const objectiveControl = this.formBuilder.control(this.action?.objective ?? null);
		const assessmentControl = this.formBuilder.control(this.action?.assessment ?? null);
		const planControl = this.formBuilder.control(this.action?.plan ?? null);

		this.form.addControl('subjective', subjectiveControl);
		this.form.addControl('objective', objectiveControl);
		this.form.addControl('assessment', assessmentControl);
		this.form.addControl('plan', planControl);

		// for the notes view, the hidden Notes form property is updated with the combined values of the appropriate displayed controls
		merge(
			subjectiveControl.valueChanges,
			objectiveControl.valueChanges,
			assessmentControl.valueChanges,
			planControl.valueChanges
		).pipe(
			takeUntilDestroyed(this.destroyRef)
		).subscribe(() => {
			const notesProps: string[] = [];
			if (subjectiveControl.value) notesProps.push(subjectiveControl.value);
			if (objectiveControl.value) notesProps.push(objectiveControl.value);
			if (assessmentControl.value) notesProps.push(assessmentControl.value);
			if (planControl.value) notesProps.push(planControl.value);
			this.form.get('notes')?.setValue(notesProps.join('\n\n'));
		});
	}


}