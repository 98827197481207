import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'app-tick-value',
	templateUrl: './tick-value.component.html',
	styleUrl: './tick-value.component.scss',
	standalone: true,
	imports: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TickValueComponent {

	@Input() value!: string | null;

	constructor() {
	}
}