import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { provideEffects } from '@ngrx/effects';
import { NavigationActionTiming, provideRouterStore } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { GlobalErrorHandler } from './app/global-error-handler';
import { AuthInterceptor } from './app/interceptors/auth.interceptor';
import { ClientInterceptor } from './app/interceptors/client.interceptor';
import { RetryInterceptor } from './app/interceptors/retry.interceptor';
import { effectsServices } from './app/store/effects';
import { metaReducers } from './app/store/meta-reducers';
import { reducers } from './app/store/reducers';
import { environment } from './environments/environment';

if (environment.production) {
	enableProdMode();
}

bootstrapApplication(AppComponent, {
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ClientInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
		provideHttpClient(
			withInterceptorsFromDi() // todo: replace the class based interceptors with functional interceptors (details here: https://blog.herodevs.com/angular-15-introduces-functional-http-interceptors-59299cce60bf)
		),
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: LOCALE_ID, useValue: 'en-GB' },
		{ provide: ErrorHandler, useClass: GlobalErrorHandler },
		provideIonicAngular({ mode: 'md', spinner: 'bubbles', innerHTMLTemplatesEnabled: true }),
		provideRouter(routes),
		provideStore(reducers, {
			metaReducers: metaReducers,
			// all checks are automatically disabled in production builds, as per ngrx docs (https://ngrx.io/guide/store/configuration/runtime-checks)
			runtimeChecks: {
				strictStateImmutability: true,
				strictActionImmutability: true,
				strictStateSerializability: true,
				strictActionSerializability: true,
				strictActionWithinNgZone: true,
				strictActionTypeUniqueness: true
			}
		}),
		provideRouterStore({ navigationActionTiming: NavigationActionTiming.PostActivation }), // ROUTER_NAVIGATION is only called after resolvers & guards
		provideEffects(effectsServices),
		provideStoreDevtools({ maxAge: 100, logOnly: environment.production }),
		provideAnimations()
	]
}).catch(error => console.log('CRIS bootstrap error', error));