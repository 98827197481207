import { createAction, props } from '@ngrx/store';

export enum ErrorActionTypes {
	ClientErrored = '[Effect] Client Errored',
	SignalRErrored = '[Effect] Signal R Errored',
	HttpRequestErrored = '[Effect] Http Request Errored'
}

export const clientErrored = createAction(
	ErrorActionTypes.ClientErrored,
	props<{ toastMessage: string, errorMessage: string }>()
)

export const signalRErrored = createAction(
	ErrorActionTypes.SignalRErrored,
	props<{ errorMessage: string }>()
)

export const httpRequestErrored = createAction(
	ErrorActionTypes.HttpRequestErrored,
	props<{ statusCode: number, requestUrl: string }>()
)