import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, pairwise, startWith, tap } from 'rxjs/operators';
import { routePaths } from 'src/app/app.routes';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { ErrorService } from 'src/app/services/error.service';
import { GraphAggregateChangeOperation, Team, TeamTypeName, User, UserTypeName } from 'src/app/types/aggregate-graph.types';
import { connectHubCompleted } from '../aggregate-graph-hub/aggregate-graph-hub.actions';
import { logoutInitiated } from '../auth/auth.actions';
import { requestedUrl } from '../auth/auth.selectors';
import { clientErrored } from '../error/error.actions';
import { searchedPatientSelected } from '../menu-selection/menu-selection.actions';
import { AppState } from '../reducers';
import * as userGraphActions from './user-graph.actions';
import { accountDeactivated, patientListId, selectedTeams, userId } from './user-graph.selectors';

@Injectable()
export class UserGraphEffects {

	constructor(
		private actions$: Actions,
		private navController: NavController,
		private currentUserService: CurrentUserService,
		private store: Store<AppState>,
		private alertController: AlertController,
		private errorService: ErrorService) {
	}

	subscribeUserInitiated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(connectHubCompleted, userGraphActions.userGraphSystemRestarted),
			map(() => userGraphActions.subscribeUserRequested())
		)
	);

	userGraphGenerated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(userGraphActions.userGraphGenerated),
			map(action =>
				userGraphActions.userGraphLoaded({
					user: action.aggregates.find(agg => agg.aggregateType === UserTypeName) as User,
					teams: action.aggregates.filter(agg => agg.aggregateType === TeamTypeName) as Team[]
				})
			),
			catchError(error => of(clientErrored({ toastMessage: 'User graph load failed', errorMessage: this.errorService.getErrorMessage(error) })))
		)
	);

	userGraphChanged$ = createEffect(() =>
		this.actions$.pipe(
			ofType(userGraphActions.userGraphChanged),
			concatMap(action => {
				const actions: any[] = [];
				action.changes.forEach(change => {
					// guard against b/e not sending the operation
					if (!change.operation) throw new Error('Error processing userGraphChanged action: operation is missing');

					// user can only be updated
					if (change.aggregate.aggregateType === UserTypeName && change.operation === GraphAggregateChangeOperation.Update) {
						actions.push(userGraphActions.userUpdated({ partialUser: change.aggregate as User }));
					}
					// team can be updated or added
					else if (change.aggregate.aggregateType === TeamTypeName) {
						if (change.operation === GraphAggregateChangeOperation.Update) {
							actions.push(userGraphActions.teamUpdated({ partialTeam: change.aggregate as Team }));
						}
						else if (change.operation === GraphAggregateChangeOperation.Addition) {
							actions.push(userGraphActions.teamAdded({ team: change.aggregate as Team }));
						}
					}
				});
				return actions;
			}),
			catchError(error => of(clientErrored({ toastMessage: 'User graph change failed', errorMessage: this.errorService.getErrorMessage(error) })))
		)
	);

	userLoaded$ = createEffect(() =>
		this.store.select(userId).pipe(
			startWith(false),
			pairwise(),
			filter(([wasLoaded, isLoaded]) => wasLoaded !== isLoaded && isLoaded),
			concatLatestFrom(() => [this.store.select(selectedTeams), this.store.select(patientListId), this.store.select(requestedUrl)]),
			tap(([, selectedTeams, patientListId, requestedUrl]) => this.navController.navigateRoot(
				requestedUrl && requestedUrl !== '/' ? requestedUrl : (
					selectedTeams.length === 0 ? [`/${routePaths.patientList}/${patientListId}`] : [`/${routePaths.service}/${selectedTeams[0].id}`]
				))
			)
		),
		{ dispatch: false }
	);

	selectTeamRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(userGraphActions.selectTeamRequested),
			map(payload => payload.teamId),
			concatLatestFrom(() => this.store.pipe(select(selectedTeams))),
			filter(([teamId, selectedTeams]) => !selectedTeams.some(t => t.id === teamId)),
			concatMap(([teamId]) => this.currentUserService.putSelectedTeam(teamId).pipe(
				map(() => userGraphActions.teamSelected({ teamId: teamId })),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Select team error', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	deselectTeamRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(userGraphActions.deselectTeamRequested),
			map(payload => payload.teamId),
			concatMap(teamId => this.currentUserService.deleteSelectedTeam(teamId).pipe(
				map(() => userGraphActions.teamDeselected({ teamId: teamId })),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Deselect team error', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	searchedPatientSelected$ = createEffect(() =>
		this.actions$.pipe(
			ofType(searchedPatientSelected),
			mergeMap(payload => this.currentUserService.putSearchedPatient(payload.patient.patientId).pipe(
				map(() => userGraphActions.searchedPatientAdded({ patient: payload.patient })),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Save patient error', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	userDeactivated$ = createEffect(() =>
		this.store.select(accountDeactivated).pipe(
			filter(accountDeactivated => accountDeactivated),
			map(() => logoutInitiated()),
			tap(async () => {
				const alert = await this.alertController.create({
					subHeader: 'Your CRIS account has been deactivated',
					message: 'Please contact the IT Helpdesk if you need to restore access',
					buttons: ['Ok'],
					backdropDismiss: false
				});
				alert.present();
			})
		)
	);

	saveUserLoginPinRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(userGraphActions.saveUserLoginPinRequested),
			mergeMap(payload => this.currentUserService.setLoginPin(payload.pin).pipe(
				map(() => userGraphActions.saveUserLoginPinSucceeded()),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Save pin error', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);
}