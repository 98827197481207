@for (formGroup of formArray.controls; track formGroup; let index = $index; let count = $count) {
	<div class="form-group" @enterLeave [@.disabled]="readOnly">
		<app-heading background="secondary-2" size="small">
			{{ getGroupHeading(index) }}
			@if (!readOnly && count > 1) {
				<app-svg-icon name="close-sharp" (click)="removeGroup(index)"></app-svg-icon>
			}
		</app-heading>
		@for (control of metaData.controls; track control.id; let index = $index) {
			@switch (control.type) {
				@case ('text-input') {
					<app-text-input class="validatable-control" [readOnly]="readOnly" [label]="control.metaData.label!" [placeholder]="control.metaData.placeholder!"
									[formControl]="getFormControl(formGroup, control.id)" (click)="controlClicked()">
					</app-text-input>
				}
				@case ('text-area') {
					<app-textarea class="validatable-control" [readOnly]="readOnly" [label]="control.metaData.label!" [placeholder]="control.metaData.placeholder!" debounce
								  [formControl]="getFormControl(formGroup, control.id)" (click)="controlClicked()">
					</app-textarea>
				}
				@case ('checkbox') {
					<app-checkbox class="validatable-control" [readOnly]="readOnly" [label]="control.metaData.label!"
								  [formControl]="getFormControl(formGroup, control.id)" (click)="controlClicked()">
					</app-checkbox>
				}
				@case ('select') {
					<app-select class="validatable-control" [readOnly]="readOnly" [label]="control.metaData.label!" [placeholder]="control.metaData.placeholder!"
								[items]="control.metaData.items!" [formControl]="getFormControl(formGroup, control.id)" (click)="controlClicked()">
					</app-select>
				}
				@case ('multi-checkbox') {
					<app-multi-checkbox class="validatable-group" [readOnly]="readOnly" [label]="control.metaData.label!" [subLabel]="control.metaData.subLabel!"
										[items]="control.metaData.items!" [minItems]="control.metaData.validators?.required ? 1 : 0"
										[formControl]="getFormControl(formGroup, control.id)" (click)="controlClicked()">
					</app-multi-checkbox>
				}
				@case ('multi-item-picker') {
					<app-multi-item-picker class="validatable-group" [readOnly]="readOnly" [label]="control.metaData.label!" [items]="control.metaData.items!"
										   [minItems]="control.metaData.validators?.required ? 1 : 0" [formControl]="getFormControl(formGroup, control.id)" (click)="controlClicked()">
					</app-multi-item-picker>
				}
				@case ('listy-picker') {
					<app-modality-body-parts-picker class="validatable-group" [readOnly]="readOnly" [formControl]="getFormControl(formGroup, control.id)" (click)="controlClicked()">
					</app-modality-body-parts-picker>
				}
				@default {
					<div style="border: 1px solid red"><strong>{{ control.type }}</strong> not implemented<br/>{{ control | json }} ({{control.id}})</div>
				}
			}
		}
	</div>
}
@if (!readOnly) {
	<app-item (click)="appendGroup(true)">
		<app-item-value [value]="metaData.addLabel"></app-item-value>
		<app-svg-icon name="add-circle-outline"></app-svg-icon>
	</app-item>
}