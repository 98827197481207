import { createSelector } from '@ngrx/store';
import { differenceInDays } from 'date-fns';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { accessToken } from '../auth/auth.selectors';
import { visitsByVisitNumberDesc } from '../patient-graph/patient-graph.selectors';
import { AppState } from '../reducers';
import { sheet } from '../sheet-graph/sheet-graph.selectors';

export const selectGranularityChangeActive = (state: AppState) => state.sheet.granularityChangeActive;
export const selectSelectedGranularity = (state: AppState) => state.sheet.selectedGranularity;
export const selectGranularSlotNumber = (state: AppState) => state.sheet.granularSlotNumber;
export const selectSheetEndDate = (state: AppState) => state.sheet.sheetEndDate;
export const selectSelectorDate = (state: AppState) => state.sheet.selectorDate;
export const selectSelectedDate = (state: AppState) => state.sheet.selectedDate;
export const selectSelectedVisitId = (state: AppState) => state.sheet.selectedVisitId;
export const selectViewStatus = (state: AppState) => state.sheet.viewStatus;
export const selectViewSelector = (state: AppState) => state.sheet.viewSelector;
export const selectSheetActionNotes = (state: AppState) => state.sheet.actionNotes;
export const selectSelectedItemId = (state: AppState) => state.sheet.selectedItemId;
export const selectSelectedSlotIndex = (state: AppState) => state.sheet.selectedSlotIndex;
export const selectSelectedActionId = (state: AppState) => state.sheet.selectedActionId;
export const selectResizeActive = (state: AppState) => state.sheet.resizeActive;
export const selectGridChangeData = (state: AppState) => state.sheet.gridChangeData;

export const granularityChangeActive = createSelector(
	selectGranularityChangeActive,
	(isActive) => isActive
);

export const selectedGranularity = createSelector(
	selectSelectedGranularity,
	(granularity) => granularity
);

export const granularSlotNumber = createSelector(
	selectGranularSlotNumber,
	(slotNumber) => slotNumber
);

export const sheetEndDate = createSelector(
	selectSheetEndDate,
	(sheetEndDate) => sheetEndDate
);

export const selectorDate = createSelector(
	selectSelectorDate,
	(selectorDate) => selectorDate
);

export const selectedDate = createSelector(
	selectSelectedDate,
	(selectedDate) => selectedDate
);

const selectedSlotIndices = createSelector(
	sheet,
	selectedDate,
	(sheet, selectedDate) => {

		const daysToSkip = sheet ? differenceInDays(selectedDate, sheet.sheetStartDate) : 0;
		const startSlotIndex = sheet ? sheet.slotsPerDay.slice(0, daysToSkip).reduce((acc, val) => acc + val, 0) : 0;
		const endSlotIndex = sheet ? startSlotIndex + sheet.slotsPerDay[daysToSkip] : 0;

		return { startSlotIndex, endSlotIndex };
	}
);

export interface SelectedDatePayload {
	selectedDateSlotLabels: string[];
	selectedDateRows: Row[];
}

export const selectedDatePayload = createSelector(
	sheet,
	selectedSlotIndices,
	(sheet, selectedSlotIndices): SelectedDatePayload => {

		let selectedDateRows: Row[] = [];
		let selectedDateSlotLabels: string[] = [];

		if (sheet) {
			selectedDateSlotLabels = sheet.slotLabels.slice(selectedSlotIndices.startSlotIndex, selectedSlotIndices.endSlotIndex);
			selectedDateRows = sheet.rows.map(row => {
				const daySlots = getDaySlots(selectedSlotIndices.startSlotIndex, selectedSlotIndices.endSlotIndex, row.slots);
				const dayRow: Row = { ...row, slots: daySlots };
				return {
					row: dayRow,
					showRow: !row.isRemoved || daySlots.some(slot => !!slot?.action)
				}
			// todo: review this when we add template grouping and user sorting: .sort((a, b) => a.sortPosition - b.sortPosition);
			}).filter(vm => vm.showRow).map(vm => vm.row).sort((a, b) => {
				const nameA = (a.name ?? '').toUpperCase(); // ignore upper and lowercase
				const nameB = (b.name ?? '').toUpperCase(); // ignore upper and lowercase
				if (nameA < nameB) {
				  return -1;
				}
				if (nameA > nameB) {
				  return 1;
				}
				return 0;
			  });
		}

		return { selectedDateRows, selectedDateSlotLabels };
	}
);

const getDaySlots = (startSlotIndex: number, endSlotIndex: number, slots: (Slot | null)[]) => {

	// get the slots for the selected day
	const filteredSlots = slots.slice(startSlotIndex, endSlotIndex) ?? [];

	// remove any that are duplicated due to slotSpan
	// note: the server returns empty slots (e.g. 24 for a typical "hour" granularity day), even if other slots are multi span
	//		 E.g. 24 "hour" granularity slots, where one spans 2 hours, equals 25 hours! Need to remove them
	let indices: number[] = [];
	filteredSlots.forEach((slot, index) => { if (slot && slot.slotSpan > 1) indices.push(index) });
	indices.reverse().forEach(index => filteredSlots.splice(index + 1, (filteredSlots[index] as Slot).slotSpan - 1));

	// the last slot might have slotSpan > 1 that pushes into the next day, which would break the UI grid (making the item/row wrap)
	if (filteredSlots.length) {
		const lastSlot = filteredSlots[filteredSlots.length - 1];
		if (lastSlot && lastSlot.slotSpan > 1) {
			const slotsPerDay = endSlotIndex - startSlotIndex;
			const spannedSlotCountExclusiveOfLastSlotSpan = filteredSlots.slice(0, filteredSlots.length - 2).reduce((acc, slotOrNull) => acc + (slotOrNull?.slotSpan ?? 1), 0) + 1;
			const newSlotSpan = Math.min(lastSlot.slotSpan, slotsPerDay - spannedSlotCountExclusiveOfLastSlotSpan);
			filteredSlots.pop();
			filteredSlots.push({ ...lastSlot, slotSpan: newSlotSpan });
		}
	}

	// the ui needs to know the granular slot index for each "scheduled action" slot
	let granularSlotIndex = 0;
	for (let i = 0; i < filteredSlots.length; i++) {
		const slot = filteredSlots[i];
		if (slot && (slot.isScheduledAction || slot.isGranular)) {
			filteredSlots[i] = { ...slot, granularSlotIndex };
		}
		granularSlotIndex += slot?.slotSpan ?? 1;
	}

	return filteredSlots;
};

export const selectedSheetVisit = createSelector(
	selectSelectedVisitId,
	visitsByVisitNumberDesc,
	(selectedVisitId, visits) => visits.find(visit => visit.id === selectedVisitId) ?? null
);

export const selectedItem = createSelector(
	selectSelectedItemId,
	sheet,
	(selectedItemId, sheet) => sheet ? sheet.rows.find(r => r.id === selectedItemId) ?? null : null
);

export interface SelectedSlotPayload {
	selectedItem: Row | null;
	selectedSlot: Slot | null;
	defaultPerformedDate: string;
}

export const selectedSlotPayload = createSelector(
	selectedItem,
	sheet,
	selectSelectedSlotIndex,
	selectedSlotIndices,
	(selectedItem, sheet, selectedSlotIndex, selectedSlotIndices): SelectedSlotPayload => {
		return {
			selectedItem,
			selectedSlot: selectedItem && selectedSlotIndex !== null ? selectedItem.slots.slice(selectedSlotIndices.startSlotIndex, selectedSlotIndices.endSlotIndex)[selectedSlotIndex] : null,
			defaultPerformedDate: sheet && selectedSlotIndex !== null ? sheet.slotDefaultPerformedDates.slice(selectedSlotIndices.startSlotIndex, selectedSlotIndices.endSlotIndex)[selectedSlotIndex] : ''
		};
	}
);

export const loadedActionPayload = createSelector(
	selectSelectedActionId,
	accessToken,
	(selectedActionId, accessToken) => ({ selectedActionId, accessToken })
);

export const viewStatus = createSelector(
	selectViewStatus,
	(viewStatus) => viewStatus
);

export const viewSelector = createSelector(
	selectViewSelector,
	(viewStatus) => viewStatus
);

export const sheetActionNotes = createSelector(
	selectSheetActionNotes,
	(actionNotes) => actionNotes
);

export const sheetResizeActive = createSelector(
	selectResizeActive,
	(resizeActive) => resizeActive
);

export const gridChangeData = createSelector(
	selectGridChangeData,
	(gridChangeData) => gridChangeData
);