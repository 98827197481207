import { Injectable } from '@angular/core';
import { addDays, differenceInMonths, format, formatDistanceToNowStrict, isToday, isTomorrow, isYesterday, parseISO } from 'date-fns';

export interface DateParts {
	datePart: string;
	timePart: string;
}

@Injectable({
	providedIn: 'root'
})
export class DateService {

	constructor() {
	}

	public format(dateValue: string, dateFormat: string, offsetDays: number = 0): string {

		let date = parseISO(dateValue);

		if (offsetDays) date = addDays(date, offsetDays);

		return format(date, this.fixDateFormatStrings(dateFormat));
	}

	public formatAsDay(dateValue: string, fallbackDateFormat: string): string {

		const date = parseISO(dateValue);

		if (isToday(date)) return 'Today';
		if (isTomorrow(date)) return 'Tomorrow';
		if (isYesterday(date)) return 'Yesterday';

		return format(date, this.fixDateFormatStrings(fallbackDateFormat));
	}

	public formatAsDistance(dateValue: string | Date, omitSuffix: boolean = false): string {

		const date = dateValue instanceof Date ? dateValue : parseISO(dateValue);

		return formatDistanceToNowStrict(date, { addSuffix: !omitSuffix });
	}

	public differenceInMonthsToNow(dateValue: string): number {
		return differenceInMonths(new Date(), parseISO(dateValue));
	}

	// need to fix historic moment.js date formats
	private readonly matchRegex = /[YD]/g;
	private readonly charsToReplace: { [char: string]: string } = { Y: 'y', D: 'd' };

	private fixDateFormatStrings(dateFilter: string): string {
		return dateFilter.replace(this.matchRegex, c => this.charsToReplace[c]);
	}
}