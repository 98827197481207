import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, exhaustMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { UsersService } from 'src/app/services/users.service';
import { clientErrored } from '../error/error.actions';
import { AppState } from '../reducers';
import * as userSearchActions from './user-search.actions';
import { searchParams } from './user-search.selectors';

@Injectable()
export class UserSearchEffects {

	constructor(private actions$: Actions, private store: Store<AppState>, private usersService: UsersService) {
	}

	newSearchRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(userSearchActions.newSearchRequested),
			withLatestFrom(this.store.pipe(select(searchParams))),
			switchMap(([payload, searchParams]) => {
				return this.usersService.queryUsers(payload.queryTerm, payload.participationTypeFilters, searchParams.appendsRetrieved * searchParams.resultsPerAppend, searchParams.resultsPerAppend).pipe(
					map(searchResults => (userSearchActions.newSearchSucceeded({ searchResults: searchResults }))),
					catchError((error: HttpErrorResponse) => [
						userSearchActions.newSearchFailed({ statusCode: error.status }),
						clientErrored({ toastMessage: 'User search error', errorMessage: error.message })
					])
				)
			})
		)
	);

	appendResultsRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(userSearchActions.appendResultsRequested),
			withLatestFrom(this.store.pipe(select(searchParams))),
			exhaustMap(([, searchParams]) => this.usersService.queryUsers(searchParams.queryTerm, searchParams.participationTypeFilters, (searchParams.appendsRetrieved + 1) * searchParams.resultsPerAppend, searchParams.resultsPerAppend).pipe(
				map(searchResults => (userSearchActions.appendResultsSucceeded({ searchResults: searchResults }))),
				catchError((error: HttpErrorResponse) => [
					userSearchActions.appendResultsFailed({ statusCode: error.status }),
					clientErrored({ toastMessage: 'User search append error', errorMessage: error.message })
				])
			))
		)
	);
}