import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, exhaustMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { PatientsService } from 'src/app/services/patients.service';
import { clientErrored } from '../error/error.actions';
import * as patientSearchActions from './patient-search.actions';
import { AppState } from '../reducers';
import { searchParams } from './patient-search.selectors';

@Injectable()
export class PatientSearchEffects {

	constructor(
		private actions$: Actions,
		private store: Store<AppState>,
		private patientsService: PatientsService) {
	}

	newSearchRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientSearchActions.newSearchRequested),
			map(action => action.queryTerm),
			withLatestFrom(this.store.pipe(select(searchParams))),
			switchMap(([queryTerm, searchParams]) => this.patientsService.queryPatients(queryTerm, searchParams.appendsRetrieved * searchParams.resultsPerAppend, searchParams.resultsPerAppend).pipe(
				map(searchResults => (patientSearchActions.newSearchSucceeded({ searchResults: searchResults }))),
				catchError((error: HttpErrorResponse) => [
					patientSearchActions.newSearchFailed({ statusCode: error.status, message: error.message }),
					clientErrored({ toastMessage: 'Patient search error', errorMessage: error.message })
				])
			))
		)
	);

	appendResultsRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientSearchActions.appendResultsRequested),
			withLatestFrom(this.store.pipe(select(searchParams))),
			exhaustMap(([, searchParams]) => this.patientsService.queryPatients(searchParams.queryTerm, (searchParams.appendsRetrieved + 1) * searchParams.resultsPerAppend, searchParams.resultsPerAppend).pipe(
				map(searchResults => (patientSearchActions.appendResultsSucceeded({ searchResults: searchResults }))),
				catchError((error: HttpErrorResponse) => [
					patientSearchActions.appendResultsFailed({ statusCode: error.status, message: error.message }),
					clientErrored({ toastMessage: 'Patient search error', errorMessage: error.message })
				])
			))
		)
	);
}