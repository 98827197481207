import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SelectControl, SelectItem } from 'src/app/form-controls/select/select.control';
import { TextInputControl } from 'src/app/form-controls/text-input/text-input.control';
import { SheetItemService } from 'src/app/services/sheet-item.service';
import { SheetItemDetailConfig, SheetItemFormBase } from 'src/app/services/sheets-renderer.service';
import { Row } from 'src/app/types/aggregate-graph.types';
import { DietPreparation, NutritionInstruction, NutritionMethod, NutritionTube } from 'src/app/types/sheet-instruction.types';
import { InstructionsFormComponent } from '../../instructions-form/instructions-form.component';

interface NutritionInstructionFormGroup {
	rerPercentage: FormControl<number | null>;
	dietPreparation: FormControl<DietPreparation | null>;
	diet: FormControl<string | null>;
	nutritionMethod: FormControl<NutritionMethod | null>;
	nutritionTube: FormControl<NutritionTube | null>;
}

@Component({
	selector: 'app-nutrition-item-form',
	templateUrl: './nutrition-item-form.component.html',
	styleUrl: './nutrition-item-form.component.scss',
	standalone: true,
	imports: [ReactiveFormsModule, InstructionsFormComponent, TextInputControl, SelectControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NutritionItemFormComponent implements SheetItemFormBase, OnInit {

	@Input() instructionTypeName!: string;
	@Input() item!: Row | null;
	@Input() minDate!: string;
	@Input() maxDate!: string;
	@Input() instructionsFormArray!: FormArray;
	@Input() itemConfig!: SheetItemDetailConfig;

	dietPreparations: SelectItem[] = [
		{ text: 'Commercial WellPet (Over The Counter)', value: DietPreparation.CommercialWellPet },
		{ text: 'Commercial Therapeutic (Prescription diet)', value: DietPreparation.CommercialTherapeutic },
		{ text: 'Hospital Prepared (Tempt foods)', value: DietPreparation.HospitalPrepared },
		{ text: 'Other (specify in "Comment")', value: DietPreparation.Other },
	];

	nutritionMethods: SelectItem[] = [
		{ text: 'Per os.', value: NutritionMethod.PerOs },
		{ text: 'Assisted Feeding', value: NutritionMethod.AssistedFeeding },
		{ text: 'Handed feed from height', value: NutritionMethod.HandedFeedFromHeight },
		{ text: 'Handed small bits of food', value: NutritionMethod.HandedFeedSmallBits },
		{ text: 'Supervised feeding', value: NutritionMethod.SupervisedFeeding },
		{ text: 'Tempt to eat', value: NutritionMethod.TemptToEat },
	];

	nutritionTubes: SelectItem[] = [
		{ text: 'Gastronomy', value: NutritionTube.Gastronomy },
		{ text: 'Jejunostomy', value: NutritionTube.Jejunostomy },
		{ text: 'NasalOesphageal', value: NutritionTube.NasalOesphageal },
		{ text: 'Oesphageal', value: NutritionTube.Oesphageal },
		{ text: 'PEG', value: NutritionTube.PEG },
	];


	constructor(private sheetItemService: SheetItemService, private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.sheetItemService.getInstructions(this.item, this.instructionTypeName, this.itemConfig.scheduleConfig.defaultScheduleType, this.itemConfig.scheduleConfig.defaultRepeatingScheduleFrequency).forEach(instruction => {
			const group: FormGroup<NutritionInstructionFormGroup> = this.sheetItemService.buildExistingInstructionGroup(this.instructionTypeName, instruction);
			const nutritionInstruction = instruction as NutritionInstruction;
			group.addControl('rerPercentage', this.formBuilder.control(nutritionInstruction.rerPercentage ?? null, Validators.required));
			group.addControl('dietPreparation', this.formBuilder.control(nutritionInstruction.dietPreparation ?? null, Validators.required));
			group.addControl('diet', this.formBuilder.control(nutritionInstruction.diet ?? null, Validators.required));
			group.addControl('nutritionMethod', this.formBuilder.control(nutritionInstruction.nutritionMethod ?? null, Validators.required));
			group.addControl('nutritionTube', this.formBuilder.control(nutritionInstruction.nutritionTube ?? null));
			this.instructionsFormArray.push(group);
		});
	}

	addInstruction() {
		const group: FormGroup<NutritionInstructionFormGroup> = this.sheetItemService.getNewInstructionGroup(this.instructionTypeName, this.itemConfig.scheduleConfig.defaultScheduleType, this.itemConfig.scheduleConfig.defaultRepeatingScheduleFrequency);
		group.addControl('rerPercentage', this.formBuilder.control(null, Validators.required));
		group.addControl('dietPreparation', this.formBuilder.control(null, Validators.required));
		group.addControl('diet', this.formBuilder.control(null, Validators.required));
		group.addControl('nutritionMethod', this.formBuilder.control(null, Validators.required));
		group.addControl('nutritionTube', this.formBuilder.control(null));
		this.instructionsFormArray.push(group);
	}
}