import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CheckboxControl } from 'src/app/form-controls/checkbox/checkbox.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { OralHealthCareAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface OralHealthCareActionFormGroup {
	suction: FormControl<boolean | null>;
	cleanCavity: FormControl<boolean | null>;
	cleanEquipment: FormControl<boolean | null>;
}

@Component({
	selector: 'app-oral-health-care-action-controls',
	templateUrl: './oral-health-care-action-controls.component.html',
	styleUrls: ['../../../common.scss', './oral-health-care-action-controls.component.scss'],
	standalone: true,
	imports: [CommonActionControlsComponent, ActionFormComponent, ReactiveFormsModule, CheckboxControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OralHealthCareActionControlsComponent implements SheetActionControlsBase {

	slot!: Slot | null;
	action!: OralHealthCareAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as OralHealthCareAction;
	}

	@Input() form!: FormGroup<OralHealthCareActionFormGroup>;
	@Input() item!: Row | null;
	
	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.form.addControl('suction', this.formBuilder.control(this.action?.suction ?? null));
		this.form.addControl('cleanCavity', this.formBuilder.control(this.action?.cleanCavity ?? null));
		this.form.addControl('cleanEquipment', this.formBuilder.control(this.action?.cleanEquipment ?? null));
	}
}