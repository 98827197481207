<app-action-form [item]="item" [slot]="slot" [form]="form">
	<div class="form-section app-margin-horizontal app-padding">
		<div class="form-group" [formGroup]="form">
			<!-- TO DO: Create a common component we can use -->
			<p class="app-padding">1. Examine the smear on low power oil objective (x40) lens for gross assessment for platelet clumps and comment.</p>
			<app-heading background="secondary-2" size="small">Platelet count...</app-heading>
			<app-checkbox label="...may not be accurate due to clumping, platelets appear too clumped for accurate estimate. " formControlName="plateletCountClumpingGeneral"></app-checkbox>
			<app-checkbox label="...may not be accurate due to clumping at feathered edge." formControlName="plateletCountClumpingFeatheredEdge"></app-checkbox>
			<app-checkbox label="...may not be accurate due to clumping in body smear." formControlName="plateletCountClumpingBody"></app-checkbox>
			<app-checkbox label="...should be accurate due to minimal clumping." formControlName="plateletCountAccurate"></app-checkbox>
		</div>
		<div class="form-group" [formGroup]="form">
			<!-- TO DO: Create a common component we can use -->
			<p class="app-padding">2. Examine the smear for platelet morphology and number using high power oil objective (x100) lens.</p>
			<app-heading background="secondary-2" size="small">Take ten counts, then average:</app-heading>
			<app-text-input label="Result 1" placeholder="Value" formControlName="averageOne" inputMode="numeric"></app-text-input>
			<app-text-input label="Result 2" placeholder="Value" formControlName="averageTwo" inputMode="numeric"></app-text-input>
			<app-text-input label="Result 3" placeholder="Value" formControlName="averageThree" inputMode="numeric"></app-text-input>
			<app-text-input label="Result 4" placeholder="Value" formControlName="averageFour" inputMode="numeric"></app-text-input>
			<app-text-input label="Result 5" placeholder="Value" formControlName="averageFive" inputMode="numeric"></app-text-input>
			<app-text-input label="Result 6" placeholder="Value" formControlName="averageSix" inputMode="numeric"></app-text-input>
			<app-text-input label="Result 7" placeholder="Value" formControlName="averageSeven" inputMode="numeric"></app-text-input>
			<app-text-input label="Result 8" placeholder="Value" formControlName="averageEight" inputMode="numeric"></app-text-input>
			<app-text-input label="Result 9" placeholder="Value" formControlName="averageNine" inputMode="numeric"></app-text-input>
			<app-text-input label="Result 10" placeholder="Value" formControlName="averageTen" inputMode="numeric"></app-text-input>
		</div>
		<div class="form-group" [formGroup]="form">
			<app-text-input label="Average" placeholder="Value" formControlName="averageTotal" inputMode="numeric"></app-text-input>
			<app-unit-quantity label="x 15,000 =" unitLabel="μL" formControlName="averageMultiplied" placeholder="Value" inputMode="numeric"></app-unit-quantity>	
		</div>
		<div class="form-group" [formGroup]="form">
			<app-heading background="secondary-2" size="small">3. Evaluation</app-heading>
			<app-checkbox label="Increased: The count is above the reference interval for the species." formControlName="evaluationIncreased"></app-checkbox>
			<app-checkbox label="Adequate: The count is within the reference interval for the species." formControlName="evaluationAdequate"></app-checkbox>
			<app-checkbox label="Low: The count is below the reference interval for the species." formControlName="evaluationLow"></app-checkbox>
			<app-checkbox label="Very low: The platelet count is below a medical decision limit associated with spontaneaous haemorrhage, i.e <30,000 μL." formControlName="evaluationVeryLow"></app-checkbox>
		</div>
		<app-common-action-controls [action]="action" [form]="form"></app-common-action-controls>
	</div>
</app-action-form>
