import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import { DESKTOP_BREAKPOINT, MENU_BAR_BREAKPOINT, MOBILE_BREAKPOINT } from './platform.effects';

export const selectBreakpoint = (state: AppState) => state.platform.breakpoint;

export const isDesktop = createSelector(
	selectBreakpoint,
	(breakpoint) => breakpoint >= DESKTOP_BREAKPOINT
);

export const isMobile = createSelector(
	selectBreakpoint,
	(breakpoint) => breakpoint <= MOBILE_BREAKPOINT
);

export const isTablet = createSelector(
	isMobile,
	isDesktop,
	(isMobile, isDesktop) => !isMobile && !isDesktop
);

export const isMenuBottom = createSelector(
	selectBreakpoint,
	(breakpoint) => breakpoint < MENU_BAR_BREAKPOINT
);

export interface BreakpointInfo {
	isMobile: boolean;
	isTablet: boolean;
	isDesktop: boolean;
}

export const breakpointInfo = createSelector(
	isMobile,
	isTablet,
	isDesktop,
	(isMobile, isTablet, isDesktop): BreakpointInfo => ({ isMobile, isTablet, isDesktop })
);