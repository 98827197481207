<app-action-form [item]="item" [slot]="slot" [form]="form">
	<div class="form-section app-margin-horizontal app-padding">
        <div class="form-group" [formGroup]="form">
            <app-checkbox label="Check dressing - clean / dry" formControlName="checkDressing"></app-checkbox>
            <app-checkbox label="Check pressure setting is correct" formControlName="checkPressureSetting"></app-checkbox>
            <app-checkbox label="Check mode is correct" formControlName="checkMode"></app-checkbox>
        </div>
    
        <div class="form-group" [formGroup]="form">
            <app-unit-quantity label="Pressure" unitLabel="mmHg" placeholder="Value" inputMode="decimal" formControlName="pressure"></app-unit-quantity>
            <app-select label="Mode" placeholder="Choose..." [items]="pressureModes" [saveValue]="true" formControlName="pressureMode"></app-select>
        </div>
        <app-common-action-controls [action]="action" [form]="form"></app-common-action-controls>
    </div>
</app-action-form>
