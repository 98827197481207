import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { setTaskDocumentWidgetValueRequested } from '../store/patient-task/patient-task.actions';
import { AppState } from '../store/reducers';
import { DocumentChildEntity, Section, TaskDocument, Widget, WidgetAction, WidgetMetaData } from '../types/aggregate-graph.types';
import { WidgetValueMapperService } from './widget-value-mapper.service';

export interface SectionGroups {
	[sectionId: number]: FormGroup;
}

export interface WidgetGroups {
	[groupId: number]: FormGroup;
}

export interface WidgetControls {
	[widgetId: number]: AbstractControl;
}

export interface WidgetValues {
	[id: number]: any;
}

export interface WidgetActions {
	[widgetId: number]: WidgetAction[];
}

export interface TaskDocumentViewModel {
	form: FormGroup;
	sections: Section[];
	sectionGroups: SectionGroups;
	widgetGroups: WidgetGroups;
	widgetControls: WidgetControls;
	widgetActions: WidgetActions;
	widgetValues: WidgetValues;
	subscriptionDestroyer: Subject<void>;
}

@Injectable({
	providedIn: 'root'
})
export class TaskDocumentService {

	constructor(private store: Store<AppState>, private widgetValueMapperService: WidgetValueMapperService) {
	}

	buildViewModel(taskDocument: TaskDocument, isReadOnly: boolean): TaskDocumentViewModel {

		const manifest = taskDocument.manifest;

		// create the view model
		const model: TaskDocumentViewModel = {
			form: new FormGroup({}),
			sections: this.sortByIndexAsc(structuredClone(manifest.sections)), // ngrx state cannot be mutated, so a new object is required
			sectionGroups: {},
			widgetGroups: {},
			widgetControls: {},
			widgetActions: {},
			widgetValues: { ...taskDocument.widgetValues },
			subscriptionDestroyer: new Subject<void>()
		};

		// sort the sections, groups and widgets
		model.sections.forEach(section => {
			section.groups = this.sortByIndexAsc(section.groups);
			section.groups.forEach(group => {
				group.widgets = this.sortByIndexAsc(group.widgets);
				group.widgets.forEach(widget => {
					if (widget.metaData.actions) {
						model.widgetActions[widget.id] = widget.metaData.actions;
					}
				});
			});
		});

		// build the reactive form
		manifest.sections.forEach((section: Section) => {
			const sectionGroup = new FormGroup({});
			section.groups.forEach(group => {
				const widgetGroup = new FormGroup({});
				group.widgets.forEach(widget => {
					const control = this.buildFormWidgetControl(widget, model.widgetValues[widget.id] || widget.metaData.defaultValue || null);
					widgetGroup.addControl(widget.id.toString(), control);
					model.widgetControls[widget.id] = control;
				});
				sectionGroup.addControl(group.id.toString(), widgetGroup);
				model.widgetGroups[group.id] = widgetGroup;
			});
			model.form.addControl(section.id.toString(), sectionGroup);
			model.sectionGroups[section.id] = sectionGroup;
		});

		// handle widget (form control value) changes (subscriptionDestroyer must be used to unsubscribe in an ngDestroy component/directive lifecycle hook)
		if (!isReadOnly) {
			Object.entries(model.widgetControls).forEach(([key, control]) => {
				// Counter intuitively, valueChanges fires if the value is "recalculated" (even if the value stays the same). E.g. at time of writing this code (ng v^17.0.2), triggering validation
				// by calling the Validator registerOnValidatorChange function inside a custom control will cause valueChanges to fire.
				// Using distinctUntilChanged is the suggested approach by angular devs to filter valueChanges to new values only (https://github.com/angular/angular/issues/25749#issuecomment-417492746),
				// but it does not seem to work in all cases. For belt and braces, JSON stringify is used to do a string comparison of the values.
				(control as FormControl).valueChanges.pipe(takeUntil(model.subscriptionDestroyer), distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))).subscribe(value => {
					//console.log('key', key, 'value', value, `editor: ${!isReadOnly}`);
					const widgetId = parseInt(key);
					this.store.dispatch(setTaskDocumentWidgetValueRequested({ documentInstanceId: taskDocument.id, widgetId: widgetId, value: (typeof value === 'string') ? value.trim() : value }));
					if (model.widgetActions.hasOwnProperty(widgetId)) {
						model.widgetValues[widgetId] = value;
						this.runWidgetActions(model.widgetActions, model.widgetValues, model.widgetGroups);
					}
				})
			});
		}

		// run any widget actions
		this.runWidgetActions(model.widgetActions, model.widgetValues, model.widgetGroups);

		// return the model for rendering
		return model;
	}

	private sortByIndexAsc<T extends DocumentChildEntity>(arrayToSort: T[]): T[] {
		return arrayToSort.sort((a, b) => a.sortIndex - b.sortIndex);
	}

	private buildFormWidgetControl(widget: Widget, value: any): AbstractControl {
		const validators = this.getWidgetValidators(widget.metaData);
		return new FormControl(this.widgetValueMapperService.mapWidgetValue(widget.widgetType, value), { validators: validators });
	}

	private runWidgetActions(widgetActions: WidgetActions, widgetValues: WidgetValues, widgetGroups: WidgetGroups) {
		Object.keys(widgetActions).forEach(key => {
			const widgetId = parseInt(key);
			const widgetValue = widgetValues[widgetId];
			widgetActions[widgetId].forEach(widgetAction => {
				switch (widgetAction.actionType) {
					case 'enable-groups':
						this.enableGroups(widgetAction, widgetValue, widgetGroups);
						break;
				}
			});
		});
	}

	private enableGroups(widgetAction: WidgetAction, widgetValue: any, widgetGroups: WidgetGroups) {
		const actionData: {
			groups: number[],
			enabledValue: boolean | string | number
		} = widgetAction.data;
		const isEnabled = widgetValue === actionData.enabledValue;
		const opts = {
			emitEvent: false
		};
		actionData.groups.forEach(groupId => {
			const widgetGroup = widgetGroups[groupId];
			if (widgetGroup.enabled !== isEnabled) {
				if (isEnabled) widgetGroup.enable(opts);
				else widgetGroup.disable(opts);
			}
		});
	}

	getWidgetValidators(metaData: WidgetMetaData): ValidatorFn[] {
		const validators = [];

		if (metaData && metaData.validators) {
			if (metaData.validators.required) {
				validators.push(Validators.required);
			}
			if (metaData.validators.requiredTrue) {
				validators.push(Validators.requiredTrue);
			}
			if (metaData.validators.minLength) {
				validators.push(Validators.minLength(metaData.validators.minLength));
			}
			if (metaData.validators.pattern) {
				validators.push(Validators.pattern(metaData.validators.pattern));
			}
		}

		return validators;
	}

	updateWidgets(taskDocument: TaskDocument, model: TaskDocumentViewModel) {
		let mustRunWidgetActions = false;

		model.widgetValues = taskDocument.widgetValues;

		Object.keys(model.widgetValues).forEach(key => {
			const widgetId = parseInt(key);
			model.widgetControls[widgetId].patchValue(model.widgetValues[widgetId], { emitEvent: false });
			if (model.widgetActions.hasOwnProperty(widgetId)) mustRunWidgetActions = true;
		});

		if (mustRunWidgetActions) this.runWidgetActions(model.widgetActions, model.widgetValues, model.widgetGroups);
	}
}