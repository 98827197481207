import { SearchedPatient } from '../services/patients.service';
import { SheetGranularity } from './sheet.types';

export interface GraphChangeContext {
	clientSessionId: string | null;
}

export type PartialEntity<T> = Partial<T> & { id: string };

export interface GraphAggregate {
	id: string;
	aggregateType: string;
}

export enum GraphAggregateChangeOperation { Addition = 1, Update = 2, Removal = 3 }

export interface GraphAggregateChange {
	operation: GraphAggregateChangeOperation;
	aggregate: GraphAggregate;
}

export const ClientTypeName = 'Client';

export interface Client extends GraphAggregate {
	referenceId: string;
	referenceType: ClientReferenceType;
	contactTitle: string;
	contactFirstName: string;
	contactSurname: string;
	companyName: string;
	contactMethods: ContactMethod[];
	addresses: Address[];
	contactNote: string;
	lastCommitDate: string;
}

export enum ClientReferenceType {
	Owner = 1,
	ReferringVet = 2
}

export interface ContactMethod {
	key: string;
	value: string;
}

export interface Address {
	key: string;
	value: string[];
}

export const PatientTypeName = 'Patient';

export interface Patient extends GraphAggregate {
	name: string;
	species: string;
	breed: string;
	colour: string;
	isActive: boolean;
	sex: string;
	birthDate: string;
	caseNumber: string;
	isNeutered: boolean;
	careWarning: string;
	bloodType: string;
	problems: string | null;
	alerts: string | null;
}

export enum ToDoStatus { // this needs to match the API/domain definition
	NotStarted = 0,
	Started = 1,
	Skipped = 2,
	Complete = 4,
	Attention = 8
}

export interface ToDo {
	id: number;
	label: string;
	status: ToDoStatus;
	comment: string;
}

export interface Recipient {
	serviceId: string;
	recipientId: number;
	rejectedDate: string | null;
	completedDate: string | null;
	toDos: { [id: number]: ToDo };
}

export interface TaskBase {
	visitId: string;
	visitServiceId: string;
	patientId: string;
	patient: Patient;
	taskName: string;
	summary: string | null;
	recipients: Recipient[];
	isUrgent: boolean;
	urgentReason: string;
	scheduledDate: string | null;
	recalledDate: string | null;
	rejectedDate: string | null;
	recalledRejectedReason: string;
	finalisedDate: string | null;
}

export const SheetTypeName = 'Sheet';

export interface Sheet extends GraphAggregate {
	visitId: string;
	sheetCreatedDate: string; // the date the sheet was created
	sheetStartDate: string; // the date the slots start from (e.g. 6am, even though the sheet might have been created at 2pm)
	scheduledUntilDate: string;
	maxScheduledUntilDate: string;
	granularity: SheetGranularity;
	slotLabels: string[];
	rows: Row[];
	slotsTotal: number;
	slotsPerDay: number[];
	slotDefaultPerformedDates: string[];
	isFinalised: boolean;
}

export interface Row {
	id: number;
	sortPosition: number;
	instructionTypeName: string;
	actionTypeName: string;
	instructions: { [id: number]: Instruction };
	slots: (Slot | null)[];
	name: string | null;
	notes: string | null;
	isRemoved: boolean;
}

export interface Instruction {
	id: number;
	schedule: Unscheduled | WhenRequired | OneOffSchedule | RepeatingSchedule | OnAdmitAndDailySchedule;
	instructionTypeName: string;
}

export enum ScheduleType {
	Unscheduled = 'Unscheduled',
	WhenRequired = 'WhenRequired',
	OneOff = 'OneOffSchedule',
	Repeating = 'RepeatingSchedule',
	OnAdmitAndDaily = 'OnAdmitAndDailySchedule'
}

export interface Schedule {
	scheduleTypeName: ScheduleType;
}

export interface Unscheduled extends Schedule {
}

export interface WhenRequired extends Schedule {
}

export interface OneOffSchedule extends Schedule {
	start: string;
	timeToComplete: string;
}

export interface RepeatingSchedule extends Schedule {
	start: string;
	timeToComplete: string;
	frequency: string;
	last: string | null;
}

export interface OnAdmitAndDailySchedule extends Schedule {
}

export interface Slot {
	isGranular: boolean;
	granularityCount: number;
	isScheduledAction: boolean;
	isAction: boolean;
	isSkippedScheduledAction: boolean;
	action: Action | null;
	startSlot: number;
	slotSpan: number;
	actionedBy: string;
	actionedDate: string;
	granularSlotIndex?: number; // this is a client side property (the backend does not know about it)
}

export interface Action {
	id: number;
	actionTypeName: string;
	performedDate: string;
	notes: string;
	isComplete: boolean;
	isSkipped: boolean;
	skippedReason: string;
}

export const ServiceTaskTypeName = 'ServiceTask';

export interface ServiceTask extends GraphAggregate, TaskBase {
	recipientId: number;
	serviceId: string;
	visitTeam: VisitParticipant[];
	receivedDate: string;
	removedDate: string | null;
}

export interface RecipientId {
	id: number;
	teamId: string; // serviceId
}

export interface TaskSummarySettings {
	userCanEdit: boolean;
	displayLabel: string | null;
	displayPlaceholder: string | null;
}

export interface TaskRecipient {
	id: RecipientId;
	nextRecipientIds: number[];
	isOptional: boolean;
	label: string;
	circumstances: string;
	canReject: boolean;
	canSchedule: boolean;
	toDoLabels: string[];
}

export interface TaskManifest {
	id: string;
	version: number;
	name: string;
	shortName: string;
	summarySettings: TaskSummarySettings;
	canBeUrgent: boolean;
	canSchedule: boolean;
	requestDocumentDefinitionId: string;
	allRecipients: { [id: number]: TaskRecipient };
	initialRecipientIds: number[];
}

export const TaskTypeName = 'PatientRecordTask';

export interface PatientRecordTask extends GraphAggregate, TaskBase {
	manifest: TaskManifest;
	taskSharerName: string;
	createdDate: string;
	firstSharedDate: string | null;
	completedDate: string | null;
}

export const TaskDocumentTypeName = 'TaskDocument';

export interface TaskDocument extends GraphAggregate {
	taskId: string;
	isRequestDocument: boolean;
	manifest: DocumentManifest;
	widgetValues: { [id: number]: any };
	createdDate: string;
	createdByUserId: number;
	lastUpdatedDate: string;
	lastUpdatedByUserId: number;
}

export interface DocumentManifest {
	documentDefinitionId: string;
	versionNumber: number;
	name: string;
	shortName: string;
	creationInstruction: string;
	sections: Section[];
	isTask: boolean;
	isReport: boolean;
	isDocument: boolean;
}

export interface DocumentChildEntity {
	id: number;
	sortIndex: number;
}

export interface SectionMetaData {
	title: string;
}

export interface Section extends DocumentChildEntity {
	metaData: SectionMetaData;
	groups: Group[];
}

export interface Group extends DocumentChildEntity {
	metaData: any;
	widgets: Widget[];
}

export interface WidgetAction {
	actionType: string;
	data: any;
}

export interface WidgetMetaData {
	label?: string;
	subLabel?: string;
	validators?: {
		required?: boolean;
		requiredTrue?: boolean;
		minLength?: number;
		pattern?: string | RegExp;
	};
	defaultValue?: any;
	actions?: WidgetAction[];
	placeholder?: string;
	items?: any;
}

export interface Widget extends DocumentChildEntity {
	widgetType: string;
	metaData: WidgetMetaData;
}

export interface GroupRepeaterMetaData extends WidgetMetaData {
	label: string;
	addLabel: string;
	controls: {
		id: number;
		type: string;
		metaData: WidgetMetaData;
	}[];
}

export const TeamTypeName = 'Team';

export interface Team extends GraphAggregate {
	name: string;
	isActive: boolean;
	isAutomated: boolean;
	teamMembershipEnabled: boolean;
	userIsTeamAdministrator: boolean;
	userIsTeamMember: boolean;
	userCanEditMemberList: boolean;
}

export const UserTypeName = 'User';

export interface User extends GraphAggregate {
	userId: number;
	fullName: string;
	initials: string;
	isActive: boolean;
	isSystemAdministrator: boolean;
	isClinician: boolean;
	isStudent: boolean;
	patientListId: string;
	selectedTeamIds: string[];
	searchedPatients: SearchedPatient[];
}

export const VisitTypeName = 'Visit';

export enum PatientStatus { InPatient = 1, OutPatient = 2, ToGoHome = 3, Discharged = 4, Deceased = 5 } // this needs to match the domain definition
export enum ResuscitationStatus { Resuscitate = 1, ResuscitateOpenChest = 2, DoNotResuscitate = 3 } // this needs to match the domain definition

export interface Visit extends GraphAggregate {
	visitNumber: number;
	admittingTeamId: string;
	admittingUserId: number;
	admittingUserName: string;
	currentTeamId: string;
	admissionDate: string;
	dischargeDate: string | null;
	ward: string | null;
	kennel: number | null;
	barrierWarning: string;
	patientStatus: PatientStatus | null;
	resuscitationStatus: ResuscitationStatus | null;
	participants: VisitParticipant[];
	sheetId: string | null;
}

export interface VisitParticipant {
	userId: number;
	fullName: string;
	initials: string;
	participationType: VisitParticipationType;
}

export enum VisitParticipationType { // this needs to match the domain definition
	Clinician = 2, // a.k.a. Resident
	SupervisingClinician = 3, // Supervisor, a.k.a. Senior
	Student = 4,
	Consultant = 5, // not displayed
	Nurse = 6,
	Intern = 7, // qualified vet training in a specific area
	OutOfHoursClinician = 8
}

export interface ItemProperties {
	name: string | null;
	notes: string | null;
}
