import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { CoercionService } from 'src/app/services/coercion.service';

@Component({
	selector: 'app-item',
	templateUrl: './item.component.html',
	styleUrl: './item.component.scss',
	standalone: true,
	imports: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemComponent {

	private isUnderlined: boolean = false;
	@Input() set underlined(value: any) { this.isUnderlined = this.coercionService.coerceToBoolean(value) }
	@HostBinding('class.underlined') get isUnderlinedComponent() { return this.isUnderlined }

	private isColumnar: boolean = false;
	@Input() set columnar(value: any) { this.isColumnar = this.coercionService.coerceToBoolean(value) }
	@HostBinding('class.columnar') get isColumnarComponent() { return this.isColumnar }

	private isSelected: boolean = false;
	@Input() set selected(value: any) { this.isSelected = this.coercionService.coerceToBoolean(value) }
	@HostBinding('class.selected') get isSelectedComponent() { return this.isSelected }

	constructor(private coercionService: CoercionService) {
	}
}