import { animate, style, transition, trigger } from '@angular/animations';

export const expanderAnimateTiming = '200ms ease-in-out';

export const expanderEnterLeave = trigger('expanderEnterLeave', [
	transition(':enter', [
		style({ height: 0, opacity: 0 }),
		animate(expanderAnimateTiming, style({ height: '*', opacity: 1 }))
	]),
	transition(':leave', [
		style({ height: '*', opacity: 1 }),
		animate(expanderAnimateTiming, style({ height: 0, opacity: 0 }))
	])
])