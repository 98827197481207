import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SheetSlotComponentBase } from 'src/app/services/sheets-renderer.service';
import { PhysiotherapyAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { TickValueComponent } from '../../tick-value/tick-value.component';

@Component({
	selector: 'app-physiotherapy-slot',
	templateUrl: './physiotherapy-slot.component.html',
	styleUrl: './physiotherapy-slot.component.scss',
	standalone: true,
	imports: [TickValueComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhysiotherapySlotComponent implements SheetSlotComponentBase {

	@Input() action!: PhysiotherapyAction;
	value = null;

	constructor() {
	}
}