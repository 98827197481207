import { Pipe, PipeTransform } from '@angular/core';
import { MedicationRoute } from '../types/sheet-instruction.types';

@Pipe({
	name: 'medicationRouteName',
	standalone: true
})
export class MedicationRouteNamePipe implements PipeTransform {

	constructor() {
	}

	transform(route: MedicationRoute | null): string {
		switch(route) {
			case MedicationRoute.Iv: return 'IV';
			case MedicationRoute.Im: return 'IM';
			case MedicationRoute.Sq: return 'SQ';
			case MedicationRoute.Oral: return 'Oral';
			case MedicationRoute.Transmucosal: return 'Transmucosal';
			case MedicationRoute.Topical: return 'Topical';
			case MedicationRoute.Rectal: return 'Rectal';
			case MedicationRoute.Intranasal: return 'Intranasal';
			case MedicationRoute.Inhalation: return 'Inhalation';
			case MedicationRoute.Transdermal: return 'Transdermal';
			case MedicationRoute.Intraosseous: return 'Intraosseous';
			default: return '';
		}
	}
}