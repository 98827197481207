import { ChangeDetectionStrategy, Component, ContentChild, HostBinding, Input } from '@angular/core';
import { CoercionService } from 'src/app/services/coercion.service';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';

@Component({
	selector: 'app-item-value',
	templateUrl: './item-value.component.html',
	styleUrl: './item-value.component.scss',
	standalone: true,
	imports: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemValueComponent {

	@Input() placeholder: string | undefined = undefined;
	@Input() value: string | null | undefined = undefined;
	@HostBinding('class.placeholder') get isPlaceholder() { return !this.value; }

	private isMultiLine: boolean = false;
	@Input()
	get multiLine() {
		return this.isMultiLine;
	}
	set multiLine(value: any) {
		this.isMultiLine = this.coercionService.coerceToBoolean(value);
	}
	@HostBinding('class.multi-line') get isMultiLineComponent() { return this.isMultiLine; }

	@ContentChild(SvgIconComponent) svgIcon: SvgIconComponent | null = null;
	@HostBinding('class.has-icon') get hasIcon() { return !!this.svgIcon; }

	constructor(private coercionService: CoercionService) {
	}
}