<app-action-form [item]="item" [slot]="slot" [form]="form">
	<div class="form-section app-margin-horizontal app-padding">
        <div class="form-group" [formGroup]="form">
            <app-unit-quantity label="Volume" unitLabel="ml" placeholder="Value" inputMode="numeric" formControlName="volume"></app-unit-quantity>
            <app-select label="Method of collection" placeholder="choose..." [items]="methodOfCollectionItems" [saveValue]="true" formControlName="methodOfCollection"></app-select>
            <app-select label="Leukoctes" placeholder="choose..." [items]="leukoctesItems" [saveValue]="true" formControlName="leukoctes"></app-select>
            <app-select label="Nitrate" placeholder="choose..." [items]="nitrateItems" [saveValue]="true" formControlName="nitrate"></app-select>
            <app-select label="PH" placeholder="choose..." [items]="phItems" [saveValue]="true" formControlName="pH"></app-select>
            <app-select label="Protein" placeholder="choose..." [items]="proteinItems" [saveValue]="true" formControlName="protein"></app-select>
            <app-select label="Glucose" placeholder="choose..." [items]="glucoseItems" [saveValue]="true" formControlName="glucose"></app-select>
            <app-select label="Ketones" placeholder="choose..." [items]="ketonesItems" [saveValue]="true" formControlName="ketones"></app-select>
            <app-select label="Urobilinogen" placeholder="choose..." [items]="urobilinogenItems" [saveValue]="true" formControlName="urobilinogen"></app-select>
            <app-select label="Bilirubin" placeholder="choose..." [items]="bilirubinItems" [saveValue]="true" formControlName="bilirubin"></app-select>
            <app-select label="Erythrocytes" placeholder="choose..." [items]="erythrocytesItems" [saveValue]="true" formControlName="erythrocytes"></app-select>
            <app-select label="Haemoglobin" placeholder="choose..." [items]="haemoglobinItems" [saveValue]="true" formControlName="haemoglobin"></app-select>
            <app-text-input label="Urine specific gravity" placeholder="Value" formControlName="urineSpecificGravity" inputMode="decimal"></app-text-input>
            <app-textarea label="Sediment exam" placeholder="Value" formControlName="sedimentExam"></app-textarea>
        </div>
        <app-common-action-controls [action]="action" [form]="form"></app-common-action-controls>
    </div>
</app-action-form>

