import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class AccordionService {

	private expanderSubject$ = new Subject<number>();

	expanderExpanded$ = this.expanderSubject$.asObservable();

	constructor() {
	}

	public expanderExpanded(index: number) {
		this.expanderSubject$.next(index);
	}
}