import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular/standalone';
import { ItemLabelComponent } from 'src/app/components/item-label/item-label.component';
import { ItemComponent } from 'src/app/components/item/item.component';
import { NavigableDirective } from 'src/app/directives/navigable.directive';

export interface Unit {
	label: string;
	value?: string; // use this to save a different string to what is displayed, if required
}

@Component({
	templateUrl: './unit.popover.html',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [ItemComponent, NavigableDirective, ItemLabelComponent]
})
export class UnitPopoverComponent {

	@Input() units: Unit[] = [];

	constructor(private popoverController: PopoverController) {
	}

	unitSelected(unit: Unit) {
		this.popoverController.dismiss({ unit });
	}
} 