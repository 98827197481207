import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UnitQuantity, UnitQuantityControl } from 'src/app/form-controls/unit-quantity/unit-quantity.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { PackedCellVolumeAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface PackedCellVolumeActionFormGroup {
	pcv: FormControl<UnitQuantity | null>;
}

@Component({
	selector: 'app-packed-cell-volume-action-controls',
	templateUrl: './packed-cell-volume-action-controls.component.html',
	styleUrls: ['../../../common.scss', './packed-cell-volume-action-controls.component.scss'],
	standalone: true,
	imports: [ReactiveFormsModule, UnitQuantityControl, CommonActionControlsComponent, ActionFormComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackedCellVolumeActionControlsComponent implements SheetActionControlsBase, OnInit {

	slot!: Slot | null;
	action!: PackedCellVolumeAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as PackedCellVolumeAction;
	}

	@Input() form!: FormGroup<PackedCellVolumeActionFormGroup>;
	@Input() item!: Row | null;
	
	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.form.addControl('pcv', this.formBuilder.control(this.action?.pcv ?? null));
	}
}