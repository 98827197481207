import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar, ModalController } from '@ionic/angular/standalone';
import { LetDirective } from '@ngrx/component';
import { HeadingComponent } from 'src/app/components/heading/heading.component';
import { ItemLabelComponent } from 'src/app/components/item-label/item-label.component';
import { ItemComponent } from 'src/app/components/item/item.component';
import { SvgIconComponent } from 'src/app/components/svg-icon/svg-icon.component';
import { NavigableDirective } from 'src/app/directives/navigable.directive';
import { MultiItemPickerItem, MultiItemPickerValue } from './multi-item-picker.control';

@Component({
	templateUrl: './multi-item-picker.modal.html',
	styleUrl: './multi-item-picker.modal.scss',
	standalone: true,
	imports: [
		LetDirective, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, HeadingComponent,
		ItemComponent, ItemLabelComponent, SvgIconComponent, NavigableDirective
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiItemPickerModal {

	@Input() items!: MultiItemPickerItem[];
	@Input() selectedValues!: MultiItemPickerValue[];

	constructor(private modalController: ModalController) {
	}

	itemSelected(item: MultiItemPickerItem) {
		if (item.type === 'header') return;
		if (this.selectedValues.some(value => value.id === item.id)) this.selectedValues = this.selectedValues.filter(value => value.id !== item.id);
		else this.selectedValues.push({ id: item.id, label: item.label, note: '' });
	}

	isSelected(item: MultiItemPickerItem) {
		return this.selectedValues.some(v => v.id === item.id);
	}

	close() {
		this.modalController.dismiss({ values: this.selectedValues });
	}
}