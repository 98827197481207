import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, finalize, from, throwError, timer } from 'rxjs';
import { concatMap, filter, pairwise, retry, startWith, tap } from 'rxjs/operators';
import { LoadingOverlayService } from '../services/loading-overlay.service';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {

	constructor(private reconnectOverlayService: ReconnectOverlayService) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			// note: this runs in the context of a single request. Any number of requests could be running concurrently
			retry({
				delay: (error: HttpErrorResponse, count: number) => {

					// if a network connection error occurs, continue the retry loop
					if (error.status === 0) {
						return timer(Math.min(count * 500, 3000)).pipe(tap(() => { if (count === 1) this.reconnectOverlayService.requestFailed() }));
					}

					// not a network connection error, throw error to break the retry cycle and return the error (if not first attempt, hide the loading overlay)
					return throwError(() => error);
				}
			}),
			finalize(() => this.reconnectOverlayService.requestCompletedOrErrored())
		);
	}
}

@Injectable({ providedIn: 'root' })
export class ReconnectOverlayService {

	private subject$ = new Subject<number>();
	private requestCount = 0;
	private overlayId = '';

	constructor(private loadingOverlayService: LoadingOverlayService) {
		this.subject$.pipe(
			startWith(0),
			pairwise(),
			filter(([previousCount, currentCount]) => (previousCount === 0 && currentCount === 1) || (previousCount === 1 && currentCount === 0)),
			concatMap(([, currentCount]) => {
				if (currentCount === 1) {
					return from(this.loadingOverlayService.displayLoadingOverlay("Reconnecting to server...")).pipe(tap(id => this.overlayId = id));
				}
				return from(this.loadingOverlayService.removeLoadingOverlay(this.overlayId));
			})
		).subscribe();
	}

	public requestFailed() {
		this.requestCount++;
		this.subject$.next(this.requestCount);
	}

	public requestCompletedOrErrored() {
		this.requestCount = Math.max(this.requestCount - 1, 0);
		this.subject$.next(this.requestCount);
	}
}