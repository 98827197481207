import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { HeadingComponent } from 'src/app/components/heading/heading.component';
import { CheckboxControl } from 'src/app/form-controls/checkbox/checkbox.control';
import { TextInputControl } from 'src/app/form-controls/text-input/text-input.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { PainScoreCatActionV2 } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface PainScoreCatActionFormGroup {
	earAbsent: FormControl<boolean | null>;
	earModeratelyPresent: FormControl<boolean | null>;
	earMarkedlyPresent: FormControl<boolean | null>;
	orbitalAbsent: FormControl<boolean | null>;
	orbitalModeratelyPresent: FormControl<boolean | null>;
	orbitalMarkedlyPresent: FormControl<boolean | null>;
	muzzleAbsent: FormControl<boolean | null>;
	muzzleModeratelyPresent: FormControl<boolean | null>;
	muzzleMarkedlyPresent: FormControl<boolean | null>;
	whiskerAbsent: FormControl<boolean | null>;
	whiskerModeratelyPresent: FormControl<boolean | null>;
	whiskerMarkedlyPresent: FormControl<boolean | null>;
	headAbsent: FormControl<boolean | null>;
	headModeratelyPresent: FormControl<boolean | null>;
	headMarkedlyPresent: FormControl<boolean | null>;
	totalScore: FormControl<number | null>;

}

@Component({
	selector: 'app-pain-score-cat-action-controls-v2',
	templateUrl: './pain-score-cat-action-controls-v2.component.html',
	styleUrls: ['../../../common.scss', './pain-score-cat-action-controls-v2.component.scss'],
	standalone: true,
	imports: [HeadingComponent, CommonActionControlsComponent, ActionFormComponent, ReactiveFormsModule, CheckboxControl, TextInputControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PainScoreCatActionControlsComponentV2 implements SheetActionControlsBase {

	slot!: Slot | null;
	action!: PainScoreCatActionV2;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as PainScoreCatActionV2;
	}

	@Input() form!: FormGroup<PainScoreCatActionFormGroup>;
	@Input() item!: Row | null;

	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.form.addControl('earAbsent', this.formBuilder.control(this.action?.earAbsent ?? null));
		this.form.addControl('earModeratelyPresent', this.formBuilder.control(this.action?.earModeratelyPresent ?? null));
		this.form.addControl('earMarkedlyPresent', this.formBuilder.control(this.action?.earMarkedlyPresent ?? null));
		this.form.addControl('orbitalAbsent', this.formBuilder.control(this.action?.orbitalAbsent ?? null));
		this.form.addControl('orbitalModeratelyPresent', this.formBuilder.control(this.action?.orbitalModeratelyPresent ?? null));
		this.form.addControl('orbitalMarkedlyPresent', this.formBuilder.control(this.action?.orbitalMarkedlyPresent ?? null));
		this.form.addControl('muzzleAbsent', this.formBuilder.control(this.action?.muzzleAbsent ?? null));
		this.form.addControl('muzzleModeratelyPresent', this.formBuilder.control(this.action?.muzzleModeratelyPresent ?? null));
		this.form.addControl('muzzleMarkedlyPresent', this.formBuilder.control(this.action?.muzzleMarkedlyPresent ?? null));
		this.form.addControl('whiskerAbsent', this.formBuilder.control(this.action?.whiskerAbsent ?? null));
		this.form.addControl('whiskerModeratelyPresent', this.formBuilder.control(this.action?.whiskerModeratelyPresent ?? null));
		this.form.addControl('whiskerMarkedlyPresent', this.formBuilder.control(this.action?.whiskerMarkedlyPresent ?? null));
		this.form.addControl('headAbsent', this.formBuilder.control(this.action?.headAbsent ?? null));
		this.form.addControl('headModeratelyPresent', this.formBuilder.control(this.action?.headModeratelyPresent ?? null));
		this.form.addControl('headMarkedlyPresent', this.formBuilder.control(this.action?.headMarkedlyPresent ?? null));
		this.form.addControl('totalScore', this.formBuilder.control(this.action?.totalScore ?? null));
	}
}
