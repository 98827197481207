import { AggregateGraphHubEffects } from './aggregate-graph-hub/aggregate-graph-hub.effects';
import { AuthEffects } from './auth/auth.effects';
import { BodyPartsSearchEffects } from './body-parts-search/body-parts-search.effects';
import { ClientLoggingEffects } from './client-logging/client-logging.effects';
import { ClientEffects } from './client/client.effects';
import { ErrorEffects } from './error/error.effects';
import { PatientCommsEffects } from './patient-comms/patient-comms.effects';
import { PatientGraphEffects } from './patient-graph/patient-graph.effects';
import { PatientSearchEffects } from './patient-search/patient-search.effects';
import { PatientTaskEffects } from './patient-task/patient-task.effects';
import { PatientVisitEffects } from './patient-visit/patient-visit.effects';
import { PatientEffects } from './patient/patient.effects';
import { PlatformEffects } from './platform/platform.effects';
import { ServiceGraphEffects } from './service-graph/service-graph.effects';
import { ServiceSettingsEffects } from './service-settings/service-settings.effects';
import { ServiceTaskEffects } from './service-task/service-task.effects';
import { SheetGraphEffects } from './sheet-graph/sheet-graph.effects';
import { SheetItemSearchEffects } from './sheet-item-search/sheet-item-search.effects';
import { SheetEffects } from './sheet/sheet.effects';
import { UserGraphEffects } from './user-graph/user-graph.effects';
import { UserSearchEffects } from './user-search/user-search.effects';

export const effectsServices = [
	AggregateGraphHubEffects,
	AuthEffects,
	BodyPartsSearchEffects,
	ClientEffects,
	ClientLoggingEffects,
	ErrorEffects,
	PatientCommsEffects,
	PatientSearchEffects,
	PatientTaskEffects,
	PatientEffects,
	PatientVisitEffects,
	PatientGraphEffects,
	PlatformEffects,
	ServiceGraphEffects,
	ServiceSettingsEffects,
	ServiceTaskEffects,
	SheetEffects,
	SheetGraphEffects,
	SheetItemSearchEffects,
	UserGraphEffects,
	UserSearchEffects
];