import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SelectControl, SelectItem } from 'src/app/form-controls/select/select.control';
import { TextInputControl } from 'src/app/form-controls/text-input/text-input.control';
import { UnitQuantity, UnitQuantityControl } from 'src/app/form-controls/unit-quantity/unit-quantity.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { HeartMurmur, HeartRateAction, HeartRateRhythm, PulseQuality, SynchronousPulses } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface HeartRateActionFormGroup {
	beatsPerMinute: FormControl<UnitQuantity | null>;
	rhythm: FormControl<HeartRateRhythm | null>;
	murmur: FormControl<HeartMurmur | null>;
	synchronousPulses: FormControl<SynchronousPulses | null>;
	pulseQuality: FormControl<PulseQuality | null>;
	pulseLocation: FormControl<string | null>;
}

@Component({
	selector: 'app-measure-heartrate-action-controls',
	templateUrl: './measure-heartrate-action-controls.component.html',
	styleUrls: ['../../../common.scss', './measure-heartrate-action-controls.component.scss'],
	standalone: true,
	imports: [ReactiveFormsModule, UnitQuantityControl, SelectControl, TextInputControl, CommonActionControlsComponent, ActionFormComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeartRateActionControlsComponent implements SheetActionControlsBase, OnInit {

	slot!: Slot | null;
	action!: HeartRateAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as HeartRateAction;
	}

	@Input() form!: FormGroup<HeartRateActionFormGroup>;
	@Input() item!: Row | null;

	heartRhythmItems: SelectItem[] = [
		{ text: 'Regular rhythm', value: HeartRateRhythm.RegularRhythm },
		{ text: 'Sinus arrhythmia', value: HeartRateRhythm.SinusArrhythmia },
		{ text: 'Other', value: HeartRateRhythm.Other }
	];

	murmurItems: SelectItem[] = [
		{ text: 'Present (record in notes)', value: HeartMurmur.Present },
		{ text: 'Not Present', value: HeartMurmur.NotPresent }
	];

	synchronousPulsesItems: SelectItem[] = [
		{ text: 'Yes', value: SynchronousPulses.Yes },
		{ text: 'No', value: SynchronousPulses.No }
	];

	pulseQualityItems: SelectItem[] = [
		{ text: 'Strong', value: PulseQuality.Strong },
		{ text: 'Moderate', value: PulseQuality.Moderate },
		{ text: 'Poor', value: PulseQuality.Poor },
		{ text: 'Absent', value: PulseQuality.Absent }
	];

	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.form.addControl('beatsPerMinute', this.formBuilder.control(this.action?.beatsPerMinute ?? null));
		this.form.addControl('rhythm', this.formBuilder.control(this.action?.rhythm ?? null));
		this.form.addControl('murmur', this.formBuilder.control(this.action?.murmur ?? null));
		this.form.addControl('synchronousPulses', this.formBuilder.control(this.action?.synchronousPulses ?? null));
		this.form.addControl('pulseQuality', this.formBuilder.control(this.action?.pulseQuality ?? null));
		this.form.addControl('pulseLocation', this.formBuilder.control(this.action?.pulseLocation ?? null));
	}
}
