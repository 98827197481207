import { createAction, props } from '@ngrx/store';

export enum AggregateGraphHubActionTypes {
	//#region Connection action types
	HubActivated = '[Aggregate Graph Hub] Hub Activated',
	HubDeactivated = '[Aggregate Graph Hub] Hub Deactivated',
	ConnectHubAborted = '[Aggregate Graph Hub] Connect Hub Aborted',
	ConnectHubRequested = '[Aggregate Graph Hub] Connect Hub Requested',
	ConnectHubCompleted = '[Aggregate Graph Hub] Connect Hub Completed',
	HubConnectionClosed = '[Aggregate Graph Hub] Hub Connection Closed'
	//#endregion
}

//#region Connection actions

export const hubActivated = createAction(
	AggregateGraphHubActionTypes.HubActivated
)

export const hubDeactivated = createAction(
	AggregateGraphHubActionTypes.HubDeactivated
)

export const connectHubAborted = createAction(
	AggregateGraphHubActionTypes.ConnectHubAborted
)

export const connectHubRequested = createAction(
	AggregateGraphHubActionTypes.ConnectHubRequested
)

export const connectHubCompleted = createAction(
	AggregateGraphHubActionTypes.ConnectHubCompleted
)

export const hubConnectionClosed = createAction(
	AggregateGraphHubActionTypes.HubConnectionClosed,
	props<{ errorMessage: string | undefined }>()
)

//#endregion