import { Injectable } from '@angular/core';
import { LoadingController, LoadingOptions } from '@ionic/angular/standalone';
import { IdService } from './id.service';

const overlays: { [id: string]: HTMLIonLoadingElement } = {};

@Injectable({
	providedIn: 'root'
})
export class LoadingOverlayService {

	constructor(private loadingController: LoadingController, private idService: IdService) {
	}

	async displayLoadingOverlay(message?: string) {
		const id = this.idService.generateGuidWithoutDashes();
		const options: LoadingOptions = { id: id };

		if (message) options['message'] = message;

		const overlay = await this.loadingController.create(options);
		await overlay.present();

		overlays[id] = overlay;
		return id;
	}

	async removeLoadingOverlay(id: string) {
		const overlay = overlays[id];
		if (overlay) {
			delete overlays[id];
			await overlay.dismiss();
		}
	}

	waitThenRemoveAllOverlays() {
		setTimeout(async () => {
			Object.keys(overlays).forEach(async id => {
				const overlay = overlays[id];
				if (overlay) {
					delete overlays[id];
					await overlay.dismiss();
				}
			});
		}, 500);
	}
}