<p class="form-control-label validation-group-label">{{ label }}</p>
@for (formControl of formArray.controls; track formControl; let index = $index) {
	<app-text-input class="validatable-control" [readOnly]="readOnly" [label]="(index + 1).toString()" [placeholder]="placeholder"
					[formControl]="formControl" (click)="controlClicked()" @enterLeave [@.disabled]="readOnly">
		@if (!readOnly) {
			<app-svg-icon class="remove" name="close-sharp" (click)="removeItem(index)"></app-svg-icon>
		}
	</app-text-input>
}
@if (!readOnly) {
	<app-item class="rounded-border-box add" (click)="addItem('', true)" @enterLeave>
		<app-svg-icon name="add-circle-outline"></app-svg-icon>
		<app-item-label>{{ addText }}</app-item-label>
	</app-item>
}