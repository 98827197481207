import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SheetSlotComponentBase } from 'src/app/services/sheets-renderer.service';
import { FoodBowlOutAction, NoteAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { TickValueComponent } from '../../tick-value/tick-value.component';

@Component({
	selector: 'app-food-bowl-out-slot',
	templateUrl: './food-bowl-out-slot.component.html',
	styleUrl: './food-bowl-out-slot.component.scss',
	standalone: true,
	imports: [TickValueComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FoodBowlOutSlotComponent implements SheetSlotComponentBase {

	@Input() action!: FoodBowlOutAction;
	value = null;

	constructor() {
	}


}