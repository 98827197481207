import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SheetItemComponentBase } from 'src/app/services/sheets-renderer.service';
import { Row } from 'src/app/types/aggregate-graph.types';
import { ItemContentComponent } from '../../item-content/item-content.component';

import { InstructionBoxComponent } from '../../instruction-box/instruction-box.component';
import { NegativePressureWoundTherapyInstructions, PressureMode } from 'src/app/types/sheet-instruction.types';

@Component({
	selector: 'app-negative-pressure-wound-therapy-item',
	templateUrl: './negative-pressure-wound-therapy-item.component.html',
	styleUrl: './negative-pressure-wound-therapy-item.component.scss',
	standalone: true,
	imports: [ItemContentComponent, InstructionBoxComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NegativePressureWoundTherapyItemComponent implements SheetItemComponentBase {

	row!: Row;
	instructions!: NegativePressureWoundTherapyInstructions;
	pressureMode!: string[];
    @Input() startLabel!: string;
    @Input() endLabel!: string;
	@Input('row') set updateRow(row: Row) {
		this.row = row;
		this.instructions = row.instructions as NegativePressureWoundTherapyInstructions;
		this.pressureMode = Object.values(this.instructions).map(instruction => this.getPressureMode(instruction.pressureMode));
	}
	
	constructor() {
	}

	private getPressureMode(pressureMode: PressureMode | null) {
		switch (pressureMode) {
			case PressureMode.Continuous: return 'Continuous' ;
			case PressureMode.Intermittent: return 'Intermittent';
			default: return '';
		}
	}
}