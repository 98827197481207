<app-action-form [item]="item" [slot]="slot" [form]="form">
	<div class="form-section app-margin-horizontal app-padding">
		<div class="form-group" [formGroup]="form">
			<app-heading  background="secondary-2" size="small">Motor activity</app-heading>
			<app-checkbox label="Normal gait, normal spinal reflexes (6)" formControlName="motorActvityScoreOne"></app-checkbox>
			<app-checkbox label="Hemiparesis, tetraparesis, or decerebrate activity (5)" formControlName="motorActvityScoreTwo"></app-checkbox>
			<app-checkbox label="Recumbent, intermittent extensor ridigity (4)" formControlName="motorActvityScoreThree"></app-checkbox>
			<app-checkbox label="Recumbent, constant extensor ridigity (3)" formControlName="motorActvityScoreFour"></app-checkbox>
			<app-checkbox label="Recumbent, constant extensor ridigity with opisthotonus (2)" formControlName="motorActvityScoreFive"></app-checkbox>
			<app-checkbox label="Recumbent, hypotonia of muscles, depressed or absent spinal reflexes (1)" formControlName="motorActvityScoreSix"></app-checkbox>
		</div>
		<div class="form-group" [formGroup]="form">
			<app-heading background="secondary-2" size="small">Brain stem reflexes</app-heading>
			<app-checkbox label="Normal pupillary light reflexes and oculocephalic reflexes (6)" formControlName="reflexesScoreOne"></app-checkbox>
			<app-checkbox label="Slow pupillary light reflexes and normal to reduced oculocephalic reflexes (5)" formControlName="reflexesScoreTwo"></app-checkbox>
			<app-checkbox label="Bilateral unresponsive miosis with normal to reduced oculocephalic reflexes (4)" formControlName="reflexesScoreThree"></app-checkbox>
			<app-checkbox label="Unilateral, unresponsive mydriasis with reduced to absent oculocephalic reflexes (2)" formControlName="reflexesScoreFour"></app-checkbox>
			<app-checkbox label="Bilateral, unresponsive mydriasis with reduced to absent oculocephalic reflexes (1)" formControlName="reflexesScoreFive"></app-checkbox>
		</div>
		<div class="form-group" [formGroup]="form">
			<app-heading background="secondary-2" size="small">Level of consciousness</app-heading>
			<app-checkbox label="Occasional periods of alertness and responsive to environment (6)" formControlName="consciousnessScoreOne"></app-checkbox>
			<app-checkbox label="Depression or delirium, capable of responding but response may be inappropriate (5)" formControlName="consciousnessScoreTwo"></app-checkbox>
			<app-checkbox label="Semicomatose, responsive to visual stimuli (4)" formControlName="consciousnessScoreThree"></app-checkbox>
			<app-checkbox label="Semicomatose, responsive to auditory stimuli (3)" formControlName="consciousnessScoreFour"></app-checkbox>
			<app-checkbox label="Semicomatose, responsive to only repeated noxious stimuli (2)" formControlName="consciousnessScoreFive"></app-checkbox>
			<app-checkbox label="Comatose, unresponsive to repeated noxious stimuli (1)" formControlName="consciousnessScoreSix"></app-checkbox>
		</div>
		<div class="form-group" [formGroup]="form">
			<app-text-input label="Total score" placeholder="Value" formControlName="totalScore" inputMode="numeric"></app-text-input>
		</div>
		<app-common-action-controls [action]="action" [form]="form"></app-common-action-controls>
	</div>
</app-action-form>
