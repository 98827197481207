import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'app-instruction-box',
	templateUrl: './instruction-box.component.html',
	styleUrl: './instruction-box.component.scss',
	standalone: true,
	imports: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstructionBoxComponent {

	constructor() {}
}