<app-item>
    <div class="rounded-border-box" (click)="controlClicked()">
        <app-item-label class="validation-label">{{ label }}</app-item-label>
        @if (readOnly) {
            <app-item-value [value]="value"></app-item-value>
        }
        @else {
            <app-item-input [placeholder]="placeholder" [value]="value" [maxlength]="maxlength" (valueChange)="valueChanged($event)" (itemFocus)="itemFocused()"></app-item-input>
        }
    </div>
	@if (unitLabel) {
    	<app-item-label class="rounded-border-box unit-label" (click)="controlClicked()">{{ unitLabel }}</app-item-label>
	}
	@else {
		<app-item-label class="rounded-border-box unit-select" (click)="presentUnitsPopover($event)">{{ unit }}</app-item-label>
	}
</app-item>