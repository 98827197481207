import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface TeamMember {
	userId: number;
	fullName: string;
	isTeamAdministrator: boolean;
	canEditMemberList: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class TeamsService {

	constructor(private httpClient: HttpClient) {
	}

	enableTeamMembership(teamId: string): Observable<void> {
		return this.httpClient.post<void>(`/api/teams/${teamId}/membership-enabled`, null);
	}

	disableTeamMembership(teamId: string): Observable<void> {
		return this.httpClient.post<void>(`/api/teams/${teamId}/membership-disabled`, null);
	}

	addTeamAdministrator(teamId: string, userId: number): Observable<TeamMember> {
		return this.httpClient.post<TeamMember>(`/api/teams/${teamId}/administrators`, userId);
	}

	removeTeamAdministrator(teamId: string, userId: number): Observable<void> {
		return this.httpClient.delete<void>(`/api/teams/${teamId}/administrators/${userId}`);
	}

	addTeamMember(teamId: string, userId: number): Observable<TeamMember> {
		return this.httpClient.post<TeamMember>(`/api/teams/${teamId}/members`, userId);
	}

	removeTeamMember(teamId: string, userId: number): Observable<void> {
		return this.httpClient.delete<void>(`/api/teams/${teamId}/members/${userId}`);
	}

	setCanEditMemberListState(teamId: string, userId: number, status: boolean): Observable<void> {
		return this.httpClient.put<void>(`/api/teams/${teamId}/members/${userId}/can-edit-member-list`, JSON.stringify(status), { headers: { 'Content-Type': 'application/json' } });
	}

	getTeamMembers(teamId: string): Observable<TeamMember[]> {
		return this.httpClient.get<TeamMember[]>(`/api/teams/${teamId}/members`);
	}
}
