import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { HeadingComponent } from 'src/app/components/heading/heading.component';
import { CheckboxControl } from 'src/app/form-controls/checkbox/checkbox.control';
import { TextInputControl } from 'src/app/form-controls/text-input/text-input.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { PainScoreCatAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface PainScoreCatActionFormGroup {
	earAbsent: FormControl<boolean | null>;
	earModeratlyPresent: FormControl<boolean | null>;
	earMarkedlyPresent: FormControl<boolean | null>;
	orbitalAbsent: FormControl<boolean | null>;
	orbitalModeratlyPresent: FormControl<boolean | null>;
	orbitalMarkedlyPresent: FormControl<boolean | null>;
	muzzleAbsent: FormControl<boolean | null>;
	muzzleModeratlyPresent: FormControl<boolean | null>;
	muzzleMarkedlyPresent: FormControl<boolean | null>;
	whiskerAbsent: FormControl<boolean | null>;
	whiskerModeratlyPresent: FormControl<boolean | null>;
	whiskerMarkedlyPresent: FormControl<boolean | null>;
	headAbsent: FormControl<boolean | null>;
	headModeratlyPresent: FormControl<boolean | null>;
	headMarkedlyPresent: FormControl<boolean | null>;
	totalScore: FormControl<number | null>;

}

@Component({
	selector: 'app-pain-score-cat-action-controls',
	templateUrl: './pain-score-cat-action-controls.component.html',
	styleUrls: ['../../../common.scss', './pain-score-cat-action-controls.component.scss'],
	standalone: true,
	imports: [HeadingComponent, CommonActionControlsComponent, ActionFormComponent, ReactiveFormsModule, CheckboxControl, TextInputControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PainScoreCatActionControlsComponent implements SheetActionControlsBase {

	slot!: Slot | null;
	action!: PainScoreCatAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as PainScoreCatAction;
	}

	@Input() form!: FormGroup<PainScoreCatActionFormGroup>;
	@Input() item!: Row | null;

	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.form.addControl('earAbsent', this.formBuilder.control(this.action?.earAbsent ?? null));
		this.form.addControl('earModeratlyPresent', this.formBuilder.control(this.action?.earModeratlyPresent ?? null));
		this.form.addControl('earMarkedlyPresent', this.formBuilder.control(this.action?.earMarkedlyPresent ?? null));
		this.form.addControl('orbitalAbsent', this.formBuilder.control(this.action?.orbitalAbsent ?? null));
		this.form.addControl('orbitalModeratlyPresent', this.formBuilder.control(this.action?.orbitalModeratlyPresent ?? null));
		this.form.addControl('orbitalMarkedlyPresent', this.formBuilder.control(this.action?.orbitalMarkedlyPresent ?? null));
		this.form.addControl('muzzleAbsent', this.formBuilder.control(this.action?.muzzleAbsent ?? null));
		this.form.addControl('muzzleModeratlyPresent', this.formBuilder.control(this.action?.muzzleModeratlyPresent ?? null));
		this.form.addControl('muzzleMarkedlyPresent', this.formBuilder.control(this.action?.muzzleMarkedlyPresent ?? null));
		this.form.addControl('whiskerAbsent', this.formBuilder.control(this.action?.whiskerAbsent ?? null));
		this.form.addControl('whiskerModeratlyPresent', this.formBuilder.control(this.action?.whiskerModeratlyPresent ?? null));
		this.form.addControl('whiskerMarkedlyPresent', this.formBuilder.control(this.action?.whiskerMarkedlyPresent ?? null));
		this.form.addControl('headAbsent', this.formBuilder.control(this.action?.headAbsent ?? null));
		this.form.addControl('headModeratlyPresent', this.formBuilder.control(this.action?.headModeratlyPresent ?? null));
		this.form.addControl('headMarkedlyPresent', this.formBuilder.control(this.action?.headMarkedlyPresent ?? null));
		this.form.addControl('totalScore', this.formBuilder.control(this.action?.totalScore ?? null));
	}
}