import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UnitQuantity, UnitQuantityControl } from 'src/app/form-controls/unit-quantity/unit-quantity.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { WaterDiaryAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface WaterDiaryActionFormGroup {
	offered: FormControl<UnitQuantity | null>;
	drunk: FormControl<UnitQuantity | null>;
	refilled: FormControl<UnitQuantity | null>;
	totalConsumed: FormControl<UnitQuantity | null>;
}

@Component({
	selector: 'app-water-diary-action-controls',
	templateUrl: './water-diary-action-controls.component.html',
	styleUrls: ['../../../common.scss', './water-diary-action-controls.component.scss'],
	standalone: true,
	imports: [CommonActionControlsComponent, ActionFormComponent, ReactiveFormsModule, UnitQuantityControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WaterDiaryActionControlsComponent implements SheetActionControlsBase, OnInit {

	slot!: Slot | null;
	action!: WaterDiaryAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as WaterDiaryAction;
	}

	@Input() form!: FormGroup<WaterDiaryActionFormGroup>;
	@Input() item!: Row | null;
	
	constructor(private formBuilder: FormBuilder) {
	}

    ngOnInit(): void {
        this.form.addControl('offered', this.formBuilder.control(this.action?.offered ?? null));
        this.form.addControl('drunk', this.formBuilder.control(this.action?.drunk ?? null));
        this.form.addControl('refilled', this.formBuilder.control(this.action?.refilled ?? null));
        this.form.addControl('totalConsumed', this.formBuilder.control(this.action?.totalConsumed ?? null));
    }
}