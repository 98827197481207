import { createReducer, on } from '@ngrx/store';
import * as authActions from '../auth/auth.actions';
import * as bodyPartSearchActions from './body-parts-search.actions';
import { ImagingModalityBodyPart } from 'src/app/services/body-parts.service';

export interface State {
	queryTerm: string;
	modality: string;
	newSearchInProgress: boolean;
	searchResults: ImagingModalityBodyPart[];
	resultsPerAppend: number;
	appendsRetrieved: number;
	lastResultsRetrievedCount: number;
}

export const initialState: State = {
	queryTerm: '',
	modality: '',
	newSearchInProgress: false,
	searchResults: [],
	resultsPerAppend: 1000, // todo: see the results selector. Setting this value to 1000 to ensure we get all the results and then filter in the selector. This is a temporary fix
	//resultsPerAppend: 50, // this is a constant value, change this if more results should be displayed per fetch. Be careful setting it too low, if one page doesn't fill the scrollable parent, the infinite scroll will not fire
	appendsRetrieved: 0,
	lastResultsRetrievedCount: 0
}

export const bodyPartsSearchReducer = createReducer(
	initialState,
	on(bodyPartSearchActions.newSearchRequested, (state, { queryTerm, modality }) => ({
		...state,
		queryTerm: queryTerm,
		modality: modality,
		newSearchInProgress: true,
		searchResults: [],
		appendsRetrieved: 0,
		lastResultsRetrievedCount: 0
	})),
	on(bodyPartSearchActions.newSearchSucceeded, (state, { searchResults }) => ({
		...state,
		newSearchInProgress: false,
		searchResults: searchResults,
		lastResultsRetrievedCount: searchResults.length
	})),
	on(bodyPartSearchActions.newSearchFailed, () => initialState),
	on(bodyPartSearchActions.appendResultsSucceeded, (state, { searchResults }) => ({
		...state,
		searchResults: [...state.searchResults, ...searchResults],
		appendsRetrieved: state.appendsRetrieved + 1,
		lastResultsRetrievedCount: searchResults.length
	})),
	on(bodyPartSearchActions.appendResultsFailed, () => initialState),
	on(bodyPartSearchActions.resultsCleared, () => initialState),
	on(authActions.clearStateRequested, () => initialState)
);