import { createReducer, on } from '@ngrx/store';
import * as clientActions from './client.actions';

export interface State {
	invalidVersionAlertDisplayInitiated: boolean;
}

export const initialState: State = {
	invalidVersionAlertDisplayInitiated: false
}

export const clientReducer = createReducer(
	initialState,
	on(clientActions.showInvalidVersionAlertInitiated, (state) => ({
		...state,
		invalidVersionAlertDisplayInitiated: true
	}))
);