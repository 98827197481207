import { createSelector } from '@ngrx/store';
import { TeamMember } from 'src/app/services/teams.service';
import { AppState } from '../reducers';

export const selectServiceId = (state: AppState) => state.serviceSettings.serviceId;
export const selectServiceSettingsLoaded = (state: AppState) => state.serviceSettings.serviceSettingsLoaded;
export const selectTeamMembershipEnabled = (state: AppState) => state.serviceSettings.teamMembershipEnabled;
export const selectTeamMembers = (state: AppState) => state.serviceSettings.teamMembers;

export const serviceId = createSelector(
	selectServiceId,
	(serviceId) => serviceId
);

export const serviceSettingsLoaded = createSelector(
	selectServiceSettingsLoaded,
	(teamMembersLoaded) => teamMembersLoaded
);

export const teamMembershipEnabled = createSelector(
	selectTeamMembershipEnabled,
	(teamMembershipEnabled) => teamMembershipEnabled
);

export const teamMembers = createSelector(
	selectTeamMembers,
	(teamMembers) => [...teamMembers].sort((a: TeamMember, b: TeamMember) => a.fullName.localeCompare(b.fullName))
);

export const teamAdministrators = createSelector(
	teamMembers,
	(teamMembers) => teamMembers.filter(teamMember => teamMember.isTeamAdministrator)
);