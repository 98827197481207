import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import * as fromUserSearch from './user-search.reducer';

export const selectUserSearch = (state: AppState) => state.userSearch;

export const searchParams = createSelector(
	selectUserSearch,
	(state: fromUserSearch.State) => ({
		queryTerm: state.queryTerm,
		participationTypeFilters: state.participationTypeFilters,
		resultsPerAppend: state.resultsPerAppend,
		appendsRetrieved: state.appendsRetrieved
	})
);

export const queryTerm = createSelector(
	selectUserSearch,
	(state: fromUserSearch.State) => state.queryTerm
);

export const newSearchInProgress = createSelector(
	selectUserSearch,
	(state: fromUserSearch.State) => state.newSearchInProgress
);

export const searchResults = createSelector(
	selectUserSearch,
	(state: fromUserSearch.State) => state.searchResults
);

export const morePagesExist = createSelector(
	selectUserSearch,
	(state: fromUserSearch.State) => state.lastResultsRetrievedCount === state.resultsPerAppend
);