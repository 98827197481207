import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SelectControl, SelectItem } from 'src/app/form-controls/select/select.control';
import { Unit } from 'src/app/form-controls/shared/popovers/unit-popover/unit.popover';
import { UnitValue, UnitValueControl } from 'src/app/form-controls/unit-value/unit-value.control';
import { unitValueRequired } from 'src/app/form-validators/unit-value-required.validator';
import { SheetItemService } from 'src/app/services/sheet-item.service';
import { SheetItemDetailConfig, SheetItemFormBase } from 'src/app/services/sheets-renderer.service';
import { Row } from 'src/app/types/aggregate-graph.types';
import { MedicationAmountUnit, MedicationDoseUnit } from 'src/app/types/sheet-action-aggregate-graph.types';
import { MedicationRedLabelInstruction, MedicationRoute } from 'src/app/types/sheet-instruction.types';
import { InstructionsFormComponent } from '../../instructions-form/instructions-form.component';

interface MedicationRedLabelInstructionFormGroup {
	dose: FormControl<UnitValue | null>;
	amountToAdminister: FormControl<UnitValue | null>;
	medicationRoute: FormControl<MedicationRoute | null>;
}

@Component({
	selector: 'app-medication-red-label-item-form',
	templateUrl: './medication-red-label-item-form.component.html',
	styleUrl: './medication-red-label-item-form.component.scss',
	standalone: true,
	imports: [ReactiveFormsModule, InstructionsFormComponent, SelectControl, UnitValueControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MedicationRedLabelItemFormComponent implements SheetItemFormBase, OnInit {

	@Input() instructionTypeName!: string;
	@Input() item!: Row | null;
	@Input() minDate!: string;
	@Input() maxDate!: string;
	@Input() instructionsFormArray!: FormArray;
  	@Input() itemConfig!: SheetItemDetailConfig;

	medicationRoutes: SelectItem[] = [
		{ text: 'IV', value: MedicationRoute.Iv },
		{ text: 'IM', value: MedicationRoute.Im },
		{ text: 'SQ', value: MedicationRoute.Sq },
		{ text: 'Oral', value: MedicationRoute.Oral },
		{ text: 'Transmucosal', value: MedicationRoute.Transmucosal },
		{ text: 'Topical', value: MedicationRoute.Topical },
		{ text: 'Rectal', value: MedicationRoute.Rectal },
		{ text: 'Intranasal', value: MedicationRoute.Intranasal },
		{ text: 'Inhalation', value: MedicationRoute.Inhalation },
		{ text: 'Transdermal', value: MedicationRoute.Transdermal },
		{ text: 'OS', value: MedicationRoute.Os },
		{ text: 'OD', value: MedicationRoute.Od },
		{ text: 'OU', value: MedicationRoute.Ou }
	];

	doseUnits: Unit[] = [
		{ label: MedicationDoseUnit.MgKg },
		{ label: MedicationDoseUnit.UgKg }
	];

	amountUnits: Unit[] = [
		{ label: MedicationAmountUnit.Mg },
		{ label: MedicationAmountUnit.Ml },
		{ label: MedicationAmountUnit.Ug },
		{ label: MedicationAmountUnit.L },
		{ label: MedicationAmountUnit.G },
		{ label: MedicationAmountUnit.Kg },
		{ label: MedicationAmountUnit.Drop },
		{ label: MedicationAmountUnit.Graduation },
		{ label: MedicationAmountUnit.Tablet },
		{ label: MedicationAmountUnit.Capsule },
		{ label: MedicationAmountUnit.Spray },
		{ label: MedicationAmountUnit.Pump }
	];



	constructor(private sheetItemService: SheetItemService, private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.sheetItemService.getInstructions(this.item, this.instructionTypeName, this.itemConfig.scheduleConfig.defaultScheduleType, this.itemConfig.scheduleConfig.defaultRepeatingScheduleFrequency).forEach(instruction => {
			const group: FormGroup<MedicationRedLabelInstructionFormGroup> = this.sheetItemService.buildExistingInstructionGroup(this.instructionTypeName, instruction);
			const medicationInstruction = instruction as MedicationRedLabelInstruction;
			group.addControl('dose', this.formBuilder.control(medicationInstruction.dose ?? null, { validators: unitValueRequired() }));
			group.addControl('amountToAdminister', this.formBuilder.control(medicationInstruction.amountToAdminister ?? null, { validators: unitValueRequired() }));
			group.addControl('medicationRoute', this.formBuilder.control(medicationInstruction.medicationRoute ?? null, { validators: Validators.required }));
			this.instructionsFormArray.push(group);
		});
	}

	addInstruction() {
		const group: FormGroup<MedicationRedLabelInstructionFormGroup> = this.sheetItemService.getNewInstructionGroup(this.instructionTypeName, this.itemConfig.scheduleConfig.defaultScheduleType, this.itemConfig.scheduleConfig.defaultRepeatingScheduleFrequency);
		group.addControl('dose', this.formBuilder.control(null, { validators: unitValueRequired() }));
		group.addControl('amountToAdminister', this.formBuilder.control(null, { validators: unitValueRequired() }));
		group.addControl('medicationRoute', this.formBuilder.control(null, { validators: Validators.required }));
		this.instructionsFormArray.push(group);
	}
}