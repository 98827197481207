import { Injectable } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular/standalone';
import { act, Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { PatientsService } from 'src/app/services/patients.service';
import { CommsPanelChildType, segmentLabels } from 'src/app/types/patient.types';
import { closeAllModals } from 'src/app/types/rxjs-custom-operator.types';
import * as patientGraphActions from '../patient-graph/patient-graph.actions';
import { isDesktop, isMobile } from '../platform/platform.selectors';
import { AppState } from '../reducers';
import * as patientActions from './patient.actions';
import { patientStatus } from './patient.selectors';
import { HttpErrorResponse } from '@angular/common/http';
import { clientErrored } from '../error/error.actions';
import { ErrorService } from 'src/app/services/error.service';

@Injectable()
export class PatientEffects {

	constructor(
		private actions$: Actions, private store: Store<AppState>, private alertController: AlertController, private modalController: ModalController,
		private patientService: PatientsService, private errorService: ErrorService
	) {
	}

	subscribePatientRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientGraphActions.subscribePatientRequested),
			filter(action => action.patientChanged),
			concatLatestFrom(() => [this.store.select(isDesktop), this.store.select(isMobile)]),
			// the "tasks" segment should be selected when a patient is selected from service tasks (they are viewing a task), or,
			// if coming via patient search in desktop view (because the "visit" segment is hidden in desktop view)
			switchMap(([action, isDesktop, isMobile]) => {
				const actions = [];

				actions.push(patientActions.patientSelected({ segmentLabel: action.taskId || isDesktop ? segmentLabels.tasks : segmentLabels.visit }));

				if (!isMobile) actions.push(patientActions.commsPanelChildSelected({ childType: CommsPanelChildType.ReferringVet }));

				return actions;
			})
		)
	);

	unsubscribePatientRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientGraphActions.unsubscribePatientRequested),
			map(() => patientActions.patientDeselected())
		)
	);

	isDesktopChanged$ = createEffect(() =>
		this.store.select(isDesktop).pipe(
			concatLatestFrom(() => this.store.select(patientStatus)),
			// if a patient is selected and the selected segment is "visit", it gets hidden when switching to desktop, so select the "tasks" segment
			filter(([isDesktop, patientStatus]) => patientStatus.patientSelected && isDesktop && patientStatus.segmentLabel === segmentLabels.visit),
			map(() => patientActions.segmentChanged({ label: segmentLabels.tasks }))
		)
	);

	isMobileChanged$ = createEffect(() =>
		this.store.select(isMobile).pipe(
			concatLatestFrom(() => this.store.select(patientStatus)),
			// if a patient is selected and the selected segment is "sheet", it gets hidden when switching to desktop, so select the "visit" segment
			filter(([isMobile, patientStatus]) => patientStatus.patientSelected && isMobile && patientStatus.segmentLabel === segmentLabels.sheet),
			map(() => patientActions.segmentChanged({ label: segmentLabels.visit }))
		)
	);

	visitAdded$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientGraphActions.visitAdded),
			concatMap(() => from(this.alertController.create({
				subHeader: 'New Visit Added',
				message: 'A new visit has been added for this patient. The record will be updated.',
				buttons: ['Ok'],
				backdropDismiss: false
			})).pipe(
				switchMap(alert => from(alert.present()).pipe(map(() => alert))),
				switchMap(alert => from(alert.onDidDismiss()).pipe(
					map(() => patientGraphActions.reloadPatientRequested()),
					closeAllModals(this.modalController)
				))
			))
		)
	);

	saveProblemsRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientActions.saveProblemsRequested),
			mergeMap(action => this.patientService.setProblems(action.patientId, action.problems).pipe(
				map(() => patientGraphActions.patientUpdated({ partialPatient: { id: action.patientId, problems: action.problems } })),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Save problems failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	saveAlertsRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientActions.saveAlertsRequested),
			mergeMap(action => this.patientService.setAlerts(action.patientId, action.alerts).pipe(
				map(() => patientGraphActions.patientUpdated({ partialPatient: { id: action.patientId, alerts: action.alerts } })),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Save alerts failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);
}