import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { CoercionService } from 'src/app/services/coercion.service';

@Component({
	selector: 'app-item-label',
	templateUrl: './item-label.component.html',
	styleUrl: './item-label.component.scss',
	standalone: true,
	imports: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemLabelComponent {

	private isTruncatable: boolean = false;
	@Input() set truncate(value: any) { this.isTruncatable = this.coercionService.coerceToBoolean(value) }
	@HostBinding('class.truncate') get isTruncatableComponent() { return this.isTruncatable }

	constructor(private coercionService: CoercionService) {
	}
}