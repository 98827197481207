import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel as SignalRLogLevel } from '@microsoft/signalr';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store, select } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { firstValueFrom, from, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { AppSettings } from 'src/app/app-settings';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { ErrorService } from 'src/app/services/error.service';
import { GraphAggregate, GraphAggregateChange, GraphChangeContext, Row, Sheet } from 'src/app/types/aggregate-graph.types';
import * as authActions from '../auth/auth.actions';
import { accessToken } from '../auth/auth.selectors';
import * as errorActions from '../error/error.actions';
import * as patientGraphActions from '../patient-graph/patient-graph.actions';
import { AppState } from '../reducers';
import * as serviceGraphActions from '../service-graph/service-graph.actions';
import * as sheetGraphActions from '../sheet-graph/sheet-graph.actions';
import * as userGraphActions from '../user-graph/user-graph.actions';
import * as aggregateGraphHubActions from './aggregate-graph-hub.actions';
import { hubActivated } from './aggregate-graph-hub.selectors';

@Injectable()
export class AggregateGraphHubEffects {

	private hubConnection: HubConnection;

	constructor(
		private actions$: Actions,
		private store: Store<AppState>,
		private currentUserService: CurrentUserService,
		private errorService: ErrorService) {

		this.hubConnection = new HubConnectionBuilder()
			.withUrl(AppSettings.apiEndpoint + '/aggregateSubscriptionsHub', { accessTokenFactory: () => firstValueFrom(this.store.pipe(select(accessToken))) })
			.configureLogging(SignalRLogLevel.Error)
			.build();

		this.registerUserHubHandlers();
		this.registerPatientRecordHubHandlers();
		this.registerSheetsHubHandlers();
		this.registerServiceHubHandlers();

		this.hubConnection.onclose(async (error?: Error) => {
			this.store.dispatch(aggregateGraphHubActions.hubConnectionClosed({ errorMessage: error ? this.errorService.getErrorMessage(error) : undefined }));
		});
	}

	//#region Connection handlers

	activateHub$ = createEffect(() =>
		this.actions$.pipe(
			// authenticate succeeds (via the authenticate endpoint) only if the user is the app store reviewer user (they do not need to go through code verification)
			ofType(authActions.authenticatePasswordSucceeded, authActions.verifyCodeSucceeded, authActions.authenticatePinSucceeded, authActions.tokenRefreshSucceeded),
			concatLatestFrom(() => this.store.select(hubActivated)),
			// the hub will already be activated if the refresh token was refreshed due to a 401 response via the http interceptor, so only activate if not already activated
			filter(([, hubActivated]) => !hubActivated),
			map(() => aggregateGraphHubActions.hubActivated())
		)
	);

	deactivateHub$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authActions.logoutInitiated, authActions.tokenRefreshFailed),
			map(() => aggregateGraphHubActions.hubDeactivated())
		)
	);

	hubActivated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(aggregateGraphHubActions.hubActivated),
			map(() => aggregateGraphHubActions.connectHubRequested())
		)
	);

	hubDeactivated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(aggregateGraphHubActions.hubDeactivated),
			exhaustMap(() => from(this.hubConnection.stop()).pipe(
				catchError(error => of(errorActions.clientErrored({ toastMessage: 'Stop hub failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		),
		{ dispatch: false }
	);

	connectHubRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(aggregateGraphHubActions.connectHubRequested),
			exhaustMap(() => this.currentUserService.pingServer().pipe(
				switchMap(() => from(this.hubConnection.start()).pipe(
					map(() => aggregateGraphHubActions.connectHubCompleted()),
					catchError(error => [
						errorActions.clientErrored({ toastMessage: 'Start hub failed', errorMessage: this.errorService.getErrorMessage(error) }),
						authActions.logoutInitiated()
					])
				)),
				// in the case of 403, the user is inactive. Otherwise it will be 5xx error
				catchError((error: HttpErrorResponse) => [
					errorActions.httpRequestErrored({ statusCode: error.status, requestUrl: error.url ?? 'Url missing!!!' }),
					authActions.logoutInitiated()
				])
			))
		)
	);

	hubConnectionClosed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(aggregateGraphHubActions.hubConnectionClosed),
			map(payload => payload.errorMessage),
			concatLatestFrom(() => this.store.select(hubActivated)),
			filter(([, hubActivated]) => hubActivated),
			mergeMap(([errorMessage]) => {
				const actions: TypedAction<any>[] = [aggregateGraphHubActions.connectHubRequested()];
				if (errorMessage) actions.push(errorActions.signalRErrored({ errorMessage: errorMessage }));
				return actions;
			})
		)
	);

	//#endregion

	//#region User handlers

	private registerUserHubHandlers() {
		this.hubConnection.on('UserGraphGenerated', (aggregates: GraphAggregate[]) => {
			this.store.dispatch(userGraphActions.userGraphGenerated({ aggregates: aggregates }));
		});

		this.hubConnection.on('UserGraphChanged', (changes: GraphAggregateChange[], context: GraphChangeContext) => {
			this.store.dispatch(userGraphActions.userGraphChanged({ changes: changes, context: context }));
		});

		this.hubConnection.on('UserGraphSystemRestarted', () => {
			this.store.dispatch(userGraphActions.userGraphSystemRestarted());
		});
	}

	subscribeUserRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(userGraphActions.subscribeUserRequested),
			switchMap(() => from(this.hubConnection.send('AddUserSubscription')).pipe(
				catchError(error => of(errorActions.clientErrored({ toastMessage: 'Subscribe user failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		),
		{ dispatch: false }
	);

	//#endregion

	//#region Patient Record handlers

	private registerPatientRecordHubHandlers() {
		this.hubConnection.on('PatientRecordGraphGenerated', (patientId: string, aggregates: GraphAggregate[]) => {
			this.store.dispatch(patientGraphActions.patientGraphGenerated({ patientId: patientId, aggregates: aggregates }));
		});

		this.hubConnection.on('PatientRecordGraphChanged', (patientId: string, changes: GraphAggregateChange[], context: GraphChangeContext) => {
			this.store.dispatch(patientGraphActions.patientGraphChanged({ patientId: patientId, changes: changes, context: context }));
		});

		this.hubConnection.on('PatientRecordGraphSystemRestarted', () => {
			this.store.dispatch(patientGraphActions.patientGraphSystemRestarted());
		});
	}

	subscribePatientRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientGraphActions.subscribePatientRequested),
			concatMap(payload => from(this.hubConnection.send('AddPatientRecordSubscription', payload.patientId)).pipe(
				catchError(error => of(errorActions.clientErrored({ toastMessage: 'Subscribe patient failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		),
		{ dispatch: false }
	);

	unsubscribePatientRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientGraphActions.unsubscribePatientRequested),
			concatMap(() => from(this.hubConnection.send('RemovePatientRecordSubscription')).pipe(
				catchError(error => of(errorActions.clientErrored({ toastMessage: 'Unsubscribe patient failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		),
		{ dispatch: false }
	);

	//#endregion

	//#region Sheets handlers

	private registerSheetsHubHandlers() {
		this.hubConnection.on('SheetGenerated', (sheet: Sheet) => {
			this.store.dispatch(sheetGraphActions.sheetGraphGeneratedMessageReceived({ sheet: sheet }));
		});

		this.hubConnection.on('SheetResized', (sheetId: string, changes: GraphAggregateChange[], context: GraphChangeContext) => {
			this.store.dispatch(sheetGraphActions.sheetResizedMessageReceived({ sheetId: sheetId, partialSheet: changes[0].aggregate, context: context }));
		});

		this.hubConnection.on('RowChanged', (sheetId: string, row: Row, context: GraphChangeContext) => {
			this.store.dispatch(sheetGraphActions.rowChangedMessageReceived({ sheetId: sheetId, row: row, context: context }));
		});

		// dev note: SheetActorInitialised is called when the actor is created: first actor instance for sheetId or due to actor crash. So client will get the
		//           payload twice if first subscription to a sheetId
		this.hubConnection.on('SheetActorInitialised', (sheetId: string) => {
			this.store.dispatch(sheetGraphActions.sheetGraphSystemInitialised({ sheetId: sheetId }));
		});
	}

	subscribeSheetRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetGraphActions.subscribeSheetRequested),
			concatMap(payload => from(this.hubConnection.send('AddSheetSubscription', payload.sheetId, payload.granularity)).pipe(
				catchError(error => of(errorActions.clientErrored({ toastMessage: 'Subscribe Sheet failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		),
		{ dispatch: false }
	);

	resubscribeSheetRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetGraphActions.resubscribeSheetRequested),
			concatMap(payload => from(this.hubConnection.send('UpdateSheetSubscription', payload.sheetId, payload.granularity)).pipe(
				catchError(error => of(errorActions.clientErrored({ toastMessage: 'Resubscribe Sheet failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		),
		{ dispatch: false }
	);

	unsubscribeSheetRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetGraphActions.unsubscribeSheetRequested),
			concatMap(payload => from(this.hubConnection.send('RemoveSheetSubscription', payload.sheetId)).pipe(
				catchError(error => of(errorActions.clientErrored({ toastMessage: 'Unsubscribe Sheet failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		),
		{ dispatch: false }
	);

	//#endregion

	//#region Service handlers

	private registerServiceHubHandlers() {
		this.hubConnection.on('ServiceGraphGenerated', (serviceId: string, aggregates: GraphAggregate[]) => {
			this.store.dispatch(serviceGraphActions.serviceGraphGenerated({ serviceId: serviceId, aggregates: aggregates }));
		});

		this.hubConnection.on('ServiceGraphChanged', (serviceId: string, changes: GraphAggregateChange[], context: GraphChangeContext) => {
			this.store.dispatch(serviceGraphActions.serviceGraphChanged({ serviceId: serviceId, changes: changes, context: context }));
		});

		this.hubConnection.on('ServiceGraphSystemRestarted', () => {
			this.store.dispatch(serviceGraphActions.serviceGraphSystemRestarted());
		});
	}

	subscribeServiceRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(serviceGraphActions.subscribeServiceRequested),
			concatMap(payload => from(this.hubConnection.send('AddServiceSubscription', payload.serviceId)).pipe(
				catchError(error => of(errorActions.clientErrored({ toastMessage: 'Subscribe Service failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		),
		{ dispatch: false }
	);

	unsubscribeServiceRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(serviceGraphActions.unsubscribeServiceRequested),
			concatMap(() => from(this.hubConnection.send('RemoveServiceSubscription')).pipe(
				catchError(error => of(errorActions.clientErrored({ toastMessage: 'Unsubscribe Service failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		),
		{ dispatch: false }
	);

	//#endregion
}