<div (click)="toggle()">
	<app-item class="hoverable-element">
		<ng-content select="app-svg-icon"></ng-content>
		<ng-content select="app-item-label"></ng-content>
		<ng-content select="app-item-note"></ng-content>
		<app-svg-icon [@rotateIcon]="isExpanded ? 'expanded' : 'collapsed'"></app-svg-icon>
	</app-item>
</div>
<div [@expandCollapseContent]="isExpanded ? 'expanded' : 'collapsed'">
	<ng-content></ng-content>
</div>