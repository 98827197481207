import { UnitQuantity } from '../form-controls/unit-quantity/unit-quantity.control';
import { UnitValue } from '../form-controls/unit-value/unit-value.control';
import { Instruction } from './aggregate-graph.types';

export enum MedicationRoute { Iv, Im, Sq, Oral, Transmucosal, Topical, Rectal, Intranasal, Inhalation, Transdermal, Intraosseous, Os, Od, Ou };
export enum FluidsSubcutaneousMedicationRoute { Subcutaneous };
export enum OxygenMethod { Cpap, HighFlow, NasalProngs, OxygenKennel };
export enum PressureMode { Continuous, Intermittent };
export enum SkincareLevel { Preventative, One, Two, Three };
export enum PhysioLevel { Passive, Active, Intensive };
export enum Eye { Left, Right, Both };
export enum DietPreparation { CommercialWellPet, CommercialTherapeutic, HospitalPrepared, Other }
export enum NutritionMethod { PerOs, AssistedFeeding, HandedFeedFromHeight, HandedFeedSmallBits, SupervisedFeeding, TemptToEat }
export enum NutritionMethodV2 { PerOs, AssistedFeeding, FeedFromHeight, HandedFeedSmallBits, SupervisedFeeding, TemptToEat }
export enum NutritionTube { Gastronomy, Jejunostomy, NasalOesphageal, Oesphageal, PEG }
export enum NutritionTubeV2 { Gastrostomy, Jejunostomy, NasalOesophageal, Oesophageal, PEG }
export enum Method { CryoCuff, Pack }
export enum MedicationInfusionRoute { Iv }
export enum MedicationInfusionDilutionUnits { mgMl = "mg/ml", mEqMl = "mEq/ml", μgMl = "μg/ml" }
export type MedicationInstructions = { [id: number]: MedicationInstruction };
export type MedicationInstructionsV2 = { [id: number]: MedicationInstructionV2 };
export type MedicationRedLabelInstructions = { [id: number]: MedicationRedLabelInstruction };
export type MedicationRedLabelInstructionsV2 = { [id: number]: MedicationRedLabelInstructionV2 };
export type FluidsSubcutaneousInstructions = { [id: number]: FluidsSubcutaneousInstruction };
export type FluidsInfusionInstructions = { [id: number]: FluidsInfusionInstruction };
export type FluidsInfusionInstructionsV2 = { [id: number]: FluidsInfusionInstructionV2 };
export type FluidsInfusionRedLabelInstructions = { [id: number]: FluidsInfusionRedLabelInstruction };
export type MedicationInfusionInstructions = { [id: number]: MedicationInfusionInstruction };
export type MedicationInfusionInstructionsV2 = { [id: number]: MedicationInfusionInstructionV2 };
export type MedicationInfusionRedLabelInstructions = { [id: number]: MedicationInfusionRedLabelInstruction };
export type MedicationInfusionRedLabelInstructionsV2 = { [id: number]: MedicationInfusionRedLabelInstructionV2 };
export type FluidsBolusInstructions = { [id: number]: FluidsBolusInstruction };
export type HotCompressInstructions = { [id: number]: HotCompressInstruction };
export type OxygenInstructions = { [id: number]: OxygenInstruction };
export type OxygenInstructionsV2 = { [id: number]: OxygenInstructionV2 };
export enum LubricatedEye { Left, Right, Both };
export type EyeLubricationInstructions = { [id: number]: EyeLubricationInstruction };
export type NutritionInstructions = { [id: number]: NutritionInstruction };
export type NutritionInstructionsV2 = { [id: number]: NutritionInstructionV2 };
export type ColdCompressInstructions = { [id: number]: ColdCompressInstruction };
export type ColdCompressInstructionsV2 = { [id: number]: ColdCompressInstructionV2 };
export type PhysiotherapyInstructions = { [id: number]: PhysiotherapyInstruction };
export type IvtfSupplementationInstructions = { [id: number]: IvtfSupplementationInstruction };
export type SkincareInstructions = { [id: number]: SkincareInstruction };
export type NegativePressureWoundTherapyInstructions = { [id: number]: NegativePressureWoundTherapyInstruction };


export interface MedicationInstruction extends Instruction {
	dose: UnitValue | null;
	amountToAdminister: UnitValue | null;
	medicationRoute: MedicationRoute | null;
}

export interface MedicationInstructionV2 extends Instruction {
	dose: UnitValue | null;
	amountToAdminister: UnitValue | null;
	medicationRoute: MedicationRoute | null;
}

export interface MedicationRedLabelInstruction extends Instruction {
	dose: UnitValue | null;
	amountToAdminister: UnitValue | null;
	medicationRoute: MedicationRoute | null;
}

export interface MedicationRedLabelInstructionV2 extends Instruction {
	dose: UnitValue | null;
	amountToAdminister: UnitValue | null;
	medicationRoute: MedicationRoute | null;
}

export interface FluidsInfusionInstruction extends Instruction {
	rate: UnitQuantity | null;
	totalRate: UnitQuantity | null;
	medicationRoute: MedicationRoute | null;
}

export interface FluidsInfusionInstructionV2 extends Instruction {
	rate: UnitValue | null;
	totalRate: UnitValue | null;
	medicationRoute: MedicationRoute | null;
}

export interface FluidsInfusionRedLabelInstruction extends Instruction {
	rate: UnitQuantity | null;
	totalRate: UnitQuantity | null;
	medicationRoute: MedicationRoute | null;
}

export interface FluidsSubcutaneousInstruction extends Instruction {
	volume: UnitQuantity | null;
	medicationRoute: FluidsSubcutaneousMedicationRoute.Subcutaneous;
}

export interface MedicationInfusionInstruction extends Instruction {
	dose: UnitValue | null;
	totalDose: UnitValue | null;
	rate: UnitQuantity | null;
	medicationRoute: MedicationInfusionRoute | null;
}

export interface MedicationInfusionInstructionV2 extends Instruction {
	dose: UnitValue | null;
	totalDose: UnitValue | null;
	rate: UnitValue | null;
	dilution: UnitQuantity | null;
	medicationRoute: MedicationInfusionRoute | null;
}

export interface FluidsBolusInstruction extends Instruction {
	volume: UnitQuantity | null;
	medicationRoute: MedicationRoute | null;
}

export interface HotCompressInstruction extends Instruction {
	area: string | null;
}

export interface OxygenInstruction extends Instruction {
	percentageToGive: UnitQuantity | null;
	oxygenMethod: OxygenMethod | null;
}

export interface OxygenInstructionV2 extends Instruction {
	percentageToGive: UnitValue | null;
	oxygenMethod: OxygenMethod | null;
}

export interface NegativePressureWoundTherapyInstruction extends Instruction {
	pressureSetting: UnitQuantity | null;
	pressureMode: PressureMode | null;
}

export interface TransferInstruction extends Instruction {
	receivingService: string | null;
}

export interface TransferInstructionV2 extends Instruction {
	receivingService: string | null;
	sendingService: string | null;
}
export interface BloodSampleInstruction extends Instruction {
	testDetails: string | null;
}
export interface SkincareInstruction extends Instruction {
	area: string | null;
	skincareLevel: SkincareLevel | null;
}

export interface PhysiotherapyInstruction extends Instruction {
	physioLevel: PhysioLevel | null;
}

export interface EyeLubricationInstruction extends Instruction {
	lubricatedEye: LubricatedEye | null;
}
export interface NutritionInstruction extends Instruction {
	rerPercentage: number | null;
	dietPreparation: DietPreparation | null;
	diet: string | null;
	nutritionMethod: NutritionMethod | null;
	nutritionTube: NutritionTube | null;
}


export interface NutritionInstructionV2 extends Instruction {
	rerPercentage: number | null;
	dietPreparation: DietPreparation | null;
	diet: string | null;
	nutritionMethod: NutritionMethodV2 | null;
	nutritionTube: NutritionTubeV2 | null;
}

export interface ColdCompressInstruction extends Instruction {
	area: string | null;
	method: Method | null;	
}
export interface ColdCompressInstructionV2 extends Instruction {
	area: string | null;
	method: Method | null;	
}

export interface IvtfSupplementationInstruction extends Instruction {
	dose: UnitQuantity | null;
}

export interface MedicationInfusionRedLabelInstruction extends Instruction {
	dose: UnitValue | null;
	totalDose: UnitValue | null;
	rate: UnitQuantity | null;
	medicationRoute: MedicationInfusionRoute | null;
}

export interface MedicationInfusionRedLabelInstructionV2 extends Instruction {
	dose: UnitValue | null;
	totalDose: UnitValue | null;
	rate: UnitValue | null;
	dilution: UnitQuantity | null;
	medicationRoute: MedicationInfusionRoute | null;
}

export interface EuthanasiaInstruction extends Instruction {
}
