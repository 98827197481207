import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UnitQuantity, UnitQuantityControl } from 'src/app/form-controls/unit-quantity/unit-quantity.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { UrineOutputActionV2 } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface UrineOutputActionFormGroup {
	volume: FormControl<UnitQuantity | null>;
	rate: FormControl<UnitQuantity | null>;
}

@Component({
	selector: 'app-urine-output-action-controls',
	templateUrl: './urine-output-action-controls-v2.component.html',
	styleUrls: ['../../../common.scss', './urine-output-action-controls-v2.component.scss'],
	standalone: true,
	imports: [CommonActionControlsComponent, ActionFormComponent,UnitQuantityControl, ReactiveFormsModule],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UrineOutputActionControlsComponentV2 implements SheetActionControlsBase {

	slot!: Slot | null;
	action!: UrineOutputActionV2;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as UrineOutputActionV2;
	}

	@Input() form!: FormGroup<UrineOutputActionFormGroup>;
	@Input() item!: Row | null;
	
	constructor(private formBuilder: FormBuilder) {
	}

  	ngOnInit(): void {
      	this.form.addControl('volume', this.formBuilder.control(this.action?.volume ?? null));
      	this.form.addControl('rate', this.formBuilder.control(this.action?.rate ?? null));
  	}
}
