<app-item-content [instructionItemContentTemplate]="itemContentBoxes" [row]="row" [startLabel]="startLabel" [endLabel]="endLabel"></app-item-content>
<ng-template #itemContentBoxes let-index="index" let-instruction="instruction" let-overlaps="scheduledAndOverlapsSelectedDate">
	@if (overlaps) {
		@if (instruction.method !== null) {
			<app-instruction-box>{{ method[index] }}</app-instruction-box>
		}
		@if (instruction.area) {
			<app-instruction-box>{{ instruction.area }}</app-instruction-box>
		}
	}
</ng-template>