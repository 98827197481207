import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { ModalController, NavController } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store, select } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { routePaths } from 'src/app/app.routes';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { ErrorService } from 'src/app/services/error.service';
import { LoadingOverlayService } from 'src/app/services/loading-overlay.service';
import { closeAllModals } from 'src/app/types/rxjs-custom-operator.types';
import { clientErrored } from '../error/error.actions';
import { AppState } from '../reducers';
import * as userGraphActions from '../user-graph/user-graph.actions';
import * as authActions from './auth.actions';
import { recentUsers, userName, verificationCodeInfo } from './auth.selectors';

const RecentUsersKey = 'RecentUsers';

@Injectable()
export class AuthEffects {

	constructor(
		private actions$: Actions,
		private navController: NavController,
		private authService: AuthService,
		private alertService: AlertService,
		private store: Store<AppState>,
		private modalController: ModalController,
		private loadingOverlayService: LoadingOverlayService,
		private errorService: ErrorService
	) { }

	loginInitiated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authActions.loginInitiated),
			mergeMap(() => from(Preferences.get({ key: RecentUsersKey })).pipe(
				map(valueObject => authActions.recentUsersLoaded({ recentUsers: valueObject.value ? JSON.parse(valueObject.value) : [] })),
				catchError((error: any) => [
					authActions.recentUsersLoaded({ recentUsers: [] }),
					clientErrored({ toastMessage: 'Startup error', errorMessage: this.errorService.getErrorMessage(error) })
				])
			))
		)
	);

	checkUsernameStatus$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authActions.usernameEntered, authActions.recentUserSelected),
			map(payload => payload.username),
			exhaustMap(username => this.authService.checkStatus(username).pipe(
				map(() => authActions.pinRequired()),
				catchError((error: HttpErrorResponse) => {
					if (error.status === 401) {
						return of(authActions.passwordRequired());
					}
					return of(clientErrored({ toastMessage: 'Check status error', errorMessage: this.errorService.getErrorMessage(error) }));
				})
			))
		)
	);

	passwordEntered$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authActions.passwordEntered),
			map(payload => payload.password),
			concatLatestFrom(() => this.store.pipe(select(userName))),
			exhaustMap(([password, userName]) => this.authService.authenticatePassword({ username: userName!, password }).pipe(
				map(responseObject => {
					if (typeof responseObject === 'string') {
						// usual flow for normal users (i.e. not app store reviewer user)
						return authActions.verificationCodeRequired({ sessionId: responseObject })
					}
					else {
						// current api logic is to not require verification code for app store reviewer user
						return authActions.authenticatePasswordSucceeded({ tokenInfo: responseObject })
					}
				}),
				catchError((error: HttpErrorResponse) => [
					authActions.authenticatePasswordFailed(),
					clientErrored({ toastMessage: 'Invalid username or password', errorMessage: this.errorService.getErrorMessage(error) })
				])
			))
		)
	);

	resendCodeRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authActions.resendCodeRequested),
			withLatestFrom(
				this.store.pipe(select(verificationCodeInfo))
			),
			exhaustMap(([, verificationCodeInfo]) => this.authService.resendCode({ sessionId: verificationCodeInfo.sessionId, username: verificationCodeInfo.userName }).pipe(
				map(() => authActions.resendCodeSucceeded()),
				catchError((error: HttpErrorResponse) => {
					let message: string;
					switch (error.status) {
						case 404:
							message = 'Code expired';
							break;
						default:
							message = 'Resend code error';
							break;
					}
					return of(clientErrored({ toastMessage: message, errorMessage: this.errorService.getErrorMessage(error) }));
				})
			))
		)
	);

	resendCodeSucceeded$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authActions.resendCodeSucceeded),
			tap(() => this.alertService.displayToastAlert('Code resent. Check your email'))
		),
		{ dispatch: false }
	);

	verificationCodeEntered$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authActions.verificationCodeEntered),
			withLatestFrom(
				this.store.pipe(select(verificationCodeInfo))
			),
			exhaustMap(([payload, verificationCodeInfo]) => this.authService.verifyCode({ sessionId: verificationCodeInfo.sessionId, username: verificationCodeInfo.userName, verificationCode: payload.verificationCode }).pipe(
				map(tokenInfo => authActions.verifyCodeSucceeded({ tokenInfo: tokenInfo })),
				catchError((error: HttpErrorResponse) => {
					let message: string;
					switch (error.status) {
						case 404:
							message = 'Code expired';
							break;
						case 401:
							message = 'Invalid code';
							break;
						default:
							message = 'Verify code error';
							break;
					}
					this.alertService.displayToastAlert(message);
					return of(authActions.verifyCodeFailed());
				})
			))
		)
	);

	pinEntered$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authActions.pinEntered),
			map(payload => payload.pin),
			concatLatestFrom(() => this.store.pipe(select(userName))),
			exhaustMap(([pin, userName]) => this.authService.authenticatePin({ username: userName!, pin }).pipe(
				map(tokenInfo => authActions.authenticatePinSucceeded({ tokenInfo: tokenInfo })),
				catchError((error: HttpErrorResponse) => [
					authActions.authenticatePinFailed(),
					clientErrored({ toastMessage: 'Invalid pin', errorMessage: this.errorService.getErrorMessage(error) })
				])
			))
		)
	);

	userGraphLoaded$ = createEffect(() =>
		this.actions$.pipe(
			ofType(userGraphActions.userGraphLoaded),
			concatLatestFrom(() => [this.store.pipe(select(userName)), this.store.pipe(select(recentUsers))]),
			filter(([, userName, recentUsers]) => recentUsers.length === 0 || recentUsers[0].userName !== userName),
			map(([action, userName, recentUsers]) => authActions.recentUsersUpdated({
				recentUsers: [
					{ fullName: action.user.fullName, userName: userName! },
					...recentUsers.filter(recentUser => recentUser.userName !== userName).slice(0, 4)
				]
			}))
		)
	);

	recentUsersUpdated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authActions.recentUsersUpdated),
			map((action) => {
				Preferences.set({ key: RecentUsersKey, value: JSON.stringify(action.recentUsers) })
			}
			)),
		{ dispatch: false }
	);

	logoutInitiated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authActions.logoutInitiated),
			map(() => {
				this.navController.navigateRoot([`/${routePaths.login}`]);
				return authActions.clearStateRequested();
			}),
			closeAllModals(this.modalController)
		)
	);

	tokenRefreshFailed$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authActions.tokenRefreshFailed),
			map(() => {
				this.navController.navigateRoot([`/${routePaths.login}`]);
				this.alertService.displayToastAlert('Session expired');
				return authActions.clearStateRequested();
			}),
			closeAllModals(this.modalController)
		)
	);

	clearStateRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(authActions.clearStateRequested),
			tap(() => this.loadingOverlayService.waitThenRemoveAllOverlays())
		),
		{ dispatch: false }
	);
}