import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, EMPTY, throwError } from 'rxjs';
import { AppSettings } from '../app-settings';
import { Store } from '@ngrx/store';
import { AppState } from '../store/reducers';
import { showInvalidVersionAlertRequested } from '../store/client/client.actions';

const ClientVersionHeader = 'CRIS-Client-Version';
const ClientRequiredVersionHeader = 'CRIS-Client-Required-Version';

@Injectable()
export class ClientInterceptor implements HttpInterceptor {

	constructor(private store: Store<AppState>) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(this.prepareRequest(request)).pipe(
			catchError((error: HttpErrorResponse) => {
				if (error.status === 418 && error.headers.has(ClientRequiredVersionHeader)) {
					this.store.dispatch(showInvalidVersionAlertRequested({ requiredVersion: error.headers.get(ClientRequiredVersionHeader) as string }));
					return EMPTY;
				}
				return throwError(() => error);
			})
		);
	}

	private prepareRequest(request: HttpRequest<any>): HttpRequest<any> {
		return request.clone({
			headers: request.headers.append(ClientVersionHeader, AppSettings.version)
		});
	}
}