import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SelectControl, SelectItem } from 'src/app/form-controls/select/select.control';
import { TextInputControl } from 'src/app/form-controls/text-input/text-input.control';
import { SheetItemService } from 'src/app/services/sheet-item.service';
import { SheetItemDetailConfig, SheetItemFormBase } from 'src/app/services/sheets-renderer.service';
import { Row } from 'src/app/types/aggregate-graph.types';
import { DietPreparation, NutritionInstructionV2, NutritionMethodV2, NutritionTubeV2 } from 'src/app/types/sheet-instruction.types';
import { InstructionsFormComponent } from '../../instructions-form/instructions-form.component';

interface NutritionInstructionFormGroup {
	rerPercentage: FormControl<number | null>;
	dietPreparation: FormControl<DietPreparation | null>;
	diet: FormControl<string | null>;
	nutritionMethod: FormControl<NutritionMethodV2 | null>;
	nutritionTube: FormControl<NutritionTubeV2 | null>;
}

@Component({
	selector: 'app-nutrition-item-form',
	templateUrl: './nutrition-item-form-v2.component.html',
	styleUrl: './nutrition-item-form-v2.component.scss',
	standalone: true,
	imports: [ReactiveFormsModule, InstructionsFormComponent, TextInputControl, SelectControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NutritionItemFormComponentV2 implements SheetItemFormBase, OnInit {

	@Input() instructionTypeName!: string;
	@Input() item!: Row | null;
	@Input() minDate!: string;
	@Input() maxDate!: string;
	@Input() instructionsFormArray!: FormArray;
	@Input() itemConfig!: SheetItemDetailConfig;

	dietPreparations: SelectItem[] = [
		{ text: 'Commercial WellPet (Over The Counter)', value: DietPreparation.CommercialWellPet },
		{ text: 'Commercial Therapeutic (Prescription diet)', value: DietPreparation.CommercialTherapeutic },
		{ text: 'Hospital Prepared (Tempt foods)', value: DietPreparation.HospitalPrepared },
		{ text: 'Other (specify in "Comment")', value: DietPreparation.Other },
	];

	nutritionMethods: SelectItem[] = [
		{ text: 'Per os.', value: NutritionMethodV2.PerOs },
		{ text: 'Assisted Feeding', value: NutritionMethodV2.AssistedFeeding },
		{ text: 'Feed from height', value: NutritionMethodV2.FeedFromHeight },
		{ text: 'Handed small bits of food', value: NutritionMethodV2.HandedFeedSmallBits },
		{ text: 'Supervised feeding', value: NutritionMethodV2.SupervisedFeeding },
		{ text: 'Tempt to eat', value: NutritionMethodV2.TemptToEat },
	];

	nutritionTubes: SelectItem[] = [
		{ text: 'Gastrostomy', value: NutritionTubeV2.Gastrostomy },
		{ text: 'Jejunostomy', value: NutritionTubeV2.Jejunostomy },
		{ text: 'Nasal-Oesophageal', value: NutritionTubeV2.NasalOesophageal },
		{ text: 'Oesophageal', value: NutritionTubeV2.Oesophageal },
		{ text: 'PEG', value: NutritionTubeV2.PEG },
	];


	constructor(private sheetItemService: SheetItemService, private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.sheetItemService.getInstructions(this.item, this.instructionTypeName, this.itemConfig.scheduleConfig.defaultScheduleType, this.itemConfig.scheduleConfig.defaultRepeatingScheduleFrequency).forEach(instruction => {
			const group: FormGroup<NutritionInstructionFormGroup> = this.sheetItemService.buildExistingInstructionGroup(this.instructionTypeName, instruction);
			const nutritionInstruction = instruction as NutritionInstructionV2;
			group.addControl('rerPercentage', this.formBuilder.control(nutritionInstruction.rerPercentage ?? null));
			group.addControl('dietPreparation', this.formBuilder.control(nutritionInstruction.dietPreparation ?? null));
			group.addControl('diet', this.formBuilder.control(nutritionInstruction.diet ?? null));
			group.addControl('nutritionMethod', this.formBuilder.control(nutritionInstruction.nutritionMethod ?? null));
			group.addControl('nutritionTube', this.formBuilder.control(nutritionInstruction.nutritionTube ?? null));
			this.instructionsFormArray.push(group);
		});
	}

	addInstruction() {
		const group: FormGroup<NutritionInstructionFormGroup> = this.sheetItemService.getNewInstructionGroup(this.instructionTypeName, this.itemConfig.scheduleConfig.defaultScheduleType, this.itemConfig.scheduleConfig.defaultRepeatingScheduleFrequency);
		group.addControl('rerPercentage', this.formBuilder.control(null));
		group.addControl('dietPreparation', this.formBuilder.control(null));
		group.addControl('diet', this.formBuilder.control(null));
		group.addControl('nutritionMethod', this.formBuilder.control(null));
		group.addControl('nutritionTube', this.formBuilder.control(null));
		this.instructionsFormArray.push(group);
	}
}