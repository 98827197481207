import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SelectControl, SelectItem } from 'src/app/form-controls/select/select.control';
import { Unit } from 'src/app/form-controls/shared/popovers/unit-popover/unit.popover';
import { UnitQuantity, UnitQuantityControl } from 'src/app/form-controls/unit-quantity/unit-quantity.control';
import { UnitValue, UnitValueControl } from 'src/app/form-controls/unit-value/unit-value.control';
import { unitQuantityRequired } from 'src/app/form-validators/unit-quantity-required.validator';
import { unitValueRequired } from 'src/app/form-validators/unit-value-required.validator';
import { SheetItemService } from 'src/app/services/sheet-item.service';
import { SheetItemDetailConfig, SheetItemFormBase } from 'src/app/services/sheets-renderer.service';
import { Row } from 'src/app/types/aggregate-graph.types';
import { MedicationInfusionDoseUnit, MedicationInfusionTotalDoseUnit } from 'src/app/types/sheet-action-aggregate-graph.types';
import { MedicationInfusionInstruction, MedicationInfusionRoute } from 'src/app/types/sheet-instruction.types';
import { InstructionsFormComponent } from '../../instructions-form/instructions-form.component';

interface MedicationInfusionInstructionFormGroup {
	dose: FormControl<UnitValue | null>;
	totalDose: FormControl<UnitValue | null>;
	rate: FormControl<UnitQuantity | null>;
	medicationRoute: FormControl<MedicationInfusionRoute | null>;
}

@Component({
	selector: 'app-medication-infusion-item-form',
	templateUrl: './medication-infusion-item-form.component.html',
	styleUrl: './medication-infusion-item-form.component.scss',
	standalone: true,
	imports: [ReactiveFormsModule, InstructionsFormComponent, UnitQuantityControl, SelectControl, UnitValueControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MedicationInfusionItemFormComponent implements SheetItemFormBase, OnInit {

	@Input() instructionTypeName!: string;
	@Input() item!: Row | null;
	@Input() minDate!: string;
	@Input() maxDate!: string;
	@Input() instructionsFormArray!: FormArray;
  	@Input() itemConfig!: SheetItemDetailConfig;

	medicationRoutes: SelectItem[] = [
		{ text: 'IV', value: MedicationInfusionRoute.Iv }
	];

	doseUnits: Unit[] = [
		{ label: MedicationInfusionDoseUnit.MgKgHr },
		{ label: MedicationInfusionDoseUnit.UgKgHr }
	];

	totalDoseUnits: Unit[] = [
		{ label: MedicationInfusionTotalDoseUnit.MgHr },
		{ label: MedicationInfusionTotalDoseUnit.UgHr }
	];

	constructor(private sheetItemService: SheetItemService, private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.sheetItemService.getInstructions(this.item, this.instructionTypeName, this.itemConfig.scheduleConfig.defaultScheduleType, this.itemConfig.scheduleConfig.defaultRepeatingScheduleFrequency).forEach(instruction => {
			const group: FormGroup<MedicationInfusionInstructionFormGroup> = this.sheetItemService.buildExistingInstructionGroup(this.instructionTypeName, instruction);
			const medicationInfusionInstruction = instruction as MedicationInfusionInstruction;
			group.addControl('dose', this.formBuilder.control(medicationInfusionInstruction.dose ?? null, { validators: unitValueRequired() }));
			group.addControl('totalDose', this.formBuilder.control(medicationInfusionInstruction.totalDose ?? null, { validators: unitValueRequired() }));
			group.addControl('rate', this.formBuilder.control(medicationInfusionInstruction.rate ?? null, { validators: unitQuantityRequired() }));
			group.addControl('medicationRoute', this.formBuilder.control(MedicationInfusionRoute.Iv, { validators: Validators.required }));
			this.instructionsFormArray.push(group);
		});
	}

	addInstruction() {
		const group: FormGroup<MedicationInfusionInstructionFormGroup> = this.sheetItemService.getNewInstructionGroup(this.instructionTypeName, this.itemConfig.scheduleConfig.defaultScheduleType, this.itemConfig.scheduleConfig.defaultRepeatingScheduleFrequency);
		group.addControl('dose', this.formBuilder.control(null, { validators: unitValueRequired() }));
		group.addControl('totalDose', this.formBuilder.control(null, { validators: unitValueRequired() }));
		group.addControl('rate', this.formBuilder.control(null, { validators: unitQuantityRequired() }));
		group.addControl('medicationRoute', this.formBuilder.control(MedicationInfusionRoute.Iv, { validators: Validators.required }));
		this.instructionsFormArray.push(group);
	}
}