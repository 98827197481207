import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SheetSlotComponentBase } from 'src/app/services/sheets-renderer.service';
import { BloodGlucoseAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { TickValueComponent } from '../../tick-value/tick-value.component';

@Component({
	selector: 'app-blood-glucose-slot',
	templateUrl: './blood-glucose-slot.component.html',
	styleUrl: './blood-glucose-slot.component.scss',
	standalone: true,
	imports: [TickValueComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BloodGlucoseSlotComponent implements SheetSlotComponentBase {

	action!: BloodGlucoseAction;
	value!: string | null;

	@Input('action') set updateAction(action: BloodGlucoseAction | null) {
		this.action = action as BloodGlucoseAction;
		this.value = this.action.bloodGlucose?.quantity?.toString() ?? null;
	}

	constructor() {
	}
}