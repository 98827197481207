<app-action-form [item]="item" [slot]="slot" [form]="form">
	<div class="form-section app-margin-horizontal app-padding">
		<div class="form-group" [formGroup]="form">
			<app-unit-quantity [units]="amountUnits" label="Amount Given" inputMode="decimal" placeholder="Value" formControlName="amountGiven"></app-unit-quantity>
		</div>
		<app-common-action-controls [action]="action" [form]="form"></app-common-action-controls>
	</div>
	<ng-container taskControls [formGroup]="form">
		<app-user-signature formControlName="approvalOne" label="Approval 1"></app-user-signature>
		<app-user-signature formControlName="approvalTwo" label="Approval 2"></app-user-signature>
	</ng-container>
</app-action-form>