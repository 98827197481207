import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class CoercionService {

	constructor() {
	}

	public coerceToBoolean(value: any): boolean {
		return value != null && `${value}` !== 'false';
	}
}