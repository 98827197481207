import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { AlertController, IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar, ModalController } from '@ionic/angular/standalone';
import { ButtonComponent } from 'src/app/components/button/button.component';
import { InformativeComponent } from 'src/app/components/informative/informative.component';
import { CheckboxControl } from 'src/app/form-controls/checkbox/checkbox.control';
import { ServiceNamePipe } from 'src/app/pipes/service-name.pipe';
import { TaskRecipient } from 'src/app/types/aggregate-graph.types';

@Component({
	templateUrl: './share-task.modal.html',
	styleUrl: './share-task.modal.scss',
	standalone: true,
	imports: [IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, ReactiveFormsModule, ServiceNamePipe, CheckboxControl, InformativeComponent, ButtonComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareTaskModal implements OnInit {

	@Input() recipients!: TaskRecipient[];

	formArray: FormArray<FormControl<boolean | null>> = new FormArray<FormControl<boolean | null>>([]);

	constructor(private modalController: ModalController, private alertController: AlertController) {
	}

	ngOnInit(): void {
		this.recipients.forEach(recipient => this.formArray.push(new FormControl(!recipient.isOptional)));
	}

	close() {
		this.modalController.dismiss();
	}

	async shareClicked() {
		const recipients = this.recipients.filter((_, index) => this.formArray.at(index).value);

		if (!recipients.length) {
			const alert = await this.alertController.create({
				header: 'No Recipients Selected',
				subHeader: 'You must select at least one recipient',
				buttons: ['Ok'],
				backdropDismiss: false
			});
			await alert.present();
			return;
		}

		this.modalController.dismiss({ recipients: recipients });
	}
}
