import { createReducer, on } from '@ngrx/store';
import { addDays, differenceInDays, formatISO, isAfter, isBefore } from 'date-fns';
import { SheetActionNotes, SheetGranularity, SheetViewSelector, SheetViewStatus } from 'src/app/types/sheet.types';
import { clearStateRequested } from '../auth/auth.actions';
import * as sheetGraphActions from '../sheet-graph/sheet-graph.actions';
import * as sheetActions from './sheet.actions';

export interface GridChangeData {
	graphGenerated: boolean;
	selectedDate: string;
	selectedDateChanged: boolean;
	granularityChanged: boolean;
	granularSlotNumber: number;
	currentMinute: string;
	oldSlotCount: number;
	slotCount: number;
}

export interface State {
	granularityChangeActive: boolean;
	selectedGranularity: SheetGranularity;
	granularSlotNumber: number;
	sheetEndDate: string;
	selectorDate: string;
	selectedDate: string;
	selectedVisitId: string;
	selectedItemId: number | null;
	selectedSlotIndex: number | null;
	selectedActionId: number | null;
	viewStatus: SheetViewStatus;
	viewSelector: SheetViewSelector;
	actionNotes: SheetActionNotes[] | null;
	resizeActive: boolean;
	gridChangeData: GridChangeData;
}

export const initialState: State = {
	granularityChangeActive: false,
	selectedGranularity: SheetGranularity.SixtyMinutes,
	granularSlotNumber: 0,
	sheetEndDate: '',
	selectorDate: '',
	selectedDate: '',
	selectedVisitId: '',
	selectedItemId: null,
	selectedSlotIndex: null,
	selectedActionId: null,
	viewStatus: SheetViewStatus.NoSheet,
	viewSelector: SheetViewSelector.Sheet,
	actionNotes: null,
	resizeActive: false,
	gridChangeData: {
		graphGenerated: false,
		selectedDate: '',
		selectedDateChanged: false,
		granularityChanged: false,
		granularSlotNumber: 0,
		currentMinute: '',
		oldSlotCount: 0,
		slotCount: 0
	}
}

export const sheetReducer = createReducer(
	initialState,
	on(sheetGraphActions.createSheetCompleted, (state, { visitId }) => ({
		...initialState,
		selectedVisitId: visitId,
		viewStatus: SheetViewStatus.Loading,
		gridChangeData: {  ...initialState.gridChangeData, selectedDateChanged: true }
	})),
	on(sheetActions.sheetLoadInitiated, (state, { visitId, sheetId }) => {
		const visitIdChanged = state.selectedVisitId !== visitId;

		const newState = visitIdChanged ? { ...initialState } : { ...state };

		return {
			...newState,
			viewStatus: visitIdChanged ? (sheetId ? SheetViewStatus.Loading : SheetViewStatus.NoSheet) : state.viewStatus,
			selectedGranularity: visitIdChanged ? initialState.selectedGranularity : state.selectedGranularity,
			selectedVisitId: visitId,
			actionNotes: visitIdChanged ? null : state.actionNotes,
			gridChangeData: {  ...newState.gridChangeData, selectedDateChanged: visitIdChanged }
		}
	}),
	on(sheetActions.selectedVisitChanged, (state, { visitId, sheetId }) => ({
		...initialState,
		viewStatus: sheetId ? SheetViewStatus.Loading : SheetViewStatus.NoSheet,
		selectedGranularity: initialState.selectedGranularity,
		selectedVisitId: visitId,
		actionNotes: null,
		gridChangeData: {  ...initialState.gridChangeData, selectedDateChanged: true }
	})),
	on(sheetGraphActions.sheetGraphGenerated, (state, { sheet, granularityChangeActive }) => {
		const sheetEndDate = getSheetEndDate(sheet.sheetStartDate, sheet.slotsPerDay.length);
		const now = new Date();
		const initialDate = isAfter(now, sheet.sheetStartDate) && isBefore(now, sheetEndDate) ? formatISO(addDays(sheet.sheetStartDate, differenceInDays(now, sheet.sheetStartDate))) : sheet.sheetStartDate;

		const gridChangeData: GridChangeData = {
			...state.gridChangeData,
			graphGenerated: true,
			selectedDate: granularityChangeActive ? state.selectedDate : initialDate,
			slotCount: 1440 / sheet.granularity,
			granularSlotNumber: state.granularSlotNumber,
			currentMinute: formatISO(new Date())
		};

		return {
			...state,
			viewStatus: SheetViewStatus.Sheet,
			sheetEndDate: sheetEndDate,
			selectorDate: granularityChangeActive ? state.selectorDate : initialDate,
			selectedDate: granularityChangeActive ? state.selectedDate : initialDate,
			gridChangeData: gridChangeData
		}
	}),
	on(sheetActions.changeGranularityRequested, (state, { granularity, slotNumber }) => {

		const gridChangeData: GridChangeData = {
			...state.gridChangeData,
			graphGenerated: false,
			selectedDateChanged: false,
			granularityChanged: true,
			oldSlotCount: 1440 / state.selectedGranularity
		};

		return {
			...state,
			viewStatus: SheetViewStatus.Loading,
			granularityChangeActive: true,
			selectedGranularity: granularity,
			granularSlotNumber: slotNumber,
			gridChangeData: gridChangeData
		}
	}),
	on(sheetActions.changeGranularityCompleted, (state) => ({
		...state,
		viewStatus: SheetViewStatus.Sheet,
		granularityChangeActive: false
	})),
	on(sheetActions.selectDayRequested, (state, { date }) => {

		const gridChangeData: GridChangeData = {
			...state.gridChangeData,
			graphGenerated: false,
			selectedDateChanged: true,
			granularityChanged: false,
			oldSlotCount: 0
		};

		return {
			...state,
			viewStatus: SheetViewStatus.Loading,
			selectorDate: date,
			actionNotes: null,
			gridChangeData: gridChangeData
		}
	}),
	on(sheetActions.selectDayCompleted, (state, { date }) => {

		const gridChangeData: GridChangeData = {
			...state.gridChangeData,
			graphGenerated: true,
			selectedDate: date,
			slotCount: 1440 / state.selectedGranularity,
			granularSlotNumber: state.granularSlotNumber,
			currentMinute: formatISO(new Date())
		};

		return {
			...state,
			viewStatus: SheetViewStatus.Sheet,
			selectedDate: date,
			gridChangeData: gridChangeData
		}
	}),
	on(sheetActions.updateScheduledUntilDateRequested, (state) => ({
		...state,
		resizeActive: true
	})),
	on(sheetActions.updateScheduledUntilDateCompleted, (state, { sheetEndDate }) => {

		const gridChangeData: GridChangeData = {
			...state.gridChangeData,
			graphGenerated: true,
			selectedDate: sheetEndDate,
			slotCount: 1440 / state.selectedGranularity,
			granularSlotNumber: state.granularSlotNumber,
			currentMinute: formatISO(new Date())
		};

		return {
			...state,
			viewStatus: SheetViewStatus.Sheet,
			resizeActive: false,
			sheetEndDate: sheetEndDate,
			selectedDate: sheetEndDate,
			gridChangeData: gridChangeData
		}
	}),
	on(sheetActions.loadSheetItemEditorRequested, (state, { item }) => ({
		...state,
		selectedItemId: item.id
	})),
	on(sheetActions.loadSheetSlotEditorRequested, (state, { itemId, slotIndex }) => ({
		...state,
		selectedItemId: itemId,
		selectedSlotIndex: slotIndex,
		selectedActionId: null
	})),
	on(sheetActions.addSheetSlotActionCompleted, (state, { actionId }) => ({
		...state,
		selectedActionId: actionId
	})),
	on(sheetActions.addSheetItemInstructionsCompleted, (state) => ({
		...state,
		viewStatus: SheetViewStatus.Sheet
	})),
	on(sheetActions.changeViewStatusRequested, (state, { viewSelector: viewStatus }) => ({
		...state,
		viewStatus: SheetViewStatus.Loading,
		viewSelector: viewStatus,
		actionNotes: null
	})),
	on(sheetActions.changeViewStatusCompleted, (state, { viewStatus }) => ({
		...state,
		viewStatus: viewStatus
	})),
	on(sheetActions.loadSheetActionNotesCompleted, (state, { actionNotes }) => ({
		...state,
		viewStatus: actionNotes.length ? SheetViewStatus.ActionNotes : SheetViewStatus.NoActionNotes,
		actionNotes: actionNotes
	})),
	on(sheetActions.sheetActionNotesCleared, (state) => ({
		...state,
		actionNotes: null
	})),
	on(sheetActions.sheetCleared, () => initialState),
	on(clearStateRequested, () => initialState)
);

export const getSheetEndDate = (sheetStartDate: string, slotsPerDayLength: number): string => formatISO(addDays(sheetStartDate, slotsPerDayLength - 1));