import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SheetSlotComponentBase } from 'src/app/services/sheets-renderer.service';
import { ToGoHomeAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { TickValueComponent } from '../../tick-value/tick-value.component';

@Component({
	selector: 'app-to-go-home-slot',
	templateUrl: './to-go-home-slot.component.html',
	styleUrl: './to-go-home-slot.component.scss',
	standalone: true,
	imports: [TickValueComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToGoHomeSlotComponent implements SheetSlotComponentBase {

	@Input() action!: ToGoHomeAction;
	value = null;

	constructor() {
	}
}