import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { SheetItemService } from 'src/app/services/sheet-item.service';
import { SheetItemDetailConfig, SheetItemFormBase } from 'src/app/services/sheets-renderer.service';
import { Row } from 'src/app/types/aggregate-graph.types';
import { InstructionsFormComponent } from '../../instructions-form/instructions-form.component';

@Component({
	selector: 'app-temperature-item-form',
	templateUrl: './temperature-item-form.component.html',
	styleUrl: './temperature-item-form.component.scss',
	standalone: true,
	imports: [ReactiveFormsModule, InstructionsFormComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemperatureItemFormComponent implements SheetItemFormBase, OnInit {

	@Input() instructionTypeName!: string;
	@Input() item!: Row | null;
	@Input() minDate!: string;
	@Input() maxDate!: string;
	@Input() instructionsFormArray!: FormArray;
  	@Input() itemConfig!: SheetItemDetailConfig;

	constructor(private sheetItemService: SheetItemService, private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.sheetItemService.getInstructions(this.item, this.instructionTypeName, this.itemConfig.scheduleConfig.defaultScheduleType, this.itemConfig.scheduleConfig.defaultRepeatingScheduleFrequency).forEach(instruction => {
			const group = this.sheetItemService.buildExistingInstructionGroup(this.instructionTypeName, instruction);
			// add any custom form controls here
			this.instructionsFormArray.push(group);
		});
	}

	addInstruction() {
		const group = this.sheetItemService.getNewInstructionGroup(this.instructionTypeName, this.itemConfig.scheduleConfig.defaultScheduleType, this.itemConfig.scheduleConfig.defaultRepeatingScheduleFrequency);
		// add any custom form controls here
		this.instructionsFormArray.push(group);
	}
}