import { createAction, props } from '@ngrx/store';
import { SearchedPatient } from 'src/app/services/patients.service';

export enum PatientSearchActionTypes {
	NewSearchRequested = '[Patient Search Page] New Search Requested',
	NewSearchSucceeded = '[Patient Search Effect] New Search Succeeded',
	NewSearchFailed = '[Patient Search Effect] New Search Failed',
	AppendResultsRequested = '[Patient Search Page] Append Results Requested',
	AppendResultsSucceeded = '[Patient Search Effect] Append Results Succeeded',
	AppendResultsFailed = '[Patient Search Effect] Append Results Failed',
	ResultsCleared = '[Patient Search Page] Results Cleared'
}

export const newSearchRequested = createAction(
	PatientSearchActionTypes.NewSearchRequested,
	props<{ queryTerm: string }>()
)

export const newSearchSucceeded = createAction(
	PatientSearchActionTypes.NewSearchSucceeded,
	props<{ searchResults: SearchedPatient[] }>()
)

export const newSearchFailed = createAction(
	PatientSearchActionTypes.NewSearchFailed,
	props<{ statusCode: number, message: string }>()
)

export const appendResultsRequested = createAction(
	PatientSearchActionTypes.AppendResultsRequested
)

export const appendResultsSucceeded = createAction(
	PatientSearchActionTypes.AppendResultsSucceeded,
	props<{ searchResults: SearchedPatient[] }>()
)

export const appendResultsFailed = createAction(
	PatientSearchActionTypes.AppendResultsFailed,
	props<{ statusCode: number, message: string }>()
)

export const resultsCleared = createAction(
	PatientSearchActionTypes.ResultsCleared
)