import { Injectable } from '@angular/core';
import { SelectItem } from '../form-controls/select/select.control';

@Injectable({
	providedIn: 'root'
})
export class WidgetMetadataMapperService {

	constructor() {
	}

	mapRadioGroupOptionsToSelectItems(options: string[] | null): SelectItem[] {
		// the select control now displays radio-group widgets, so need to map the options to select items
		if (options && options.length) {
			return options.map(option => ({ type: 'option', text: option }));
		}
		return [];
	}
}