import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { BehaviorSubject, debounceTime, Observable, skip } from 'rxjs';

export const debounceDuration: number = 2000;

@Component({
	selector: 'app-item-textarea',
	templateUrl: './item-textarea.component.html',
	styleUrl: './item-textarea.component.scss',
	standalone: true,
	imports: [LetDirective],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemTextAreaComponent implements OnChanges, OnInit, OnDestroy {

	@Input() placeholder: string = '';
	@Input() value: string | null = null;
	@Input() maxlength: number | null = null;
	@Input() debounce: number = 0;

	@Output() valueChange = new EventEmitter<string>();
	@Output() itemFocus = new EventEmitter();

	@ViewChild('textarea') textarea!: ElementRef<HTMLTextAreaElement>;

	inputValueBehavorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
	inputValue$: Observable<string> = this.inputValueBehavorSubject.asObservable();

	constructor() {
	}

	ngOnChanges(changes: SimpleChanges) {
		this.inputValueBehavorSubject.next(changes['value'].currentValue);
	}

	ngOnInit(): void {
		if (this.debounce) {
			this.inputValueBehavorSubject.asObservable().pipe(skip(1), debounceTime(this.debounce)).subscribe(value => this.valueChange.emit(value));
		}
	}

	onInput(event: Event) {
		this.inputValueBehavorSubject.next((event.target as HTMLTextAreaElement).value);
	}

	onChange(event: Event) {
		if (!this.debounce) this.valueChange.emit((event.target as HTMLTextAreaElement).value);
	}

	onFocus() {
		this.itemFocus.emit();
	}

	focusInput() {
		this.textarea.nativeElement.focus();
	}

	ngOnDestroy(): void {
		this.inputValueBehavorSubject.complete();
	}
}