import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SheetItemComponentBase } from 'src/app/services/sheets-renderer.service';
import { Row } from 'src/app/types/aggregate-graph.types';
import { ColdCompressInstructionsV2, Method } from 'src/app/types/sheet-instruction.types';
import { InstructionBoxComponent } from '../../instruction-box/instruction-box.component';
import { ItemContentComponent } from '../../item-content/item-content.component';

@Component({
	selector: 'app-cold-compress-item',
	templateUrl: './cold-compress-item-v2.component.html',
	styleUrl: './cold-compress-item-v2.component.scss',
	standalone: true,
	imports: [ItemContentComponent, InstructionBoxComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColdCompressItemComponentV2 implements SheetItemComponentBase {

	row!: Row;
	instructions!: ColdCompressInstructionsV2;
	method!: string[];
	@Input() startLabel!: string;
    @Input() endLabel!: string;
	@Input('row') set updateRow(row: Row) {
		this.row = row;
		this.instructions = row.instructions as ColdCompressInstructionsV2;
		this.method = Object.values(this.instructions).map(instruction => this.getMethodName(instruction.method));
	}

	private getMethodName(method: Method | null) {
		switch(method) {
			case Method.CryoCuff: return 'Cryo Cuff';
			case Method.Pack: return 'Pack';
			default: return '';
		}
	}

	constructor() {
	}
}