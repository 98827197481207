import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert.service';
import * as errorActions from './error.actions';

@Injectable()
export class ErrorEffects {

	constructor(private actions$: Actions, private alertService: AlertService) {
	}

	clientErrored$ = createEffect(() =>
		this.actions$.pipe(
			ofType(errorActions.clientErrored),
			tap(payload => {
				this.alertService.displayToastAlert(payload.toastMessage);
				console.error(payload.errorMessage);
			})
		),
		{ dispatch: false }
	);
}