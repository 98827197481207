import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SelectControl } from 'src/app/form-controls/select/select.control';
import { TextInputControl } from 'src/app/form-controls/text-input/text-input.control';
import { SheetItemService } from 'src/app/services/sheet-item.service';
import { SheetItemDetailConfig, SheetItemFormBase } from 'src/app/services/sheets-renderer.service';
import { Row } from 'src/app/types/aggregate-graph.types';
import { HotCompressInstruction } from 'src/app/types/sheet-instruction.types';
import { InstructionsFormComponent } from '../../instructions-form/instructions-form.component';

interface HotCompressInstructionFormGroup {
	area: FormControl<string | null>;
}

@Component({
	selector: 'app-hot-compress-item-form',
	templateUrl: './hot-compress-item-form.component.html',
	styleUrl: './hot-compress-item-form.component.scss',
	standalone: true,
	imports: [ReactiveFormsModule, InstructionsFormComponent, SelectControl, TextInputControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HotCompressItemFormComponent implements SheetItemFormBase, OnInit {

	@Input() instructionTypeName!: string;
	@Input() item!: Row | null;
	@Input() minDate!: string;
	@Input() maxDate!: string;
	@Input() instructionsFormArray!: FormArray;
	@Input() itemConfig!: SheetItemDetailConfig;
	
	constructor(private sheetItemService: SheetItemService, private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.sheetItemService.getInstructions(this.item, this.instructionTypeName, this.itemConfig.scheduleConfig.defaultScheduleType, this.itemConfig.scheduleConfig.defaultRepeatingScheduleFrequency).forEach(instruction => {
			const group: FormGroup<HotCompressInstructionFormGroup> = this.sheetItemService.buildExistingInstructionGroup(this.instructionTypeName, instruction);
			const coldCompressInstruction = instruction as HotCompressInstruction;
			group.addControl('area', this.formBuilder.control(coldCompressInstruction.area ?? null));
			this.instructionsFormArray.push(group);
		});
	}

	addInstruction() {
		const group: FormGroup<HotCompressInstructionFormGroup> = this.sheetItemService.getNewInstructionGroup(this.instructionTypeName, this.itemConfig.scheduleConfig.defaultScheduleType, this.itemConfig.scheduleConfig.defaultRepeatingScheduleFrequency);
		group.addControl('area', this.formBuilder.control(null));
		this.instructionsFormArray.push(group);
	}
}