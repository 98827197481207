import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UnitQuantity, UnitQuantityControl } from 'src/app/form-controls/unit-quantity/unit-quantity.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { MedicationAction, MedicationAmountUnit } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';
import { Unit } from 'src/app/form-controls/shared/popovers/unit-popover/unit.popover';

interface MedicationActionFormGroup {
	amountGiven: FormControl<UnitQuantity | null>;
}

@Component({
	selector: 'app-medication-action-controls',
	templateUrl: './medication-action-controls.component.html',
	styleUrls: ['../../../common.scss', './medication-action-controls.component.scss'],
	standalone: true,
	imports: [ActionFormComponent, ReactiveFormsModule, UnitQuantityControl, CommonActionControlsComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MedicationActionControlsComponent implements SheetActionControlsBase, OnInit {

	slot!: Slot | null;
	action!: MedicationAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as MedicationAction;
	}

	@Input() form!: FormGroup<MedicationActionFormGroup>;
	@Input() item!: Row | null;

	medicationAmountUnits: Unit[] = [
		{ label: MedicationAmountUnit.Mg },
		{ label: MedicationAmountUnit.Ml },
		{ label: MedicationAmountUnit.Ug },
		{ label: MedicationAmountUnit.L },
		{ label: MedicationAmountUnit.G },
		{ label: MedicationAmountUnit.Kg },
		{ label: MedicationAmountUnit.Drop },
		{ label: MedicationAmountUnit.Graduation },
		{ label: MedicationAmountUnit.Tablet },
		{ label: MedicationAmountUnit.Capsule },
		{ label: MedicationAmountUnit.Spray },
		{ label: MedicationAmountUnit.Pump },
		{ label: MedicationAmountUnit.Ui },
		{ label: MedicationAmountUnit.Na },
	];

	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.form.addControl('amountGiven', this.formBuilder.control(this.action?.amountGiven ?? null));
	}
}