import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidationErrors, Validator, Validators } from '@angular/forms';
import { enterLeave } from 'src/app/animations/common.animations';
import { SelectControl, SelectItem, SelectValue } from '../select/select.control';
import { TextAreaControl } from '../textarea/textarea.control';

export interface SelectWithInfoValue {
	selection: SelectValue,
	info: string | null
}

@Component({
	selector: 'app-select-with-info',
	templateUrl: './select-with-info.control.html',
	styleUrl: './select-with-info.control.scss',
	standalone: true,
	imports: [ReactiveFormsModule, SelectControl, TextAreaControl],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [enterLeave],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: SelectWithInfoControl,
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: SelectWithInfoControl
		}
	]
})
export class SelectWithInfoControl implements ControlValueAccessor, Validator, OnInit {

	@Input() readOnly: boolean = false;
	@Input() label: string = '';
	@Input() selectLabel: string = '';
	@Input() selectPlaceholder: string = '';
	@Input() selectItems: SelectItem[] = [];
	@Input() infoLabel: string = '';
	@Input() infoPlaceholder: string = '';
	@Input() infoIdentifiers: number[] = [];
	@Input() saveValue: boolean = false;

	value: SelectWithInfoValue | null = null;

	get selectPlaceholderValue() { return this.readOnly ? '' : this.selectPlaceholder }
	get infoPlaceholderValue() { return this.readOnly ? '' : this.infoPlaceholder }
	selectionIdentified = false;

	formGroup: FormGroup;
	selectControl = new FormControl(null);
	infoControl = new FormControl(null, { validators: [Validators.required] });

	constructor(private changeDetectorRef: ChangeDetectorRef, private formBuilder: FormBuilder, private destroyRef: DestroyRef) { // note: DestroyRef is needed if takeUntilDestroyed is used outside the constructor
		this.formGroup = this.formBuilder.group({
			selection: this.selectControl,
			info: this.infoControl
		});
	}

	ngOnInit() {
		if (!this.readOnly) {
			this.formGroup.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value: SelectWithInfoValue) => {
				this.setSelectionIdentified(value.selection);
				if (!this.selectionIdentified) this.formGroup.get('info')!.setValue(null, { emitEvent: false });
				this.value = { selection: value.selection, info: this.selectionIdentified ? value.info : null };
				this.onChange(this.value);
			});
		}
	}

	private setSelectionIdentified(selection: SelectValue | undefined) {
		const selectedItemId = this.selectItems.find(i => i.type === 'option' && i.text === selection)?.id;
		this.selectionIdentified = selectedItemId === undefined ? false : this.infoIdentifiers.includes(selectedItemId);
	}

	writeValue(value: SelectWithInfoValue | null): void {
		this.value = value;

		this.formGroup.setValue({ selection: this.value?.selection ?? null, info: this.value?.info ?? null }, { emitEvent: false });
		this.setSelectionIdentified(this.value?.selection);

		// this is required for the view to update correctly (it's a general approach recommended by Angular devs using ChangeDetectionStrategy.OnPush: https://github.com/angular/angular/issues/21780)
		this.changeDetectorRef.markForCheck();
	}

	private onChange: (value: SelectWithInfoValue | null) => void = () => { };

	registerOnChange(fn: (value: SelectWithInfoValue | null) => void): void {
		this.onChange = fn;
	}

	private onTouched: () => void = () => { };

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(disabled: boolean): void {
	}

	controlClicked() {
		if (!this.readOnly) this.onTouched();
	}

	infoRequired: boolean = false;

	validate(): ValidationErrors | null {
		this.infoRequired = this.selectionIdentified && !this.value?.info;
		if (this.infoRequired) return { infoRequired: true };
		return null;
	}
}