<ion-header>
	<ion-toolbar>
		<ion-title>Share Task</ion-title>
		<ion-buttons slot="end">
			<ion-button (click)="close()" color="primary">
				<ion-icon slot="icon-only" name="close-sharp"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
<ng-container>
	<div class="half-margin-top container">
		<div class="scroll-vertical">
			@for(recipient of recipients; track recipient.id.id; let index = $index) {
				<div class="recipient">
					<app-checkbox hideBorderBox [readOnly]="!recipient.isOptional" [label]="recipient.id.teamId | serviceName" [formControl]="formArray.controls[index]">
					</app-checkbox>
					@if(recipient.circumstances) {
						<app-informative>{{ recipient.circumstances }}</app-informative>
					}
				</div>
			}
		</div>
		<div class="button-container modal-buttons">
			<app-button fill="solid" (click)="shareClicked()">Share</app-button>
		</div>
	</div>
</ng-container>