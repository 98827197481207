import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { addDays, areIntervalsOverlapping, formatISO, isAfter, isBefore, max } from 'date-fns';
import { take } from 'rxjs';
import { DateService } from 'src/app/services/date.service';
import { TimeSpanConversionService } from 'src/app/services/timespan-conversion.service';
import { AppState } from 'src/app/store/reducers';
import { selectedDate } from 'src/app/store/sheet/sheet.selectors';
import { Instruction, OneOffSchedule, RepeatingSchedule, Row, ScheduleType } from 'src/app/types/aggregate-graph.types';
import { InstructionBoxComponent } from '../instruction-box/instruction-box.component';

interface InstructionInfo {
	instruction: Instruction;
	scheduledAndOverlapsSelectedDate: boolean;
	range: string | null;
	frequency: string | null;
}
@Component({
	selector: 'app-item-content',
	templateUrl: './item-content.component.html',
	styleUrl: './item-content.component.scss',
	standalone: true,
	imports: [NgTemplateOutlet, InstructionBoxComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemContentComponent {

	ScheduleType = ScheduleType;

	@Input() instructionItemContentTemplate!: TemplateRef<any>;
	@Input() startLabel!: string;
	@Input() endLabel!: string;
	@Input() showFrequency: boolean = true;

	row!: Row;
	instructionInfoItems: InstructionInfo[] = [];

	@Input('row') set setRow(row: Row) {
		this.row = row;

		this.store.pipe(select(selectedDate), take(1)).subscribe(selectedDate => {
			const items: InstructionInfo[] = [];
			const instructions = Object.values(row.instructions);

			// add an instruction info item for each "schedulable" instruction (e.g. "one-off" or "repeating")
			instructions.filter(({ schedule }) =>
				(schedule.scheduleTypeName === ScheduleType.OneOff || schedule.scheduleTypeName === ScheduleType.Repeating) &&
				this.scheduleOverlapsSelectedDate((schedule as OneOffSchedule | RepeatingSchedule), selectedDate)
			).forEach(instruction => {
				const schedule = instruction.schedule;
				const range = this.getRange(schedule as OneOffSchedule | RepeatingSchedule, selectedDate);
				const frequency = this.getFrequency(schedule as OneOffSchedule | RepeatingSchedule);

				items.push({ instruction, range, frequency, scheduledAndOverlapsSelectedDate: true });
			});

			// in case of no "scheduled" instructions (e.g. "unschduled" or "when required")
			if (!items.length && instructions.length) items.push({
				instruction: instructions[0],
				range: null,
				frequency: null,
				scheduledAndOverlapsSelectedDate: false
			});

			this.instructionInfoItems = items;
		});
	}

	private scheduleOverlapsSelectedDate(schedule: OneOffSchedule | RepeatingSchedule, selectedDate: string): boolean {
		// note: the "time to complete" property has not been taken into account here. Only the date the schedule action starts is considered
		return areIntervalsOverlapping(
			{ start: schedule.start, end: schedule.scheduleTypeName === ScheduleType.OneOff ? schedule.start : (schedule as RepeatingSchedule).last ?? addDays(selectedDate, 1) },
			{ start: selectedDate, end: addDays(selectedDate, 1) }
		);
	}

	constructor(private store: Store<AppState>, private dateService: DateService, private timeSpanConversionService: TimeSpanConversionService) {
	}

	private getRange(schedule: OneOffSchedule | RepeatingSchedule, selectedDate: string): string | null {
		switch (schedule.scheduleTypeName) {
			case ScheduleType.OneOff:
				return `At ${this.toTime(schedule.start)}`;
			case ScheduleType.Repeating:
				const start = this.toTime(formatISO(max([schedule.start, selectedDate])));
				const last = (schedule as RepeatingSchedule).last;
				const endOfToday = addDays(selectedDate, 1);
				const lastIsToday = last ? isAfter(last, selectedDate) && isBefore(last, endOfToday) : false;
				const end = this.toTime(lastIsToday ? last! : formatISO(endOfToday));
				return `${start}-${end}`;
			default:
				return null;
		}
	}

	private getFrequency(schedule: OneOffSchedule | RepeatingSchedule): string | null {
		switch (schedule.scheduleTypeName) {
			case ScheduleType.Repeating:
				const frequency = this.timeSpanConversionService.convertCSharpTimeSpanToTimeQuantity((schedule as RepeatingSchedule).frequency);
				return `q${frequency.quantity}${frequency.unit}`;
			default:
				return null;
		}
	}

	private toTime(date: string) {
		return this.dateService.format(date, 'HH:mm');
	}
}