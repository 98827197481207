import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Unit } from 'src/app/form-controls/shared/popovers/unit-popover/unit.popover';
import { UnitQuantity, UnitQuantityControl } from 'src/app/form-controls/unit-quantity/unit-quantity.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { MedicationActionV2, MedicationAmountUnitV2 } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface MedicationActionFormGroup {
	amountGiven: FormControl<UnitQuantity | null>;
}

@Component({
	selector: 'app-medication-action-controls',
	templateUrl: './medication-action-controls-v2.component.html',
	styleUrls: ['../../../common.scss', './medication-action-controls-v2.component.scss'],
	standalone: true,
	imports: [ActionFormComponent, ReactiveFormsModule, UnitQuantityControl, CommonActionControlsComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MedicationActionControlsComponentV2 implements SheetActionControlsBase, OnInit {

	slot!: Slot | null;
	action!: MedicationActionV2;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as MedicationActionV2;
	}

	@Input() form!: FormGroup<MedicationActionFormGroup>;
	@Input() item!: Row | null;

	medicationAmountUnits: Unit[] = [
		{ label: MedicationAmountUnitV2.Mg },
		{ label: MedicationAmountUnitV2.Ml },
		{ label: MedicationAmountUnitV2.Ug },
		{ label: MedicationAmountUnitV2.L },
		{ label: MedicationAmountUnitV2.G },
		{ label: MedicationAmountUnitV2.Kg },
		{ label: MedicationAmountUnitV2.Drop },
		{ label: MedicationAmountUnitV2.Graduation },
		{ label: MedicationAmountUnitV2.Tablet },
		{ label: MedicationAmountUnitV2.Capsule },
		{ label: MedicationAmountUnitV2.Spray },
		{ label: MedicationAmountUnitV2.Pump },
		{ label: MedicationAmountUnitV2.Iu },
		{ label: MedicationAmountUnitV2.Na },
	];

	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.form.addControl('amountGiven', this.formBuilder.control(this.action?.amountGiven ?? null));
	}
}