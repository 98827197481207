import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { IonIcon, IonRippleEffect } from '@ionic/angular/standalone';
import { CoercionService } from 'src/app/services/coercion.service';

@Component({
	selector: 'app-chip',
	templateUrl: './chip.component.html',
	styleUrl: './chip.component.scss',
	standalone: true,
	imports: [IonIcon, IonRippleEffect],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipComponent {

	@Input() startIcon: string = '';
	@Input() startIconColor = 'primary';
	@Input() endIcon: string = '';
	@Input() endIconColor = 'primary';
	@Input() label: string = '';

	private isClickable: boolean = false;
	@Input()
	get clickable() {
		return this.isClickable;
	}
	set clickable(value: any) {
		this.isClickable = this.coercionService.coerceToBoolean(value);
	}

	@Input()
	public get shrinkable() {
		return this.isShrinkable;
	}
	public set shrinkable(value: any) {
		this.isShrinkable = this.coercionService.coerceToBoolean(value);
	}

	@HostBinding('class.shrinkable') public isShrinkable: boolean = false;

	@HostBinding('class') get getCustomClasses() {
		return {
			'clickable': this.isClickable,
			'navigable-element': this.isClickable,
			'ion-activatable': this.isClickable
		}
	};

	constructor(private coercionService: CoercionService) {
	}
}