import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, delay, filter, map, mergeMap } from 'rxjs/operators';
import { stackedGridItemSwitchedDuration } from 'src/app/animations/common.animations';
import { routeParams } from 'src/app/app.routes';
import { ErrorService } from 'src/app/services/error.service';
import { SheetsService } from 'src/app/services/sheets.service';
import { accessToken } from '../auth/auth.selectors';
import { clientErrored } from '../error/error.actions';
import { patientId } from '../patient-graph/patient-graph.selectors';
import { AppState } from '../reducers';
import { selectRouteParam } from '../router/router.selectors';
import * as sheetActions from '../sheet/sheet.actions';
import { granularityChangeActive, selectedGranularity } from '../sheet/sheet.selectors';
import * as sheetGraphActions from './sheet-graph.actions';
import { sheetId } from './sheet-graph.selectors';

@Injectable()
export class SheetGraphEffects {

	constructor(private actions$: Actions, private store: Store<AppState>, private sheetsService: SheetsService, private errorService: ErrorService) {
	}

	createSheetRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetGraphActions.createSheetRequested),
			mergeMap(action => this.sheetsService.createForVisit(action.visitId).pipe(
				map(payload => sheetGraphActions.createSheetCompleted({ visitId: action.visitId, sheetId: payload.sheetId })),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Create sheet failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	createSheetCompleted$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetGraphActions.createSheetCompleted),
			concatLatestFrom(() => this.store.select(selectedGranularity)),
			map(([payload, granularity]) => sheetGraphActions.subscribeSheetRequested({ sheetId: payload.sheetId, granularity }))
		)
	);

	sheetLoadInitiated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetActions.sheetLoadInitiated),
			filter(payload => payload.sheetId !== null),
			concatLatestFrom(() => this.store.select(selectedGranularity)),
			map(([payload, granularity]) => sheetGraphActions.subscribeSheetRequested({ sheetId: payload.sheetId!, granularity }))
		)
	);

	selectedVisitChanged$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetActions.selectedVisitChanged),
			concatLatestFrom(() => [this.store.select(sheetId), this.store.select(selectedGranularity)]),
			mergeMap(([payload, previousSheetId, granularity]) => {
				const actions: any[] = [];
				if (previousSheetId) {
					actions.push(sheetGraphActions.unsubscribeSheetRequested({ sheetId: previousSheetId }));
				}
				if (payload.sheetId) {
					actions.push(sheetGraphActions.subscribeSheetRequested({ sheetId: payload.sheetId, granularity }));
				}
				return actions;
			})
		)
	);

	patientIdParamChanged$ = createEffect(() =>
		this.store.select(selectRouteParam(routeParams.patientId)).pipe(
			concatLatestFrom(() => [
				this.store.select(accessToken),
				this.store.select(patientId),
				this.store.select(sheetId)
			]),
			filter(([, accessToken, previousPatientId, sheetId]) => !!accessToken && !!sheetId && !!previousPatientId),
			mergeMap(([, , , sheetId]) => [
				sheetGraphActions.unsubscribeSheetRequested({ sheetId }),
				sheetActions.sheetCleared()
			])
		)
	);

	sheetGraphGeneratedMessageReceived$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetGraphActions.sheetGraphGeneratedMessageReceived),
			concatLatestFrom(() => [this.store.select(sheetId), this.store.select(selectedGranularity), this.store.select(granularityChangeActive)]),
			// skip if granularity has changed since the original signalR message was sent
			filter(([action, selectedSheetId, selectedGranularity,]) => action.sheet.id === selectedSheetId && action.sheet.granularity === selectedGranularity),
			concatMap(([action, , , granularityChangeActive]) => {
				const actions: any[] = [sheetGraphActions.sheetGraphGenerated({ sheet: action.sheet, granularityChangeActive: granularityChangeActive })];
				if (granularityChangeActive) actions.push(sheetActions.changeGranularityCompleted({ granularity: action.sheet.granularity }));
				return actions;
			})
		)
	);

	sheetResizedMessageReceived$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetGraphActions.sheetResizedMessageReceived),
			concatLatestFrom(() => this.store.select(sheetId)),
			filter(([action, selectedSheetId]) => action.sheetId === selectedSheetId),
			map(([action,]) => sheetGraphActions.sheetResized({ partialSheet: action.partialSheet }))
		)
	);

	rowChangedMessageReceived$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetGraphActions.rowChangedMessageReceived),
			concatLatestFrom(() => this.store.select(sheetId)),
			filter(([action, selectedSheetId]) => action.sheetId === selectedSheetId),
			map(([action,]) => sheetGraphActions.rowChanged({ row: action.row }))
		)
	);

	resubscribeSheetRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetActions.changeGranularityRequested),
			delay(stackedGridItemSwitchedDuration),
			concatLatestFrom(() => [this.store.select(sheetId)]),
			map(([action, sheetId]) => sheetGraphActions.resubscribeSheetRequested({ sheetId, granularity: action.granularity }))
		)
	);

	// dev note: This is sent from the server when a sheet actor starts (one actor instance per sheetId).
	//           The actor starts when a new sheetId subscription is requested, or if an existing actor crashed.
	sheetGraphSystemInitialised$ = createEffect(() =>
		this.actions$.pipe(
			ofType(sheetGraphActions.sheetGraphSystemInitialised),
			concatLatestFrom(() => [this.store.select(sheetId), this.store.select(selectedGranularity)]),
			filter(([payload, sheetId]) => payload.sheetId === sheetId),
			map(([, sheetId, granularity]) => sheetGraphActions.subscribeSheetRequested({ sheetId, granularity }))
		)
	);
}