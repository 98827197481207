import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface ActionInfo {
	actionMethod: string;
	actionPayload: any;
}

@Injectable({
	providedIn: 'root'
})
export class ClientLoggingService {

	constructor(private httpClient: HttpClient) {
	}

	logAction(actionTypes: string[]) {
		return this.httpClient.post<void>('/api/client-logging/log-action', actionTypes, { headers: { 'Content-Type': 'application/json' } });
	}

	logError(actionError: any) {
		return this.httpClient.post<void>('/api/client-logging/log-error', actionError, { headers: { 'Content-Type': 'application/json' } });
	}

	logGlobalError(globalError: string) {
		return this.httpClient.post<void>('/api/client-logging/log-global-error', JSON.stringify(globalError), { headers: { 'Content-Type': 'application/json' } });
	}
}
