import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class CurrentUserService {

	constructor(private httpClient: HttpClient) {
	}

	pingServer() {
		return this.httpClient.get('/api/current-user/ping-server');
	}

	putSelectedTeam(teamId: string) {
		return this.httpClient.put<string>(`/api/current-user/selected-teams/${teamId}`, null);
	}

	deleteSelectedTeam(teamId: string) {
		return this.httpClient.delete(`/api/current-user/selected-teams/${teamId}`);
	}

	putSearchedPatient(patientId: string) {
		return this.httpClient.put<string>(`/api/current-user/searched-patients/${patientId}`, null);
	}

	setLoginPin(pin: number) {
		return this.httpClient.put<void>('/api/current-user/pin', JSON.stringify(pin), { headers: { 'Content-Type': 'application/json' } });
	}
}