import { createAction, props } from '@ngrx/store';
import { TeamMember } from 'src/app/services/teams.service';

export enum ServiceSettingsActionTypes {
	AdministerServiceInitiated = '[Service Settings Component] Administer Service Initiated',
	ManageServiceMembersInitiated = '[Service Settings Component] Manage Service Members Initiated',
	LoadServiceSettingsRequested = '[Service Settings Effect] Load Service Settings Requested',
	ServiceSettingsLoaded = '[Service Settings Effect] Service Settings Loaded',
	ServiceSettingsUnloaded = '[Service Settings Modal] Service Settings Unloaded',
	EnableTeamMembershipRequested = '[Service Settings Modal] Enable Team Membership Requested',
	TeamMembershipEnabled = '[Service Settings Effect] Team Membership Enabled',
	DisableTeamMembershipRequested = '[Service Settings Modal] Disable Team Membership Requested',
	TeamMembershipDisabled = '[Service Settings Effect] Team Membership Disabled',
	AddTeamAdministratorRequested = '[Service Settings Modal] Add Team Administrator Requested',
	AddTeamMemberRequested = '[Service Settings Modal] Add Team Member Requested',
	TeamMemberAdded = '[Service Settings Effect] Team Member Added',
	RemoveTeamAdministratorRequested = '[Service Settings Modal] Remove Team Administrator Requested',
	RemoveTeamMemberRequested = '[Service Settings Modal] Remove Team Member Requested',
	TeamMemberRemoved = '[Service Settings Effect] Team Member Removed',
	SetCanEditMemberListStateRequested = '[Service Settings Modal] Set Can Edit Member List State Requested',
	CanEditMemberListStateChanged = '[Service Settings Effect] Can Edit Member List State Changed'
}

export const administerServiceInitiated = createAction(
	ServiceSettingsActionTypes.AdministerServiceInitiated,
	props<{ serviceId: string, teamMembershipEnabled: boolean }>()
)

export const manageServiceMembersInitiated = createAction(
	ServiceSettingsActionTypes.ManageServiceMembersInitiated,
	props<{ serviceId: string, teamMembershipEnabled: boolean }>()
)

export const loadServiceSettingsRequested = createAction(
	ServiceSettingsActionTypes.LoadServiceSettingsRequested,
	props<{ serviceId: string, teamMembershipEnabled: boolean }>()
)

export const serviceSettingsLoaded = createAction(
	ServiceSettingsActionTypes.ServiceSettingsLoaded,
	props<{ serviceId: string, teamMembers: TeamMember[] }>()
)

export const serviceSettingsUnloaded = createAction(
	ServiceSettingsActionTypes.ServiceSettingsUnloaded
)

export const enableTeamMembershipRequested = createAction(
	ServiceSettingsActionTypes.EnableTeamMembershipRequested,
	props<{ serviceId: string }>()
)

export const teamMembershipEnabled = createAction(
	ServiceSettingsActionTypes.TeamMembershipEnabled
)

export const disableTeamMembershipRequested = createAction(
	ServiceSettingsActionTypes.DisableTeamMembershipRequested,
	props<{ serviceId: string }>()
)

export const teamMembershipDisabled = createAction(
	ServiceSettingsActionTypes.TeamMembershipDisabled
)

export const addTeamAdministratorRequested = createAction(
	ServiceSettingsActionTypes.AddTeamAdministratorRequested,
	props<{ serviceId: string, userId: number }>()
)

export const addTeamMemberRequested = createAction(
	ServiceSettingsActionTypes.AddTeamMemberRequested,
	props<{ serviceId: string, userId: number }>()
)

export const teamMemberAdded = createAction(
	ServiceSettingsActionTypes.TeamMemberAdded,
	props<{ serviceId: string, teamMember: TeamMember }>()
)

export const removeTeamAdministratorRequested = createAction(
	ServiceSettingsActionTypes.RemoveTeamAdministratorRequested,
	props<{ serviceId: string, userId: number }>()
)

export const removeTeamMemberRequested = createAction(
	ServiceSettingsActionTypes.RemoveTeamMemberRequested,
	props<{ serviceId: string, userId: number }>()
)

export const teamMemberRemoved = createAction(
	ServiceSettingsActionTypes.TeamMemberRemoved,
	props<{ serviceId: string, userId: number }>()
)

export const setCanEditMemberListStateRequested = createAction(
	ServiceSettingsActionTypes.SetCanEditMemberListStateRequested,
	props<{ serviceId: string, userId: number, canEditMemberList: boolean }>()
)

export const canEditMemberListStateChanged = createAction(
	ServiceSettingsActionTypes.CanEditMemberListStateChanged,
	props<{ serviceId: string, userId: number, canEditMemberList: boolean }>()
)