<app-action-form [item]="item" [slot]="slot" [form]="form">
	<div class="form-section app-margin-horizontal app-padding">
		<div class="form-group" [formGroup]="form">
			<app-heading background="secondary-2" size="small">In the kennel the dog is...</app-heading>
			<app-checkbox label="Quiet (0)" formControlName="inKennelQuiet"></app-checkbox>
			<app-checkbox label="Crying or whimpering (1)" formControlName="inKennelCrying"></app-checkbox>
			<app-checkbox label="Groaning (2)" formControlName="inKennelGroaning"></app-checkbox>
			<app-checkbox label="Screaming (3)" formControlName="inKennelScreaming"></app-checkbox>
		</div>
		<div class="form-group" [formGroup]="form">
			<app-heading background="secondary-2" size="small">In the kennel the dog is...</app-heading>
			<app-checkbox label="Ignoring any wound or painful area (0)" formControlName="inKennelIgnoringWound"></app-checkbox>
			<app-checkbox label="Looking at the wound or painful area (1)" formControlName="inKennelLookingAtWound"></app-checkbox>
			<app-checkbox label="Licking the wound or painful area (2)" formControlName="inKennelLickingWound"></app-checkbox>
			<app-checkbox label="Rubbing the wound or painful area (3)" formControlName="inKennelRubbingWound"></app-checkbox>
			<app-checkbox label="Chewing the wound or painful area (4)" formControlName="inKennelChewingWound"></app-checkbox>
		</div>
		<div class="form-group" [formGroup]="form">
			<app-heading background="secondary-2" size="small">
				Put a lead on the dog and lead it out of the kennel. When the dog rises / walks out it is...<br>
				NOTE: Do not complete this section in the case of spinal, pelvic or multiple limb fractures, 
				or where assistance is required to aid locomotion.
			</app-heading>
			<app-checkbox label="Normal (0)" formControlName="onLeadNormal"></app-checkbox>
			<app-checkbox label="Lame (1)" formControlName="onLeadLame"></app-checkbox>
			<app-checkbox label="Slow or reluctant (2)" formControlName="onLeadSlow"></app-checkbox>
			<app-checkbox label="Stiff (3)" formControlName="onLeadStiff"></app-checkbox>
			<app-checkbox label="Refuses to move (4)" formControlName="onLeadRefusesToMove"></app-checkbox>
		</div>
		<div class="form-group" [formGroup]="form">
			<app-heading background="secondary-2" size="small">Apple gentle pressure two inches around the wound or painful area. Does the dog...</app-heading>
			<app-checkbox label="Do nothing (0)" formControlName="onPressureDoNothing"></app-checkbox>
			<app-checkbox label="Look around (1)" formControlName="onPressureLookAround"></app-checkbox>
			<app-checkbox label="Flinch (2)" formControlName="onPressureFlinch"></app-checkbox>
			<app-checkbox label="Growl or guard area (3)" formControlName="onPressureGrowl"></app-checkbox>
			<app-checkbox label="Snap (4)" formControlName="onPressureSnap"></app-checkbox>
			<app-checkbox label="Cry (5)" formControlName="onPressureCry"></app-checkbox>
		</div>
		<div class="form-group" [formGroup]="form">
			<app-heading background="secondary-2" size="small">Overall the dog is...</app-heading>
			<app-checkbox label="Happy and content or bouncy (0)" formControlName="overallHappy"></app-checkbox>
			<app-checkbox label="Quiet (1)" formControlName="overallQuiet"></app-checkbox>
			<app-checkbox label="Indifferent, or non-responsive to surroundings (2)" formControlName="overallIndifferent"></app-checkbox>
			<app-checkbox label="Nervous, anxious, or fearful (3)" formControlName="overallNervous"></app-checkbox>
			<app-checkbox label="Depressed or non-responsive to stimulation (4)" formControlName="overallDepressed"></app-checkbox>
		</div>
		<div class="form-group" [formGroup]="form">
			<app-heading background="secondary-2" size="small">Overall the dog is...</app-heading>
			<app-checkbox label="Comfortable (0)" formControlName="overallComfortable"></app-checkbox>
			<app-checkbox label="Unsettled (1)" formControlName="overallUnsettled"></app-checkbox>
			<app-checkbox label="Restless (2)" formControlName="overallRestless"></app-checkbox>
			<app-checkbox label="Hunched or tense (3)" formControlName="overallHunched"></app-checkbox>
			<app-checkbox label="Rigid (4)" formControlName="overallRigid"></app-checkbox>
		</div>
		<div class="form-group" [formGroup]="form">
			<app-text-input label="Total score" placeholder="Value" formControlName="totalScore" inputMode="numeric"></app-text-input>
		</div>
		<app-common-action-controls [action]="action" [form]="form"></app-common-action-controls>
	</div>
</app-action-form>
