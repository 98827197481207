import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { TransferActionV2 } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';
import { CheckboxControl } from 'src/app/form-controls/checkbox/checkbox.control';


interface TransferActionV2FormGroup {
	catheterCheck: FormControl<boolean | null>;
	surgicalSiteCheck: FormControl<boolean | null>;
	medication: FormControl<boolean | null>;
	scheduleAuthorisation: FormControl<boolean | null>;
}

@Component({
	selector: 'app-transfer-action-controls',
	templateUrl: './transfer-action-controls-v2.component.html',
	styleUrls: ['../../../common.scss', './transfer-action-controls-v2.component.scss'],
	standalone: true,
	imports: [CommonActionControlsComponent, ActionFormComponent, CheckboxControl, ReactiveFormsModule],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransferActionControlsComponentV2 implements SheetActionControlsBase, OnInit {

	slot!: Slot | null;
	action!: TransferActionV2;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as TransferActionV2;
	}

	@Input() form!: FormGroup<TransferActionV2FormGroup>;
	@Input() item!: Row | null;
	
	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit(): void {
		this.form.addControl('catheterCheck', this.formBuilder.control(this.action?.catheterCheck ?? null));
		this.form.addControl('surgicalSiteCheck', this.formBuilder.control(this.action?.surgicalSiteCheck ?? null));
		this.form.addControl('medication', this.formBuilder.control(this.action?.medication ?? null));
		this.form.addControl('scheduleAuthorisation', this.formBuilder.control(this.action?.scheduleAuthorisation ?? null));
	}
}