import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isBefore } from 'date-fns';

// this validator expects two controls with date strings. If either control does not have a value, it will pass validation

export function isAfter(childControlName: string, childControlToCompareName: string): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {

		const childControl = control.get(childControlName);
		const childControlToCompare = control.get(childControlToCompareName);

		if (!childControl?.value || !childControlToCompare?.value) return null;

		if (isBefore(childControl.value as string, childControlToCompare.value as string)) return { isBefore: true };

		return null;
	}
};