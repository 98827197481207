import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SheetSlotComponentBase } from 'src/app/services/sheets-renderer.service';
import { FaecesCheckAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { TickValueComponent } from '../../tick-value/tick-value.component';

@Component({
	selector: 'app-faeces-check-slot',
	templateUrl: './faeces-check-slot.component.html',
	styleUrl: './faeces-check-slot.component.scss',
	standalone: true,
	imports: [TickValueComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FaecesCheckSlotComponent implements SheetSlotComponentBase {

	action!: FaecesCheckAction;
	value!: string | null;

	@Input('action') set updateAction(action: FaecesCheckAction | null) {
		this.action = action as FaecesCheckAction;
		this.value = this.action.faecesPassed ? 'Yes' : 'No';
	}

	constructor() {
	}
}
