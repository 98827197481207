import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import { teams } from '../user-graph/user-graph.selectors';

export const selectServiceId = (state: AppState) => state.serviceGraph.serviceId;
export const selectSubscribedDate = (state: AppState) => state.serviceGraph.subscribedDate;
export const selectServiceLoaded = (state: AppState) => state.serviceGraph.serviceLoaded;

export const serviceId = createSelector(
	selectServiceId,
	(serviceId) => serviceId
);

export const subscribedDate = createSelector(
	selectSubscribedDate,
	(subscribedDate) => subscribedDate
);

export const serviceLoaded = createSelector(
	selectServiceLoaded,
	(serviceLoaded) => serviceLoaded
);

export const selectedTeam = createSelector(
	serviceId,
	teams,
	(serviceId, teams) => serviceId ? teams.find(t => t.id === serviceId) : undefined
);