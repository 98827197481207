<app-action-form [item]="item" [slot]="slot" [form]="form">
	<div class="form-section app-margin-horizontal app-padding">
        <div class="form-group" [formGroup]="form">
            <app-unit-quantity label="Approximate Size" unitLabel="cm" placeholder="Value" inputMode="decimal" formControlName="size"></app-unit-quantity>
            <app-select label="Palpation" placeholder="Choose..." [items]="palpation" [saveValue]="true" formControlName="palpation"></app-select>
        </div>
        <app-common-action-controls [action]="action" [form]="form"></app-common-action-controls>
    </div>
</app-action-form>

