import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, mergeMap, tap } from 'rxjs/operators';
import { ClientLoggingService } from 'src/app/services/client-logging.service';
import { bufferUntilQuiet } from 'src/app/types/rxjs-custom-operator.types';
import { ErrorActionTypes, clientErrored, signalRErrored } from '../error/error.actions';

@Injectable()
export class ClientLoggingEffects {

	constructor(
		private actions$: Actions,
		private clientLoggingService: ClientLoggingService) {
	}

	log$ = createEffect(() =>
		this.actions$.pipe(
			filter(action => action.type !== ErrorActionTypes.ClientErrored && action.type !== ErrorActionTypes.SignalRErrored && action.type !== ErrorActionTypes.HttpRequestErrored),
			bufferUntilQuiet(this.actions$, 1000),
			mergeMap(actions => this.clientLoggingService.logAction(actions.map(a => a.type))
		)),
		{ dispatch: false }
	);

	clientErrored$ = createEffect(() =>
		this.actions$.pipe(
			ofType(clientErrored, signalRErrored),
			mergeMap(action => this.clientLoggingService.logError({ ...action }))
		),
		{ dispatch: false }
	);
}
