<app-action-form [item]="item" [slot]="slot" [form]="form">
	<div class="form-section app-margin-horizontal app-padding">
		<div class="form-group" [formGroup]="form">
			<app-heading background="secondary-2" size="small">Ear Position</app-heading>
			<app-checkbox label="0 - Absent, Ears facing forward" formControlName="earAbsent"></app-checkbox>
			<app-checkbox label="1 - Moderately present, Ears slightly pulled apart" formControlName="earModeratelyPresent"></app-checkbox>
			<app-checkbox label="2 - Markedly present, Ears rotated outwards" formControlName="earMarkedlyPresent"></app-checkbox>
		</div>
		<div class="form-group" [formGroup]="form">
			<app-heading background="secondary-2" size="small">Orbital tightening</app-heading>
			<app-checkbox label="0 - Absent, Eyes opened" formControlName="orbitalAbsent"></app-checkbox>
			<app-checkbox label="1 - Moderately present, Partially closed eyes" formControlName="orbitalModeratelyPresent"></app-checkbox>
			<app-checkbox label="2 - Markedly present, Squinted eyes" formControlName="orbitalMarkedlyPresent"></app-checkbox>
		</div>
		<div class="form-group" [formGroup]="form">
			<app-heading background="secondary-2" size="small">Muzzle tension</app-heading>
			<app-checkbox label="0 - Absent, Relaxed (round shape)" formControlName="muzzleAbsent"></app-checkbox>
			<app-checkbox label="1 - Moderately present, Mild tension" formControlName="muzzleModeratelyPresent"></app-checkbox>
			<app-checkbox label="2 - Markedly present, Tense (elliptical shape)" formControlName="muzzleMarkedlyPresent"></app-checkbox>
		</div>
		<div class="form-group" [formGroup]="form">
			<app-heading background="secondary-2" size="small">Whisker change</app-heading>
			<app-checkbox label="0 - Absent, Loose (relaxed) and curve" formControlName="whiskerAbsent"></app-checkbox>
			<app-checkbox label="1 - Moderately present, Slightly curved or straight (closer together)" formControlName="whiskerModeratelyPresent"></app-checkbox>
			<app-checkbox label="2 - Markedly present, Straight and moving forward (away from the face)" formControlName="whiskerMarkedlyPresent"></app-checkbox>
		</div>
		<div class="form-group" [formGroup]="form">
			<app-heading background="secondary-2" size="small">Head position</app-heading>
			<app-checkbox label="0 - Absent, Head above the shoulder line" formControlName="headAbsent"></app-checkbox>
			<app-checkbox label="1 - Moderately present, Head aligned with the shoulder line" formControlName="headModeratelyPresent"></app-checkbox>
			<app-checkbox label="2 - Markedly present, Head below the shoulder line or tilted down (chin towards the chest)" formControlName="headMarkedlyPresent"></app-checkbox>
		</div>
		<div class="form-group" [formGroup]="form">
			<app-text-input label="Total score" placeholder="Value" formControlName="totalScore" inputMode="numeric"></app-text-input>
		</div>
		<app-common-action-controls [action]="action" [form]="form"></app-common-action-controls>
	</div>
</app-action-form>