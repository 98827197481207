import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { loginInitiated } from '../store/auth/auth.actions';
import { AppState } from '../store/reducers';

export const canActivateLogin: CanActivateFn = (activatedRoute: ActivatedRouteSnapshot, routerState: RouterStateSnapshot) => {
	var store = inject(Store<AppState>);
	store.dispatch(loginInitiated());
	return true;
};