import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'app-svg-icon',
	templateUrl: './svg-icon.component.html',
	styleUrl: './svg-icon.component.scss',
	standalone: true,
	imports: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgIconComponent {

	@Input() name!: string;

	constructor(private sanitizer: DomSanitizer) {
	}

	get svgIcon() {
		return this.sanitizer.bypassSecurityTrustHtml(svgIcons[this.name] ?? svgIcons['chevron-forward-sharp']);
	}
}

// note 1: you can download svg icons from sites like https://ionic.io/ionicons (sharp means the edges are not rounded)
// note 2: useful website for removing whitespace around svg files: https://svgcrop.com/
// note 3: set the appropriate style width or height for related text to enable size from parent css via font-size
// note 4: ensure any fill/stroke atrributes are set to "currentColor" to allow control via parent css via color
// note 5: to set colour in parent component, set the --color css variable
const svgIcons: { [key: string]: string } = {
	'add-circle-outline': '<svg xmlns="http://www.w3.org/2000/svg" style="width: 1.3rem" viewBox="48 48 416 416"><path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"></path><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M256 176v160M336 256H176"></path></svg>',
	'box-outline': '<svg xmlns="http://www.w3.org/2000/svg" style="width: 1.2rem" viewBox="48 48 416 416"><rect x="64" y="64" width="384" height="384" rx="48" ry="48" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"></rect></svg>',
	'arrow-up': '<svg xmlns="http://www.w3.org/2000/svg" style="width: 1rem" viewBox="88 76 336 360"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M112 244l144-144 144 144M256 120v292"></path></svg>',
	'caret-back': '<svg xmlns="http://www.w3.org/2000/svg" style="width: 0.4rem" viewBox="150.44 92.15 211.12 327.67"><path fill="currentColor" d="M321.94 98L158.82 237.78a24 24 0 000 36.44L321.94 414c15.57 13.34 39.62 2.28 39.62-18.22v-279.6c0-20.5-24.05-31.56-39.62-18.18z"></path></svg>',
	'caret-down': '<svg xmlns="http://www.w3.org/2000/svg" style="width: 0.6rem" viewBox="92.15 150.44 327.67 211.12"><path fill="currentColor" d="M98 190.06l139.78 163.12a24 24 0 0036.44 0L414 190.06c13.34-15.57 2.28-39.62-18.22-39.62h-279.6c-20.5 0-31.56 24.05-18.18 39.62z"></path></svg>',
	'caret-forward': '<svg xmlns="http://www.w3.org/2000/svg" style="width: 0.4rem" viewBox="150.44 92.18 211.12 327.67"><path fill="currentColor" d="M190.06 414l163.12-139.78a24 24 0 000-36.44L190.06 98c-15.57-13.34-39.62-2.28-39.62 18.22v279.6c0 20.5 24.05 31.56 39.62 18.18z"></path></svg>',
	'checkbox-outline': '<svg xmlns="http://www.w3.org/2000/svg" style="width: 1.2rem" viewBox="48 48 416 416"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M352 176L217.6 336 160 272"></path><rect x="64" y="64" width="384" height="384" rx="48" ry="48" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32"></rect></svg>',
	'checkmark-sharp': '<svg xmlns="http://www.w3.org/2000/svg" style="width: 1rem" viewBox="64 96 384 320"><path fill="none" stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10" stroke-width="44" d="M416 128L192 384l-96-96"></path></svg>',
	'chevron-forward-sharp': '<svg xmlns="http://www.w3.org/2000/svg" style="width: 0.7rem" viewBox="150 78 212 356"><path fill="none" stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10" stroke-width="48" d="M184 112l144 144-144 144"></path></svg>',
	'chevron-back-sharp': '<svg xmlns="http://www.w3.org/2000/svg" style="width: 1rem" viewBox="150 78 212 356"><path fill="none" stroke="currentColor" stroke-linecap="square" stroke-miterlimit="10" stroke-width="48" d="M328 112L184 256l144 144"></path></svg>',
	'close-sharp': '<svg xmlns="http://www.w3.org/2000/svg" style="width: 0.8rem" viewBox="112 112 288 288"><path fill="currentColor" d="M400 145.49L366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49z"></path></svg>',
	'heart-sharp': '<svg xmlns="http://www.w3.org/2000/svg" style="width: 1rem" viewBox="47.99 64 416.02 384"><path fill="currentColor" d="M256 448l-9-6c-42.78-28.57-96.91-60.86-137-108.32-42.25-50-62.52-101.35-62-157C48.63 114.54 98.46 64 159.08 64c48.11 0 80.1 28 96.92 48.21C272.82 92 304.81 64 352.92 64c60.62 0 110.45 50.54 111.08 112.65.56 55.68-19.71 107-62 157-40.09 47.49-94.22 79.78-137 108.35z"></path></svg>',
	'heart-dislike-sharp': '<svg xmlns="http://www.w3.org/2000/svg" style="width: 1rem" viewBox="32 42 448 422"><path fill="currentColor" d="M32 64.45l389.47 389.94 22.84-22.47L54.85 42 32 64.45zM62.67 192.91c-.56 55.63 19.77 106.94 62.16 156.88 40.25 47.42 94.56 79.67 137.47 108.21l9 6 9-6c18.49-12.3 39.1-25.3 59.79-39.89L71.74 149.28a114.62 114.62 0 00-9.07 43.63zM417.83 349.79c42.39-49.94 62.72-101.25 62.16-156.88-.63-62-50.61-112.54-111.43-112.54-48.26 0-80.35 28-97.23 48.17-16.88-20.2-49-48.17-97.23-48.17A108.24 108.24 0 00142.84 85l270 270.48c1.71-1.89 3.37-3.78 4.99-5.69z"></path></svg>'
};