import { createAction, props } from '@ngrx/store';
import { ImagingModalityBodyPart } from 'src/app/services/body-parts.service';

export enum BodyPartSearchActionTypes {
	NewSearchRequested = '[Body Part Search Modal] New Search Requested',
	NewSearchSucceeded = '[Body Part Search Effect] New Search Succeeded',
	NewSearchFailed = '[Body Part Search Effect] New Search Failed',
	AppendResultsRequested = '[Body Part Search Modal] Append Results Requested',
	AppendResultsSucceeded = '[Body Part Search Effect] Append Results Succeeded',
	AppendResultsFailed = '[Body Part Search Effect] Append Results Failed',
	ResultsCleared = '[Body Part Search Modal] Results Cleared'
}

export const newSearchRequested = createAction(
	BodyPartSearchActionTypes.NewSearchRequested,
	props<{ queryTerm: string, modality: string }>()
)

export const newSearchSucceeded = createAction(
	BodyPartSearchActionTypes.NewSearchSucceeded,
	props<{ searchResults: ImagingModalityBodyPart[] }>()
)

export const newSearchFailed = createAction(
	BodyPartSearchActionTypes.NewSearchFailed,
	props<{ statusCode: number }>()
)

export const appendResultsRequested = createAction(
	BodyPartSearchActionTypes.AppendResultsRequested
)

export const appendResultsSucceeded = createAction(
	BodyPartSearchActionTypes.AppendResultsSucceeded,
	props<{ searchResults: ImagingModalityBodyPart[] }>()
)

export const appendResultsFailed = createAction(
	BodyPartSearchActionTypes.AppendResultsFailed,
	props<{ statusCode: number }>()
)

export const resultsCleared = createAction(
	BodyPartSearchActionTypes.ResultsCleared
)