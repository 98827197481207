import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormArray, FormBuilder, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidationErrors, Validator, Validators } from '@angular/forms';
import { enterLeave } from 'src/app/animations/common.animations';
import { ItemLabelComponent } from 'src/app/components/item-label/item-label.component';
import { ItemComponent } from 'src/app/components/item/item.component';
import { SvgIconComponent } from 'src/app/components/svg-icon/svg-icon.component';
import { SelectControl } from '../select/select.control';
import { TextInputControl } from '../text-input/text-input.control';

@Component({
	selector: 'app-multi-text-input',
	templateUrl: './multi-text-input.control.html',
	styleUrl: './multi-text-input.control.scss',
	standalone: true,
	imports: [ReactiveFormsModule, ItemComponent, ItemLabelComponent, SvgIconComponent, SelectControl, TextInputControl, SvgIconComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: MultiTextInputControl,
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: MultiTextInputControl
		}
	],
	animations: [enterLeave]
})
export class MultiTextInputControl implements ControlValueAccessor, Validator, OnInit {

	@Input() readOnly: boolean = false;
	@Input() label: string = '';
	@Input() placeholder: string = '';
	@Input() addText: string = 'Add';
	@Input() minItems: number = 0;

	formArray!: FormArray<FormControl<string>>;

	constructor(private changeDetectorRef: ChangeDetectorRef, private formBuilder: FormBuilder, private destroyRef: DestroyRef) { // note: DestroyRef is needed if takeUntilDestroyed is used outside the constructor
	}

	ngOnInit() {
		this.formArray = this.formBuilder.nonNullable.array<string>([]);

		if (!this.readOnly) {
			this.formArray.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
				this.onChange(value);
				this.onValidatorChange();
				this.changeDetectorRef.markForCheck();
			});
		}
	}

	writeValue(value: string[] | null): void {

		// FormArray.setValue() errors if its structure does not match the value, so need to ensure the number of items matches first
		const itemsCount = value?.length ?? 0;

		if (!value && this.formArray.length) {
			this.formArray.clear({ emitEvent: false });
		}
		else if (this.formArray.length < itemsCount) {
			for (let i = this.formArray.length; i < itemsCount; i++) this.addItem(value?.at(i) ?? '', false);
		}
		else if (this.formArray.length > itemsCount) {
			do { this.formArray.removeAt(this.formArray.length - 1, { emitEvent: false }) } while (this.formArray.length > itemsCount);
		}

		this.formArray.setValue(value ?? [], { emitEvent: false });

		// this is required for the view to update correctly (it's a general approach recommended by Angular devs using ChangeDetectionStrategy.OnPush: https://github.com/angular/angular/issues/21780)
		this.changeDetectorRef.markForCheck();
	}

	private onChange: (value: string[] | null) => void = () => { };

	registerOnChange(fn: (value: string[] | null) => void): void {
		this.onChange = fn;
	}

	private onTouched: () => void = () => { };

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(disabled: boolean): void {
	}

	controlClicked() {
		if (this.readOnly) return;

		this.onTouched();
	}

	addItem(value: string, emitEvent: boolean) {
		this.formArray.push(this.formBuilder.nonNullable.control(value, [Validators.required]), { emitEvent: emitEvent });
		this.onTouched();
	}

	removeItem(index: number) {
		this.formArray.removeAt(index);
		this.onTouched();
	}

	// custom validation
	private onValidatorChange: () => void = () => { }; // call this when the internal state has changed requiring external validation

	registerOnValidatorChange(fn: () => void): void {
		this.onValidatorChange = fn;
	}

	validate(): ValidationErrors | null {
		let errors: { [key: string]: any } = {};
		if (this.formArray.length < this.minItems) errors['minLength'] = { requiredLength: this.minItems, actualLength: this.formArray.length };
		errors = { ...errors, ...this.formArray.errors };
		return Object.keys(errors).length ? errors : null;
	}
}