import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SelectControl, SelectItem } from 'src/app/form-controls/select/select.control';
import { UnitQuantity, UnitQuantityControl } from 'src/app/form-controls/unit-quantity/unit-quantity.control';

import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { CheckBladderSizeAction, Palpation } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface CheckBladderSizeActionFormGroup {
	size: FormControl<UnitQuantity | null>;
	palpation: FormControl<Palpation | null>;
}

@Component({
	selector: 'app-check-bladder-size-action-controls',
	templateUrl: './check-bladder-size-action-controls.component.html',
	styleUrls: ['../../../common.scss', './check-bladder-size-action-controls.component.scss'],
	standalone: true,
	imports: [ReactiveFormsModule, UnitQuantityControl, SelectControl, CommonActionControlsComponent, ActionFormComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckBladderSizeActionControlsComponent implements SheetActionControlsBase, OnInit {

	slot!: Slot | null;
	action!: CheckBladderSizeAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as CheckBladderSizeAction;
	}

	@Input() form!: FormGroup<CheckBladderSizeActionFormGroup>;
	@Input() item!: Row | null;
	
	palpation: SelectItem[] = [
		{ text: 'Soft', value: Palpation.Soft },
		{ text: 'Hard', value: Palpation.Hard },
		{ text: 'Moderate', value: Palpation.Moderate },
	];

	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.form.addControl('size', this.formBuilder.control(this.action?.size ?? null));
		this.form.addControl('palpation', this.formBuilder.control(this.action?.palpation ?? null));
	}
}