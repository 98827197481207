import { createReducer, on } from '@ngrx/store';
import { Sheet } from 'src/app/types/aggregate-graph.types';
import { clearStateRequested } from '../auth/auth.actions';
import * as sheetGraphActions from './sheet-graph.actions';

export interface State {
	sheetId: string;
	sheet: Sheet | null;
}

export const initialState: State = {
	sheetId: '',
	sheet: null
}

export const sheetGraphReducer = createReducer(
	initialState,
	on(sheetGraphActions.subscribeSheetRequested, (state, { sheetId }) => ({
		...state,
		sheetId: sheetId
	})),
	on(sheetGraphActions.sheetGraphGenerated, (state, { sheet }) => ({
		...state,
		sheet: sheet
	})),
	on(sheetGraphActions.sheetResized, (state, { partialSheet }) => {
		if (state.sheet === null) return { ...state };
		return {
			...state,
			sheet: { ...state.sheet, ...partialSheet }
		};
	}),
	on(sheetGraphActions.rowChanged, (state, { row }) => {
		if (state.sheet === null) return { ...state };
		const rows = state.sheet.rows.filter(r => r.id !== row.id);
		rows.push(row);
		return {
			...state,
			sheet: { ...state.sheet,
				rows: rows
			 }
		};
	}),
	on(sheetGraphActions.unsubscribeSheetRequested, () => initialState),
	on(clearStateRequested, () => initialState)
);