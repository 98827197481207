import { ChangeDetectionStrategy, Component, NgZone } from '@angular/core';
import { AlertButton, AlertController, IonAlert, IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar, ModalController } from '@ionic/angular/standalone';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ButtonComponent } from 'src/app/components/button/button.component';
import { AccordionDirective } from 'src/app/components/expander/accordion.directive';
import { expanderEnterLeave } from 'src/app/components/expander/expander.animations';
import { ExpanderComponent } from 'src/app/components/expander/expander.component';
import { ItemLabelComponent } from 'src/app/components/item-label/item-label.component';
import { ItemComponent } from 'src/app/components/item/item.component';
import { SpinnerComponent } from 'src/app/components/spinner/spinner.component';
import { SvgIconComponent } from 'src/app/components/svg-icon/svg-icon.component';
import { NavigableDirective } from 'src/app/directives/navigable.directive';
import { UserSearchModal } from 'src/app/modals/user-search/user-search.modal';
import { TeamMember } from 'src/app/services/teams.service';
import { SearchedUser } from 'src/app/services/users.service';
import { AppState } from 'src/app/store/reducers';
import { selectedTeam } from 'src/app/store/service-graph/service-graph.selectors';
import { addTeamAdministratorRequested, disableTeamMembershipRequested, enableTeamMembershipRequested, removeTeamAdministratorRequested, serviceSettingsUnloaded } from 'src/app/store/service-settings/service-settings.actions';
import { serviceSettingsLoaded, teamAdministrators } from 'src/app/store/service-settings/service-settings.selectors';
import { Team } from 'src/app/types/aggregate-graph.types';
import { filterUndefinedOrNull } from 'src/app/types/rxjs-custom-operator.types';

@Component({
	templateUrl: './service-administration.modal.html',
	styleUrl: './service-administration.modal.scss',
	standalone: true,
	imports: [
		LetDirective, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, ButtonComponent, ItemComponent, ItemLabelComponent, SvgIconComponent, SpinnerComponent,
		ExpanderComponent, AccordionDirective, NavigableDirective, IonAlert
	],
	animations: [expanderEnterLeave],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceAdministrationModal {

	selectedTeam$: Observable<Team>;
	serviceSettingsLoaded$: Observable<boolean>;
	teamAdministrators$: Observable<TeamMember[]>;

	private selectedTeam!: Team;
	private teamAdministrators!: TeamMember[];

	constructor(private store: Store<AppState>, private modalController: ModalController, private alertController: AlertController, private ngZone: NgZone) {
		this.selectedTeam$ = this.store.select(selectedTeam).pipe(filterUndefinedOrNull(), tap(selectedTeam => this.selectedTeam = selectedTeam));
		this.serviceSettingsLoaded$ = this.store.select(serviceSettingsLoaded);
		this.teamAdministrators$ = this.store.select(teamAdministrators).pipe(tap(teamAdministrators => this.teamAdministrators = teamAdministrators));
	}

	//#region Enabling Team Membership

	teamMembershipEnabledAlertIsOpen: boolean = false;
	teamMembershipEnabledAlertButtons: AlertButton[] = [{ text: 'Cancel', role: 'cancel' }, { text: 'Enable', role: 'confirm', handler: () => this.enableTeamMembership() }];

	presentEnableTeamMembershipAlert() {
		this.teamMembershipEnabledAlertIsOpen = true;
	}

	enableTeamMembershipAlertDismissed() {
		this.teamMembershipEnabledAlertIsOpen = false;
	}

	enableTeamMembership() {
		this.ngZone.run(() => this.store.dispatch(enableTeamMembershipRequested({ serviceId: this.selectedTeam.id })));
	}

	//#endregion

	//#region Disabling Team Membership

	teamMembershipDisabledAlertIsOpen: boolean = false;
	teamMembershipDisabledAlertButtons: AlertButton[] = [{ text: 'Cancel', role: 'cancel' }, { text: 'Disable', role: 'confirm', handler: () => this.disableTeamMembership() }];

	presentDisableTeamMembershipAlert() {
		this.teamMembershipDisabledAlertIsOpen = true;
	}

	disableTeamMembershipAlertDismissed() {
		this.teamMembershipDisabledAlertIsOpen = false;
	}

	disableTeamMembership() {
		this.ngZone.run(() => this.store.dispatch(disableTeamMembershipRequested({ serviceId: this.selectedTeam.id })));
	}

	//#endregion

	//#region Adding & Removing Team Administrators

	async presentUserSearchModal() {
		const modal = await this.modalController.create({
			component: UserSearchModal,
			cssClass: 'stacked-modal',
			backdropDismiss: false
		});
		await modal.present();
		const { data } = await modal.onWillDismiss();
		if (!data) return;
		const user: SearchedUser = data.user;
		if (this.teamAdministrators.some(teamMember => teamMember.userId === user.id)) {
			const alert = await this.alertController.create({
				subHeader: 'User is already a team member',
				buttons: ['Ok'],
				backdropDismiss: false
			});
			await alert.present();
		}
		else {
			this.store.dispatch(addTeamAdministratorRequested({ serviceId: this.selectedTeam.id, userId: user.id }));
		}
	}

	removeTeamAdministrator(userId: number) {
		this.store.dispatch(removeTeamAdministratorRequested({ serviceId: this.selectedTeam.id, userId: userId }));
	}

	//#endregion

	async close() {
		await this.modalController.dismiss();
		this.store.dispatch(serviceSettingsUnloaded());
	}
}
