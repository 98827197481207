import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SheetItemComponentBase } from 'src/app/services/sheets-renderer.service';
import { Row } from 'src/app/types/aggregate-graph.types';
import { ItemContentComponent } from '../../item-content/item-content.component';
import { NutritionInstructions } from 'src/app/types/sheet-instruction.types';
import { InstructionBoxComponent } from '../../instruction-box/instruction-box.component';

@Component({
	selector: 'app-nutrition-item',
	templateUrl: './nutrition-item.component.html',
	styleUrl: './nutrition-item.component.scss',
	standalone: true,
	imports: [ItemContentComponent, InstructionBoxComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NutritionItemComponent implements SheetItemComponentBase {
	
	row!: Row;
	instructions!: NutritionInstructions;
	@Input() startLabel!: string;
    @Input() endLabel!: string;
	@Input('row') set updateRow(row: Row) {
		this.row = row;
		this.instructions = row.instructions as NutritionInstructions;
	}
	
	constructor() {
	}
}