@if(viewModel) {
    <app-item class="buttons">
        @if(viewModel.sections.length > 1) {
            <app-item-button (click)="expandCollapseSections(true)">Expand all</app-item-button>
            <app-item-button (click)="expandCollapseSections(false)">Collapse all</app-item-button>
        }
        @if(showEditButton) {
            <app-item-button (click)="editDocument()">Edit</app-item-button>
        }
    </app-item>
    <div class="scroll-vertical">
        @if(viewModel.sections.length === 1) {
			<ng-container *ngTemplateOutlet="taskSectionContent; context: { task: task }"></ng-container>
            <ng-container *ngTemplateOutlet="sectionContent; context: { section: viewModel.sections[0] }"></ng-container>
        }
        @else {
			@if (task.manifest.canBeUrgent || task.manifest.summarySettings.userCanEdit) {
				<app-expander class="validatable-section" [formGroup]="taskPropertiesForm">
					<app-item-label class="validation-section-label">Task properties</app-item-label>
					<ng-container *ngTemplateOutlet="taskSectionContent; context: { task: task }"></ng-container>
				</app-expander>
			}
            @for(section of viewModel.sections; track section.id; let index = $index) {
                <app-expander class="validatable-section" [formGroup]="viewModel.sectionGroups[section.id]">
                    <app-item-label class="validation-section-label">{{section.metaData.title}}</app-item-label>
                    <ng-container *ngTemplateOutlet="sectionContent; context: { section: section }"></ng-container>
                </app-expander>
            }
        }
    </div>
}
<ng-template #taskSectionContent let-task="task">
	<div class="form-section">
		@if (task.manifest.summarySettings.userCanEdit) {
			<div class="form-group" [formGroup]="summaryForm!">
				<app-textarea class="validatable-control" [readOnly]="isReadOnly" [label]="task.manifest.summarySettings.displayLabel" [placeholder]="task.manifest.summarySettings.displayPlaceholder" debounce formControlName="summary">
				</app-textarea>
			</div>
		}
		@if (task.manifest.canBeUrgent) {
			<div class="form-group validatable-group" [formGroup]="urgentForm!">
				<app-checkbox [readOnly]="isReadOnly" label="URGENT REQUEST" formControlName="urgent">
				</app-checkbox>
				<app-textarea class="validatable-group-control" [readOnly]="isReadOnly" label="Give reason for urgency" placeholder="reason..." debounce formControlName="urgentReason">
				</app-textarea>
			</div>
		}
	</div>
</ng-template>
<ng-template #sectionContent let-section="section">
    <div class="form-section">
        @for(group of section.groups; track group.id) {
			@if (viewModel.widgetGroups[group.id].enabled) {
				<div class="form-group" [formGroup]="viewModel.widgetGroups[group.id]" @enterLeave [@.disabled]="isReadOnly">
					@for (widget of group.widgets; track widget.id) {
						@switch (widget.widgetType) {
							@case ('heading') {
								<app-heading background="secondary-2" size="small">{{ widget.metaData.label }}</app-heading>
							}
							@case ('text') {
								<p class="form-control-label">{{ widget.metaData.label }}</p>
							}
							@case ('text-input') {
								<app-text-input class="validatable-control" [readOnly]="isReadOnly" [label]="widget.metaData.label" [placeholder]="widget.metaData.placeholder"
												[formControl]="getWidgetControl(widget.id)">
								</app-text-input>
							}
							@case ('text-area') {
								<app-textarea class="validatable-control" [readOnly]="isReadOnly" [label]="widget.metaData.label" [placeholder]="widget.metaData.placeholder" debounce
											  [formControl]="getWidgetControl(widget.id)">
								</app-textarea>
							}
							@case ('checkbox') {
								<app-checkbox class="validatable-control" [readOnly]="isReadOnly" [label]="widget.metaData.label" [formControl]="getWidgetControl(widget.id)">
								</app-checkbox>
							}
							@case ('datetime') {
								<app-datetime class="validatable-control" [readOnly]="isReadOnly" [label]="widget.metaData.label" [placeholder]="widget.metaData.displayFormat"
											  [formControl]="getWidgetControl(widget.id)">
								</app-datetime>
							}
							@case ('select') {
								<app-select class="validatable-control" [readOnly]="isReadOnly" [label]="widget.metaData.label" [placeholder]="widget.metaData.placeholder"
											[items]="widget.metaData.items" [formControl]="getWidgetControl(widget.id)">
								</app-select>
							}
							@case ('select-with-info') {
								<app-select-with-info class="validatable-group" [readOnly]="isReadOnly" [label]="widget.metaData.label" selectLabel="Response"
													  [selectPlaceholder]="widget.metaData.placeholder" [selectItems]="widget.metaData.items" infoLabel="Detail"
													  [infoPlaceholder]="widget.metaData.infoPlaceholder"[infoIdentifiers]="widget.metaData.infoIdentifiers"
													  [formControl]="getWidgetControl(widget.id)">
								</app-select-with-info>
							}
							@case ('user-picker') {
								<app-user-picker class="validatable-control" [readOnly]="isReadOnly" [label]="widget.metaData.label" [placeholder]="widget.metaData.placeholder"
												 [formControl]="getWidgetControl(widget.id)">
								</app-user-picker>
							}
							@case ('user-signature') {
								<app-user-signature class="validatable-control" [readOnly]="isReadOnly" [label]="widget.metaData.label" [alreadySignedMessage]="widget.metaData.alreadySignedMessage"
													[requireClinician]="true" [notClinicianMessage]="widget.metaData.invalidUserRoleMessage" [formControl]="getWidgetControl(widget.id)">
								</app-user-signature>
							}
							@case ('multi-text-input') {
								<app-multi-text-input class="validatable-group" [readOnly]="isReadOnly" [label]="widget.metaData.label" [placeholder]="widget.metaData.placeholder"
													  [minItems]="widget.metaData.validators?.required ? 1 : 0" [formControl]="getWidgetControl(widget.id)">
								</app-multi-text-input>
							}
							@case ('multi-checkbox') {
								<app-multi-checkbox class="validatable-group" [readOnly]="isReadOnly" [label]="widget.metaData.label" [subLabel]="widget.metaData.subLabel"
													[items]="widget.metaData.items" [minItems]="widget.metaData.validators?.required ? 1 : 0" [formControl]="getWidgetControl(widget.id)">
								</app-multi-checkbox>
							}
							@case ('multi-item-picker') {
								<app-multi-item-picker class="validatable-group" [readOnly]="isReadOnly" [label]="widget.metaData.label" [items]="widget.metaData.items"
													   [minItems]="widget.metaData.validators?.required ? 1 : 0" [formControl]="getWidgetControl(widget.id)">
								</app-multi-item-picker>
							}
							@case ('listy-picker') {
								<app-modality-body-parts-picker class="validatable-group" [readOnly]="isReadOnly" [formControl]="getWidgetControl(widget.id)">
								</app-modality-body-parts-picker>
							}
							@case ('group-repeater') {
								<app-group-repeater [readOnly]="isReadOnly" [metaData]="widget.metaData" [formControl]="getWidgetControl(widget.id)"></app-group-repeater>
							}
							@case ('radio-group') {
								<app-select class="validatable-control" [readOnly]="isReadOnly" [label]="widget.metaData.label" [placeholder]="widget.metaData.placeholder"
											[items]="widgetMetadataMapperService.mapRadioGroupOptionsToSelectItems(widget.metaData.options)" [formControl]="getWidgetControl(widget.id)">
								</app-select>
							}
							@default {
								<div style="border: 1px solid red"><strong>{{ widget.widgetType }}</strong> not implemented<br/>{{ widget | json }} ({{widget.id}})</div>
							}
						}
					}
				</div>
			}
        }
    </div>
</ng-template>