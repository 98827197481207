import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IonRippleEffect } from '@ionic/angular/standalone';

@Component({
	selector: 'app-item-button',
	templateUrl: './item-button.component.html',
	styleUrl: './item-button.component.scss',
	standalone: true,
	imports: [IonRippleEffect],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemButtonComponent {
	constructor() {
	}
}