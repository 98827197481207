import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { ClientsService } from 'src/app/services/clients.service';
import { ErrorService } from 'src/app/services/error.service';
import { ClientTypeName } from 'src/app/types/aggregate-graph.types';
import { CommsPanelChildType } from 'src/app/types/patient.types';
import { clientErrored } from '../error/error.actions';
import * as patientGraphActions from '../patient-graph/patient-graph.actions';
import * as patientActions from '../patient/patient.actions';
import { patientSelected, selectedCommsPanelChildType } from '../patient/patient.selectors';
import { isMobile } from '../platform/platform.selectors';
import { AppState } from '../reducers';
import * as patientCommsActions from './patient-comms.actions';

@Injectable()
export class PatientCommsEffects {

	constructor(private store: Store<AppState>, private actions$: Actions, private clientsService: ClientsService, private errorService: ErrorService) {
	}

	isMobileChanged$ = createEffect(() =>
		this.store.select(isMobile).pipe(
			concatLatestFrom(() => [this.store.select(patientSelected), this.store.select(selectedCommsPanelChildType)]),
			filter(([isMobile, patientSelected, childType]) => patientSelected && (isMobile || childType === CommsPanelChildType.None)),
			map(([isMobile,]) => patientActions.commsPanelChildSelected({ childType: isMobile ? CommsPanelChildType.None : CommsPanelChildType.ReferringVet }))
		)
	);

	ownerContactNoteChanged$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientCommsActions.ownerContactNoteChanged),
			mergeMap(action => this.clientsService.setContactNote(action.clientId, action.contactNote).pipe(
				map(() => patientGraphActions.clientUpdated({ partialClient: { id: action.clientId, aggregateType: ClientTypeName, contactNote: action.contactNote } })),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Set owner contact note failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);
}
