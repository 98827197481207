<app-instructions-form [instructionTypeName]="instructionTypeName" [instructionsFormArray]="instructionsFormArray" [minDate]="minDate" [minDate]="minDate"
					   [preScheduleInstructionTemplate]="preScheduleInstruction" [postScheduleInstructionTemplate]="postScheduleInstruction" [scheduleConfig]="itemConfig.scheduleConfig" (addInstructionClicked)="addInstruction()">
	<!--only need to set the template references above if they are used in app-instructions-form-->
</app-instructions-form>
<ng-template #preScheduleInstruction let-group="instructionFormGroup">
	<div class="form-group" [formGroup]="group">
		<app-select label="Method" placeholder="choose..." [items]="methods" [saveValue]="true" formControlName="method"></app-select>
        <app-text-input label="Area" placeholder="Text..." formControlName="area" inputMode="text"></app-text-input>
	</div>
</ng-template>
<ng-template #postScheduleInstruction let-group="instructionFormGroup">
	<!--add any form controls here, typically inside "form-group" classed divs. Public properties/methods of this component are accessible to the template-->
</ng-template>