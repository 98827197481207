<app-action-form [item]="item" [slot]="slot" [form]="form">
	<div class="form-section app-margin-horizontal app-padding">
        <div class="form-group" [formGroup]="form">
            <app-select label="Size" placeholder="Choose..." [items]="size" [saveValue]="true" formControlName="size"></app-select>
            <app-unit-value label="Scanned bladder size" unitLabel="cm x cm" placeholder="Value" formControlName="scannedSize"></app-unit-value>
            <app-select label="Palpation" placeholder="Choose..." [items]="palpation" [saveValue]="true" formControlName="palpation"></app-select>
        </div>
        <app-common-action-controls [action]="action" [form]="form"></app-common-action-controls>
    </div>
</app-action-form>

