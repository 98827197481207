import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../store/reducers';
import { teams } from '../store/user-graph/user-graph.selectors';
import { Team } from '../types/aggregate-graph.types';

@Injectable({
	providedIn: 'root'
})
export class ServicesService {

	private services: Team[] = [];

	constructor(private store: Store<AppState>) {
		this.store.select(teams).subscribe(teams => this.services = teams);
	}

	getService(id: string): Team | undefined {
		return this.services.find(t => t.id === id);
	}
}