import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SelectControl } from 'src/app/form-controls/select/select.control';
import { UnitQuantity, UnitQuantityControl } from 'src/app/form-controls/unit-quantity/unit-quantity.control';
import { SheetItemService } from 'src/app/services/sheet-item.service';
import { SheetItemDetailConfig, SheetItemFormBase } from 'src/app/services/sheets-renderer.service';
import { Row } from 'src/app/types/aggregate-graph.types';
import { IvtfSupplementationInstruction } from 'src/app/types/sheet-instruction.types';
import { InstructionsFormComponent } from '../../instructions-form/instructions-form.component';

interface IvtfSupplementationFormGroup {
	dose: FormControl<UnitQuantity | null>;
}

@Component({
	selector: 'app-ivtf-supplementation-item-form',
	templateUrl: './ivtf-supplementation-item-form.component.html',
	styleUrl: './ivtf-supplementation-item-form.component.scss',
	standalone: true,
	imports: [ReactiveFormsModule, InstructionsFormComponent, UnitQuantityControl, SelectControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IvtfSupplementationItemFormComponent implements SheetItemFormBase, OnInit {

	@Input() instructionTypeName!: string;
	@Input() item!: Row | null;
	@Input() minDate!: string;
	@Input() maxDate!: string;
	@Input() instructionsFormArray!: FormArray;
  	@Input() itemConfig!: SheetItemDetailConfig;



	constructor(private sheetItemService: SheetItemService, private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.sheetItemService.getInstructions(this.item, this.instructionTypeName, this.itemConfig.scheduleConfig.defaultScheduleType, this.itemConfig.scheduleConfig.defaultRepeatingScheduleFrequency).forEach(instruction => {
			const group: FormGroup<IvtfSupplementationFormGroup> = this.sheetItemService.buildExistingInstructionGroup(this.instructionTypeName, instruction);
			const ivtfSupplementationInstruction = instruction as IvtfSupplementationInstruction;
			group.addControl('dose', this.formBuilder.control(ivtfSupplementationInstruction.dose ?? null));
			this.instructionsFormArray.push(group);
		});
	}

	addInstruction() {
		const group: FormGroup<IvtfSupplementationFormGroup> = this.sheetItemService.getNewInstructionGroup(this.instructionTypeName, this.itemConfig.scheduleConfig.defaultScheduleType, this.itemConfig.scheduleConfig.defaultRepeatingScheduleFrequency);
		group.addControl('dose', this.formBuilder.control(null));
		this.instructionsFormArray.push(group);
	}
}