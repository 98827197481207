<app-action-form [item]="item" [slot]="slot" [form]="form">
	<div class="form-section app-margin-horizontal app-padding">
        <div class="form-group" [formGroup]="form">
            <app-unit-quantity label="Rate" unitLabel="ml/hr" placeholder="Value" inputMode="decimal" formControlName="rate"></app-unit-quantity>
        </div>
        <app-common-action-controls [action]="action" [form]="form"></app-common-action-controls>
    </div>
	<ng-container taskControls [formGroup]="form">
		<app-user-signature formControlName="approvalOne" label="Approval 1"></app-user-signature>
		<app-user-signature formControlName="approvalTwo" label="Approval 2"></app-user-signature>
	</ng-container>
</app-action-form>