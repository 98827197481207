<ion-header>
	<ion-toolbar>
		<ion-title>Draft Task</ion-title>
		<ion-buttons slot="end">
			<ion-button (click)="close()" color="primary">
				<ion-icon slot="icon-only" name="close-sharp"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
<ng-container *ngrxLet="{ task: task$, taskDocument: taskDocument$, profileInfo: profileInfo$ } as vm; suspenseTpl: loading">
	@if(vm.task && vm.taskDocument){
		<div class="half-margin-top container">
			<div class="fixed app-padding-horizontal">
				<app-heading background="primary-2">{{ vm.taskDocument.manifest.name }}</app-heading>
			</div>
			<div class="scroll-vertical">
				<app-task-document [task]="vm.task" [taskDocument]="vm.taskDocument" [showEditButton]="false" [isReadOnly]="false"></app-task-document>
			</div>
			<div class="modal-buttons button-container">
				<app-button fill="solid" (click)="shareTaskClicked(vm.profileInfo, vm.task)">Share task</app-button>
			</div>
		</div>
	}
	@else{
		<app-spinner class="margin-top"></app-spinner>
	}
</ng-container>
<ng-template #loading>
	<app-spinner class="margin-top"></app-spinner>
</ng-template>