import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ModalController } from '@ionic/angular/standalone';
import { ItemLabelComponent } from 'src/app/components/item-label/item-label.component';
import { ItemValueComponent } from 'src/app/components/item-value/item-value.component';
import { ItemComponent } from 'src/app/components/item/item.component';
import { UserSearchModal } from 'src/app/modals/user-search/user-search.modal';
import { SearchedUser } from 'src/app/services/users.service';
import { VisitParticipationType } from 'src/app/types/aggregate-graph.types';

type UserPickerValue = SearchedUser | null;

@Component({
	selector: 'app-user-picker',
	templateUrl: './user-picker.control.html',
	styleUrl: './user-picker.control.scss',
	standalone: true,
	imports: [ReactiveFormsModule, ItemComponent, ItemLabelComponent, ItemValueComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: UserPickerControl,
		multi: true
	}]
})
export class UserPickerControl implements ControlValueAccessor {

	@Input() readOnly: boolean = false;
	@Input() label: string = '';
	@Input() placeholder: string = '';
	@Input() participationTypeFilters: VisitParticipationType[] = [];

	value: UserPickerValue = null;

	get placeholderValue() { return this.readOnly ? '' : this.placeholder }

	constructor(private changeDetectorRef: ChangeDetectorRef, private modalController: ModalController) {
	}

	writeValue(value: UserPickerValue): void {
		this.value = value;

		// this is required for the view to update correctly (it's a general approach recommended by Angular devs using ChangeDetectionStrategy.OnPush: https://github.com/angular/angular/issues/21780)
		this.changeDetectorRef.markForCheck();
	}

	private onChange: (value: UserPickerValue) => void = () => { };

	registerOnChange(fn: (value: UserPickerValue) => void): void {
		this.onChange = fn;
	}

	private onTouched: () => void = () => { };

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(disabled: boolean): void {
	}

	async controlClicked(event: Event) {
		if (this.readOnly) return;

		this.onTouched();

		const popover = await this.modalController.create({
			component: UserSearchModal,
			cssClass: 'stacked-modal',
			componentProps: {
				participationTypeFilters: this.participationTypeFilters
			},
			backdropDismiss: false
		});

		await popover.present();

		const { data } = await popover.onWillDismiss();

		if (!data) return;

		const user: SearchedUser = data.user;

		if (user.id === this.value?.id && user.fullname === this.value?.fullname) return; // name can get changed for a user

		this.value = user;
		this.changeDetectorRef.markForCheck(); // ensure the view shows the new value (needed due to OnPush change detection strategy)
		this.onChange(this.value);
	}
}