import { createReducer, on } from '@ngrx/store';
import * as aggregateGraphHubActions from './aggregate-graph-hub.actions';

export interface State {
	activated: boolean
}

export const initialState: State = {
	activated: false
}

export const aggregateGraphHubReducer = createReducer(
	initialState,
	on(aggregateGraphHubActions.hubActivated, (state) => ({
		...state,
		activated: true
	})),
	on(aggregateGraphHubActions.hubDeactivated, (state) => ({
		...state,
		activated: false
	}))
);