<app-item-content [instructionItemContentTemplate]="itemContentCustomInstructionBoxes" [row]="row" [startLabel]="startLabel" [endLabel]="endLabel" [showFrequency]="false"></app-item-content>
<ng-template #itemContentCustomInstructionBoxes let-instruction="instruction" let-overlaps="scheduledAndOverlapsSelectedDate">
	@if (overlaps) {
		@if (instruction.rate) {
			<app-instruction-box>{{ instruction.rate.value }} {{ instruction.rate.unit }}</app-instruction-box>		
		}
		@if (instruction.medicationRoute !== null) {
			<app-instruction-box>{{ instruction.medicationRoute | medicationRouteName }}</app-instruction-box>
		}
	}
</ng-template>