import { createAction, props } from '@ngrx/store';
import { Action, Instruction, ItemProperties, Row } from 'src/app/types/aggregate-graph.types';
import { SheetActionNotes, SheetGranularity, SheetViewSelector, SheetViewStatus } from 'src/app/types/sheet.types';

export enum SheetActionTypes {
	SheetLoadInitiated = '[Effect] Sheet Load Initiated',
	SelectedVisitChanged = '[Effect] Selected Visit Changed',
	LoadSheetItemEditorRequested = '[Service] Load Sheet Item Editor Requested',
	LoadSheetItemEditorCompleted = '[Effect] Load Sheet Item Editor Completed',
	AddSheetItemInstructionsRequested = '[Modal] Add Sheet Item Instructions Requested',
	AddSheetItemInstructionsCompleted = '[Effect] Add Sheet Item Instructions Completed',
	UpdateSheetItemInstructionsRequested = '[Modal] Update Sheet Item Instructions Requested',
	UpdateSheetItemInstructionsCompleted = '[Effect] Update Sheet Item Instructions Completed',
	LoadSheetSlotEditorRequested = '[Service] Load Sheet Slot Editor Requested',
	LoadSheetSlotEditorCompleted = '[Effect] Load Sheet Slot Editor Completed',
	SaveSheetSlotActionRequested = '[Modal] Save Sheet Slot Action Requested',
	AddSheetSlotActionCompleted = '[Effect] Add Sheet Slot Action Completed',
	UpdateSheetSlotActionCompleted = '[Effect] Update Sheet Slot Action Completed',
	SelectDayRequested = '[Component] Select Day Requested',
	SelectDayCompleted = '[Effect] Select Day Completed',
	UpdateScheduledUntilDateRequested = '[Effect] Update Scheduled Until Date Requested',
	UpdateScheduledUntilDateCompleted = '[Effect] Update Scheduled Until Date Completed',
	ChangeViewStatusRequested = '[Component] Change View Status Requested',
	ChangeViewStatusCompleted = '[Component] Change View Status Completed',
	LoadSheetActionNotesRequested = '[Effect] Load Sheet Action Notes Requested',
	LoadSheetActionNotesCompleted = '[Effect] Load Sheet Action Notes Completed',
	SheetActionNotesCleared = '[Effect] Sheet Action Notes Cleared',
	SetItemPropertiesRequested = '[Effect] Set Item Properties Requested',
	SetItemPropertiesCompleted = '[Effect] Set Item Properties Completed',
	ChangeGranularityRequested = '[Component] Change Granularity Requested',
	ChangeGranularityCompleted = '[Effect] Change Granularity Completed',
	ExportSheetRequested = '[Sheet Graph Effect] Export Sheet Requested',
	ExportSheetCompleted = '[Sheet Graph Effect] Export Sheet Completed',
	RemoveSheetItemRequested = '[Component] Remove Sheet Item Requested',
	RemoveSheetItemCompleted = '[Effect] Remove Sheet Item Completed',
	PresentItemSelectorRequested = '[Component] Present Item Selector Requested',
	PresentItemSearchModalAllowed = '[Component] Present Item Search Modal Allowed',
	FinalisedSheetEditAttempted = '[Effect] Finalised Sheet Edit Attempted',
	SheetCleared = '[Effect] Sheet Cleared'
}

export const sheetLoadInitiated = createAction(
	SheetActionTypes.SheetLoadInitiated,
	props<{ visitId: string, sheetId: string | null }>()
)

export const selectedVisitChanged = createAction(
	SheetActionTypes.SelectedVisitChanged,
	props<{ visitId: string, sheetId: string | null }>()
)

export const exportSheetRequested = createAction(
	SheetActionTypes.ExportSheetRequested
)

export const finalisedSheetEditAttempted = createAction(
	SheetActionTypes.FinalisedSheetEditAttempted
)

export const exportSheetCompleted = createAction(
	SheetActionTypes.ExportSheetCompleted,
)

export const presentItemSearchModalRequested = createAction(
	SheetActionTypes.PresentItemSelectorRequested
)

export const presentItemSearchModalAllowed = createAction(
	SheetActionTypes.PresentItemSearchModalAllowed
)

export const loadSheetItemEditorRequested = createAction(
	SheetActionTypes.LoadSheetItemEditorRequested,
	props<{ item: Row }>()
)

export const loadSheetItemEditorCompleted = createAction(
	SheetActionTypes.LoadSheetItemEditorCompleted
)

export const addSheetItemInstructionsRequested = createAction(
	SheetActionTypes.AddSheetItemInstructionsRequested,
	props<{ instructions: Instruction[], itemProperties: ItemProperties }>()
)

export const addSheetItemInstructionsCompleted = createAction(
	SheetActionTypes.AddSheetItemInstructionsCompleted
)

export const setItemPropertiesRequested = createAction(
	SheetActionTypes.SetItemPropertiesRequested,
	props<{ sheetId: string, itemId: number, itemProperties: ItemProperties }>()
)

export const setItemPropertiesCompleted = createAction(
	SheetActionTypes.SetItemPropertiesCompleted
)

export const updateSheetItemInstructionsRequested = createAction(
	SheetActionTypes.UpdateSheetItemInstructionsRequested,
	props<{ itemId: number, instructions: Instruction[], itemProperties: ItemProperties }>()
)

export const updateSheetItemInstructionsCompleted = createAction(
	SheetActionTypes.UpdateSheetItemInstructionsCompleted
)

export const loadSheetSlotEditorRequested = createAction(
	SheetActionTypes.LoadSheetSlotEditorRequested,
	props<{ itemId: number, slotIndex: number }>()
)

export const loadSheetSlotEditorCompleted = createAction(
	SheetActionTypes.LoadSheetSlotEditorCompleted
)

export const saveSheetSlotActionRequested = createAction(
	SheetActionTypes.SaveSheetSlotActionRequested,
	props<{ rowId: number, action: Action, isAddition: boolean }>()
)

export const addSheetSlotActionCompleted = createAction(
	SheetActionTypes.AddSheetSlotActionCompleted,
	props<{ actionId: number }>()
)

export const updateSheetSlotActionCompleted = createAction(
	SheetActionTypes.UpdateSheetSlotActionCompleted
)

export const selectDayRequested = createAction(
	SheetActionTypes.SelectDayRequested,
	props<{ date: string }>()
)

export const selectDayCompleted = createAction(
	SheetActionTypes.SelectDayCompleted,
	props<{ date: string }>()
)

export const updateScheduledUntilDateRequested = createAction(
	SheetActionTypes.UpdateScheduledUntilDateRequested,
	props<{ sheetId: string, requestedDate: string }>()
)

export const updateScheduledUntilDateCompleted = createAction(
	SheetActionTypes.UpdateScheduledUntilDateCompleted,
	props<{ sheetEndDate: string }>()
)

export const loadSheetActionNotesRequested = createAction(
	SheetActionTypes.LoadSheetActionNotesRequested,
	props<{ sheetId: string }>()
)

export const loadSheetActionNotesCompleted = createAction(
	SheetActionTypes.LoadSheetActionNotesCompleted,
	props<{ actionNotes: SheetActionNotes[] }>()
)

export const changeViewStatusRequested = createAction(
	SheetActionTypes.ChangeViewStatusRequested,
	props<{ viewSelector: SheetViewSelector }>()
)

export const changeViewStatusCompleted = createAction(
	SheetActionTypes.ChangeViewStatusCompleted,
	props<{ viewStatus: SheetViewStatus }>()
)

export const changeGranularityRequested = createAction(
	SheetActionTypes.ChangeGranularityRequested,
	props<{ granularity: SheetGranularity, slotNumber: number }>()
)

export const changeGranularityCompleted = createAction(
	SheetActionTypes.ChangeGranularityCompleted,
	props<{ granularity: SheetGranularity }>()
)

export const sheetActionNotesCleared = createAction(
	SheetActionTypes.SheetActionNotesCleared
)

export const removeSheetItemRequested = createAction(
	SheetActionTypes.RemoveSheetItemRequested,
	props<{ sheetId: string, itemId: number }>()
)

export const removeSheetItemCompleted = createAction(
	SheetActionTypes.RemoveSheetItemCompleted
)

export const sheetCleared = createAction(
	SheetActionTypes.SheetCleared
)