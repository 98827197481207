<ion-header>
	<ion-toolbar>
		<ion-title>Add Sheet Item</ion-title>
		<ion-buttons slot="end">
			<ion-button (click)="close()" color="primary">
				<ion-icon slot="icon-only" name="close-sharp"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
<ion-searchbar class="app-search" clear-icon="arrow-back-sharp" placeholder="Search items" [debounce]="400" (ionInput)="queryTermChanged($event)"></ion-searchbar>
<div class="scroll-vertical app-padding-horizontal ion-content-scroll-host" *ngrxLet="{ searchResults: searchResults$, queryTerm: queryTerm$, newSearchInProgress: newSearchInProgress$, morePagesExist: morePagesExist$ } as vm">
	@if (vm.queryTerm) {
		@if (vm.newSearchInProgress) {
			<app-spinner></app-spinner>
		}
		@else {
			@for (item of vm.searchResults; track item.instructionTypeName; let isLast = $last) {
				<app-item appNavigable (click)="itemSelected(item)" [underlined]="!isLast">
					{{item.label}}
				</app-item>
			}
			@empty {
				<app-informative>No results found</app-informative>
			}
		}
	}
	@else {
		<app-informative>Start typing to see search results</app-informative>
	}
	<ion-infinite-scroll (ionInfinite)="appendResults()" [disabled]="!vm.morePagesExist">
		<ion-infinite-scroll-content>
		</ion-infinite-scroll-content>
	</ion-infinite-scroll>
</div>