import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { ErrorService } from 'src/app/services/error.service';
import { PatientsService } from 'src/app/services/patients.service';
import { VisitsService } from 'src/app/services/visits.service';
import { VisitParticipant } from 'src/app/types/aggregate-graph.types';
import { clientErrored, httpRequestErrored } from '../error/error.actions';
import * as patientGraphActions from '../patient-graph/patient-graph.actions';
import { visitsByVisitNumberDesc } from '../patient-graph/patient-graph.selectors';
import { AppState } from '../reducers';
import * as patientVisitActions from './patient-visit.actions';

@Injectable()
export class PatientVisitEffects {

	constructor(
		private actions$: Actions, private visitsService: VisitsService, private patientService: PatientsService,
		private store: Store<AppState>, private errorService: ErrorService, private alertController: AlertController) {
	}

	changePatientStatusRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientVisitActions.changePatientStatusRequested),
			mergeMap(action => this.visitsService.setPatientStatus(action.visitId, action.status).pipe(
				map(() => patientGraphActions.visitUpdated({ partialVisit: { id: action.visitId, patientStatus: action.status } })),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Change patient status failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	changeCurrentTeamRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientVisitActions.changeCurrentTeamRequested),
			mergeMap(action => this.visitsService.setCurrentTeam(action.visitId, action.teamId).pipe(
				map(() => patientGraphActions.visitUpdated({ partialVisit: { id: action.visitId, currentTeamId: action.teamId } })),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Change service failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	addVisitParticipantRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientVisitActions.addParticipantRequested),
			mergeMap(action => this.visitsService.addVisitParticipant(action.visitId, action.participant.userId, action.participant.participationType).pipe(
				concatLatestFrom(() => this.store.select(visitsByVisitNumberDesc)),
				map(([, visits]) => patientGraphActions.visitUpdated({
					partialVisit: {
						id: action.visitId,
						participants: [...visits.find(v => v.id === action.visitId)!.participants, action.participant].sort(sortVisitParticipantsByTypeDescThenNameAsc)
					}
				})),
				catchError((error: HttpErrorResponse) => {
					if (error.status === 409) {
						return of(httpRequestErrored({ statusCode: error.status, requestUrl: error.url ?? 'Url missing!!!' })).pipe(
							tap(async () => (await this.alertController.create({
								subHeader: 'Forbidden',
								message: 'This user cannot be added as they are not in the correct role',
								buttons: ['Ok'],
								backdropDismiss: false
							})).present())
						);
					}
					return of(clientErrored({ toastMessage: 'Add participant failed', errorMessage: this.errorService.getErrorMessage(error) }));
				})
			))
		)
	);

	removeVisitParticipantRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientVisitActions.removeParticipantRequested),
			mergeMap(action => this.visitsService.removeVisitParticipant(action.visitId, action.userId).pipe(
				concatLatestFrom(() => this.store.select(visitsByVisitNumberDesc)),
				map(([, visits]) => patientGraphActions.visitUpdated({
					partialVisit: {
						id: action.visitId,
						participants: visits.find(v => v.id === action.visitId)!.participants.filter(p => p.userId !== action.userId)
					}
				})),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Remove participant failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	changeWardRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientVisitActions.changeWardRequested),
			mergeMap(action => this.visitsService.setWard(action.visitId, action.ward).pipe(
				map(() => patientGraphActions.visitUpdated({ partialVisit: { id: action.visitId, ward: action.ward } })),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Change ward failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	changeKennelRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientVisitActions.changeKennelRequested),
			mergeMap(action => this.visitsService.setKennel(action.visitId, action.kennel).pipe(
				map(() => patientGraphActions.visitUpdated({ partialVisit: { id: action.visitId, kennel: action.kennel } })),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Change kennel failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	changeDischargeDateRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientVisitActions.changeDischargeDateRequested),
			mergeMap(action => this.visitsService.setDischargeDate(action.visitId, action.dischargeDate).pipe(
				map(() => patientGraphActions.visitUpdated({ partialVisit: { id: action.visitId, dischargeDate: action.dischargeDate } })),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Change discharge date failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	changeResuscitationStatusRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientVisitActions.changeResuscitationStatusRequested),
			mergeMap(action => this.visitsService.setResuscitationStatus(action.visitId, action.status).pipe(
				map(() => patientGraphActions.visitUpdated({ partialVisit: { id: action.visitId, resuscitationStatus: action.status } })),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Change resuscitation status failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);

	changeBloodTypeRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(patientVisitActions.changeBloodTypeRequested),
			mergeMap(action => this.patientService.setBloodType(action.patientId, action.bloodType).pipe(
				map(() => patientGraphActions.patientUpdated({ partialPatient: { id: action.patientId, bloodType: action.bloodType } })),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Change blood type failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);
}

const participantTypeToSortMapping: { [participationType: number]: number } = {
	3: 1,
	2: 2,
	7: 3,
	4: 4
};

const sortVisitParticipantsByTypeDescThenNameAsc = (a: VisitParticipant, b: VisitParticipant) => participantTypeToSortMapping[a.participationType] - participantTypeToSortMapping[b.participationType] || a.fullName.localeCompare(b.fullName);