import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SelectControl, SelectItem } from 'src/app/form-controls/select/select.control';
import { TextInputControl } from 'src/app/form-controls/text-input/text-input.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { ColdCompressAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { Method } from 'src/app/types/sheet-instruction.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface ColdCompressActionFormGroup {
	area: FormControl<string | null>;
	method: FormControl<Method | null>;
	timeKeptOn: FormControl<string | null>;
}

@Component({
	selector: 'app-cold-compress-action-controls',
	templateUrl: './cold-compress-action-controls.component.html',
	styleUrls: ['../../../common.scss', './cold-compress-action-controls.component.scss'],
	standalone: true,
	imports: [ReactiveFormsModule, SelectControl, TextInputControl, CommonActionControlsComponent, ActionFormComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColdCompressActionControlsComponent implements SheetActionControlsBase, OnInit {

	slot!: Slot | null;
	action!: ColdCompressAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as ColdCompressAction;
	}

	@Input() form!: FormGroup<ColdCompressActionFormGroup>;
	@Input() item!: Row | null;

	methodItems: SelectItem[] = [
		{ text: 'Cryo Cuff', value: Method.CryoCuff },
		{ text: 'Pack', value: Method.Pack },
	];

	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.form.addControl('area', this.formBuilder.control(this.action?.area ?? null));
		this.form.addControl('method', this.formBuilder.control(this.action?.method ?? null));
		this.form.addControl('timeKeptOn', this.formBuilder.control(this.action?.timeKeptOn ?? null));
	}
}