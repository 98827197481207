import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SheetSlotComponentBase } from 'src/app/services/sheets-renderer.service';
import { PainScoreExoticAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { TickValueComponent } from '../../tick-value/tick-value.component';

@Component({
	selector: 'app-pain-score-exotic-slot',
	templateUrl: './pain-score-exotic-slot.component.html',
	styleUrl: './pain-score-exotic-slot.component.scss',
	standalone: true,
	imports: [TickValueComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PainScoreExoticSlotComponent implements SheetSlotComponentBase {

	action!: PainScoreExoticAction;
	value!: string | null;

	@Input('action') set updateAction(action: PainScoreExoticAction | null) {
		this.action = action as PainScoreExoticAction;
		this.value = this.action.score?.toString() ?? null;
	}

	constructor() {
	}
}