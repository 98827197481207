import { ModalController } from '@ionic/angular/standalone';
import { Observable, OperatorFunction, UnaryFunction, pipe } from 'rxjs';
import { buffer, debounceTime, filter, tap } from 'rxjs/operators';

export function closeAllModals<T>(modalController: ModalController) {
	return function (source: Observable<T>) {
		return source.pipe(
			tap(async (source) => {
				let modal = await modalController.getTop();
				while (modal) {
					await modal.dismiss();
					modal = await modalController.getTop();
				}
			})
		)
	}
};

export function filterUndefinedOrNull<T>(): UnaryFunction<Observable<T | undefined | null>, Observable<T>> {
	return pipe(
		// this works for both undefined and null due to the nullish != operator. Do not change to !==
		filter(x => x != null) as OperatorFunction<T | undefined | null, T>
	);
}

export function bufferUntilQuiet<T>(source: Observable<T>, quietPeriod: number): OperatorFunction<T, T[]> {
	return buffer(source.pipe(debounceTime(quietPeriod)));
}