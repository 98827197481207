<app-instructions-form [instructionTypeName]="instructionTypeName" [instructionsFormArray]="instructionsFormArray" [minDate]="minDate" [minDate]="minDate"
					   [preScheduleInstructionTemplate]="preScheduleInstruction" [postScheduleInstructionTemplate]="postScheduleInstruction" [scheduleConfig]="itemConfig.scheduleConfig" (addInstructionClicked)="addInstruction()">
	<!--only need to set the template references above if they are used in app-instructions-form-->
</app-instructions-form>
<ng-template #preScheduleInstruction let-group="instructionFormGroup">
	<div class="form-group" [formGroup]="group">
		<app-text-input label="% of RER per 24h" placeholder="Value" inputMode="decimal" formControlName="rerPercentage"></app-text-input>
		<app-select label="Diet preparation" placeholder="Choose" [items]="dietPreparations" [saveValue]="true" formControlName="dietPreparation"></app-select>
		<app-text-input label="Diet" placeholder="E.g. GI low fat, Hills DD, Purina HA etc" formControlName="diet"></app-text-input>
		<app-select label="Method" placeholder="Choose" [items]="nutritionMethods" [saveValue]="true" formControlName="nutritionMethod"></app-select>
		<app-select label="Tube" placeholder="Choose" [items]="nutritionTubes" [saveValue]="true" formControlName="nutritionTube"></app-select>
	</div>
</ng-template>
<ng-template #postScheduleInstruction let-group="instructionFormGroup">
	<!--add any form controls here, typically inside "form-group" classed divs. Public properties/methods of this component are accessible to the template-->
</ng-template>