import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TextAreaControl } from 'src/app/form-controls/textarea/textarea.control';
import { Action } from 'src/app/types/aggregate-graph.types';

@Component({
	selector: 'app-common-action-controls',
	templateUrl: './common-action-controls.component.html',
	styleUrl: './common-action-controls.component.scss',
	standalone: true,
	imports: [ReactiveFormsModule, TextAreaControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonActionControlsComponent {

	@Input() action!: Action;
	@Input() form!: FormGroup;
	@Input() showNotes: boolean = true;

	notesControl!: FormControl;

	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.notesControl = this.formBuilder.control(this.action?.notes ?? null);
		this.form.addControl('notes', this.notesControl);
	}
}