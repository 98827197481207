import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers';

export const selectSheetId = (state: AppState) => state.sheetGraph.sheetId;
export const selectSheet = (state: AppState) => state.sheetGraph.sheet;

export const sheetId = createSelector(
	selectSheetId,
	(sheetId) => sheetId
);

export const sheet = createSelector(
	selectSheet,
	(sheet) => sheet
);