import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SelectControl, SelectItem } from 'src/app/form-controls/select/select.control';
import { TextInputControl } from 'src/app/form-controls/text-input/text-input.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { MucousMembranesAndCrtAction, MucousMembranesColor } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface MucousMembranesAndCrtActionFormGroup {
	color: FormControl<MucousMembranesColor | null>;
	crt: FormControl<number | null>;
}
@Component({
	selector: 'app-mucous-membranes-and-crt-action-controls',
	templateUrl: './mucous-membranes-and-crt-action-controls.component.html',
	styleUrls: ['../../../common.scss', './mucous-membranes-and-crt-action-controls.component.scss'],
	standalone: true,
	imports: [CommonActionControlsComponent, ActionFormComponent, ReactiveFormsModule, TextInputControl, SelectControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MucousMembranesAndCrtActionControlsComponent implements SheetActionControlsBase, OnInit {

	slot!: Slot | null;
	action!: MucousMembranesAndCrtAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as MucousMembranesAndCrtAction;
	}

	@Input() form!: FormGroup<MucousMembranesAndCrtActionFormGroup>;
	@Input() item!: Row | null;
	
    mucousMembranesColor: SelectItem[] = [
		{ text: 'Congested (Red)', value: MucousMembranesColor.Congested },
		{ text: 'Cyanotic (Blue)', value: MucousMembranesColor.Cyanotic },
		{ text: 'Icteric (Yellow)', value: MucousMembranesColor.Icteric },
		{ text: 'Normal', value: MucousMembranesColor.Normal },
		{ text: 'Pale', value: MucousMembranesColor.Pale },
		{ text: 'Pigmented', value: MucousMembranesColor.Pigmented },
	];

	constructor(private formBuilder: FormBuilder) {
	}

    ngOnInit(): void {
        this.form.addControl('color', this.formBuilder.control(this.action?.color ?? null));
        this.form.addControl('crt', this.formBuilder.control(this.action?.crt ?? null));
    }
}