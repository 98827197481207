import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { LetDirective } from '@ngrx/component';
import { enterReplace } from 'src/app/animations/common.animations';
import { DateTimeControl } from 'src/app/form-controls/datetime/datetime.control';
import { SelectControl, SelectItem, SelectValue } from 'src/app/form-controls/select/select.control';
import { Unit } from 'src/app/form-controls/shared/popovers/unit-popover/unit.popover';
import { UnitQuantity, UnitQuantityControl } from 'src/app/form-controls/unit-quantity/unit-quantity.control';
import { timeQuantityFactor } from 'src/app/form-validators/time-quantity-factor.validator';
import { unitQuantityRequired } from 'src/app/form-validators/unit-quantity-required.validator';
import { SheetItemService } from 'src/app/services/sheet-item.service';
import { SheetItemScheduleConfig } from 'src/app/services/sheets-renderer.service';
import { TimeSpanConversionService, TimeUnit } from 'src/app/services/timespan-conversion.service';
import { OneOffSchedule, RepeatingSchedule, Schedule, ScheduleType } from 'src/app/types/aggregate-graph.types';
import { fiveMinuteIntervalMinuteValues } from 'src/app/types/sheet.types';

@Component({
	selector: 'app-schedule-controls',
	templateUrl: './schedule-controls.component.html',
	styleUrl: './schedule-controls.component.scss',
	standalone: true,
	imports: [ReactiveFormsModule, LetDirective, SelectControl, DateTimeControl, UnitQuantityControl],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [enterReplace]
})
export class ScheduleControlsComponent implements OnInit {

	@Input() instructionFormGroup!: FormGroup;
	@Input() minDate!: string;
	@Input() maxDate!: string;
	@Input() config!: SheetItemScheduleConfig;

	ScheduleType = ScheduleType;

	frequencyItems: SelectItem[] = [
		{ text: 'Repeating', value: ScheduleType.Repeating },
		{ text: 'One off', value: ScheduleType.OneOff },
		{ text: 'When required (PRN)', value: ScheduleType.WhenRequired },
		{ text: 'Unscheduled', value: ScheduleType.Unscheduled }
	];

	scheduleFormGroup!: FormGroup;

	minuteValues = fiveMinuteIntervalMinuteValues;

	private initialFormValue!: Schedule;

	timeToCompleteControl = new FormControl<UnitQuantity | null>(null, [unitQuantityRequired(), timeQuantityFactor(TimeUnit.Minutes, 5)]);
	repeatEveryControl = new FormControl<UnitQuantity | null>(null, [unitQuantityRequired(), timeQuantityFactor(TimeUnit.Minutes, 5)]);

	timeToCompleteUnits: Unit[] = [{ label: 'minutes', value: TimeUnit.Minutes }, { label: 'hours', value: TimeUnit.Hours }];
	repeatEveryUnits: Unit[] = [{ label: 'minutes', value: TimeUnit.Minutes }, { label: 'hours', value: TimeUnit.Hours }, { label: 'days', value: TimeUnit.Days }];

	constructor(private sheetItemService: SheetItemService, private destroyRef: DestroyRef, private timeSpanConversionService: TimeSpanConversionService) {
	}

	ngOnInit(): void {
		this.scheduleFormGroup = this.instructionFormGroup.get('schedule') as FormGroup;
		this.initialFormValue = structuredClone(this.scheduleFormGroup.value);

		if ((this.initialFormValue as Schedule).scheduleTypeName === ScheduleType.OneOff) {
			this.initialiseTimeSpanControl(this.timeToCompleteControl, 'timeToComplete', (this.initialFormValue as OneOffSchedule).timeToComplete);
			this.scheduleFormGroup.controls['timeToComplete'].setValidators([Validators.required]);
		}
		else if ((this.initialFormValue as Schedule).scheduleTypeName === ScheduleType.Repeating) {
			this.initialiseTimeSpanControl(this.timeToCompleteControl, 'timeToComplete', (this.initialFormValue as RepeatingSchedule).timeToComplete);
			this.scheduleFormGroup.controls['timeToComplete'].setValidators([Validators.required]);
		}

		if ((this.initialFormValue as Schedule).scheduleTypeName === ScheduleType.Repeating) {
			this.initialiseTimeSpanControl(this.repeatEveryControl, 'frequency', (this.initialFormValue as RepeatingSchedule).frequency);
			this.scheduleFormGroup.controls['frequency'].setValidators([Validators.required]);
		}
	}

	frequencyChanged(scheduleType: SelectValue) {
		var newSchedule = this.sheetItemService.getNewSchedule(scheduleType as ScheduleType, this.config.defaultRepeatingScheduleFrequency);
		var newScheduleFormGroup = this.sheetItemService.getScheduleFormGroup(newSchedule);
		this.instructionFormGroup.setControl('schedule', newScheduleFormGroup);
		this.scheduleFormGroup = this.instructionFormGroup.get('schedule') as FormGroup;
		switch (scheduleType) {
			case ScheduleType.OneOff:
				this.initialiseTimeSpanControl(this.timeToCompleteControl, 'timeToComplete', (newSchedule as OneOffSchedule).timeToComplete);
				break;
			case ScheduleType.Repeating:
				this.initialiseTimeSpanControl(this.timeToCompleteControl, 'timeToComplete', (newSchedule as RepeatingSchedule).timeToComplete);
				this.initialiseTimeSpanControl(this.repeatEveryControl, 'frequency', (newSchedule as RepeatingSchedule).frequency);
				break;
		}
	}

	private initialiseTimeSpanControl(control: FormControl<UnitQuantity | null>, formControlName: string, initialFormControlValue: string) {
		control.setValue(this.getUnitQuantityFromTimeSpan(initialFormControlValue));
		control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(unitQuantity => {
			this.scheduleFormGroup.get(formControlName)?.setValue(this.getTimeSpanFromUnitQuantity(control.invalid, unitQuantity));
		});
	}

	private getUnitQuantityFromTimeSpan(timespan: string): UnitQuantity {
		// note: only days or hours or minutes are ever saved, not a combination
		return this.timeSpanConversionService.convertCSharpTimeSpanToTimeQuantity(timespan);
	}

	private getTimeSpanFromUnitQuantity(unitQuantityControlInvalid: boolean, unitQuantity: UnitQuantity | null): string | null {
		if (unitQuantityControlInvalid || unitQuantity === null) return null;

		if (unitQuantity.quantity === null || unitQuantity.unit === null) return null;

		// note: only days or hours or minutes are ever saved, not a combination
		return this.timeSpanConversionService.convertTimeUnitToCSharpTimeSpan(unitQuantity.unit as TimeUnit, unitQuantity.quantity);
	}
}