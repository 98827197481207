import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ItemLabelComponent } from 'src/app/components/item-label/item-label.component';
import { ItemComponent } from 'src/app/components/item/item.component';
import { SvgIconComponent } from 'src/app/components/svg-icon/svg-icon.component';
import { CoercionService } from 'src/app/services/coercion.service';

@Component({
	selector: 'app-checkbox',
	templateUrl: './checkbox.control.html',
	styleUrl: './checkbox.control.scss',
	standalone: true,
	imports: [ReactiveFormsModule, ItemComponent, ItemLabelComponent, SvgIconComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: CheckboxControl,
		multi: true
	}]
})
export class CheckboxControl implements ControlValueAccessor {

	@Input() readOnly: boolean = false;
	@Input() label: string = '';

	showBorderBox: boolean = true;
	@Input() set hideBorderBox(value: any) { this.showBorderBox = !this.coercionService.coerceToBoolean(value) }

	constructor(private coercionService: CoercionService, private changeDetectorRef: ChangeDetectorRef) {
	}

	value: boolean = false;

	writeValue(value: boolean | null): void {
		this.value = value ?? false;

		// this is required for the view to update correctly (it's a general approach recommended by Angular devs using ChangeDetectionStrategy.OnPush: https://github.com/angular/angular/issues/21780)
		this.changeDetectorRef.markForCheck();
	}

	private onChange: (value: boolean) => void = () => { };

	registerOnChange(fn: (value: boolean) => void): void {
		this.onChange = fn;
	}

	private onTouched: () => void = () => { };

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(disabled: boolean): void {
	}

	clicked() {
		if (this.readOnly) return;
		this.value = !this.value;
		this.onChange(this.value);
		this.onTouched();
	}
}