import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { AlertController, IonPopover } from '@ionic/angular/standalone';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs';
import { ChipComponent } from 'src/app/components/chip/chip.component';
import { ItemLabelComponent } from 'src/app/components/item-label/item-label.component';
import { ItemComponent } from 'src/app/components/item/item.component';
import { SvgIconComponent } from 'src/app/components/svg-icon/svg-icon.component';
import { AppState } from 'src/app/store/reducers';
import { profileInfo } from 'src/app/store/user-graph/user-graph.selectors';

export interface UserSignature {
	fullName: string | null;
	initials: string | null;
}

@Component({
	selector: 'app-user-signature',
	templateUrl: './user-signature.control.html',
	styleUrl: './user-signature.control.scss',
	standalone: true,
	imports: [ReactiveFormsModule, ItemComponent, ItemLabelComponent, ChipComponent, SvgIconComponent, IonPopover],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: UserSignatureControl,
		multi: true
	}]
})
export class UserSignatureControl implements ControlValueAccessor, OnInit {

	@Input() readOnly: boolean = false;
	@Input() label: string = '';
	@Input() canUntick: boolean = true;
	@Input() requireClinician: boolean = false;

	private alreadySignedMessage!: string;
	@Input('alreadySignedMessage') set updateAlreadySignedMessage(message: string | undefined) { if (message) this.alreadySignedMessage = message; }

	private notClinicianMessage: string = 'Only clinicians can sign this document.';
	@Input('notClinicianMessage') set updateNotClinicianMessage(message: string | undefined) { if (message) this.notClinicianMessage = message; }

	constructor(private changeDetectorRef: ChangeDetectorRef, private store: Store<AppState>, private alertController: AlertController) {
	}

	ngOnInit(): void {
		this.alreadySignedMessage = this.requireClinician && !this.canUntick ? 'This document has already been signed by a clinician.' : 'This document has already been signed.';
	}

	value!: UserSignature | null;

	writeValue(value: UserSignature | null): void {
		this.value = value;

		// this is required for the view to update correctly (it's a general approach recommended by Angular devs using ChangeDetectionStrategy.OnPush: https://github.com/angular/angular/issues/21780)
		this.changeDetectorRef.markForCheck();
	}

	private onChange: (value: UserSignature | null) => void = () => { };

	registerOnChange(fn: (value: UserSignature | null) => void): void {
		this.onChange = fn;
	}

	private onTouched: () => void = () => { };

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(disabled: boolean): void {
	}

	clicked() {
		if (this.readOnly) return;

		this.onTouched();

		if (this.value && !this.canUntick) {
			this.showBlockerAlert(this.alreadySignedMessage);
			return;
		}

		this.store.pipe(select(profileInfo), take(1)).subscribe(profileInfo => {

			if (this.requireClinician && !profileInfo.isClinician && !profileInfo.isSystemAdministrator) {
				this.showBlockerAlert(this.notClinicianMessage);
			}
			else {
				if (this.value) this.value = null;
				else this.value = { fullName: profileInfo.fullName, initials: profileInfo.initials };
				this.onChange(this.value);
			}
		});
	}

	private async showBlockerAlert(message: string) {
		const alert = await this.alertController.create({
			header: 'Forbidden',
			subHeader: message,
			buttons: ['Ok'],
			backdropDismiss: false
		});
		alert.present();
	}

	// #region Popover Logic

	popoverIsOpen: boolean = false;
	@ViewChild('popover') popover!: IonPopover;

	handleUserClick(event: Event) {
		event.stopPropagation();
		this.popover.event = event;
		this.popoverIsOpen = true;
	}

	// #endregion
}