<app-action-form [item]="item" [slot]="slot" [form]="form">
	<div class="form-section app-margin-horizontal app-padding">
        <div class="form-group" [formGroup]="form">
            <app-select label="Mucous Membranes" placeholder="Choose..." [items]="mucousMembranesColor" [saveValue]="true" formControlName="color"></app-select>
            <app-text-input label="CRT" placeholder="Value" formControlName="crt"></app-text-input>
        </div>
        <app-common-action-controls [action]="action" [form]="form"></app-common-action-controls> 
    </div>
</app-action-form>

