import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CheckboxControl } from 'src/app/form-controls/checkbox/checkbox.control';
import { SelectControl, SelectItem } from 'src/app/form-controls/select/select.control';
import { UnitQuantity, UnitQuantityControl } from 'src/app/form-controls/unit-quantity/unit-quantity.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { NegativePressureWoundTherapyAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { PressureMode } from 'src/app/types/sheet-instruction.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface NegativePressureWoundTherapyActionFormGroup {
	checkDressing: FormControl<boolean | null>;
	checkPressureSetting: FormControl<boolean | null>;
	checkMode: FormControl<boolean | null>;
	pressure: FormControl<UnitQuantity | null>;
	pressureMode: FormControl<PressureMode | null>;
}
@Component({
	selector: 'app-negative-pressure-wound-therapy-action-controls',
	templateUrl: './negative-pressure-wound-therapy-action-controls.component.html',
	styleUrls: ['../../../common.scss', './negative-pressure-wound-therapy-action-controls.component.scss'],
	standalone: true,
	imports: [ReactiveFormsModule, UnitQuantityControl, CommonActionControlsComponent, ActionFormComponent, SelectControl, CheckboxControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NegativePressureWoundTherapyActionControlsComponent implements SheetActionControlsBase, OnInit {

	slot!: Slot | null;
	action!: NegativePressureWoundTherapyAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as NegativePressureWoundTherapyAction;
	}

	@Input() form!: FormGroup<NegativePressureWoundTherapyActionFormGroup>;
	@Input() item!: Row | null;

	pressureModes: SelectItem[] = [
		{ text: 'Continuous', value: PressureMode.Continuous },
		{ text: 'Intermittent', value: PressureMode.Intermittent }
	];
	
	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.form.addControl('checkDressing', this.formBuilder.control(this.action?.checkDressing ?? null));
		this.form.addControl('checkPressureSetting', this.formBuilder.control(this.action?.checkPressureSetting ?? null));
		this.form.addControl('checkMode', this.formBuilder.control(this.action?.checkMode ?? null));
		this.form.addControl('pressure', this.formBuilder.control(this.action?.pressure ?? null));
		this.form.addControl('pressureMode', this.formBuilder.control(this.action?.pressureMode ?? null));
	}
}