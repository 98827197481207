import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular/standalone';
import { AppSettings } from 'src/app/app-settings';

export const LogMessageUri = '/api/logs/client-message';

export class SignalRError {
	message: string;
	error: any;

	constructor(message: string, error: string) {
		this.message = message;
		this.error = error;
	}
}

@Injectable({
	providedIn: 'root'
})
export class ErrorService {

	constructor(private platform: Platform) {
	}

	getErrorMessage(error: any): string {

		let errorObject = this.getPropertiesFromError(error);

		errorObject = {
			...errorObject,
			clientName: 'CRIS.UI',
			clientVersion: AppSettings.version,
			clientOrigin: window.location.origin,
			clientApiEndpoint: AppSettings.apiEndpoint,
			clientDateTime: (new Date()).toISOString(),
			clientPlatforms: this.platform.platforms()
		};

		return JSON.stringify(errorObject);
	}

	private getPropertiesFromError(error: any): object {
		let errorObject: any;

		// a server-side http error occurred
		if (error instanceof HttpErrorResponse) {
			const err: HttpErrorResponse = error;
			errorObject = { name: err.name, status: err.status, statusText: err.statusText, url: err.url, message: err.message };
			if (err.error && err.error.stack) errorObject = { ...errorObject, stack: err.error.stack };
		}

		// a signalR error occurred
		else if (error instanceof SignalRError) {
			const err: SignalRError = error;
			errorObject = { name: SignalRError.name, message: err.message, ...this.getPropertiesFromError(err.error) };
		}

		// a standard error occurred
		else if (error instanceof Error) {
			const err: Error = error;
			errorObject = { name: err.name, message: err.message };
			if (err.stack) errorObject = { ...errorObject, stack: err.stack };
		}

		// a client-side or network error occurred
		else if (error instanceof ErrorEvent) {
			const err: ErrorEvent = error;
			errorObject = { type: err.type, message: err.message };
			if (err.error && err.error.stack) errorObject = { ...errorObject, stack: err.error.stack };
		}

		// an error of unknown type occurred
		else {
			errorObject = {};
			try {
				errorObject = { ...errorObject, name: error.name, type: typeof error, message: error.toString(), ...error };
			} catch (e) {
				errorObject = { type: 'CRIS could not convert the object to an error', message: error.toString() };
			}
		}

		return errorObject;
	}
}