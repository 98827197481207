import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DateTimeControl } from 'src/app/form-controls/datetime/datetime.control';
import { SelectControl, SelectItem } from 'src/app/form-controls/select/select.control';
import { TextInputControl } from 'src/app/form-controls/text-input/text-input.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { CatheterSite, CheckIvCatheterAction, EuthanasiaAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { fiveMinuteIntervalMinuteValues } from 'src/app/types/sheet.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface EuthanasiaActionFormGroup {
	timeOfDeath: FormControl<string | null>;
	euthanasedBy: FormControl<string | null>;
	priced: FormControl<boolean | null>;
}

@Component({
	selector: 'app-euthanasia-action-controls',
	templateUrl: './euthanasia-action-controls.component.html',
	styleUrls: ['../../../common.scss', './euthanasia-action-controls.component.scss'],
	standalone: true,
	imports: [ReactiveFormsModule, CommonActionControlsComponent, ActionFormComponent, SelectControl, DateTimeControl, TextInputControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EuthanasiaActionControlsComponent implements SheetActionControlsBase, OnInit {

	slot!: Slot | null;
	action!: EuthanasiaAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as EuthanasiaAction;
	}

	@Input() form!: FormGroup<EuthanasiaActionFormGroup>;
	@Input() item!: Row | null;

	priced: SelectItem[] = [
		{ text: 'Yes', value: true },
		{ text: 'No', value: false }
	];

	constructor(private formBuilder: FormBuilder) {
	}

	minuteValues = fiveMinuteIntervalMinuteValues;

	ngOnInit() {
		this.form.addControl('timeOfDeath', this.formBuilder.control(this.action?.timeOfDeath ?? null));
		this.form.addControl('euthanasedBy', this.formBuilder.control(this.action?.euthanasedBy ?? null));
		this.form.addControl('priced', this.formBuilder.control(this.action?.priced ?? null));
	}
}
