import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import * as fromBodyPartsSearch from './body-parts-search.reducer';

export const selectBodyPartsSearch = (state: AppState) => state.bodyPartsSearch;

export const searchParams = createSelector(
	selectBodyPartsSearch,
	(state: fromBodyPartsSearch.State) => ({
		queryTerm: state.queryTerm,
		modality: state.modality,
		resultsPerAppend: state.resultsPerAppend,
		appendsRetrieved: state.appendsRetrieved
	})
);

export const queryTerm = createSelector(
	selectBodyPartsSearch,
	(state: fromBodyPartsSearch.State) => state.queryTerm
);

export const newSearchInProgress = createSelector(
	selectBodyPartsSearch,
	(state: fromBodyPartsSearch.State) => state.newSearchInProgress
);

export const searchResults = createSelector(
	selectBodyPartsSearch,
	// todo: remove the filtering at some point. Should be done in the b/e.  This is a temporary fix.
	//       The query is for 1000 results at the moment (body parts count is currenty 300 results). If it ever goes over 1000, the reducer.resultsPerAppend value needs to be increased
	(state: fromBodyPartsSearch.State) => state.searchResults.filter(bodyPart => bodyPart.modality === state.modality)
);

export const morePagesExist = createSelector(
	selectBodyPartsSearch,
	(state: fromBodyPartsSearch.State) => state.lastResultsRetrievedCount === state.resultsPerAppend
);