import { animate, state, style, transition, trigger } from '@angular/animations';

export const stackedGridItemSwitchedDuration = 150;

export const stackedGridItemSwitched = trigger('stackedGridItemSwitched', [
	state('true', style({ opacity: 1, 'z-index': 'auto' })),
	state('false', style({ opacity: 0, 'z-index': -1 })),
	transition('false => true', animate(`${stackedGridItemSwitchedDuration}ms ${stackedGridItemSwitchedDuration}ms ease-in`)),
	transition('true => false', animate(`${stackedGridItemSwitchedDuration}ms ease`))
]);

export const stackedGridItemOverlayed = trigger('stackedGridItemOverlayed', [
	transition(':enter', [
		style({ transform: 'translateY(100px)', opacity: 0 }),
		animate('200ms ease-in', style({ transform: 'translateY(0)', opacity: 1 }))
	]),
	transition(':leave', [
		style({ transform: 'translateY(0)', opacity: 1 }),
		animate('200ms', style({ transform: 'translateY(100px)', opacity: 0 }))
	])
]);

export const detailContentEnterLeave = trigger('detailContentEnterLeave', [
	transition(':enter', [
		style({ opacity: 0 }),
		animate('150ms 150ms ease-in', style({ opacity: 1 }))
	]),
	transition(':leave', [
		style({ opacity: 1 }),
		animate('150ms ease', style({ opacity: 0 }))
	])
]);

/* KEEPING THIS, WE'LL PROBABLY USE IT AT SOME POINT
export const flipIcon = trigger('flipIcon', [
	state('true', style({ transform: 'scaleX(-1)' })),
	state('false', style({ transform: 'scale(-1)' })),
	transition('true <=> false', animate('300ms ease'))
])
*/

export const enterLeaveTiming = '300ms ease-in-out';

export const enterLeave = trigger('enterLeave', [
	transition(':enter', [
		style({ height: 0, opacity: 0 }),
		animate(enterLeaveTiming, style({ height: '*', opacity: 1 }))
	]),
	transition(':leave', [
		style({ height: '*', opacity: 1 }),
		animate(enterLeaveTiming, style({ height: 0, opacity: 0 }))
	])
])

export const enterReplace = trigger('enterReplace', [
	transition(':enter', [
		style({ height: 0, opacity: 0 }),
		animate('200ms ease-in-out', style({ height: '*', opacity: 1 }))
	]),
	transition(':leave', [
		style({ height: '*', opacity: 1 }),
		animate('150ms', style({ height: 0, opacity: 0 }))
	])
])

export const enterFade = trigger('enterFade', [
	transition(':enter', [
		style({ opacity: 0 }),
		animate('150ms ease-in', style({ opacity: 1 }))
	]),
	transition(':leave', [
		style({ opacity: 1 }),
		animate('150ms ease', style({ opacity: 0 }))
	])
]);