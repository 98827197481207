import { createAction, props } from '@ngrx/store';
import { SearchedPatient } from 'src/app/services/patients.service';

export enum MenuSelectionActionTypes {
	UserVisitsSelected = '[Menu] User Visits Selected',
	TeamTasksSelected = '[Menu] Team Tasks Selected',
	SearchedPatientSelected = '[Menu] Searched Patient Selected'
}

export const userVisitsSelected = createAction(
	MenuSelectionActionTypes.UserVisitsSelected
)

export const teamTasksSelected = createAction(
	MenuSelectionActionTypes.TeamTasksSelected,
	props<{ teamId: string }>()
)

export const searchedPatientSelected = createAction(
	MenuSelectionActionTypes.SearchedPatientSelected,
	props<{ patient: SearchedPatient }>()
)