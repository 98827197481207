<app-action-form [item]="item" [slot]="slot" [form]="form">
	<div class="form-section app-margin-horizontal app-padding">
		<div class="form-group" [formGroup]="form">
			<app-select label="Respiratory patterns" placeholder="choose..." [items]="respiratoryPatterns" [saveValue]="true" formControlName="respiratoryPatterns"></app-select>
			<app-select label="Stertor (low pitched snorting noise)" placeholder="choose..." [items]="bearScoreNoiseLevels" [saveValue]="true" formControlName="stertor"></app-select>
			<app-select label="Stridor (high pitched laryngeal noise)" placeholder="choose..." [items]="bearScoreNoiseLevels" [saveValue]="true" formControlName="stridor"></app-select>
			<app-select label="Dyspnoea" placeholder="choose..." [items]="bearScorePresence" [saveValue]="true" formControlName="dyspnoea"></app-select>
			<app-select label="Cyanosis" placeholder="choose..." [items]="bearScorePresence" [saveValue]="true" formControlName="cyanosis"></app-select>
		</div>
		<div class="form-group">
			<ul>
				<li>If 0 - 4: Continue to monitor in Wards. RR and effort at least q4 hours</li>
				<li>If 5 for dyspnoea or cyanosis: Take to ICU, start flow by oxygen, contact Clinician or ECC Clinician if severely dyspnoeic. Consider sedation.</li>
				<li>If 5 for breathing patterns / respiratory noises or inspiratory effort: contact Clinician. Prepare to take to ICU for flow by oxygen and consider sedation.</li>
			</ul>
		</div>
		<div class="form-group" [formGroup]="form">
			<app-text-input label="Total score" placeholder="Value" formControlName="totalScore" inputMode="numeric"></app-text-input>
		</div>
		<app-common-action-controls [action]="action" [form]="form"></app-common-action-controls>
	</div>
</app-action-form>
