import { createReducer, on } from '@ngrx/store';
import { Client, Patient, PatientRecordTask, TaskDocument, Visit } from 'src/app/types/aggregate-graph.types';
import { clearStateRequested } from '../auth/auth.actions';
import * as patientTaskActions from '../patient-task/patient-task.actions';
import * as patientGraphActions from './patient-graph.actions';

export interface State {
	patientId: string;
	taskId: string;
	serviceId: string;
	patientLoaded: boolean;
	patient: Patient | null;
	clients: Client[];
	visits: Visit[];
	tasks: PatientRecordTask[];
	taskDocuments: TaskDocument[];
}

export const initialState: State = {
	patientId: '',
	taskId: '',
	serviceId: '',
	patientLoaded: false,
	patient: null,
	clients: [],
	visits: [],
	tasks: [],
	taskDocuments: []
}

export const patientGraphReducer = createReducer(
	initialState,
	on(patientGraphActions.subscribePatientRequested, (state, { patientId, taskId, serviceId }) => ({
		...state,
		patientId: patientId.toLocaleLowerCase(), // sometimes users paste in upper case id
		taskId: taskId.toLocaleLowerCase(), // sometimes users paste in upper case id
		serviceId: serviceId.toLocaleLowerCase() // sometimes users paste in upper case id
	})),
	on(patientGraphActions.patientGraphLoaded, (state, { patient, clients, visits, tasks, taskDocuments }) => ({
		...state,
		patientLoaded: true,
		patient: patient,
		clients: clients,
		visits: visits,
		tasks: tasks,
		taskDocuments: taskDocuments
	})),
	on(patientGraphActions.patientUpdated, (state, { partialPatient }) => ({
		...state,
		patient: { ...state.patient!, ...partialPatient }
	})),
	on(patientGraphActions.clientUpdated, (state, { partialClient }) => {
		const clientsToUpdate = state.clients.filter(c => c.id === partialClient.id);
		if (clientsToUpdate.length === 0) return { ...state };
		return {
			...state,
			clients: [...state.clients.filter(c => c.id !== partialClient.id), ...clientsToUpdate.map(client => ({ ...client, ...partialClient }))]
		}
	}),
	on(patientGraphActions.visitUpdated, (state, { partialVisit }) => {
		const visitToUpdate = state.visits.find(v => v.id === partialVisit.id);
		if (visitToUpdate === undefined) return { ...state };
		const updatedVisit = { ...visitToUpdate, ...partialVisit };
		return {
			...state,
			visits: [...state.visits.filter(v => v.id !== updatedVisit.id), updatedVisit]
		};
	}),
	on(patientGraphActions.taskAdded, (state, { task }) => ({
		...state,
		tasks: [...state.tasks, task]
	})),
	on(patientGraphActions.taskUpdated, (state, { partialTask }) => {
		const taskToUpdate = state.tasks.find(t => t.id === partialTask.id);
		if (taskToUpdate === undefined) return { ...state };
		const updatedTask = { ...taskToUpdate, ...partialTask };
		return {
			...state,
			tasks: [...state.tasks.filter(t => t.id !== updatedTask.id), updatedTask]
		};
	}),
	on(patientGraphActions.taskDocumentAdded, (state, { taskDocument }) => ({
		...state,
		taskDocuments: [...state.taskDocuments, taskDocument]
	})),
	on(patientGraphActions.taskDocumentUpdated, (state, { partialTaskDocument }) => {
		const taskDocumentToUpdate = state.taskDocuments.find(td => td.id === partialTaskDocument.id);
		if (taskDocumentToUpdate === undefined) return { ...state };
		// the server only sends the widget that has changed, not the whole document
		const updatedTaskDocument = { ...taskDocumentToUpdate, widgetValues: { ...taskDocumentToUpdate.widgetValues, ...partialTaskDocument.widgetValues } };
		return {
			...state,
			taskDocuments: [...state.taskDocuments.filter(td => td.id !== updatedTaskDocument.id), updatedTaskDocument]
		};
	}),
	on(patientGraphActions.unsubscribePatientRequested, () => initialState),
	on(patientGraphActions.patientGraphSystemRestarted, (state) => ({
		...state,
		patient: null,
		client: null,
		visits: [],
		tasks: []
	})),
	on(patientGraphActions.reloadPatientRequested, (state) => ({
		...state,
		patientLoaded: false,
		patient: null,
		client: null,
		visits: [],
		tasks: []
	})),
	on(patientTaskActions.shareDraftTaskCompleted, (state, { taskId, firstSharedDate }) => {
		const taskToUpdate = state.tasks.find(t => t.id === taskId);
		if (taskToUpdate === undefined) return { ...state };
		return {
			...state,
			tasks: [...state.tasks.filter(t => t.id !== taskId), { ...taskToUpdate, firstSharedDate }]
		};
	}),
	on(clearStateRequested, () => initialState)
);