import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, map, of, switchMap } from 'rxjs';
import { ErrorService } from 'src/app/services/error.service';
import { TasksService } from 'src/app/services/tasks.service';
import { clientErrored } from '../error/error.actions';
import { AppState } from '../reducers';
import * as serviceGraphActions from '../service-graph/service-graph.actions';
import * as serviceTaskActions from './service-task.actions';

@Injectable()
export class ServiceTaskEffects {

	constructor(private actions$: Actions, private store: Store<AppState>, private errorService: ErrorService, private tasksService: TasksService) {
	}

	serviceGraphLoaded$ = createEffect(() =>
		this.actions$.pipe(
			ofType(serviceGraphActions.serviceGraphLoaded),
			map(payload =>
				serviceTaskActions.serviceTasksLoaded({
					inboxServiceTasks: payload.serviceTasks.filter(st => !st.removedDate),
					scheduledServiceTasks: payload.serviceTasks.filter(st => st.scheduledDate)
				})
			)
		)
	);

	serviceTaskUpdated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(serviceGraphActions.serviceTaskUpdated),
			map(payload => payload.partialServiceTask),
			concatLatestFrom(() => [this.store.select(state => state.serviceTask.inboxServiceTasks), this.store.select(state => state.serviceTask.scheduledServiceTasks)]),
			switchMap(([partialServiceTask, inboxServiceTasks, scheduledServiceTasks]) => {
				const inboxServiceTask = inboxServiceTasks.find(st => st.id === partialServiceTask.id);
				const scheduledServiceTask = scheduledServiceTasks.find(st => st.id === partialServiceTask.id);
				const actions = [];

				// note: the server combines inbox and scheduled service tasks into a single set, so this effect needs to handle the fact that an update could apply to one or both

				// note: a removedDate of null means the the value was changed during the server commit, whereas undefined means the value was not changed
				if (inboxServiceTask) {
					if (partialServiceTask.removedDate) actions.push(serviceTaskActions.inboxServiceTaskRemoved({ id: partialServiceTask.id }));
					else actions.push(serviceTaskActions.inboxServiceTaskUpdated({ partialServiceTask: partialServiceTask }));
				}
				else if (scheduledServiceTask && partialServiceTask.removedDate === null) {
					actions.push(serviceTaskActions.inboxServiceTaskAdded({ serviceTask: { ...scheduledServiceTask, ...partialServiceTask } }));
				}

				// note: a scheduledDate of null means the the value was changed during the server commit, whereas undefined means the value was not changed
				if (scheduledServiceTask) {
					if (partialServiceTask.scheduledDate === null) actions.push(serviceTaskActions.scheduledServiceTaskRemoved({ id: partialServiceTask.id }));
					else actions.push(serviceTaskActions.scheduledServiceTaskUpdated({ partialServiceTask: partialServiceTask }));
				}
				else if (inboxServiceTask && partialServiceTask.scheduledDate !== null) {
					actions.push(serviceTaskActions.scheduledServiceTaskAdded({ serviceTask: { ...inboxServiceTask, ...partialServiceTask } }));
				}

				return actions;
			})
		)
	);

	serviceTaskAdded$ = createEffect(() =>
		this.actions$.pipe(
			ofType(serviceGraphActions.serviceTaskAdded),
			switchMap(payload => {
				const serviceTask = payload.serviceTask;
				const actions = [];

				if (serviceTask.removedDate === null) actions.push(serviceTaskActions.inboxServiceTaskAdded({ serviceTask: serviceTask }));
				if (serviceTask.scheduledDate) actions.push(serviceTaskActions.scheduledServiceTaskAdded({ serviceTask: serviceTask }));

				return actions;
			})
		)
	);

	removeTaskRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(serviceTaskActions.removeServiceTaskRequested),
			exhaustMap(action => this.tasksService.removeTask(action.taskId, action.recipientId).pipe(
				map(() => serviceTaskActions.removeServiceTaskCompleted()),
				catchError((error: HttpErrorResponse) => of(clientErrored({ toastMessage: 'Remove task failed', errorMessage: this.errorService.getErrorMessage(error) })))
			))
		)
	);
}