import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { PopoverController } from '@ionic/angular/standalone';
import { ItemLabelComponent } from 'src/app/components/item-label/item-label.component';
import { ItemValueComponent } from 'src/app/components/item-value/item-value.component';
import { ItemComponent } from 'src/app/components/item/item.component';
import { SelectPopoverComponent } from './select.popover';

export type SelectValue = string | number | boolean | null;

export interface SelectItem {
	id?: number; // this is used in select-with-info control (also used in widget metadata)
	type?: 'option' | 'header';
	text: string;
	value?: string | number | boolean; // this is not use in widget metadata, but can be used for saving value instead of text
}

@Component({
	selector: 'app-select',
	templateUrl: './select.control.html',
	styleUrl: './select.control.scss',
	standalone: true,
	imports: [ReactiveFormsModule, ItemComponent, ItemLabelComponent, ItemValueComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: SelectControl,
		multi: true
	}]
})
export class SelectControl implements ControlValueAccessor {

	@Input() readOnly: boolean = false;
	@Input() label: string = '';
	@Input() placeholder: string = '';
	@Input() items: SelectItem[] = [];
	@Input() saveValue: boolean = false;
	@Output() selectionChange = new EventEmitter<SelectValue>();

	value: SelectValue = null;
	text: string = '';

	get placeholderValue() { return this.readOnly ? '' : this.placeholder }

	constructor(private changeDetectorRef: ChangeDetectorRef, private popoverController: PopoverController) {
	}

	writeValue(value: SelectValue): void {
		this.value = value;
		this.text = this.items.find(item => (this.saveValue ? item.value : item.text) === value)?.text ?? '';
		// this is required for the view to update correctly (it's a general approach recommended by Angular devs using ChangeDetectionStrategy.OnPush: https://github.com/angular/angular/issues/21780)
		this.changeDetectorRef.markForCheck();
	}

	private onChange: (value: SelectValue) => void = () => { };

	registerOnChange(fn: (value: SelectValue) => void): void {
		this.onChange = fn;
	}

	private onTouched: () => void = () => { };

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(disabled: boolean): void {
	}

	async controlClicked(event: Event) {
		if (this.readOnly) return;

		this.onTouched();

		const popover = await this.popoverController.create({
			component: SelectPopoverComponent,
			cssClass: 'popover-menu',
			componentProps: { items: this.items },
			event: event,
			alignment: 'end',
			showBackdrop: false
		});

		await popover.present();

		const { data } = await popover.onWillDismiss();

		if (!data) return;

		const item = data.item as SelectItem;

		const value = (this.saveValue ? item.value : item.text) as SelectValue;

		if (value !== null && this.value !== value) { // note: value could be 0, so must check for null explicitly
			this.value = value;
			this.text = item.text;
			this.changeDetectorRef.markForCheck(); // ensure the view shows the new property values (needed due to OnPush change detection strategy)
			this.onChange(value);
			this.selectionChange.emit(value);
		}
	}
}