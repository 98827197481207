import { createSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import { visitsByVisitNumberDesc } from '../patient-graph/patient-graph.selectors';
import { PatientRecordTask, Visit } from 'src/app/types/aggregate-graph.types';

export const selectSelectedTaskId = (state: AppState) => state.patientTask.selectedTaskId;
export const selectTasks = (state: AppState) => state.patientGraph.tasks;
export const selectTaskDocuments = (state: AppState) => state.patientGraph.taskDocuments;
export const selectSelectedTaskDocumentId = (state: AppState) => state.patientTask.selectedTaskDocumentId;

export const selectedTaskId = createSelector(
	selectSelectedTaskId,
	(taskId) => taskId
);

export const selectedTask = createSelector(
	selectedTaskId,
	selectTasks,
	(taskId, tasks) => tasks.find(t => t.id === taskId)
);

export const selectedTaskDocuments = createSelector(
	selectedTaskId,
	selectTaskDocuments,
	(taskId, taskDocuments) => taskDocuments.filter(t => t.taskId === taskId)
);

export const selectedTaskDocument = createSelector(
	selectSelectedTaskDocumentId,
	selectedTaskDocuments,
	(documentId, taskDocuments) => taskDocuments.find(td => td.id === documentId)
);

export const selectedTaskRequestDocument = createSelector(
	selectedTaskDocuments,
	(taskDocuments) => taskDocuments.find(td => td.isRequestDocument)
);

export interface VisitTaskList {
	visit: Visit;
	tasks: PatientRecordTask[];
}

export const visitTasks = createSelector(
	visitsByVisitNumberDesc,
	selectTasks,
	(visits, tasks): VisitTaskList[] => visits.map(visit => ({
		visit: visit,
		tasks: tasks.filter(task => task.visitId === visit.id).sort(sortTasksByCreatedDateAsc)
	}))
);

const sortTasksByCreatedDateAsc = (a: PatientRecordTask, b: PatientRecordTask) => a.createdDate.localeCompare(b.createdDate);