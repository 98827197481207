import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UnitValue, UnitValueControl } from 'src/app/form-controls/unit-value/unit-value.control';
import { UserSignature, UserSignatureControl } from 'src/app/form-controls/user-signature/user-signature.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { MedicationInfusionRedLabelActionV2 } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface MedicationInfusionRedLabelActionFormGroup {
	rate: FormControl<UnitValue | null>;
	approvalOne: FormControl<UserSignature | null>;
	approvalTwo: FormControl<UserSignature | null>;
}

@Component({
	selector: 'app-medication-infusion-red-label-action-controls',
	templateUrl: './medication-infusion-red-label-action-controls-v2.component.html',
	styleUrls: ['../../../common.scss', './medication-infusion-red-label-action-controls-v2.component.scss'],
	standalone: true,
	imports: [ReactiveFormsModule, CommonActionControlsComponent, ActionFormComponent, UserSignatureControl, UnitValueControl],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MedicationInfusionRedLabelActionControlsComponentV2 implements SheetActionControlsBase, OnInit {

	slot!: Slot | null;
	action!: MedicationInfusionRedLabelActionV2;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as MedicationInfusionRedLabelActionV2;
	}

	@Input() form!: FormGroup<MedicationInfusionRedLabelActionFormGroup>;
	@Input() item!: Row | null;
	
	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.form.addControl('rate', this.formBuilder.control(this.action?.rate ?? null));
		this.form.addControl('approvalOne', this.formBuilder.control(this.action?.approvalOne ?? null));
		this.form.addControl('approvalTwo', this.formBuilder.control(this.action?.approvalTwo ?? null));
	}
}