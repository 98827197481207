import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SelectControl, SelectItem } from 'src/app/form-controls/select/select.control';
import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { VomitingOrRegurgitation, VomitingOrRegurgitationAction } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface VomitingOrRegurgitationActionFormGroup {
	vomiting: FormControl<VomitingOrRegurgitation | null>;
	regurgitation: FormControl<VomitingOrRegurgitation | null>;
}


@Component({
	selector: 'app-vomiting-or-regurgitation-action-controls',
	templateUrl: './vomiting-or-regurgitation-action-controls.component.html',
	styleUrls: ['../../../common.scss', './vomiting-or-regurgitation-action-controls.component.scss'],
	standalone: true,
	imports: [ReactiveFormsModule, SelectControl, CommonActionControlsComponent, ActionFormComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class VomitingOrRegurgitationActionControlsComponent implements SheetActionControlsBase, OnInit {

	slot!: Slot | null;
	action!: VomitingOrRegurgitationAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as VomitingOrRegurgitationAction;
	}

	@Input() form!: FormGroup<VomitingOrRegurgitationActionFormGroup>;
	@Input() item!: Row | null;
	
	vomitingItems: SelectItem[] = [
		{ text: 'Yes', value: VomitingOrRegurgitation.Yes },
		{ text: 'Unclear', value: VomitingOrRegurgitation.Unclear }
	];

	regurgitationItems: SelectItem[] = [
		{ text: 'Yes', value: VomitingOrRegurgitation.Yes },
		{ text: 'Unclear', value: VomitingOrRegurgitation.Unclear }
	];

	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit() {
		this.form.addControl('vomiting', this.formBuilder.control(this.action?.vomiting ?? null));
		this.form.addControl('regurgitation', this.formBuilder.control(this.action?.regurgitation ?? null));
	}
}