import { createReducer, on } from '@ngrx/store';
import { CommsPanelChildType, SelectedPatientSegmentPanel, segmentLabels } from 'src/app/types/patient.types';
import { clearStateRequested } from '../auth/auth.actions';
import * as patientActions from './patient.actions';

export interface State {
	patientSelected: boolean;
	segmentLabel: string;
	selectedPatientSegmentPanel: SelectedPatientSegmentPanel;
	selectedCommsPanelChildType: CommsPanelChildType;
}

export const initialState: State = {
	patientSelected: false,
	segmentLabel: '',
	selectedPatientSegmentPanel: SelectedPatientSegmentPanel.None,
	selectedCommsPanelChildType: CommsPanelChildType.None
}

export const patientReducer = createReducer(
	initialState,
	on(patientActions.patientSelected, (state, { segmentLabel }) => ({
		...state,
		patientSelected: true,
		segmentLabel: segmentLabel,
		selectedPatientSegmentPanel: getSelectedPatientSegmentPanel(segmentLabel)
	})),
	on(patientActions.patientDeselected, () => initialState),
	on(patientActions.segmentChanged, (state, { label }) => ({
		...state,
		segmentLabel: label,
		selectedPatientSegmentPanel: getSelectedPatientSegmentPanel(label)
	})),
	on(patientActions.commsPanelChildSelected, (state, { childType }) => ({
		...state,
		selectedCommsPanelChildType: childType
	})),
	on(clearStateRequested, () => initialState)
);

const getSelectedPatientSegmentPanel = (segmentLabel: string): SelectedPatientSegmentPanel => {
	switch (segmentLabel) {
		case segmentLabels.visit:
			return SelectedPatientSegmentPanel.Visit;
		case segmentLabels.sheet:
			return SelectedPatientSegmentPanel.Sheet;
		case segmentLabels.tasks:
			return SelectedPatientSegmentPanel.Tasks;
		case segmentLabels.comms:
			return SelectedPatientSegmentPanel.Comms;
		default:
			return SelectedPatientSegmentPanel.None;
	}
};