import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ExpanderGroupService {

	private expanderSubject$ = new Subject<boolean>();

	expanderGroupStateChangedSubject$ = this.expanderSubject$.asObservable();

	constructor() {
	}

	public expandCollapseAll(expand: boolean) {
		this.expanderSubject$.next(expand);
	}
}