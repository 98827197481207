import { Injectable, OnDestroy } from '@angular/core';
import { AlertController, AlertOptions, ToastController } from '@ionic/angular/standalone';
import { Subject, Subscription, concatMap, delay, from, mergeMap, tap } from 'rxjs';

export interface AlertButton {
	text: string;
	role?: string;
	cssClass?: string | string[];
	handler?: (value: any) => boolean | void | {
		[key: string]: any;
	};
}

@Injectable({
	providedIn: 'root'
})
export class AlertService implements OnDestroy {

	private toastAlertSubject = new Subject<{ message: string, duration: number }>();
	private toastAlertSubcription: Subscription;

	constructor(private toastController: ToastController, private alertController: AlertController) {
		this.toastAlertSubcription = this.toastAlertSubject.pipe(
			concatMap(params => from(this.toastController.create({ message: params.message, position: 'bottom' })).pipe(
				mergeMap(alert => from(alert.present()).pipe(
					delay(params.duration),
					tap(() => alert.dismiss()),
					delay(500)
				))
			))
		).subscribe();
	}

	public displayToastAlert(message: string, duration?: number) {
		this.toastAlertSubject.next({ message: message, duration: duration || 1000 });
	}

	public async displayBlockingAlert(heading: string, message: string, buttonText: string | undefined = undefined, buttonHandler: (() => boolean) | undefined = undefined) {
		const options: AlertOptions = {
			subHeader: heading,
			message: message,
			backdropDismiss: false
		};

		if (buttonText && typeof(buttonHandler) !== 'undefined') {
			options.buttons = [{
				text: buttonText,
				handler: buttonHandler
			}];

		}

		const alert = await this.alertController.create(options);

		await alert.present();
	}

	ngOnDestroy(): void {
		this.toastAlertSubcription.unsubscribe();
	}
}