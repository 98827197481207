<app-item class="rounded-border-box" columnar (click)="controlClicked()">
	@if (label) {
		<div>
			<app-item-label class="validation-label">{{label}}</app-item-label>
			<ng-content></ng-content>
		</div>
	}
    @if (readOnly) {
		@if (value || alwaysDisplayValue) {
        	<app-item-value [value]="value" multiLine></app-item-value>
		}
    }
    @else {
        <app-item-textarea [placeholder]="placeholder" [value]="value" [debounce]="debounceDuration" (valueChange)="valueChanged($event)" (itemFocus)="itemFocused()"></app-item-textarea>
    }
</app-item>