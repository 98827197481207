import { createAction, props } from '@ngrx/store';
import { GraphAggregate, GraphAggregateChange, GraphChangeContext, PartialEntity, ServiceTask } from 'src/app/types/aggregate-graph.types';

export enum ServiceGraphActionTypes {
	SubscribeServiceRequested = '[Service Graph Effect] Subscribe Service Requested',
	UnsubscribeServiceRequested = '[Service Graph Effect] Unsubscribe Service Requested',
	ServiceGraphLoaded = '[Service Graph Effect] Service Graph Loaded',
	ServiceTaskUpdated = '[Service Graph Effect] Service Task Updated',
	ServiceTaskAdded = '[Service Graph Effect] Service Task Added',
	ServiceGraphGenerated = '[Service Graph Effect] Service Graph Generated',
	ServiceGraphChanged = '[Service Graph Effect] Service Graph Changed',
	ServiceGraphSystemRestarted = '[Service Graph Effect] Service Graph System Restarted',
	ReloadServiceRequested = '[Service Effect] Reload Service Requested'
}

export const subscribeServiceRequested = createAction(
	ServiceGraphActionTypes.SubscribeServiceRequested,
	props<{ serviceId: string }>()
)

export const unsubscribeServiceRequested = createAction(
	ServiceGraphActionTypes.UnsubscribeServiceRequested
)

export const serviceGraphLoaded = createAction(
	ServiceGraphActionTypes.ServiceGraphLoaded,
	props<{ serviceTasks: ServiceTask[] }>()
)

export const serviceTaskUpdated = createAction(
	ServiceGraphActionTypes.ServiceTaskUpdated,
	props<{ partialServiceTask: PartialEntity<ServiceTask> }>()
)

export const serviceTaskAdded = createAction(
	ServiceGraphActionTypes.ServiceTaskAdded,
	props<{ serviceTask: ServiceTask }>()
)

export const serviceGraphGenerated = createAction(
	ServiceGraphActionTypes.ServiceGraphGenerated,
	props<{ serviceId: string, aggregates: GraphAggregate[] }>()
)

export const serviceGraphChanged = createAction(
	ServiceGraphActionTypes.ServiceGraphChanged,
	props<{ serviceId: string, changes: GraphAggregateChange[], context: GraphChangeContext }>()
)

export const serviceGraphSystemRestarted = createAction(
	ServiceGraphActionTypes.ServiceGraphSystemRestarted
)

export const reloadServiceRequested = createAction(
	ServiceGraphActionTypes.ReloadServiceRequested
)