import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidationErrors, Validator } from '@angular/forms';
import { ModalController } from '@ionic/angular/standalone';
import { enterLeave } from 'src/app/animations/common.animations';
import { ItemLabelComponent } from 'src/app/components/item-label/item-label.component';
import { ItemComponent } from 'src/app/components/item/item.component';
import { SvgIconComponent } from 'src/app/components/svg-icon/svg-icon.component';
import { BodyPartsSearchModal } from 'src/app/modals/body-parts-search/body-parts-search.modal';
import { ImagingModalityBodyPart } from 'src/app/services/body-parts.service';
import { SelectControl, SelectItem } from '../select/select.control';
import { TextAreaControl } from '../textarea/textarea.control';

export interface BodyPartValue {
	id: number;
	name: string;
	notes: string;
}

export interface ModalityBodyPartsPickerValue {
	modality: string | null;
	bodyParts: BodyPartValue[];
}

@Component({
	selector: 'app-modality-body-parts-picker',
	templateUrl: './modality-body-parts-picker.control.html',
	styleUrl: './modality-body-parts-picker.control.scss',
	standalone: true,
	imports: [ReactiveFormsModule, ItemComponent, ItemLabelComponent, SvgIconComponent, SelectControl, TextAreaControl],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: ModalityBodyPartsPickerControl,
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: ModalityBodyPartsPickerControl
		}
	],
	animations: [enterLeave]
})
export class ModalityBodyPartsPickerControl implements ControlValueAccessor, Validator, OnInit {

	@Input() readOnly: boolean = false;

	get modalityPlaceholder() { return this.readOnly ? '' : 'choose...' }
	get notePlaceholder() { return this.readOnly ? '' : 'notes...' }

	selectItems: SelectItem[] = [
		{ text: 'CT', value: 'CT' },
		{ text: 'Fluoroscopy', value: 'Fluoro' },
		{ text: 'MRI', value: 'MRI' },
		{ text: 'Radiography', value: 'Rad' },
		{ text: 'Ultrasound', value: 'US' }
	];

	formGroup!: FormGroup;
	modalityControl!: FormControl;
	bodyPartsArray!: FormArray;

	constructor(private changeDetectorRef: ChangeDetectorRef, private formBuilder: FormBuilder, private modalController: ModalController, private destroyRef: DestroyRef) { // note: DestroyRef is needed if takeUntilDestroyed is used outside the constructor
	}

	ngOnInit() {
		this.modalityControl = this.formBuilder.control(null);
		this.bodyPartsArray = this.formBuilder.array([]);
		this.formGroup = this.formBuilder.group({
			modality: this.modalityControl,
			bodyParts: this.bodyPartsArray
		});

		if (!this.readOnly) {
			this.formGroup.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value: ModalityBodyPartsPickerValue) => {
				this.onChange(value);
				this.onValidatorChange();
				this.changeDetectorRef.markForCheck();
			});
		}
	}

	writeValue(value: ModalityBodyPartsPickerValue | null): void {

		// FormArray.setValue() errors if its structure does not match the value, so need to ensure the number of items matches first
		const bodyPartsCount = value?.bodyParts.length ?? 0;

		if (!value && this.bodyPartsArray.length) {
			this.bodyPartsArray.clear({ emitEvent: false });
		}
		else if (this.bodyPartsArray.length < bodyPartsCount) {
			for (let i = this.bodyPartsArray.length; i < bodyPartsCount; i++) this.addBodyPartControl(value!.bodyParts[i], false);
		}
		else if (this.bodyPartsArray.length > bodyPartsCount) {
			do { this.bodyPartsArray.removeAt(this.bodyPartsArray.length - 1, { emitEvent: false }) } while (this.bodyPartsArray.length > bodyPartsCount);
		}

		this.modalityControl.setValue(value?.modality ?? null, { emitEvent: false });
		this.bodyPartsArray.setValue(value?.bodyParts ?? [], { emitEvent: false });

		// this is required for the view to update correctly (it's a general approach recommended by Angular devs using ChangeDetectionStrategy.OnPush: https://github.com/angular/angular/issues/21780)
		this.changeDetectorRef.markForCheck();
	}

	private onChange: (value: ModalityBodyPartsPickerValue | null) => void = () => { };

	registerOnChange(fn: (value: ModalityBodyPartsPickerValue | null) => void): void {
		this.onChange = fn;
	}

	private onTouched: () => void = () => { };

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(disabled: boolean): void {
	}

	controlClicked() {
		if (this.readOnly) return;

		this.onTouched();
	}

	async presentAddBodyPartModal() {

		this.onTouched();

		const popover = await this.modalController.create({
			component: BodyPartsSearchModal,
			cssClass: 'stacked-modal',
			componentProps: {
				modality: this.formGroup.value.modality,
				modalityText: this.selectItems.find(si => si.value! === this.formGroup.value.modality)!.text!
			},
			backdropDismiss: false
		});

		await popover.present();

		const { data } = await popover.onWillDismiss();

		if (!data) return;

		const bodyPart: ImagingModalityBodyPart = data.bodyPart;

		this.addBodyPartControl({ id: bodyPart.id, name: bodyPart.name, notes: '' }, true);
	}

	removeBodyPart(index: number) {
		this.bodyPartsArray.removeAt(index);
	}

	getNotesControl(formGroup: AbstractControl) {
		return formGroup.get('notes') as FormControl;
	}

	private addBodyPartControl(bodyPart: BodyPartValue, emitEvent: boolean) {
		this.bodyPartsArray.push(this.formBuilder.group({
			id: [bodyPart.id],
			name: [bodyPart.name],
			notes: [bodyPart.notes]
		}), { emitEvent: emitEvent });
	}

	// custom validation
	private onValidatorChange: () => void = () => { }; // call this when the internal state has changed requiring external validation

	registerOnValidatorChange(fn: () => void): void {
		this.onValidatorChange = fn;
	}

	validate(): ValidationErrors | null {
		const value = this.formGroup.value as ModalityBodyPartsPickerValue;
		if (value.modality && !value.bodyParts.length) return { bodyPartsRequired: true };
		return null;
	}
}