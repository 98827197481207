import { createAction, props } from '@ngrx/store';
import { PatientStatus, ResuscitationStatus, VisitParticipant } from 'src/app/types/aggregate-graph.types';

export enum PatientVisitActionTypes {
	ChangePatientStatusRequested = '[Component] Change Patient Status Requested',
	ChangeCurrentTeamRequested = '[Component] Change Current Team Requested',
	AddParticipantRequested = '[Component] Add Participant Requested',
	RemoveParticipantRequested = '[Component] Remove Participant Requested',
	ChangeWardRequested = '[Component] Change Ward Requested',
	ChangeKennelRequested = '[Component] Change Kennel Requested',
	ChangeDischargeDateRequested = '[Component] Change Discharge Date Requested',
	ChangeResuscitationStatusRequested = '[Component] Change Resuscitation Status Requested',
	ChangeBloodTypeRequested = '[Component] Change Blood Type Requested'
}

export const changePatientStatusRequested = createAction(
	PatientVisitActionTypes.ChangePatientStatusRequested,
	props<{ visitId: string, status: PatientStatus }>()
)

export const changeCurrentTeamRequested = createAction(
	PatientVisitActionTypes.ChangeCurrentTeamRequested,
	props<{ visitId: string, teamId: string }>()
)

export const addParticipantRequested = createAction(
	PatientVisitActionTypes.AddParticipantRequested,
	props<{ visitId: string, participant: VisitParticipant }>()
)

export const removeParticipantRequested = createAction(
	PatientVisitActionTypes.RemoveParticipantRequested,
	props<{ visitId: string, userId: number }>()
)

export const changeWardRequested = createAction(
	PatientVisitActionTypes.ChangeWardRequested,
	props<{ visitId: string, ward: string }>()
)

export const changeKennelRequested = createAction(
	PatientVisitActionTypes.ChangeKennelRequested,
	props<{ visitId: string, kennel: number }>()
)

export const changeDischargeDateRequested = createAction(
	PatientVisitActionTypes.ChangeDischargeDateRequested,
	props<{ visitId: string, dischargeDate: string }>()
)

export const changeResuscitationStatusRequested = createAction(
	PatientVisitActionTypes.ChangeResuscitationStatusRequested,
	props<{ visitId: string, status: ResuscitationStatus }>()
)

export const changeBloodTypeRequested = createAction(
	PatientVisitActionTypes.ChangeBloodTypeRequested,
	props<{ patientId: string, bloodType: string }>()
)