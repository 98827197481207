<app-action-form [item]="item" [slot]="slot" [form]="form">
	<div class="form-section app-margin-horizontal app-padding">
        <div class="form-group" [formGroup]="form">
            <app-unit-quantity label="Offered" unitLabel="ml" placeholder="Value" inputMode="numeric" formControlName="offered"></app-unit-quantity>
            <app-unit-quantity label="Drunk" unitLabel="ml" placeholder="Value" inputMode="numeric" formControlName="drunk"></app-unit-quantity>
            <app-unit-quantity label="Refilled" unitLabel="ml" placeholder="Value" inputMode="numeric" formControlName="refilled"></app-unit-quantity>
            <app-unit-quantity label="Total Consumed" unitLabel="ml" placeholder="Value" inputMode="numeric" formControlName="totalConsumed"></app-unit-quantity>
        </div>
        <app-common-action-controls [action]="action" [form]="form"></app-common-action-controls>
    </div>
</app-action-form>
