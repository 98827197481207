<div class="popover-menu-content">
    @for(item of items; track item; let last = $last) {
        @if(item.type === 'header') {
            <app-heading background="secondary-3">{{ item.text }}</app-heading>
        }
        @else {
            <app-item [underlined]="!last" appNavigable (click)="itemSelected(item)">
                <app-item-label>{{ item.text }}</app-item-label>
            </app-item>
        }
    }
</div>