import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TimeQuantity, TimeUnit } from '../services/timespan-conversion.service';

// this validator is specifically for validating the unit-quantity control when it is used as a time-unit.
// It validates that the quantity is a factor of requested TimeUnit (i.e. no remainder).
// It skips validation if: the control value is null, the unit is null or quantity is null (the unitQuantityRequired validator does this).
// It skips validation if the unit is not the requested TimeUnit.
// It errors if the quantity is not an integer.

export function timeQuantityFactor(unit: TimeUnit, factor: number): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {

		if (control.value !== null) {

			const timeQuantity = control.value as TimeQuantity;

			if (timeQuantity.unit !== null && timeQuantity.quantity !== null) {

				if (unit !== timeQuantity.unit) return null;

				if (timeQuantity.quantity % factor) return { timeQuantityFactor: true, unit, quantity: timeQuantity.quantity };
			}
		}

		return null;
	}
};