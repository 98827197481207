import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SheetItemComponentBase } from 'src/app/services/sheets-renderer.service';
import { Row } from 'src/app/types/aggregate-graph.types';
import { ItemContentComponent } from '../../item-content/item-content.component';


@Component({
	selector: 'app-check-iv-catheter-item',
	templateUrl: './check-iv-catheter-item.component.html',
	styleUrl: './check-iv-catheter-item.component.scss',
	standalone: true,
	imports: [ItemContentComponent,],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckIvCatheterItemComponent implements SheetItemComponentBase {

	@Input() row!: Row;
    @Input() startLabel!: string;
    @Input() endLabel!: string;

	constructor() {
	}
}
