import { UnitQuantity } from '../form-controls/unit-quantity/unit-quantity.control';
import { UnitValue } from '../form-controls/unit-value/unit-value.control';
import { UserSignature } from '../form-controls/user-signature/user-signature.control';
import { Action } from './aggregate-graph.types';
import { Method, PressureMode } from './sheet-instruction.types';

export enum HeartRateRhythm { RegularRhythm, SinusArrhythmia, Other }
export enum HeartMurmur { Present, NotPresent }
export enum SynchronousPulses { Yes, No }
export enum PulseQuality { Strong, Moderate, Poor, Absent }
export enum MucousMembranesColor { Congested, Cyanotic, Icteric, Normal, Pale, Pigmented }
export enum RespiratoryEffort { Normal, Abnormal }
export enum Palpation { Soft, Hard, Moderate }
export enum PlasmaColour { Normal, Haemolysed, Icteric, Lipaemic }
export enum VomitingOrRegurgitation { Yes, Unclear }
export enum PantingEffort { Normal, Abnormal }
export enum MethodOfCollection { Cysto, FreeCatch }
export enum Leukoctes { Negative, TwentyFive, SeventyFive, OneHundred }
export enum Nitrate { Negative, Positive }
export enum PH { Five, Six, Seven, Eight, Nine }
export enum Protein { Negative, Thirty, OneHundred, FiveHundred }
export enum Glucose { Normal, Fifty, OneHundred, ThreeHundred, OneThousand }
export enum Ketones { Negative, OnePlus, TwoPlus, ThreePlus }
export enum Urobilinogen { Normal, OnePlus, TwoPlus, ThreePlus, FourPlus }
export enum Bilirubin { Negative, OnePlus, TwoPlus, ThreePlus }
export enum Erythrocytes { Negative, OnePlus, TwoPlus, ThreePlus, FourPlus }
export enum Haemoglobin { Positive, OnePlus, TwoPlus, ThreePlus, FourPlus }
export enum MedicationDoseUnit { Ui = 'UI', MgKg = 'mg/kg', UgKg = 'μg/kg' }
export enum MedicationDoseUnitV2 { Iu = 'IU', MgKg = 'mg/kg', UgKg = 'μg/kg', MgM2 = 'mg/m2', IuM2 = 'IU/m2' }
export enum MedicationInfusionDoseUnit { MgKgHr = 'mg/kg/hr', UgKgHr = 'μg/kg/hr' }
export enum MedicationInfusionTotalDoseUnit { MgHr = 'mg/hr', UgHr = 'μg/hr' }
export enum MedicationAmountUnit { Ui = 'UI', Mg = 'mg', Ml = 'ml', Ug = 'μg', L = 'L', G = 'g', Kg = 'kg', Drop = 'drop', Graduation = 'graduation', Tablet = 'tablet', Capsule = 'capsule', Spray = 'spray', Pump = 'pump', Na = 'n/a' }
export enum MedicationAmountUnitV2 { Iu = 'IU', Mg = 'mg', Ml = 'ml', Ug = 'μg', L = 'L', G = 'g', Kg = 'kg', Drop = 'drop', Graduation = 'graduation', Tablet = 'tablet', Capsule = 'capsule', Spray = 'spray', Pump = 'pump', Na = 'n/a' }
export enum CatheterSite { LeftCephalic, RightCephalic, LeftSaphenous, RightSaphenous, LeftJugular, RightJugular }
export enum RespiratoryPatterns { Normal, Irregular, InspiratoryEffort, ConstantlyPanting }
export enum OtherMeasurementUnit { Mg = 'mg', Ml = 'ml', Ug = 'μg', L = 'L', G = 'g', Kg = 'kg', Percentage = '%', Ng = 'ng', Ms = 'ms', Secs = 'secs', Mins = 'mins', Hrs = 'hrs', UL = 'U/L', Degrees = '°C', mmHg = 'mmHg', HPF = '/HPF', NoUnits = 'No units', Other = 'Other' }
export enum BearScoreNoiseLevel { NotAudible, Mild, Moderate, Severe }
export enum BearScorePresence { NotPresent, Present }
export enum BladderSize { Small, Medium, Large }


export interface HeartRateAction extends Action {
	beatsPerMinute: UnitQuantity | null;
	rhythm: HeartRateRhythm | null;
	murmur: HeartMurmur | null;
	synchronousPulses: SynchronousPulses | null;
	pulseQuality: PulseQuality | null;
	pulseLocation: string | null;
}
export interface NoteAction extends Action {
}

export interface CleanKennelAction extends Action {
}

export interface PlaceIntravenousCatheterAction extends Action {
}

export interface CheckBedOrLitterTrayAction extends Action {
}

export interface FaecesSampleAction extends Action {
}

export interface BloodGlucoseAction extends Action {
	bloodGlucose: UnitQuantity | null;
}

export interface UrineAction extends Action {
	urinePassed: boolean | null;
}

export interface FlushIntravenousCatheterAction extends Action {
}

export interface CheckIdTagAction extends Action {
}

export interface CheckIvCatheterAction extends Action {
	datePlaced: string | null;
	day: number | null;
	catheterSite: CatheterSite | null;
}

export interface RespiratoryRateAction extends Action {
	breathsPerMinute: number | null;
	panting: boolean | null;
	effort: RespiratoryEffort | null;

}

export interface FaecesCheckAction extends Action {
	faecesPassed: boolean | null;
}
export interface UrinalysisAction extends Action {
	volume: UnitQuantity | null;
	methodOfCollection: MethodOfCollection | null;
	leukoctes: Leukoctes | null;
	nitrate: Nitrate | null;
	pH: PH | null;
	protein: Protein | null;
	glucose: Glucose | null;
	ketones: Ketones | null;
	urobilinogen: Urobilinogen | null;
	bilirubin: Bilirubin | null;
	erythrocytes: Erythrocytes | null;
	haemoglobin: Haemoglobin | null;
	urineSpecificGravity: number | null;
	sedimentExam: string | null;
}

export interface BloodGasAction extends Action {
	fO2: UnitQuantity | null;
	ph: number | null;
	pCO2: UnitQuantity | null;
	pO2: UnitQuantity | null;
	ctHb: UnitQuantity | null;
	sO2: UnitQuantity | null;
	hCtc: UnitQuantity | null;
	fCOHb: UnitQuantity | null;
	fMetHb: UnitQuantity | null;
	cKPlus: UnitQuantity | null;
	cNaPlus: UnitQuantity | null;
	cCa2Plus: UnitQuantity | null;
	cClMinus: UnitQuantity | null;
	cGlu: UnitQuantity | null;
	cLac: UnitQuantity | null;
	ctBil: UnitQuantity | null;
	cUrea: UnitQuantity | null;
	cCrea: UnitQuantity | null;
	pHT: number | null;
	pCO2T: UnitQuantity | null;
	cBaseEcfC: UnitQuantity | null;
	cHCO3EcfC: UnitQuantity | null;
}

export interface WaterDiaryAction extends Action {
	offered: UnitQuantity | null;
	drunk: UnitQuantity | null;
	refilled: UnitQuantity | null;
	totalConsumed: UnitQuantity | null;
}

export interface SoapAction extends Action {
	subjective: string | null;
	objective: string | null;
	assessment: string | null;
	plan: string | null;
}

export interface BloodPressureAction extends Action {
	systolic: UnitQuantity | null;
	mean: UnitQuantity | null;
	location: string | null;
	cuffSize: number | null;
	demeanor: number | null;
}

export interface CheckBladderSizeAction extends Action {
	size: UnitQuantity | null;
	palpation: Palpation | null;
}

export interface CheckBladderSizeActionV2 extends Action {
	size: BladderSize | null;
	scannedSize: UnitValue | null;
	palpation: Palpation | null;
}

export interface TotalSolidsAction extends Action {
	totalSolids: UnitQuantity | null;
	plasmaColour: PlasmaColour | null;
}

export interface VomitingOrRegurgitationAction extends Action {
	vomiting: VomitingOrRegurgitation | null;
	regurgitation: VomitingOrRegurgitation | null;
}

export interface MucousMembranesAndCrtAction extends Action {
	color: MucousMembranesColor | null;
	crt: number | null;
}

export interface WalkOutAction extends Action {
}

export interface PackedCellVolumeAction extends Action {
	pcv: UnitQuantity | null;
}

export interface WeightAction extends Action {
	weight: UnitQuantity | null;
}

export interface UrineOutputActionV2 extends Action {
	volume: UnitQuantity | null;
	rate: UnitQuantity | null;
}
export interface UrineOutputAction extends Action {
	volume: UnitQuantity | null;
}

export interface UrineSampleAction extends Action {
}

export interface MentationAction extends Action {
	mentationScore: UnitQuantity | null;
}

export interface TemperatureAction extends Action {
	temperature: UnitQuantity | null;
}

export interface FluidsInfusionAction extends Action {
	rate: UnitQuantity | null;
}

export interface FluidsInfusionActionV2 extends Action {
	rate: UnitValue | null;
}

export interface FluidsInfusionRedLabelAction extends Action {
	rate: UnitQuantity | null;
	approvalOne: UserSignature | null;
	approvalTwo: UserSignature | null;
}

export interface FluidsSubcutaneousAction extends Action {
	amountGiven: UnitQuantity | null;
}

export interface FluidsBolusAction extends Action {
	amountGiven: UnitQuantity | null;
}

export interface MedicationInfusionAction extends Action {
	rate: UnitQuantity | null;
}

export interface MedicationInfusionActionV2 extends Action {
	rate: UnitValue | null;
}

export interface MedicationAction extends Action {
	amountGiven: UnitQuantity | null;
}

export interface MedicationActionV2 extends Action {
	amountGiven: UnitQuantity | null;
}

export interface MedicationRedLabelAction extends Action {
	amountGiven: UnitQuantity | null;
	approvalOne: UserSignature | null;
	approvalTwo: UserSignature | null;
}

export interface MedicationRedLabelActionV2 extends Action {
	amountGiven: UnitQuantity | null;
	approvalOne: UserSignature | null;
	approvalTwo: UserSignature | null;
}

export interface PainScoreDogAction extends Action {
	inKennelQuiet: boolean | null;
	inKennelCrying: boolean | null;
	inKennelGroaning: boolean | null;
	inKennelScreaming: boolean | null;
	inKennelIgnoringWound: boolean | null;
	inKennelLookingAtWound: boolean | null;
	inKennelLickingWound: boolean | null;
	inKennelRubbingWound: boolean | null;
	inKennelChewingWound: boolean | null;
	onLeadNormal: boolean | null;
	onLeadLame: boolean | null;
	onLeadSlow: boolean | null;
	onLeadStiff: boolean | null;
	onLeadRefusesToMove: boolean | null;
	onPressureDoNothing: boolean | null;
	onPressureLookAround: boolean | null;
	onPressureFlinch: boolean | null;
	onPressureGrowl: boolean | null;
	onPressureSnap: boolean | null;
	onPressureCry: boolean | null;
	overallHappy: boolean | null;
	overallQuiet: boolean | null;
	overallIndifferent: boolean | null;
	overallNervous: boolean | null;
	overallDepressed: boolean | null;
	overallComfortable: boolean | null;
	overallUnsettled: boolean | null;
	overallRestless: boolean | null;
	overallHunched: boolean | null;
	overallRigid: boolean | null;
	totalScore: number | null;
}

export interface PainScoreCatAction extends Action {
	earAbsent: boolean | null;
	earModeratlyPresent: boolean | null;
	earMarkedlyPresent: boolean | null;
	orbitalAbsent: boolean | null;
	orbitalModeratlyPresent: boolean | null;
	orbitalMarkedlyPresent: boolean | null;
	muzzleAbsent: boolean | null;
	muzzleModeratlyPresent: boolean | null;
	muzzleMarkedlyPresent: boolean | null;
	whiskerAbsent: boolean | null;
	whiskerModeratlyPresent: boolean | null;
	whiskerMarkedlyPresent: boolean | null;
	headAbsent: boolean | null;
	headModeratlyPresent: boolean | null;
	headMarkedlyPresent: boolean | null;
	totalScore: number | null;
}

export interface PainScoreCatActionV2 extends Action {
	earAbsent: boolean | null;
	earModeratelyPresent: boolean | null;
	earMarkedlyPresent: boolean | null;
	orbitalAbsent: boolean | null;
	orbitalModeratelyPresent: boolean | null;
	orbitalMarkedlyPresent: boolean | null;
	muzzleAbsent: boolean | null;
	muzzleModeratelyPresent: boolean | null;
	muzzleMarkedlyPresent: boolean | null;
	whiskerAbsent: boolean | null;
	whiskerModeratelyPresent: boolean | null;
	whiskerMarkedlyPresent: boolean | null;
	headAbsent: boolean | null;
	headModeratelyPresent: boolean | null;
	headMarkedlyPresent: boolean | null;
	totalScore: number | null;
}

export interface PainScoreExoticAction extends Action {
	score: number | null;
}
export interface AuthorisationClinicianAction extends Action {
}
export interface HotCompressAction extends Action {
	area: string | null;
}
export interface CheckWoundAction extends Action {
}
export interface BandageCheckAction extends Action {
}
export interface BearScoreAction extends Action {
	respiratoryPatterns: RespiratoryPatterns | null;
	stertor: BearScoreNoiseLevel | null;
	stridor: BearScoreNoiseLevel | null;
	dyspnoea: BearScorePresence | null;
	cyanosis: BearScorePresence | null;
	totalScore: number | null;
}
export interface SchirmerTearTestLeftEyeAction extends Action {
	eye: UnitQuantity | null;
}

export interface SchirmerTearTestLeftEyeActionV2 extends Action {
	result: UnitQuantity | null;
}
export interface NutritionAction extends Action {
	amountGiven: UnitQuantity | null;
	amountEaten: UnitQuantity | null;
}

export interface NutritionActionV2 extends Action {
	amountGiven: UnitQuantity | null;
	amountEaten: UnitQuantity | null;
}

export interface OtherMeasurementAction extends Action {
	measurement: UnitQuantity;
}

export interface OtherAction extends Action {
}

export interface ToGoHomeAction extends Action {
	medicationsToGoHome: boolean | null;
	dischargeTimeOnRx: boolean | null;
	writeCheckPrintCris: boolean | null;
	dischargeDateOnDischargeReport: boolean | null;
	completePricing: boolean | null;
	filePricingWithAccounts: boolean | null;
	medicationsReady: boolean | null;
	medicationsCollected: boolean | null;
	returnUnwantedMedications: boolean | null;
	addTimeToRemoveIv: boolean | null;
	removeIvIfNecessary: boolean | null;
	collectPatientItems: boolean | null;
	checkWoundsDressingBandages: boolean | null;
	batheOrGroom: boolean | null;
	checkBusterCollar: boolean | null;
}
export interface EyeLubricationAction extends Action {
}
export interface HydrotherapyAction extends Action {
}
export interface IntraoccularPressureLeftEyeAction extends Action {
	iop: UnitQuantity | null;
}
export interface IntraoccularPressureRightEyeAction extends Action {
	iop: UnitQuantity | null;
}

export interface OfferWaterAction extends Action {
}

export interface OralHealthCareAction extends Action {
	suction: boolean | null;
	cleanCavity: boolean | null;
	cleanEquipment: boolean | null;
}

export interface PhysiotherapyAction extends Action {
}

export interface RemovePressureBandageAction extends Action {
}

export interface SchirmerTearTestRightEyeAction extends Action {
	eye: UnitQuantity | null;
}

export interface SchirmerTearTestRightEyeActionV2 extends Action {
	result: UnitQuantity | null;
}

export interface SkincareAction extends Action {
}

export interface FoodBowlOutAction extends Action {
}

export interface NilByMouthAction extends Action {
}

export interface SurgeryAction extends Action {
}

export interface DiagnosticsAction extends Action {
}

export interface EndoscopyAction extends Action {
}

export interface BloodSampleAction extends Action {
}

export interface StandardEcgAction extends Action {
}

export interface ContinuousEcgAction extends Action {
}

export interface TransferAction extends Action {
}

export interface TransferActionV2 extends Action {
	catheterCheck: boolean | null;
	surgicalSiteCheck: boolean | null;
	medication: boolean | null;
	scheduleAuthorisation: boolean | null;
}

export interface AuthorisationAction extends Action {
}

export interface NegativePressureWoundTherapyAction extends Action {
	checkDressing: boolean | null;
	checkPressureSetting: boolean | null;
	checkMode: boolean | null;
	pressure: UnitQuantity | null;
	pressureMode: PressureMode | null
}

export interface GlasgowComaScaleAction extends Action {
	motorActvityScoreOne: boolean | null;
	motorActvityScoreTwo: boolean | null;
	motorActvityScoreThree: boolean | null;
	motorActvityScoreFour: boolean | null;
	motorActvityScoreFive: boolean | null;
	motorActvityScoreSix: boolean | null;
	reflexesScoreOne: boolean | null;
	reflexesScoreTwo: boolean | null;
	reflexesScoreThree: boolean | null;
	reflexesScoreFour: boolean | null;
	reflexesScoreFive: boolean | null;
	consciousnessScoreOne: boolean | null;
	consciousnessScoreTwo: boolean | null;
	consciousnessScoreThree: boolean | null;
	consciousnessScoreFour: boolean | null;
	consciousnessScoreFive: boolean | null;
	consciousnessScoreSix: boolean | null;
	totalScore: number | null;
}

export interface PlateletCountAction extends Action {
	plateletCountClumpingGeneral: boolean | null;
	plateletCountClumpingFeatheredEdge: boolean | null;
	plateletCountClumpingBody: boolean | null;
	plateletCountAccurate: boolean | null;
	averageOne: number | null;
	averageTwo: number | null;
	averageThree: number | null;
	averageFour: number | null;
	averageFive: number | null;
	averageSix: number | null;
	averageSeven: number | null;
	averageEight: number | null;
	averageNine: number | null;
	averageTen: number | null;
	averageTotal: number | null;
	averageMultiplied: UnitQuantity | null;
	evaluationIncreased: boolean | null;
	evaluationAdequate: boolean | null;
	evaluationLow: boolean | null;
	evaluationVeryLow: boolean | null;
}

export interface OxygenAction extends Action {
	percentageGiven: UnitQuantity | null;
}

export interface OxygenActionV2 extends Action {
	percentageGiven: UnitValue | null;
}

export interface BusterCollarAction extends Action {
}

export interface ColdCompressAction extends Action {
	area: string | null;
	method: Method | null;
	timeKeptOn: string | null;
}

export interface ColdCompressActionV2 extends Action {
	timeKeptOn: string | null;
}

export interface IvtfSupplementationAction extends Action {
	dose: UnitQuantity | null;
}

export interface PtsAction extends Action {
}

export interface MedicationInfusionRedLabelAction extends Action {
	rate: UnitQuantity | null;
	approvalOne: UserSignature | null;
	approvalTwo: UserSignature | null;
}

export interface MedicationInfusionRedLabelActionV2 extends Action {
	rate: UnitValue | null;
	approvalOne: UserSignature | null;
	approvalTwo: UserSignature | null;
}

export interface EuthanasiaAction extends Action {
	timeOfDeath: string | null;
	euthanasedBy: string | null;
	priced: boolean | null;
}
