<app-item-content [instructionItemContentTemplate]="itemContentCustomInstructionBoxes" [row]="row" [startLabel]="startLabel" [endLabel]="endLabel" [showFrequency]="false"></app-item-content>
<ng-template #itemContentCustomInstructionBoxes let-instruction="instruction" let-overlaps="scheduledAndOverlapsSelectedDate">
	@if (overlaps) {
		@if (instruction.totalRate) {
			<app-instruction-box>{{ instruction.totalRate.quantity }} {{ instruction.totalRate.unit }}</app-instruction-box>
		}
		@if (instruction.medicationRoute !== null) {
			<app-instruction-box>{{ instruction.medicationRoute | medicationRouteName }}</app-instruction-box>
		}
	}
</ng-template>