import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SheetSlotComponentBase } from 'src/app/services/sheets-renderer.service';
import { SchirmerTearTestRightEyeActionV2 } from 'src/app/types/sheet-action-aggregate-graph.types';
import { TickValueComponent } from '../../tick-value/tick-value.component';

@Component({
	selector: 'app-schirmer-tear-test-right-eye-slot',
	templateUrl: './schirmer-tear-test-right-eye-slot-v2.component.html',
	styleUrl: './schirmer-tear-test-right-eye-slot-v2.component.scss',
	standalone: true,
	imports: [TickValueComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SchirmerTearTestRightEyeSlotComponentV2 implements SheetSlotComponentBase {

	action!: SchirmerTearTestRightEyeActionV2;
	value!: string | null;

	@Input('action') set updateAction(action: SchirmerTearTestRightEyeActionV2 | null) {
		this.action = action as SchirmerTearTestRightEyeActionV2;
		this.value = this.action.result?.quantity?.toString() ?? null;
	}

	constructor() {
	}
}