import { Injectable } from '@angular/core';
import { exhaustMap, tap, withLatestFrom } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as clientActions from './client.actions';
import { AlertService } from 'src/app/services/alert.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { invalidVersionAlertDisplayInitiated } from './client.selectors';
import { of } from 'rxjs';
import { LoadingOverlayService } from 'src/app/services/loading-overlay.service';

@Injectable()
export class ClientEffects {

	constructor(
		private actions$: Actions,
		private store: Store<AppState>,
		private alertService: AlertService,
		private loadingOverlayService: LoadingOverlayService) {
	}

	showInvalidVersionAlertRequested$ = createEffect(() =>
		this.actions$.pipe(
			ofType(clientActions.showInvalidVersionAlertRequested),
			withLatestFrom(this.store.pipe(select(invalidVersionAlertDisplayInitiated))),
			exhaustMap(([payload, invalidVersionAlertDisplayInitiated]) => {
				if (invalidVersionAlertDisplayInitiated) {
					return of(clientActions.showInvalidVersionAlertSkipped());
				}
				else {
					return of(clientActions.showInvalidVersionAlertInitiated({ requiredVersion: payload.requiredVersion }));
				}
			})
		)
	);

	showInvalidVersionAlertInitiated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(clientActions.showInvalidVersionAlertInitiated),
			tap(payload => {
				this.alertService.displayBlockingAlert(
					'Version update',
					`Version '${payload.requiredVersion}' is available. Please reload the app.`,
					'Reload',
					() => {
						window.location.reload();
						return true;
					}
				);
				this.loadingOverlayService.waitThenRemoveAllOverlays();
			})
		),
		{ dispatch: false }
	);
}