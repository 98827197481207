<app-heading class="app-margin-horizontal" background="primary-3" size="small">{{ item?.name }}</app-heading>
<ng-content></ng-content>
<app-heading class="margin-top app-margin-horizontal" background="primary-3" size="small">Task</app-heading>
<div class="form-section app-margin-horizontal app-padding">
	<ng-content select="[taskControls]"></ng-content>
	<div class="form-group">
		@if (slot?.action?.isSkipped) {
			<app-item class="rounded-border-box no-select" columnar @enterLeave>
				<app-item-label>Reason for skipping</app-item-label>
				<app-item-value [value]="slot?.action?.skippedReason" multiLine></app-item-value>
			</app-item>
		}
		<app-item class="rounded-border-box no-select">
			<app-item-label [class.not-actioned]="!slot?.action?.isComplete && !slot?.action?.isSkipped">Actioned by</app-item-label>
			<app-item-value [value]="actionedByString"></app-item-value>
		</app-item>
	</div>
</div>