import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SelectControl, SelectItem } from 'src/app/form-controls/select/select.control';
import { TextInputControl } from 'src/app/form-controls/text-input/text-input.control';

import { SheetActionControlsBase } from 'src/app/services/sheets-renderer.service';
import { Row, Slot } from 'src/app/types/aggregate-graph.types';
import { BearScoreAction, BearScoreNoiseLevel, BearScorePresence, RespiratoryPatterns } from 'src/app/types/sheet-action-aggregate-graph.types';
import { ActionFormComponent } from '../../action-form/action-form.component';
import { CommonActionControlsComponent } from '../../common-action-controls/common-action-controls.component';

interface BearScoreActionFormGroup {
	respiratoryPatterns: FormControl<RespiratoryPatterns | null>;
	stertor: FormControl<BearScoreNoiseLevel | null>;
	stridor: FormControl<BearScoreNoiseLevel | null>;
	dyspnoea: FormControl<BearScorePresence | null>;
	cyanosis: FormControl<BearScorePresence | null>;
	totalScore: FormControl<number | null>;
}

@Component({
	selector: 'app-bear-score-action-controls',
	templateUrl: './bear-score-action-controls.component.html',
	styleUrls: ['../../../common.scss', './bear-score-action-controls.component.scss'],
	standalone: true,
	imports: [CommonActionControlsComponent, ReactiveFormsModule, TextInputControl, SelectControl, ActionFormComponent],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BearScoreActionControlsComponent implements SheetActionControlsBase, OnInit {

	slot!: Slot | null;
	action!: BearScoreAction;

	@Input('slot') set updateSlot(slot: Slot | null) {
		this.slot = slot;
		this.action = slot?.action as BearScoreAction;
	}

	@Input() form!: FormGroup<BearScoreActionFormGroup>;
	@Input() item!: Row | null;

	respiratoryPatterns: SelectItem[] = [
		{ text: 'Normal (0)', value: RespiratoryPatterns.Normal },
		{ text: 'Irregular (1)', value: RespiratoryPatterns.Irregular },
		{ text: 'Inspiratory Effort (2)', value: RespiratoryPatterns.InspiratoryEffort },
		{ text: 'Constantly Panting (5)', value: RespiratoryPatterns.ConstantlyPanting }
	];

	bearScoreNoiseLevels: SelectItem[] = [
		{ text: 'Not audible (0)', value: BearScoreNoiseLevel.NotAudible },
		{ text: 'Mild (1)', value: BearScoreNoiseLevel.Mild },
		{ text: 'Moderate (2)', value: BearScoreNoiseLevel.Moderate },
		{ text: 'Severe (5)', value: BearScoreNoiseLevel.Severe }
	];

	bearScorePresence: SelectItem[] = [
		{ text: 'Not Present (0)', value: BearScorePresence.NotPresent },
		{ text: 'Present (5)', value: BearScorePresence.Present }
	];

	constructor(private formBuilder: FormBuilder) {
	}

	ngOnInit(): void {
		this.form.addControl('respiratoryPatterns', this.formBuilder.control(this.action?.respiratoryPatterns ?? null));
		this.form.addControl('stertor', this.formBuilder.control(this.action?.stertor ?? null));
		this.form.addControl('stridor', this.formBuilder.control(this.action?.stridor ?? null));
		this.form.addControl('dyspnoea', this.formBuilder.control(this.action?.dyspnoea ?? null));
		this.form.addControl('cyanosis', this.formBuilder.control(this.action?.cyanosis ?? null));
		this.form.addControl('totalScore', this.formBuilder.control(this.action?.totalScore ?? null));
	}
}