import { createReducer, on } from '@ngrx/store';
import { SearchedUser } from 'src/app/services/users.service';
import { VisitParticipationType } from 'src/app/types/aggregate-graph.types';
import * as authActions from '../auth/auth.actions';
import * as userSearchActions from './user-search.actions';

export interface State {
	queryTerm: string;
	participationTypeFilters: VisitParticipationType[];
	newSearchInProgress: boolean;
	searchResults: SearchedUser[];
	resultsPerAppend: number;
	appendsRetrieved: number;
	lastResultsRetrievedCount: number;
}

export const initialState: State = {
	queryTerm: '',
	participationTypeFilters: [],
	newSearchInProgress: false,
	searchResults: [],
	resultsPerAppend: 50, // this is a constant value, change this if more results should be displayed per fetch. Be careful setting it too low, if one page doesn't fill the scrollable parent, the infinite scroll will not fire
	appendsRetrieved: 0,
	lastResultsRetrievedCount: 0
}

export const userSearchReducer = createReducer(
	initialState,
	on(userSearchActions.newSearchRequested, (state, { queryTerm, participationTypeFilters }) => ({
		...state,
		queryTerm: queryTerm,
		participationTypeFilters: participationTypeFilters,
		newSearchInProgress: true,
		searchResults: [],
		appendsRetrieved: 0,
		lastResultsRetrievedCount: 0
	})),
	on(userSearchActions.newSearchSucceeded, (state, { searchResults }) => ({
		...state,
		newSearchInProgress: false,
		searchResults: searchResults,
		lastResultsRetrievedCount: searchResults.length
	})),
	on(userSearchActions.newSearchFailed, () => initialState),
	on(userSearchActions.appendResultsSucceeded, (state, { searchResults }) => ({
		...state,
		searchResults: [...state.searchResults, ...searchResults],
		appendsRetrieved: state.appendsRetrieved + 1,
		lastResultsRetrievedCount: searchResults.length
	})),
	on(userSearchActions.appendResultsFailed, () => initialState),
	on(userSearchActions.resultsCleared, () => initialState),
	on(userSearchActions.userSelectionFinished, () => initialState),
	on(authActions.clearStateRequested, () => initialState)
);