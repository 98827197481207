import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { CoercionService } from 'src/app/services/coercion.service';

@Component({
	selector: 'app-informative',
	templateUrl: './informative.component.html',
	styleUrl: './informative.component.scss',
	standalone: true,
	imports: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InformativeComponent {

	private isSmall: boolean = false;
	@Input()
	get small() {
		return this.isSmall;
	}
	set small(value: any) {
		this.isSmall = this.coercionService.coerceToBoolean(value);
	}
	@HostBinding('class.small') get isSmallComponent() { return this.small; }

	constructor(private coercionService: CoercionService) {
	}
}