import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ItemLabelComponent } from 'src/app/components/item-label/item-label.component';
import { debounceDuration, ItemTextAreaComponent } from 'src/app/components/item-textarea/item-textarea.component';
import { ItemValueComponent } from 'src/app/components/item-value/item-value.component';
import { ItemComponent } from 'src/app/components/item/item.component';
import { CoercionService } from 'src/app/services/coercion.service';

@Component({
	selector: 'app-textarea',
	templateUrl: './textarea.control.html',
	styleUrl: './textarea.control.scss',
	standalone: true,
	imports: [ReactiveFormsModule, ItemComponent, ItemLabelComponent, ItemTextAreaComponent, ItemValueComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: TextAreaControl,
		multi: true
	}]
})
export class TextAreaControl implements ControlValueAccessor {

	@Input() readOnly: boolean = false;
	@Input() label: string = '';
	@Input() placeholder: string = '';

	alwaysDisplayValue: boolean = true; // there are some use cases where the value should not be shown when empty and read only
	@Input() set hideEmptyValue(value: any) { this.alwaysDisplayValue = !this.coercionService.coerceToBoolean(value) }

	debounceDuration: number = 0;
	@Input() set debounce(value: any) { this.debounceDuration = this.coercionService.coerceToBoolean(value) ? debounceDuration : 0 };

	@ViewChild(ItemTextAreaComponent) itemTextAreaComponent!: ItemTextAreaComponent;

	constructor(private changeDetectorRef: ChangeDetectorRef, private coercionService: CoercionService) {
	}

	value: string = '';

	writeValue(value: string | null): void {
		this.value = value ?? '';
		
		// this is required for the view to update correctly (it's a general approach recommended by Angular devs using ChangeDetectionStrategy.OnPush: https://github.com/angular/angular/issues/21780)
		this.changeDetectorRef.markForCheck();
	}

	private onChange: (value: string) => void = () => { };

	registerOnChange(fn: (value: string) => void): void {
		this.onChange = fn;
	}

	private onTouched: () => void = () => { };

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(disabled: boolean): void {
	}

	valueChanged(value: string) {
		this.value = value;
		this.onChange(this.value);
	}

	itemFocused() {
		this.onTouched();
	}

	controlClicked() {
		if (!this.readOnly) this.itemTextAreaComponent.focusInput();
	}
}